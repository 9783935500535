.soqa_preview{
	$font-main: 'Varela', sans-serif;
	$color-main: rgb(10,10,10);
	$color-main-merchant: rgb(128,0,128);
	$color-vivid: rgb(128,0,128);
	$color-text-question: rgb(103,45,145);
	$color-highlight: rgb(234,14,234);
	$color-avatar-text: rgb(255,255,255);
	$color-background: rgb(255,255,255);
	$color-background-alt: rgb(251,251,251);
	$color-background-input: rgb(255,255,255);
	$color-input-placeholder: rgb(120,120,120);
	$color-search-input: rgb(102,102,102);
	$color-search-border: rgb(120,120,120);
	$color-question: #f1f1f1;
	$color-button-bright-side: rgb(142, 96, 252);
	$color-button-dark-side: rgb(78, 44, 187);
	$color-button-text: rgb(255,255,255);
	$color-answer-button-background: rgb(246,247,249);
	$color-button-bright-side: rgb(142, 96, 252);
	$color-button-dark-side: rgb(78, 44, 187);
	$color-button-text: rgb(255,255,255);
	$color-answer-button-border: rgb(228,234,243);
	$color-answer-button-highlight: rgb(204,204,204);
	$color-answer-button-text: rgb(0,0,0);

	$break-small: 600px;

	margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: content-box;

	// increase specificity with a triple class
	.rr.root.widget { /* theme.default */
	  font-family: $font-main;
	  font-weight: 500;
	  padding: .5em;
	  font-size: 16px;
	  background-color: $color-background;
	  color: $color-main;
	  -webkit-font-smoothing: antialiased;

	  div * {
	  	font-family: 'Varela', sans-serif;
	  }

	  .clear {
	    clear: both;
	  }

	  [contenteditable=true]:empty:before{
	    content: attr(placeholder);
	  }

	  .WidgetPreviewCard { /* theme.default */
	    background-color: $color-background;
	  }

	  .loading img {
	    display:block;
	    max-width: 10em;
	    margin: 1em auto;
	  }

	  .headerCard {
	  }

	  .soqa_header {
	    margin-bottom: .9em;

	    .title{ /* theme.calltoaction */
	      font-size: 1.3em;
	      font-weight: 500;
	      color: $color-vivid;
	      margin-bottom: .2em;
	      text-align: center;
	    }

	    .poweredBy {
	      font-size: .6em;
	      text-align: center;
	    }

	    .soqa_row {
	      font-size: inherit;
	      color: $color-main;
	      font-weight: 500;
	      vertical-align: sub;
	      box-sizing: border-box;
	      z-index: 100000;
	    }

	    .rRLogo {
	      height: 1.3em;
	      margin-left: .4em;
	      font-size: 12px;
	      display: inline-block;
	      background: transparent;
	    }
	  }

	  .searchAsk {
	    background-color: $color-background-alt;
	    position: relative;
	    font-weight: normal;

	    .input {
	      border: 2px solid $color-search-border;
	      border-radius: 2em;
	      box-shadow: rgba(0, 0, 0, 0.04) 0 0 3.5em 0;
	      padding: .2em .7em;
	      display: block;
	      background-color: $color-background-input;
	      font-size: .9em;
	    }

	    .input:empty {
	      color: $color-input-placeholder;
	      overflow-y: hidden;
	    }

	    .searchRow {
	      margin-top: 1em;
	      position:relative;
	      vertical-align: -webkit-baseline-middle;
	      color: $color-search-input;

	      .searchField {
	        width: 88%;
	        box-sizing: border-box;
	        white-space: nowrap;
	        overflow:hidden;
	        text-align: left;
	        font-size: .9em;
	        margin-bottom: 1em;
	      }

	      .clearSearchButton { /* theme.button */
	        display: inline-block;
	        position: absolute;
	        top: .1em;
	        right: 0em;
	        width: 1.7em;
	        height: 1.7em;
	        font-size: 1em;
	        color: white;
	        font-weight: 600;

	        -webkit-touch-callout: none;
	        -webkit-user-select: none;
	        -khtml-user-select: none;
	        -moz-user-select: none;
	        -ms-user-select: none;
	        user-select: none;
	        box-sizing: border-box;
	        background-color: $color-vivid;
	        border: 1px solid $color-vivid;
	        border-radius: 2.8em;
	      }
	      .clearSearchButton:focus,
	      .clearSearchButton:hover {
	        outline-color: $color-highlight;
	        border-color: $color-highlight;
	        background-color: $color-highlight;
	      }
	    }

	    .askQuestionRow {
	      position: absolute;
	      right: 0;
	      display: inline-block;
	      padding-top: .3em;
	      height: 100%;
	      justify-content: center;
	      align-items: center;

	      .addQuestionButton {
	        width: 100px;
	        font-size: 1em;
	        font-weight: 600;
	        display: inline-block;
	      }
	    }
	  }

	  .questionList {
	    .questionNav { /* theme.calltoaction */
	      display: grid;
	      align-items: baseline;
	      grid-template-columns: 2fr 1fr;

	      text-align: left;
	      color: $color-vivid;
	      font-weight: 500;

	      .statsLabel {
	        display: inline-block;
	        font-size: .9em;
	      }

	      .statsLabel:after {
	        font-size: 1.1em;
	        content: '\2014';
	        margin: 0 .3em 0 .3em;
	        color: #a7a7a7;
	      }

	      .stats {
	        display: inline-block;
	        box-sizing: border-box;
	        width: auto;
	        text-align: left;
	        font-size: .9em;
	        margin: 0 .2em 0 0;
	      }

	      .pager {
	        margin-top: 0.4em;
	        font-size: 1.2em;
	        margin: 0.4em 0 0.7em;
	      }

	      .askQuestionRow {
	        text-align: -webkit-right;
	        margin-bottom: 1em;

	        .addQuestionButton { /* theme.button */
	          -webkit-touch-callout: none;
	          -webkit-user-select: none;
	          -khtml-user-select: none;
	          -moz-user-select: none;
	          -ms-user-select: none;
	          user-select: none;
	          box-sizing: border-box;
	          font-size: 0.7em;
	          font-weight: 500;
	          background-color: $color-vivid;
	          color: white;
	          border: 1px solid $color-vivid;
	          border-radius: 3em;
	          height: 2.6em;
	          display: block;
	          width: 8em;
	        }

	        .addQuestionButton:focus,
	        .addQuestionButton:hover {
	          outline-color: $color-highlight;
	          border-color: $color-highlight;
	          background-color: $color-highlight;
	        }
	      }
	    }
	    ul {
	      list-style-type: none;
	      padding: 0;
	    }

	    .questionBlock {
	      .question {
	        background-color: $color-question;
	        border-radius: 5px;

	        .text {
	          position: relative;
	          padding: 1em 1em .5em 5.5em;
	          min-height: 3.5em;
	          text-align: left;
	          display: block;
	          font-weight: 800;
	          font-size: 1.4em;
	          color: $color-text-question;

	          img {
	            max-width: 2.5em;
	            max-height: 2.5em;
	            position:absolute;
	            border-radius: 50%;
	            top: .75em;
	            left: 1em;
	          }
	          .avatarFake {
	            border-radius: 50%;
	            position: absolute;
	            top: .15em;
	            left: 0;
	            color: $color-avatar-text;
	            width: 1.5em;
	            height: 1.5em;
	            display: flex;
	            justify-content:center;
	            align-items: center;
	            font-size: 3em;
	          }
	        }
	        .text:before {
	          content: 'Q: ';
	        }
	      }

	      .byLine {
	        font-size: 0.6em;
	        text-align: right;
	        color: rgb(102,102,102);
	        font-weight: 500;
	        .user {
	          font-weight: bold;
	          color: $color-vivid;
	        }
	        .seller {
	          font-weight: bold;
	          color: $color-vivid;
	        }
	      }

	      .promoted {
	        background: #f8f8f8;
	      }

	      .answerList {
	        ul {
	          list-style-type: none;

	          li {
	            margin: .8em 0;
	            display: block;
	          }
	        }
	        .answer {
	          margin-bottom: 1.6em;
	          font-size: 1em;
	          line-height: 1.5em;

	          .answerBody {
	            display: flex;
	          }

	          .avatar {
	            float: left;
	            flex: 10em;
	          }

	          .answerData {
	            float: left;
	          }

	          img {
	            float: left;
	            border-radius: 50%;
	            max-height: 3em;
	            max-width: 3em;
	            margin-right: .4em;
	            margin-bottom: .4em;
	          }

	          .avatarFake {
	            border-radius: 50%;
	            color: $color-avatar-text;
	            float: left;
	            width: 2em;
	            height: 2em;
	            display: flex;
	            justify-content:center;
	            align-items: center;
	            font-size: 2.3em;
	            font-weight: 700;
	            margin-right: .4em;
	            margin-bottom: .4em;
	          }

	          .best { /* theme.calltoaction */
	            color: $color-vivid;
	            display: block;
	          }

	          .text { /* theme.default */
	            color: $color-main;
	            display: inline;
	          }
	          .text:before {
	            font-weight: bold;
	            content: 'A: ';
	          }
	          .byLine {
	            text-align: right;
	            padding-top: .4em;
	            .user {
	              font-weight: bold;
	            }
	            .seller { /* theme.calltoaction */
	              font-weight: bold;
	            }
	          }
	        }
	        .sellerAnswer {
	          .text {
	            color: $color-main-merchant;
	          }
	        }

	        .labels {
	          display: flex;
	          margin-bottom: 10px;

	          .retailerAnswerLabel,
	          .verifiedBuyerLabel {
	            padding: 0.7em 1.5em 0.7em 1.5em;
	            height: fit-content;
	            width: auto;
	            background: linear-gradient(to right,$color-button-bright-side,$color-button-dark-side);
	            color: $color-button-text;
	            font-size: 0.9em;
	            display: inline-block;
	            user-select: none;
	            box-sizing: border-box;
	            border-radius: 3em;
	            text-align: center;
	            font-weight: 500;
	          }
	      }

	        .answerFooter {
	          text-align: center;

	          .pager {
	            margin-top: .7em;
	          }
	          .voted {
	          }
	        }
	      }

	      .byLine {
	        font-size: .8em;
	      }

	      .best {
	        padding-top: 0.5em;
	        margin-left: 1em;
	      }
	    }
	  }

	  .highlight {
	    display: inline;

	    .mark { /* theme.highlight */
	      font-weight: bold;
	      background-color: $color-highlight;
	      color: white;
	    }

	    .seller {
	      color: $color-highlight;
	      font-weight: bold;
	    }
	  }

	  .voteQuestion,
	  .link {
	    color: $color-answer-button-text;
	    font-weight: 500;
	    display: block;
	    font-size: 1.8em;
	    margin: .5em 0 1em 0;
	    cursor: pointer;
	    padding: .8em;
	    line-height: 1.6em;
	    position: relative;
	    border: 1px solid $color-answer-button-border;
	    background-color: $color-answer-button-background;
	    border-radius: 2em;

	    position: relative;

	    img {
	      float: left;
	      top: .2em;
	      position: relative;
	      max-width: 1.2em;
	      max-height: 1.2em;
	      margin-right: .3em;
	    }

	    b { /* theme.calltoaction */
	      color: $color-vivid;
	    }
	  }
	  .voteQuestion:hover,
	  .link:hover {
	    transition: background-color 0.5s ease;
	    background-color: $color-answer-button-highlight;
	  }

	  .best {
	    padding-top: 0.5em;
	    margin-left: 1em;
	  }
	}







	/* ---- Adjustments for wider screens ---- */


	@media screen and (min-width: $break-small) {
	// increase specificity with a triple class
	.rr.root.widget {

	    .WidgetPreviewCard { /* theme.default */
	      padding: .8em;
	      margin: .8em;
	    }

	    .loading img {
	      max-width: 5em;
	      margin: 1em auto;
	    }

	    .soqa_header {
	      .title {
	        font-size: 2em;
	        text-align: left;
	      }
	      .poweredBy {
	        font-size: .8em;
	        text-align: left;
	      }

	      .rRLogo {
	        height: 1.8em;
	        margin-left: .4em;
	        font-size: 12px;
	        display: inline-block;
	      }
	    }

	    .searchAsk { 
	      margin-bottom: 3em;
	      padding: 2em 0;

	      .input { /* theme.input */
	        padding: .7em 2em .7em 3em;
	        font-size: 1.2em;
	      }

	      .searchRow {
	        margin-top: 0;
	        width: auto;

	        .searchField {
	          width: 90%;
	          white-space: nowrap;
	          font-size: 1.2em;
	        }

	        .clearSearchButton {
	          right: 0.1em;
	          font-size: 2em;
	          top: 0.05em;
	        }
	      }

	      .askQuestionRow {
	        padding-top: .3em;
	  
	        .addQuestionButton {
	          width: 100px;
	          font-size: 1em;
	        }
	      }
	    }

	    .questionList {

	      .questionNav {
	        font-size: 2.0em;
	        text-align: left;
	        margin-bottom: 2em;

	        .pager {
	          float:right;
	          margin: 0;
	        }

	        .askQuestionRow {

	          .addQuestionButton { /* theme.button */
	            width: 10em;
	          }
	        }
	      }

	      .questionBlock {
	        .question {
	          text-align: left;

	          .byLine {
	            text-align: right;
	            line-height: 2rem;
	            font-size: .8rem;
	          }
	          .text {
	            font-size: 1.4em;
	          }
	        }
	        .answerList {
	          .answer {
	            margin-bottom: 1em;
	            min-height: 5em;

	            .text {
	              font-size: 1em;
	            }

	            .byLine {
	            }
	            .text {
	            }
	            .text.highlight:before {
	              font-weight: bold;
	              content: 'A: ';
	              color: $color-highlight;
	            }
	          }

	          .answerFooter {
	            text-align: left;

	            .pager {
	              font-size: 1.2em;
	              margin-left: 1em;
	              margin-top: .1em;
	            }
	          }
	        }
	      }
	    }

	    .voteQuestion,
	    .link {
	      font-size: 1.1em;
	      display: inline-block;
	      box-sizing: content-box;
	      margin-left: 2em;
	      width: auto;
	      background-color: white;
	      border: 1px solid #2f2b32;
	    }

	  }
	}

	/*@media screen and (max-width: $break-small) {
	  .rr.root.widget {
	    font-size: 6px
	  }
	}*/
}