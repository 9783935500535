.reactToolTipCaret{
    box-shadow: none;
    position: fixed;
    z-index: 9002;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);

    &:before {
        background-color: $Coal;
        width: 1rem;
        height: 1rem;
        bottom: 1px;
        position: absolute;
        content: '';
    }
}

.reactToolTip {
    display: inline;

    span{
        cursor: pointer;
        padding-right: 10px;
    }

    .toolTipContent{
        box-shadow: 0 0 10px 1px rgba(0,0,0,0.5);
        background: $Salt;
        color: $SoftBlack;
        position: absolute;
        border: 1px solid $Salt;
        border-radius: 3px;
        padding: 10px;
        max-width: 750px;
        min-width: 350px;
        min-height: 100px;
        z-index: 100000;
        font-size: 18px;

        p{
            margin: 0;
        }

        h3{
            margin-bottom: 10px;
            font-size: 14px;
        }
    }
}

