&--respondent {
  padding: rem(0) rem(20);
  border-top: rem(1) solid $Quartz;
  border-bottom: rem(1) solid $Quartz;
  .tabs {
    border-top: rem(1) solid $Quartz;
    padding: 0;
    margin-left: 1em;

    &__tabitem + .tabs__tabitem {
      margin: 0 0 rem(-1) 0;
    }
    &__tabitem {
      @extend .noselect-all;
      display: inline-block;
      padding: rem(12.5) rem(10) rem(7.5);
      font-family: $f-app-font-regular;
      font-size: rem(11);
      text-transform: uppercase;
      text-align: center;
      border-right: rem(1) solid $Quartz;
      cursor: pointer;
      @include transition;
      @include breakpoint(palm) {
        margin: 0;
        width: 100%;
        text-align: center;
      }
      &:first-of-type{
        border-left: rem(1) solid $Quartz;
      }
      &:hover {
        font-family: $f-app-font-bold;
        color: $Jellyfish;
        @include transition;
      }
      &:after {
        display: block;
        content: attr(title);
        height: rem(1);
        font-family: $f-app-font-bold;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      }
      &--selected {
        font-family: $f-app-font-bold;
        cursor: default;
        border-bottom: rem(1) solid $Salt;
        color: $Obsidian;
        box-shadow: 0 rem(5) 0 #fff, 0 rem(-2) rem(8) rgba(0, 10, 30, 0.25);
        &:hover{
          color: $Obsidian;
        }
      }
      &--disabled {
        color: $c-tabs-color-disabled;
        border-bottom: none;
        &:hover {
          font-family: $f-app-font-regular;
          border-bottom: none;
          cursor: default;
        }
      }
      .content{
        overflow:hidden;
        display:block;
        &:after {
          display: block;
          content: attr(content);
          height: rem(1);
          font-family: $f-app-font-bold;
          color: transparent;
          overflow: hidden;
          visibility: hidden;
        }
      }
    }
  }
}
