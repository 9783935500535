.upsellAlert{
  max-width: 1000px;
  border-radius: 20px;
  margin-left: 15px;
  padding: 25px;
  .flex-centered{
    display: flex;
    align-items: center;
  }
  h1{
    font-size: 1.53rem;
    font-weight: bold;
  }
  p{
    font-size: 1.5em !important;
    line-height: 1.3em !important;
  }
  h1, p, a{
    font-family: "ProximaNova-Bold",Helvetica,Arial,sans-serif;;
    color: white !important;
  }
  a{
    font-size: 1.4em;
    text-decoration: underline !important;
  }
  li{
    line-height: 3.5em;
  }
}