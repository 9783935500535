.page-menu {

  &__title {
    color: $Coal;
    font-size: 1.5rem;
    padding: 1rem 1.5rem;
    letter-spacing: rem(-1);
    border-bottom: rem(1) solid $Quartz;
    .page-menu--borderless & { border-bottom: none; }
    .page-menu--inverse & { color: $Ash }
  }

  &__labels { overflow: hidden; }
  &__label {
    display: inline-block;
    padding: 1rem 2rem;
    width: 100%;
    .page-menu--inverse & { color: $Ash }
    .page-menu--navigation & {
      padding:0 3rem;
    }

    &--subpage {
      color: $Coal;
      font-size: rem(14) !important;
      padding: 0rem 2rem;
      margin-left: 2.4rem;

      &-active, &:hover {
        color: $Obsidian !important;
        .page-menu--inverse & { color: $Salt !important; }
      }
    }

    &__text {
      width: calc(100% - 30px);
      padding-left: 2rem;
    }

    &-container {
      border-bottom: rem(1) solid $Quartz;
      cursor: pointer;
      min-height: 5rem;
      transition: all 250ms ease-out;
      position: relative;
      &:hover {
        background-color: $HalfQuartz;
        .page-menu--no-highlight & { background-color: transparent;}
      }

      &--active, &:hover {
        background-color: $HalfQuartz;
        transition: all 250ms ease-out;
        color:$Salt;
        .page-menu__label--page, .page-menu__icon {
          color: $Obsidian;
          .page-menu--inverse & { color: $Salt ; }
        }
        .page-menu--no-highlight & {
          background-color: transparent;
        }
      }

      .page-menu--borderless & { border-bottom: none; }
    }
  }


  &__bar {
    transition: all 250ms ease-out;
    //background-color: $HalfQuartz;
    position: absolute;
    cursor: default;
    height: rem(50);
    width: 100%;
    //z-index: -1;

    &::after {
      content: '';
      position: absolute;
      width: rem(4);
      height: 100%;
      background-color: $Jellyfish;
      left: 0;
      .page-menu--no-bar & {
        display:none;
      }
    }
  }

  &__icon {
    font-size: 1.8rem;
    margin-top: .25rem;
    color: $Slate;
    .page-menu--inverse & { color: inherit; }
    &--active {
      color: $Jellyfish;
      .page-menu--inverse & { color: $Salt; }
    }
  }
  &__svg-icon {
    margin-top:0;
  }
  &--thick {
    .page-menu {

      &__label {
        display: inline-block;
        padding: 0 2rem;
        width: 100%;
        height:4rem;
        line-height:4rem;

        &__text {
          font-size:1.6rem;
          width: calc(100% - 40px);
          white-space: nowrap;
          opacity: 1;
          transition: all 0.5s;
        }
        &-container {
          cursor: pointer;
          transition: all 250ms ease-out;
          border-bottom: none;
          padding-bottom:2rem;
          height:6rem;
          &--active, &:hover {
            transition: all 250ms ease-out;
          }
        }
      }
    }
  }
}
