.widget_preview{
	$font-main: 'Roboto', serif;
	$color-main: rgb(102,102,102);
	$color-vivid: rgb(128,0,128);
	$color-gray-title: rgb(78, 74, 74);
	$color-highlight: rgb(234,14,234);
	$color-rr-main: #af4499;
	$color-pr-purple: #672d91;
	$color-pr-blue: #0f163e;
	$color-avatar-text: rgb(255,255,255);
	$color-background: rgb(255,255,255);
	$color-background-alt: rgb(251,251,251);
	$color-background-input: rgb(255,255,255);
	$color-input-placeholder: rgb(230,230,230);
	$color-search-border: rgb(230,230,230);
	$color-answer-button-background: rgb(246,247,249);
	$color-answer-button-border: rgb(228,234,243);
	$color-answer-button-highlight: rgb(204,204,204);
	$color-answer-button-text: rgb(0,0,0);
	$color-rr-green: #6CC94F;

	$break-small: 800px;

	.clear {
		clear: both;
	}
	@import 'transforms';


	// increase specificity with a triple class
	.rr.root.widget { /* theme.default */
		font-family: 'Roboto', serif;
		font-weight: 300;
		padding: .5em;
		font-size: 12px;
		//background-color: $color-background;
		color: $color-main;
		-webkit-font-smoothing: antialiased;

		div * {
			font-family: 'Roboto', serif;
		}
		.loading img {
			display:block;
			max-width: 10em;
			margin: 1em auto;
		}

		.progressBarContainer{
		  margin-bottom: 10px;
		  overflow: auto;

		  .label, .leftLabel{
		    font-weight: 600;
		    font-size: 0.8rem;
		  }

		  .leftLabel{
		    float: left;
		    font-size: 18px;
		  }

		  .rightLabel{
		    width: 10%;
		    font-weight: 600;
		    font-size: 0.9rem;
		    float: right;
		    text-align: left;
		  }

		  .progressBar{
		    margin-bottom: 8px;
		    margin-top: 4px;
		    border-radius: 10px;
		    background:#E2E3F1;
		    width: 80%;
		    float: left;

		    .progressMeter{
		      border-radius: 10px;
		      background: #A729C0;
		      height: 12px;
		    }
		  }
		}
	.leftColumn, .rightColumn{
		width: 100%;
		margin-top: 0;
		text-align: center;
		overflow: auto;
		margin-bottom: 30px;
	}

	.actionButton{
		user-select:none;
		color: #fff;
		background-color: $color-pr-purple;

		margin: 20px 0 1rem;
		display: inline-block;
		vertical-align: middle;
		padding: .85em 2em;
		cursor: pointer;
		border-radius: 35px;
		font-weight: 600;
		border: none;
		transition: box-shadow .5s ease;
		font-size: 1.1em;
		line-height: 1.1em;

		&:hover{
			transition: background-color .5s ease;
			background-color: #0597d1;
		}
	}

	.formFactor_LocalStoreSummary{
		max-width: 25em;
	}

	.formFactor_Testimonial{
		.leftColumn{
			float: left;
			width: 50%;
			text-align: left;
		}

		.rightColumn{
			float: right;
			width: 50%;
			text-align: left;
		}
	}


	.formFactor_Testimonial, .formFactor_Testimonial_Horizontal,
	.formFactor_LocalStoreList, .formFactor_LocalStoreSummary,
	.formFactor_ImageGallery, .formFactor_FullPageImageGallery {
		textarea, select, input, button { outline: none; };
		.WidgetPreviewcard
		 {
			background-color: $color-background;
			margin: 1em;
			text-align: center;
			cursor: pointer;

			.MerchantHeader, .MerchantHeaderV2{
				padding-bottom: 1em;

				.merchantHeaderContainer{
					float: left;
					max-width: 10em;
					text-align: left;
					padding-bottom: 15px;
					margin-right: 10px;
			    }

				.merchantHeaderStars{
					float: left;
					width: calc(100% - 10em);
					text-align: left;
					font-size: 20px;
				}

				.alignStars {
					font-size: 15px;
				}

				.MerchantLogo {
					text-align: center;
					margin-top: 1em;
					margin-bottom: 1.5em;

					img {
						max-height: 4em;
					}
				}

				.MerchantRatingLarge {
					font-size: 1em;
					display: inline-block;

					.rating {
						float: left;
						display: inline-block;
						font-size: 38px;
						font-weight: 900;
						color: $color-vivid;
						margin-right: .2em;

						span{
							color: $color-main
						}
					}
					.ratingNote {
						float: right;
						display: inline-block;
						color: $color-vivid;
						font-weight: 400;
						font-size: 15px;
						margin-top: 25px;
					}
				}

				.MerchantRating {
					font-size: 1em;
					height: 80px;
					width: 80px;
					background-color: $color-rr-green;
					border-radius: 50%;
					display: inline-block;
					position: relative;

					.eliteSeal{
						position: absolute;
						right: 0;
						bottom: -15px;
						width: 40px;
					}

					.rating {
						display: inline-block;
						font-size: 28px;
						font-weight: 500;
						color: white;
						margin-right: 0.2em;
						text-align: center;
						position: absolute;
						top: calc(50% - 20px);
						left: calc(50% - 25px);

						span{
							font-size: 0.5em;
						}
					}
				}

				.alignStars {
					display: flex;
					justify-content:center;
					align-items: center;
					margin-top: 5px;
					margin-bottom: 26px;
				}

				.Tag {
					margin: -.2em -.5em .3em -.5em;
					font-size: 1.5em;
					font-weight: 600;
					background-color: #eee;
					padding: 10px 0 10px 0;
				}
			}
			.topTitle{
				font-size: 1.4rem;
				font-weight: 500;
				text-align: left;
				margin-bottom: 12px
			}

			.FilterTagContainer{
				text-align: left;
				.FilterTag {
					background: #F2F2F2;
					border-radius: 25px;
					cursor: pointer;
					margin-bottom: 4px;
					line-height: 20px;
					padding: 8px 18px;
					font-size: 1.2em;
					font-weight: bold;
					display: inline-block;
				}

				.Information{
					display: inline-block;
					margin-left: 10px;
					vertical-align: sub;
				}
			}



			.ReviewsDistribution{
				.rr-progress-bar {
					display: flex;
					flex-direction: column;
					justify-content: center;
					text-align: center;
					white-space: nowrap;
				}

				.progressMeter{
					background: $color-rr-green !important;
				}

				.star{
					width: 13px;
					margin-bottom: 4px;
				}

			}

			.TestimonialVerticalList, .TestimonialHorizontalList {
				overflow: hidden;

				.wide{
					.TestimonialVerticalBlock {
						.avatarContainer {
							float: left;
							width: 10em;
							text-align: left;
						}

						.alignStars {
							div{
								font-size: 1.1em;
							}
							justify-content: left;
						}

						.reviewContainer {
							float: left;
							width: calc(100% - 10em);

							.text, .title, .date {
								text-align: left;
							}
						}
					}
				}

				ul {
					margin: .5em 0 0 0;
					padding: 1em;
				}

				.TestimonialVerticalBlock, .TestimonialHorizontalBlock {
					margin: 4em;
					overflow: auto;

					.UserAttribution{
						font-size:12px;
						font-weight: 700;
						color: $color-rr-main;
					}

					.UserAvatar {
						img {
							max-width: 5em;
							max-height: 5em;
							border-radius: 50%;
						}
					}

					.reviewContainer{
						overflow: hidden;
					}

					.alignStars {
						display: flex;
						justify-content:center;
						align-items: stretch;
						margin-top: .2em;
						margin-bottom: .5em;

						div{
							font-size: 0.8em;
						}

						span{
							line-height: 1.5em;
							margin-left: 10px;
							font-weight: 800;
							font-size: 1em;
						}
					}

					.title {
						font-size: 1.5em;
						font-weight: bold;
						color: $color-gray-title;
						margin-bottom: 1em;
						text-align: center;
						text-transform: uppercase;
					}
					
					.text {
						font-family: 'Varela Round',sans-serif;
						font-size: 1.3em;
						font-weight: 400;
						text-align: center;
						max-height: 125px;
						overflow: hidden;
						white-space: unset;
					}

					.date{
						text-align: center;
						font-family: 'Varela Round',sans-serif;
						font-size: 1.1em;
						margin-top: 5px;
						margin-bottom: 10px;
						color: #666;
					}

					.is_verified{
						strong{
							color: #43741c;
							font-size: 16px;
							font-weight: 700;
						}
					}

					.benchmarkContainer{
						padding: 1.4em 3em 1.4em 0;

						.benchmarkTitle{
							padding-left: 0.3em;
							font-size: 1.3em;
							font-weight: 400;
							color: $color-gray-title;
							text-align: left;
						}

						ul{
							padding: 0;
						}
					}

					.benchmark{
						overflow: auto;
						line-height: 1.4rem;
						margin-bottom: 5px;
						text-align: left;

						.descriptionContainer{
							float: none;
							width: 100%;
							font-family: 'Varela Round',sans-serif;
							font-style: italic;

							font-size: 1.1em;
							font-weight: 400;
							white-space: pre-wrap;
							text-align: left;
						}

						.startsContainer{
							display: inline-block;
							float: none;
							width: 100%;
							div{
								font-size: 0.7em;
							}
						}

					}

					ul.wide{
						padding: inherit;
					}

					.wide{
						.benchmark{
							.startsContainer{
								float: left;
								width: 40%;
							}

							.descriptionContainer{
								float: left;
								width: 60%;
							}
						}
					}
				}
			}
		}

		.PoweredBy {
			margin-top: -.8em;
			text-align: center;
			font-weight: 400;
			a{
				text-decoration: none;
			}
			img {
				max-height: 1.9em;
				position: relative;
				top: 0.4em;
				margin-left: 0.4em;
				display: inline-block;
			}
		}
	}

	.formFactor_Testimonial_Horizontal{
		height: 365px;
		width: 100%;
		border-style: none;
		overflow: hidden;
		margin: 0 auto;

		.WidgetPreviewcard
		{
			margin: .5em;
			padding: 1.5em;

			.PoweredBy{
				color: #000;
			}

			.MerchantLogo {
				margin-top: 12px;
				margin-bottom: 18px;
				img {
					max-height: 50px;
				}
			}

			.MerchantHeader{
				margin-left: auto;
				margin-right: auto;
				width: 100%;
				height: 100%;
				float: left;

				.Tag {
					margin-top: 20px;
					margin-left: 0;
					margin-right: 0;
				}
			}


			.TestimonialHorizontalListWrapper{
				display: none;
			}
		}

		.nav_link{
			cursor:pointer;
			border: none;
			height: 148px;
			width: 35px;
			background: transparent;
			position: absolute;
			top:0;
			left: 0;
			right: auto;
			padding-left: 13px;
			padding-right: 0;

			&.right{
				right: 0;
				left: auto;
				padding-right: 13px;
				padding-left: 0;
			}

			img{
				width: 100%;
				max-width: 50px;
				opacity: 0.4;
				transition: opacity 200ms ease-in;

				&:hover{
					opacity: 1.0;
				}
			}
		}
	}

	.formFactor_LocalStoreList{
		.WidgetPreviewcard
		{
			.TestimonialVerticalList{
				ul{
					box-shadow: none;
				}
			}
		}
	}

	.formFactor_Horizontal_Slim {
		//display: inline-block;
		.WidgetPreviewcard {
			text-align: center;
			padding: .25em .5em;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;

			.Title {
				flex-grow: 1;
				.text {
					font-size: 2em;
					font-weight: 900;
					color: $color-vivid;
				}
			}

			.LogoMini {
				img {
					max-width: 15em;
					width: 100%;
				}
			}

			.alignStars {
				flex-grow: 1;
				display: flex;
				justify-content:center;
				align-items: center;
				margin-top: .2em;
				margin-bottom: .5em;
			}

			.NumOf10 {
				flex-grow: 1;
				font-size: 2.4em;
				font-weight: 900;
				color: $color-main;
				.value {
					font-size: 1.2em;
					color: $color-vivid;
				}
			}

			.NumReviews {
				flex-grow: 1;
				text-transform: uppercase;
				color: $color-vivid;
				font-weight: 400;
				font-size: 1.8em;
				margin-top: .5em;
				margin-bottom: .5em;

			}
		}
		}

	.formFactor_Mini, .formFactor_Invite_Mini {
		//display: inline-block;
		.WidgetPreviewcard
		 {
			background-color: $color-background;
			margin: 1em;
			text-align: center;
			padding: .5em;
			cursor: pointer;

			.Title {
				.text {
					font-size: 1.5em;
					font-weight: 900;
					color: $color-vivid;
				}
				img {
					max-width: 20em;
				}
			}

			.alignStars {
				display: flex;
				justify-content:center;
				align-items: center;
				margin-top: .5em;
				margin-bottom: .7em;
			}

			.NumOf10 {
				font-size: 3em;
				font-weight: 900;
				color: $color-vivid;
			}

			.Tag {
				margin: -.2em -.7em 0 -.7em;
				font-size: 1.5em;
				font-weight: 600;
				font-variant: small-caps;
				background-color: #eee;
				border-radius: 5px;
				padding-top: 5px;
				padding-bottom: 5px;
			}

			.NumReviews {
				text-transform: uppercase;
				color: $color-vivid;
				font-weight: 400;
				font-size: 1.8em;
				margin-top: .5em;
				margin-bottom: .5em;

			}
		}
		.LogoMini {
			position: relative;
			margin-bottom: 2em;
			img {
				position: absolute;
				right: 1em;
				top: -.7em;
				max-width: 15em;
			}
		}
	}

	.formFactor_Invite_Mini{
		.WidgetPreviewcard
		 {
			.Title{
				.text{
					font-size: 20px;
					margin-bottom: 10px;
				}
			}
		}
	}

	// floating positions

	.floating-right {
		right: 0;
	}
	.floating-left {
		left: 0;
	}
	.floating-top {
		top: 0;
	}
	.floating-center {
		top: 50%;
		@include transform( translate(0, -50%) );
	}
	.floating-bottom {
		top: 100%;
		@include transform( translate(0, -100%) );
	}

	.formFactor_Floating, .formFactor_InviteFloating {
		position: fixed;
		padding: 0;
		margin: 0;
        z-index: 1000000;

		.WidgetPreviewcard
		 {
			position: relative;
			top: -50%;
			background-color: $color-background;
			margin: 0;
			box-shadow: 0 0 0.5em 0.02em #ababab;;
			text-align: center;
			padding: .25em .25em;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			align-items: center;

			.Logo {
				flex-grow: 1;
				img {
					max-width: 12em;
				}
			}

			.text {
				flex-grow: 1;
				display: flex;
				font-weight: 900;
				color: $color-vivid;
				padding: .5em;
				writing-mode: vertical-rl;
				text-orientation: sideways;
			}

			.alignStars {
				flex-grow: 1;
				justify-content:center;
				align-items: center;
			}
			
			.LogoBubble {
				flex-grow: 0;
				img {
					max-width: 2em;
				}
			}
		}
	}

	.formFactor_InviteFloating{
		.WidgetPreviewcard
		{
			padding: 1.25em;
		}
	}

	.formFactor_MiniWide {
		//display: inline-block;
		.WidgetPreviewcard
		 {
			background-color: $color-background;
			margin: 1em;
			box-shadow: 0 0 15px 0 rgba(0,0,0,.15);
			text-align: center;
			padding: .25em .5em;
			cursor: pointer;
			display: flex;
			align-items: center;

			.Title {
				flex-grow: 1;
				.text {
					font-size: 2em;
					font-weight: 900;
					color: $color-vivid;
				}
				img {
					max-width: 12em;
				}
			}

			.Logo {
				flex-grow: 1;
				img {
					max-width: 12em;
				}
			}

			.alignStars {
				flex-grow: 1;
				display: flex;
				justify-content:center;
				align-items: center;
				margin-top: .2em;
				margin-bottom: .5em;
			}

			.NumOf10 {
				flex-grow: 1;
				font-size: 2.4em;
				font-weight: 900;
				color: $color-main;
				.value {
					font-size: 1.2em;
					color: $color-vivid;
				}
			}

			.NumReviews {
				flex-grow: 1;
				text-transform: uppercase;
				color: $color-vivid;
				font-weight: 400;
				font-size: 1.8em;
				margin-top: .5em;
				margin-bottom: .5em;

			}
			
			.LogoBubble {
				img {
					max-width: 4em;
				}
			}
		}
	}


	.formFactor_ImageGallery, .formFactor_FullPageImageGallery{
		max-width: 2048px;

		.WidgetPreviewcard
		{
			padding: 2em;
		}

		.image_list_container{
			overflow: hidden;
			width: 100%;
			height: 155px;
			text-align: center;
			margin: 0.6em 0;

			.image_container{
				float: left;
				margin-right: 1%;
				opacity: 1;
				transition: opacity 500ms ease-in;

				.asset_container{
					position: relative;
				}

				.image_detail{
					a{
						display: inline-block;
						width: 100%;
					}

					p{
						font-size: 14px

					}
				}

				.loading{
					display: flex;
					align-items: center;
					justify-content: center;
					height: 148px;
				}
			}

			.image{
				height: 105px;
				width: 100%;
				object-fit: cover;

				&:hover{
					opacity: .5;
					transition: opacity .25s ease-in-out;
					-moz-transition: opacity .25s ease-in-out;
					-webkit-transition: opacity .25s ease-in-out;
				}
			}

			div.image_detail{
				margin-top: 5px;
				height: 35px;
				a,p{
					text-decoration: none;
					font-weight: 700;
					font-size: 12px;
					color: rgb(95, 95, 95);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				a{
					&:hover{
						text-decoration: underline;
					}
				}

				p{
					color: black;
					line-height: 14px;
					font-size: 12px;
				}
			}
		}

		.image_container_full_screen{
			background: #fff;
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
			-webkit-backface-visibility: hidden;
			will-change: opacity;
			z-index: 1000;
			overflow-y: auto;
			padding: 3em;

			.portrait{
				margin-top:0;

				video{
					width: 100%;
					max-height: 850px;
				}
			}

			video{
				padding: 0;
				max-width: 1000px;
				width: 95%;
				margin-right: 20px;
				background: black;
			}

			.loading{
				height: 35vh;
				display: flex;
				justify-content:center;
				align-items: center;
			}

			.image{
				opacity: 1;
				height: inherit;
				max-width: 100%;
				&:hover{
					opacity: 1;
				}
			}

			.image_side_container{
				position: relative;
				overflow: auto;
				padding-top: 0;
			}

			.top_container{
				text-align: right;
				padding: 10px;
				img{
					width: 20px;
				}
			}

			.left_container{
				width: 100%;
				margin-top: 0;
				text-align: center;

				img{
					max-width: 95%;

					&.loading{
						visibility: hidden;
						max-height: 90vh;
					}

					&.loaded{
						visibility: visible;
						max-height: 800px;
					}
				}
			}

			.right_container{
				margin-top: 1.5em;
				width: 100%;
				text-align: left;

				.product_image{
					width: 100px;
					clip-path: circle(40%);
				}

				.product_stats{
					display: inline-block;
					margin-left: 15px;
					margin-top: 15px;
					vertical-align: top;
					div{
						font-size: 1.2em;
						height: 1.5em;
						width: 7.5em;
					}
					p{
						font-size: 1em;
					}
				}

				a{
					padding: 0 2em 0 0;
					display: inline-block;
					text-decoration: none;
					font-size: 1.6em;
					color: black;

					&:hover{
						text-decoration: underline;
					}

					.inline_product_review{
						height: auto;
						
						p{
							margin-top: 10px;
						}
					}
				}

				.review_container{
					padding: 0;
					text-align: left;
					font-size: 1.3em;

					.inline_review_attribution{
						height: auto;

						.reviewAvatar{
							display: inline-block;
							margin-right: 20px;

							img{
								clip-path: circle(25px at center);
								width: 55px;
							}
						}

						.reviewName{
							display:inline-block;
							vertical-align: top;
							font-weight: 600;
							color: #000;
							margin-top: 5px;
						}

						.verifiedBuyer{
							color: #4a9d5b;
							font-size: 15px;
						}
					}

					.review_date{
						margin-bottom: 1em;
						color: #000;
						font-size: 0.8em;
					}

					.review_title {
						color: $color-pr-blue;
						font-size: 1.4em;
						font-weight: 800;
						padding-top: 10px;
						padding-bottom: 10px;
					}

					.review_body{
						line-height: 1.6em;
						color: $color-pr-blue;
						padding-bottom: 5px;
						font-weight: 300;
						font-size: 1.1em
					}

					.byLine {
						font-size: 1em;
						text-align: right;
						.user {
							font-weight: bold;
						}
						.seller {
							font-weight: bold;
							color: $color-vivid;
						}
					}

					.startsContainer{
						div{
							height: 1.5em;
							width: 8.5em;
							margin-bottom: 0.75em;
						}
					}
				}
			}
		}

		.nav_link{
			cursor:pointer;
			border: none;
			height: 148px;

			float: left;
			background: transparent;

			&.right{
				float: right;
			}

			img{
				width: 100%;
				max-width: 50px;
				opacity: 0.4;
				transition: opacity 200ms ease-in;

				&:hover{
					opacity: 1.0;
				}
			}
		}
	}


	.formFactor_FullPageImageGallery{
		max-width: 100%;

		.full_page_list_container{

			.loading{
				height: 30vh;
				display: flex;
				justify-content:center;
				align-items: center;
			}

			.imageWhileLoading{
				display: none;
			}

		}
	}



	/** Photo Grid */
	$photo-grid-gutter: 6px;
	$photo-grid-col-max-size: 33%;
	$photo-grid-picture-spacing: ($photo-grid-gutter / 2) 0;
	$photo-grid-picture-background: #EDF2F7;
	$photo-grid-picture-background-loaded: #1A202C;

	.gridContainer {
		position: relative;
		overflow: hidden;

		.photoGrid {
			display: flex;
			flex-wrap: wrap;
			padding-left: $photo-grid-gutter / 2;
			padding-right: $photo-grid-gutter / 2;
			padding-bottom: $photo-grid-gutter;
			justify-content: center;

			.photoColumn {
				flex-basis: 0;
				flex-grow: 1;
				flex: 0 0 $photo-grid-col-max-size;
				max-width: $photo-grid-col-max-size;
				padding-left: $photo-grid-gutter / 2;
				padding-right: $photo-grid-gutter / 2;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;



				figure{
					.imageMask, .imageMaskButton{
						height: 100%;
						width: 100%;
						padding-bottom: 10px;
						object-fit: cover;
					}

					.imageMask, .imageMaskButton{
						left: 0;
						top: 0;
						position: absolute;
					}

					.imageMaskButton{
						display: flex;
						align-items: center;
						justify-content: center;
						z-index: 10;

						div:hover{
							a p{
								text-decoration: underline;
							}
						}

						a{
							display: inline-block;
							text-decoration: none;
							border-bottom-color: $color-pr-purple;
						}

						img{
							width:50px;
							height: 50px;
							visibility: visible;
							margin-left: auto;
							margin-right: auto;
						}

						p{
							color: $color-pr-purple;
							font-weight: bold;
							font-size: 1.4em;
						}
					}

					.imageMask{
						background-color: #fff;
						z-index: 5;
						opacity: 0.90;
					}
				}

				.picture {
					margin: $photo-grid-picture-spacing;
					cursor: pointer;
					min-width: 100%;
					max-width: 100%;
					height: auto;
					background-color: $photo-grid-picture-background;
					overflow: hidden;
					position: relative;

					&:last-child {
						margin-bottom: 0;
					}

					.bg {
						background-position: center center;
						background-repeat: no-repeat;
						background-size: cover;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						transition-delay: 0.82s;
						transition: 0.75s ease all;
					}

					img.galleyThumbnail {
						width: 100%;
						height: auto;
						display: block;
						opacity: 0;
						visibility: hidden;
					}

					&.loading {
						cursor: default;

						.bg,
						img {
							opacity: 0;
						}
					}

					&.loaded {
						&::before {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-color: $photo-grid-picture-background-loaded;
							opacity: 0;
							z-index: 1;
							transition: 0.45s ease all;
						}

						&:hover {
							&::before {
								opacity: 0.5;
							}
						}

						.bg, img.galleyThumbnail {
							opacity: 1;
							z-index: 0;
							transition: 0.75s ease all;
							transition-delay: 0s;
						}

						img.galleyThumbnail {
							position: relative;
						}

						&:hover {
							.bg, img.galleyThumbnail {
								transform: scale(1.02);
							}
						}
					}
				}
			}
		}
	}
}


/* ---- Adjustments for wider screens ---- */


@media screen and (min-width: $break-small) {
	// increase specificity with a triple class
	.rr.root.widget {
		.loading img {
			max-width: 5em;
			margin: 1em auto;
		}

		.formFactor_Testimonial_Horizontal{
			max-width: 1440px;

			.WidgetPreviewcard
			{
				margin: .5em;
				padding: 1.5em;

				.MerchantHeader{
					float: left;
					width: 270px;
					margin: 0;
				}

				.TestimonialHorizontalListWrapper{
					position: relative;
					display: block;
					overflow: hidden;
					padding: 0 50px;
				}

				.TestimonialHorizontalList{
					height: 100%;
					overflow: hidden;
					position: relative;
					white-space: nowrap;
					transition-timing-function: ease;
					transition-duration: 1s;
					user-select: none;

					.TestimonialHorizontalBlock{
						margin:0;
						display: inline-block;
						height: 260px;
						overflow: hidden;
						padding: 20px 10px 0 10px;
						position: relative;
						vertical-align: top;
						white-space: normal;
						float: left;

						.alignStars {
							margin-bottom: 10px;
							font-size: 10px;
						}

						.date {
							margin-top: 8px;
							text-align: left;
							font-size: 0.8em;
						}

						.avatarContainer{
							width: 100%;
							text-align: left;
							display: flex;
							flex-direction: row;
							justify-content: flex-start;
							align-items: center;
							align-content: flex-start;

							img{
								width: 25px;
							}

							.UserAvatar{
								display: inline-block;
								margin-right: 5px;
							}

							.UserAttribution{
								display: inline-block;
							}

							.is_verified{
								display: inline-block;
								text-align: left;
							}

							.verified{
								color: #008000db;
								font-weight: bold;
							}
						}

						.reviewContainer{
							width: 100%;

							.alignStars{
								justify-content: left;
								div{
									font-size: 13px;
								}
							}

							.title{
								font-size: 1.5em;
								font-weight: bold;
								text-align: left;
							}

							.text{
								text-align: left;
								font-size: 1.3em;
								font-style: normal;
								line-height: 22px;
							}
						}
					}
				}
			}

			.nav_link{
				height: 220px;
			}
		}

		.formFactor_Testimonial, .formFactor_LocalStoreList, .formFactor_LocalStoreSummary {
			.WidgetPreviewcard
			 {
				.alignStars {
					font-size: 1.5em;
				}
				.TestimonialVerticalList, .TestimonialHorizontalList {
					.TestimonialVerticalBlock, .TestimonialHorizontalBlock {
						.UserAvatar {
							img {
								max-width: 4em;
								max-height: 4em;
							}
						}
					}
				}
			}
		}


		.formFactor_ImageGallery, .formFactor_FullPageImageGallery{
			.galleryModal{
				width: 80vw;
				height: auto;
				margin-top: 5vh;
				margin-bottom: 5vh;
				max-width: 1400px;
			}

			.image_list_container{
				height: 230px;

				.image_container{
					div.image_detail {
						a, p {
							font-size: 14px;
						}
					}
					.loading{
						height: 220px;
					}
					.image{
						height: 180px;
					}
				}
			}

			.image_container_full_screen{
				.left_container{
					margin-top: 1.5em;
					float: left;
					width: 65%;
					padding-left: 0.5em;
				}

				.right_container{
					float: left;
					width: calc(100% - 65% - 4.5em);
					margin-left: 10px;
					color: black;
					font-weight: 600;

					button{
						font-size: 1em;
						line-height: 1em;
					}
				}

				.portrait{
					video{
						width: 75%;
					}
				}

			}

			.nav_link{
				height: 220px;
				width: 7%;
			}
		}

		.formFactor_FullPageImageGallery{
			max-width: 100%;

			.gridContainer {
				.photoGrid {
					.photoColumn {
						flex: 0 0 19%;
						max-width: 19%;
					}
				}
			}
		}

		.formFactor_Mini, .formFactor_Invite_Mini {
			max-width: 17em;
			.WidgetPreviewcard
			 {

				.alignStars {
					font-size: 1.5em;
				}
				.Title {
					img {
						max-width: 12em;
					}
				}

				.NumReviews {
					font-size: 1.2em;
				}
			}
			.LogoMini {
				margin-bottom: 0;
				img {
					max-width: 7em;
				}
			}
		}

		.formFactor_Horizontal_Slim {
			font-size: .6em;
			max-width: 40em;
			.WidgetPreviewcard {
				min-height: 4em;
				.alignStars {
					font-size: 1.5em;
				}
				.Title {
					font-size: .8em;
				}
				.LogoMini {
					img {
						max-width: 15em;
						width: 100%;
					}
				}
			}
		}

		.formFactor_MiniWide {
			font-size: .6em;
			max-width: 30em;
			.WidgetPreviewcard
			 {
				min-height: 4em;
				.alignStars {
					font-size: 1.5em;
				}
				.Title {
					font-size: .8em;
				}
				.LogoBubble {
					img {
						max-width: 3em;
					}
				}
				}
			}
		}
	}

	$break-small: 600px;

.stars {
	background-repeat:no-repeat;
	background-size: 100%;
	height:3em;
	margin:0;
	padding:0;
	vertical-align:top;
	width:15em;
	border:0;
}

	.stars.zero {background-position:0 0%}
	.stars.half {background-position:0 10%}
	.stars.one {background-position:0 20%}
	.stars.onehalf {background-position:0 30%}
	.stars.two {background-position:0 40%}
	.stars.twohalf {background-position:0 50%}
	.stars.three {background-position:0 60%}
	.stars.threehalf {background-position:0 70%}
	.stars.four {background-position:0 80%}
	.stars.fourhalf {background-position:0 90%}
	.stars.five {background-position:0 100%}

	.vStars {
		background-repeat:no-repeat;
		background-size: auto 100%;
		height:15em;
		margin:0;
		padding:0;
		vertical-align:top;
		width:3em;
		border:0;
	}

	.vStars.zero {background-position: 0% 0 }
	.vStars.half {background-position: 10% 0 }
	.vStars.one {background-position: 20% 0 }
	.vStars.onehalf {background-position: 30% 0 }
	.vStars.two {background-position: 40% 0 }
	.vStars.twohalf {background-position: 50% 0 }
	.vStars.three {background-position: 60% 0 }
	.vStars.threehalf {background-position: 70% 0 }
	.vStars.four {background-position: 80% 0 }
	.vStars.fourhalf {background-position: 90% 0 }
	.vStars.five {background-position: 100% 0 }


	@media screen and (min-width: $break-small) {
	    .stars {
	        height: 1.5em;
	        width: 7.5em;
	    }
	    .vStars {
	        height: 7.5em;
	        width: 1.5em;
	    }
}
}