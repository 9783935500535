@import "../colors";

.social-share-btns {
  overflow-y: auto;

  .socialBtnContainer {
    float: left;
    margin-right: 15px;
  }

  .fb_iframe_widget span {
    vertical-align: baseline !important;
  }
}