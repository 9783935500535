.flyout-pages {
  &__header {
    display: inline-block;
    padding: 0 2rem;
    height: 5rem;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 5rem);
  }
  
  &__header-block {
    border-bottom: 1px solid $Quartz;
    line-height: 5rem;
    height: 5rem;
    font-family: $f-app-font-semibold;
    font-size: 1.6rem;
  }
  
  &__back-button {
    display: inline-block;
    position: relative;
    z-index: 10;
    height: 5rem;
    width: 5rem;

    text-align: center;

    background-color: $Slate;
    color: #fff;
    cursor: pointer;
    font-size: 2.5rem;
  }

  &--history {
    +.tooltip__caret {
      &:before {
        background-color: $Slate;
        left: 1.70rem !important;
      }
    }
  }
}