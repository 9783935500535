.modules_library-site-paginate {
  float: right;
  .paginate-counts {
    float: right;
    font-size: 0.8em;
    padding: 5px 12px;
  }
  .paginate-pages {
    float: right;
    li {
      text-align: center;
      font-size: 0.8em;
      min-width: 20px;
      padding: 4px 8px;
      line-height: 1.5em;
      display: inline-block;
      &.ellipsis {
        overflow: hidden;
      }
      &.pageButton {
        border: 1px solid $HalfQuartz;
        border-radius: 2px;
        margin-right: 2px;
        position: relative;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        color: $DarkSlate;
        &.pageButtonSelected,
        &:hover {
          background-color: $HalfQuartz;
        }
        &.pageButtonSelected {
          cursor: default;
        }
        a {
          position: relative;
          color: $DarkSlate;
          &:hover {
            color: $DarkSlate;
          }
        }
      }
    }
  }
}
