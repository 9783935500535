.color-picker {
  position: relative;
  &__heading { margin-bottom: .5rem}
  &--disabled {
    cursor: default !important;
  }

  &.form-input {
    width: 3.5rem;
    padding: 0;

    .color-picker__swatch {
      margin: 0.4rem;
    }
  }

  &__swatch {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: rem(4);
    position: relative;
    display: inline-block;
    cursor: pointer;
    border: solid 1px rgba(34, 43, 60, 0.3);
    margin-bottom:1.5rem;
    margin-right:1.5rem;
    font-size:0;
    float:left;
    &--select {
      border: solid 1px rgba(34, 43, 60, 0.3);
      color: $Salt;

      .ui-icon {
        color: $Quartz;
        position: absolute;
        bottom: rem(-4);
        right: 0;
        font-size: 2rem;
        line-height: 1;
        transform: rotate(-45deg);
      }
    }
  }

  /* &__swatch-wrapper {
    padding: rem(2);
    border-radius: rem(4);
    border: rem(1) solid $Salt;
    margin: 0;
    display: inline-block;
    line-height: 0;

    &:nth-child(7n+0) {
      margin: 0 0rem 0.4rem 0;
    }

    &:hover{
      border: rem(1) solid $Ash;
    }
  }

  &__swatch-wrapper--selected {
    border: rem(1) solid $Obsidian !important;
  } */

  &__custom {
    position: absolute;
    bottom: rem(11);
    right: rem(11);
  }

  &__container {
    padding: 1.4rem .5rem 0 2rem;
    &--bottom {
      padding-bottom:1.4rem;
      padding-right: 2rem;
    }
  }
}
