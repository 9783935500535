.card_header {
  position: relative;
  &__error {
    position: absolute;
    right: rem(35);
    top: rem(23);
  }
  &__menu {
    &_button {
      position: absolute;
      right: rem(10);
      top: rem(13);
      // height: 6rem;
      // line-height: 6.5rem;
      // width: 4rem;
      // text-align: center;
      // cursor: pointer;
      //
      .popover__content {
        width: 20rem;
        right: 0rem;
        top: 0rem;
        text-align: left;
      }

      .button {
        width: rem(24);
        height: rem(35);

        .cx-icon {
          width: rem(22);
          height: rem(33);
          line-height: rem(33);
        }
      }

      .card_header__size_button {
        width: 25%;
      } 

    }
  }

  &__name {
    padding-left: rem(15);
    padding-top: rem(10);
  }

  &--delete {
    color: $Dragonfruit;
  }
}

.card{
  &__filter-group {
    padding-left: rem(15);
    padding-bottom: rem(5);
    color: $Slate;
    overflow: hidden;
    margin-right: rem(30);

    .cx-icon {
      font-size: rem(12);
      padding-right: rem(5);
    }
  }

  &__filter-group-item {
    float: left;
    // display: inline;
    padding: 0 10px;
    border-left: solid rem(1) $Quartz;

    &:first-child{
      padding-left: 0;
      border-left: none;
    }
  }
  //
  // &__filter-group-item-spacer {
  //   height: rem(16);
  //   width: rem(1);
  //   background: $Quartz;
  //   margin-left: rem(10);
  //   margin-right: rem(10);
  // }
}
