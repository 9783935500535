@import "../colors";

.reactCustomSelectInterceptor{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
}

.reactLabel{
  -webkit-transition: color 1s;
  transition: color 1s;
  line-height: 2;
  font-size: 12px;
  vertical-align: middle;
  margin-bottom: 5px;
  font-weight: bold;
  color: #58595b;
}

.standardCase{
  .reactLabel{
    text-transform: inherit;
  }
}

.reactInput{
  background-color: $Salt;
  border: 2px solid #C8C8C8;
  color: $Obsidian;
  height: 2.7375rem;
  border-radius: $border-radius;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 7px 26px 7px 10px;
  transition: border-color $borderTransitionSpeed;
  cursor:pointer;

  &.inline {
    padding: 3px 26px 3px 5px;
    margin-bottom: 0;
  }

  &.date-dropdown{

    .reactCustomSelectButtonText{
      font-weight: bold;
    }

    .reactCustomSelectEditable{
      background: transparent;
      border: none;
      color: $Obsidian;
      width: 100%;
      height: 20px;
      font-size: 1.3rem;
    }

    .reactCustomSelectButtonIcon {
      border-left: 1px solid #BFC3C8;
      position: absolute;
      color: $Obsidian;
      font-size: 13px;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 10px 13px;
    }
  }

  :focus{
    outline: none;
  }

  &:focus{
    border: 1px solid $HalfSlate;
  }

  &.bigGray{
    height: 50px;
    margin-right: 30px;
    background-color: #D7D7D7;
    border-color: #D7D7D7;
    color: $Obsidian;
    font-weight: 700;
    font-size: 1.3rem;
  }

}

.reactCheckBox {
  label {
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }
  .reactLabel{
    vertical-align: text-bottom;
  }
}

.inputButton{
  input, button{
    height: 42px;
  }
}

.inputContainer{
  label{
    font-size: 1.1rem;
    margin-bottom: 0.4rem;
  }
}

.cmn-toggle {
  margin-left: -9999px;
  display: none;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

input.cmn-toggle-round + label {
  padding: 1px;
  width: 30px;
  height: 15px;
  background-color: #dddddd;
  border-radius: 15px;
}
input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 15px;
  transition: background 0.4s;
}
input.cmn-toggle-round + label:after {
  width: 14px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  transition: margin 0.4s;
}
input.cmn-toggle-round:checked + label:before {
  background-color: $RRMain;
}
input.cmn-toggle-round:checked + label:after {
  margin-left: 15px;
}


