.banner {
  padding: .5rem 2rem;
  background-color: $Jellyfish;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;

  &--clickable {
    cursor: pointer;
    opacity: .92;

    &:hover {
      opacity: 1;
    }
  }

  &--center {
    justify-content: center;
  }

  &--info {
    background-color: $Kiwi;

    .banner__close {
      color: inherit;
    }
  }

  &--warning {
    background-color: $Bananarama;

    .banner__close {
      color: inherit;
    }
  }
  
  &--error {
    background-color: $Dragonfruit;
  }
  
  &__text {
    align-self: center;
  }

  &__close {
    padding-left: 1rem;
    padding-right: 1rem;
    align-self: center;
    color: $Salt;
  }

  &__icon {
    margin-right: 1rem;
  }
}