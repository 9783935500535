.modules_library-site-store-alternatives,
.modules_library-site-search-page-stores {
  .modules_library-site-store-card {
    align-items: center;
    border-bottom: 1px solid $SeaSalt;
    display: flex;
    flex-direction: column;
    font-size: .8em;
    margin-bottom: 12px;
    padding-bottom: 12px;
    .screenshot {
      order: 1;
    }
    .name {
      color: $RRPurple;
      font-size: 1.5em;
      font-weight: bold;
      order: 2;
      word-wrap: break-word;
      a {
        color: $RRPurple;
      }
    }
    .subscribed {
      order: 3;
      img {
        width: 16px;
      }
    }
    .categories {
      order: 4;
    }
    .fancyStarsLink {
      order: 5;
    }
    .reviewCount {
      order: 6;
    }
    .compareButton {
      position: relative;
      top: auto;
      right: auto;
      order: 7;
      width: 50px;
    }
    @media (max-width: 749px) {
      .screenshot {
        text-align: center;
        img {
          width: 75%;
        }
      }
      .screenshot {
        padding-bottom: 12px;
        width: 75%;
        img {
          width: 100%;
        }
      }
      .name,
      .subscribed,
      .categories,
      .fancyStarsLink,
      .reviewCount {
        margin-bottom: 0;
        padding-bottom: 8px;
      }
    }
    @media (min-width: 750px) {
      flex-direction: row;
      margin-bottom: 6px;
      padding: 20px;
      .screenshot {
        width: calc(15% - 10px);
        img {
          width: 100%;
        }
      }
      .name {
        padding: 12px;
        width: calc(25% - 8px);
      }
      .subscribed {
        width: calc(15% - 10px);
      }
      .categories,
      .fancyStarsLink,
      .reviewCount {
        width: calc(15% - 8px);
      }
    }
  }
}
