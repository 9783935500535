.store-index{
  @import "../../../modules_library/store_picker.scss";
  .store-index-form {
    align-items: center;
    .fa-filter{display: none}
  }
  .views-search-unified-category-children {
    .icon {
      max-width: 100px;
    }
  }
  #StartReviewStars{
    padding-right: 20px;
  }
  .UnifiedSearchInput {
    position: relative;
    .textInput {
      padding: 24px 24px 24px 40px;
      width: 100%;
      margin: 0;
    }
    .fa-search {
      position: absolute;
      top: 17px;
      left: 10px;
    }
  }
  .UnifiedSearchSuggestSelect {
    width: 100%;
    color: $Black;
    z-index: 100000;
    background-color: white;
    border-radius: 2px;
    border: 1px solid #cacaca;
    padding: 10px 15px;
    max-height: 50vh;
    overflow-y: scroll;
    position: absolute;
    a {
      display: block;
      padding: 4px 12px;
      &:hover {
        cursor: pointer;
        background-color: $Smoke;
      }
    }
  }
}
#store_view {
  @import "../../../modules_library/mobile_nav.scss";
  @import "../../../cx-component-library/components/banner";
  .txt--Salt{
    color: white;
  }
  .pricecheck { height: 175px; }
  #demo:after { content: ' '; height: 0; position: absolute; width: 0; border: 10px solid transparent; border-top-color: #fff; top: 0; left: 250px }
  .sales { background-color: #f6f6f6 }
  dd a { font-size: 13px; color: #333 }
  .tabs-content{border: none;background: none}
  section.discover_content {
    &:before {
      -ms-transform: skewY(0);
      transform: skewY(0);
      box-shadow: none;
      width: 0;
    }
  }
  section.discover_content{
    background: #F3F3F3;
  }
  .alert-callout{
    background-color: red;
    color: white;
    padding: 20px;
    border-radius: 10px;
  }
  #store-page-rating{
    align-items: center;
    .no-highlights{
      padding: 20px;
      display: inline-block;
      text-align: center;
      border-radius: 30px;
      background-image: linear-gradient(to right, #C186DC, #C600EA, #C600EA, #A321DD);
      color: white;
      border-color: white;
      border-style: dashed;
    }
    .highlights-grid{
      .highlights-cell{
        width: 20%;
      }
    }
    .distText{
      font-size: 14px;
    }
    .reactSeparator {
      display: inline-block;
      .dropdown {
        width: 100%;
        .dropdown_toggle {
          border: none;
          color: $RRPurple;
          span{
            font-size: .9rem;
            padding-right: 5px;
          }
        }
        .dropdown-container {
          .scrollbox {
            .menu-option {
              min-width: 7.2rem;
            }
          }
        }
      }
    }
  }
  .siteReviewFilters{
    #StartReviewStars{
      padding-top: 5px;
    }
    box-shadow: 0 5px 12px 0 rgba(0,0,0,.05);
    background: #fafafa;
    border-radius: .4em;
    padding: 1.5em;
    &:before{
      content: '';
      position: relative;
      top: -13px;
      @media screen and (min-width: 1024px) {
        left: 37%;
      }
      @media screen and (max-width: 1023px) {
        left: 64%;
      }
      @media screen and (max-width: 800px) {
        left: 75%;
      }
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: #fafafa;
      border-top: 0;
      margin-left: -20px;
      margin-top: -20px;
    }
  }
  @media screen and (min-width: 1024px) {
    .tabs{
      float: right
    }
    .tabs-title > a, .tabs-link > a {
      display: block;
      line-height: 1;
      font-size: 1rem;
      padding: 1.25rem 1.5rem;
      color: $RRPurple;
    }
  }
  .drilldown .is-drilldown-submenu-parent>a::after {
    display: none;
  }
  @media screen and (max-width: 1023px) {
    .tabs-title > a, .tabs-link > a {
      font-size: .9rem;
      padding: 1rem 1.25rem;
      @media screen and (max-width: 334px) {
        font-size: .75rem;
        padding: .5rem .75rem;
      }
      display: block;
      line-height: 1;
      color: $RRPurple;
    }
    .tabs{
      border-bottom: none;
    }
  }
  .breadcrumbs{
    a{
      &.rr-purple{
        color: $RRPurple;
      }
    }
  }
  .tableRatingsSideBar{
    padding: 2rem;
  }
  .review{width: 100%}
  @media screen and (max-width: 1023px){
    .tabs-link {
      position: relative;
      display: block;
      padding: 1.25rem 1rem;
      border: 1px solid #e6e6e6;
      font-size: .75rem;
      line-height: 1;
      a {
        color: $RRPurple;
      }
    }
  }
  .openbox {
    background-color:#fff;
    color:$RRPurple;
    border:1px solid $RRPurple;
    font-size:20px;
    box-shadow: 1px 2px 2px 2px rgba(0,0,0, 0.1);
    &:hover {
      background-color: $RRPurple;
      color: #FFF;
      border: 1px solid $RRPurple;
    }
    &.button{
      padding: 0.85em 4em;
    }
  }
  .DateRangePicker{
    box-shadow: 0 5px 12px 0 rgba(0,0,0,.05);
    .DateRangePickerInput{
      border: none;
      .DateInput{
        width: 135px;
      }
    }
  }
  .tabs-title > a, .tabs-link > a {
    font-family: 'Varela Round', sans-serif;
  }
  .reactTagList {
    &.selectable {
      ul{
        li {
          i {
            font-size: 1em;
            position: relative;
          }
        }
        margin-bottom: 0;
      }
    }
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .DateRangePickerInput__arrow{
    display: none;
  }
  .review-filter-tag{
    font-size: 16px;
    color: $RRPurple;
    font-weight: 700;
    border-radius: 3px;
    .fa-star{
      color: $RRPurple;
    }
    .fa-times {
      color: #0a0a0a;
      margin-left: 5px;
    }
    &:hover{
      color: $Salt;
      .fa-star{
        color: $Salt;
      }
      .fa-times {
        color: $Salt;
      }
    }
  }
  .filter-transition-enter {
    opacity: 0.01;
  }
  .filter-transition-enter.filter-transition-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .filter-transition-leave {
    opacity: 1;
  }
  .filter-transition-leave.filter-transition-leave-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
  }
  .filter-info{
    color: #F3B24E;
    font-size: 1.2em;
  }
  .review-transition-enter {
    opacity: 0.01;
  }
  .review-transition-enter.review-transition-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .review-transition-leave {
    opacity: 1;
  }
  .review-transition-leave.review-transition-leave-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
  }
  @media screen and (min-width: 1024px) {
    .tabs-content {
      padding-bottom: 10px;
      min-height: 340px;
    }
  }
  .seller-store-hero {
    .store-stars {
      .fa-star, .fa-star-half-alt {
        font-size: 2.2em;
      }
    }
    -webkit-animation: fadein 3s;
    -moz-animation: fadein 3s;
    -o-animation: fadein 3s;
    animation: fadein 3s;
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    /* Firefox */
    @-moz-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    /* Safari and Chrome */
    @-webkit-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  #store-thumb{
    padding-top: 10px;
  }
  .seller-reps{
    .user{
      display: block;
    }

    @media screen and (max-width: 1023px) {
      .store-link {
        font-size: .7em;
      }
    }
    text-align: center;
  }
  .review{
    background: #fafafa;
    padding: 2rem;
    @media screen and (max-width: 1023px) {
      padding: 1rem;
    }
    border-bottom: 1px solid #f7f7f7;
    box-shadow: 0 5px 12px 0 rgba(0,0,0,.01);
    &.featured{
      background: #ffffff;
      box-shadow: 0 5px 16px 0 rgba(0,0,0,0.01);
    }
  }
  .compare-wrapper{
    .compare-button {
      width: 100%;
    }
  }
  .rating-count{
    font-size: 2.5em;
  }
  .progress-meter{
    background: $RRPurple;
  }
  .write-a-review-text{
    padding-right: 20px;
  }
  .store-label {
    .fancyStars span {
      margin: 0;
    }
  }
}