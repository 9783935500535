@import '../colors';

.mobileNav{
  .active-item{
    color: black;
    font-size: large;
    font-family: Raleway, sans-serif;
    font-weight: 400;
  }
  margin-right: -.9375rem;
  margin-left: -.9375rem;
  background-color: #ebebeb;
  .active-item:after{
    border-color: $RRPurple transparent transparent;
  }
}