._view_store_glossary {
  menu {
    padding: 0;
    h2 {
      padding-top: 20px;
    }
  }
  .store-glossary {
    padding: 10px;
    @media screen and (min-width: 1024px) {
      padding: 0 44px;
    }
    li {
      padding: 6px 0;
    }
    @media (max-width: 639px) {
      li {
        padding: 15px 0;
      }
    }
  }
  .stores {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ccc;
    @media screen and (min-width: 1024px) {
      height: 900px;
      li {
        width: 33vw;
      }
    }
  }
  .backtotop {
    font-size: .8em;
  }
  .hashnav {
    list-style: none;
    li {
      display: inline;
    }
  }
}
