.search {
  position: relative;

  i {
    position: absolute;
    color: $Obsidian;
    font-size: 1.6rem;
    top: 1.0rem;
    left: 1rem;

    &.cx-icon--close_x{
      right: 1rem;
      left: auto;
      color: $HalfAsh;
      cursor: pointer;
      &:hover{
        color: $Ash;
      }
    }
  }

  &--inline {
    display: inline-block;
  }

  &__control {
    @extend %control;
    padding-left: rem(30);
    font-size: 1.3rem;
    max-width: rem(250);
    &::-ms-clear { display: none; }
    &:active,
    &:focus {
      outline: none;
      border: rem(1) solid $Atlantis ;
      box-shadow: 0 0 rem(3) $Atlantis;
      @include transition();
    }
  }

  &--underline{
    .search__control {
      border: none;
      border-bottom: rem(1) solid $Ash;
      border-radius: 0;
      background: transparent;

      &:focus{
        box-shadow: none;
      }
    }
  }

  &--transparent {
    .search__control {
      border: none;
      border-radius: 0;
      background: transparent;

      &:focus{
        box-shadow: none;
      }
    }
  }

  &__control--error {
    border-color: $Dragonfruit;
    &:active,
    &:focus {
      border-color: $Dragonfruit;
      box-shadow: 0 0 rem(3) $Dragonfruit;
      @include transition();
    }
  }
}
