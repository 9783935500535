.carousel {
  position: relative;
  margin: auto;
  // overflow: hidden;
  
  &__scroller {
    transition: all 0.35s;
    white-space: nowrap;
  }
  
  &__button {
    z-index: 2;
    position: absolute;
    width: rem(40);
    height: 100%;
    display: flex;
    background-color: $Quartz;
    border-color: $Quartz;
    padding: 0 rem(10);
    border-radius: 0;
    color: $Obsidian;
    
    &:hover {
      background: $Ash;
      border-color: $Ash;
      color: $Obsidian;
    }
    
    .cx-icon {
      font-size: rem(20);
    }
    
    &--prev {
      // box-shadow: 2px 0px 5px 0px #B7C0CC;
    }
    
    &--next {
      right: 0;
      top: 0;
      // box-shadow: -2px 0px 5px 0px #B7C0CC;
    }
    
    &--centered {
      position: absolute;
      height: 10rem;
      width: 10rem;
      top: calc(50% - 5rem);
      z-index: 2;
      
      .cx-icon {
        font-size: rem(32);
      }
      
      &.carousel__button--prev {
        left: -20rem;
      }
      &.carousel__button--next {
        right: -20rem;
      }
    }
  }
  
  &--stepper {
    .carousel__button {
      position: fixed;
    }
  }
}
