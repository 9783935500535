#signupContainer{
  @media screen and (min-width: 700px) {
    background: url('/static/images/signup/signup_page_bg.jpg') no-repeat center;
  }
  background-size: cover;
  margin: 20px;
  display: flex;
  align-items: center;
  min-height: 50em;
  #signupGrid {
    position: relative;
    z-index: 1;
    max-width: 34em;
    .inputContainer{
      padding-bottom: 10px;
    }
  }
  #signupGrid:before, #signupGrid:after
  {
    content: "";
    position: absolute;
    z-index: -1;
    @media screen and (min-width: 700px) {
      box-shadow: 0 0 50px rgba(0, 0, 0, .3);
    }
    top: 50%;
    bottom: 0;
    left: 90px;
    right: 90px;
    border-radius: 140px/10px;
  }
  .signup-text{
    max-width: 40em;
  }
  .button{
    width: 100%;
  }

  #signupForm{
    z-index: 5;
    position: relative;
    background: white;
    .inputContainer{
      input{
        height: 4em;
      }
    }
  }
}