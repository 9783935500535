&--stacked {
  .tabs {
    &__tabitem {
      display:block;
      height:7.5rem;
      border-bottom: rem(1) solid $Quartz;
      text-transform: capitalize;
      font-family: $f-app-font-regular;
      position: relative;
      cursor: pointer;
      &--selected {
        color:$Salt;
        background: $Jellyfish;
      }
      .sublabel {
        display:block;
        font-size:1.2rem;
        text-transform: uppercase;
        font-family: $f-app-font-bold;
      }
    }
    &__tabitem-content {
      position:absolute;
      top: 50%;
      transform: translate(0, -50%);
      display: block;
      padding: 0 3rem;
      width:100%;
      font-size:1.8rem;
      &:after {
        @extend .fa;
        content: $fa-var-chevron-right;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 1rem;
      }
      &--sub-content {
        display: block;
        font-family: $f-app-font-regular;
        font-size: 1.2rem;
      }
    }
  }
}
