.circleStars{
    text-align: center;
    &.no-center{
      text-align: left;
    }
    .unselected {
        background-image: url(/static/js/images/rr-circle-star-off.png) !important;
      }

      .small{
        width:1.25rem;
        height:1.25rem;
      }

    span{
      display: inline-block;

      width:2rem;
      height:2rem;
      background-size: cover;

     
      background-image: url(/static/js/images/rr-circle-star-on.png);

    
      
      
    }

  .ratingMessageSpan {
    margin-left: 20px;
    font-weight: bold;
    min-width: 190px;
  }

  .clickable {
    cursor: pointer;
  }

  .reactDataTable{
    text-align: left;
  }

}

