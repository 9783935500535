.score-list {
  &__title {
    color: $Slate; 
    font-family: $f-app-font-semibold;
    &--small {
      font-size: 1.4rem;
    }

    &--medium {
      font-size: 1.6rem;
    }

    &--large {
      font-size: 1.8rem;
    }
  }
}