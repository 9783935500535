&--highlight {
  
  &.gutterless {
    margin: 0.5rem 0 0 0;
    padding: 0 2rem;
  }

  .tabs {
    &__tabitem {
      color: $Ash;
      border-bottom: rem(3) solid transparent;
      
      &:hover {
        color: $Coal;
        @include transition;
      }

      &--selected {
        color: $Obsidian;
        border-bottom: rem(3) solid $Jellyfish;
        
        &:hover {
          color: $Obsidian;
          @include transition;
        }
      }
    }
  }
}
