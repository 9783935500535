.helpRobot{

  .speech{
    text-align: left;
    height: auto;

    p{
      color: $DarkQuartz;
      font-weight: 700;

      a{
        color: $RRMain;
      }
    }

    h5{
      font-size: 1.1rem;
      margin-bottom: 15px;

      a{
        color: $Water;
      }
    }

  }


  .helpRobotContent{
    padding: 20px;
  }
}


.helpContainer{
  overflow: auto;
}