.modules_library-site-store-card {
  font-size: .8em;
  position: relative;
  .screenshot {
    img {
      background-color: $SeaSalt;
    }
  }
  .compareButton {
    position: absolute;
    right: 10px;
    top: 180px;
    a {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 50px;
      justify-content: center;        
      text-align: center;
      width: 50px;
      svg {
        margin: 0;
      }
    }
  }
  .name {
    font-size: 1.5em;
    color: $Black;
    a {
      color: $Black;
    }
  }
  .categories {
    li {
      display: inline;
      font-size: .8em;
      list-style: none;
      padding-right: 12px;        
    }
  }
  .subscribed {
    img {
      width: 16px;
    }
  }
  .fancyStars {
  }
  .reviewCount {
  }
  .modules_library-site-review-speechBubble {
    padding-top: 18px;
    .modules_library-site-review-review {
      height: 60px;
      overflow: hidden;
      .title {
        font-weight: 700;
      }
    }
    .modules_library-site-user-user {
      align-items: center;
      display: flex;
      padding-top: 12px;
      .username {
        margin-left: 12px;
      }
    }
  }
  .description {
    font-size: .7rem;
    color: $HalfSlate;
    max-height: 80px;
    overflow: hidden;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 18px;
  }
}