.form-label {
  font-family: $f-app-font-semibold;
  font-size: rem($labelfont);
  color: $Coal;
  
  &--all-caps {
    text-transform: uppercase;
  }
  
  &--error {
    color: $Dragonfruit;
  }
  
  &--bold {
    font-family: $f-app-font-bold;
  }
  
  &--description {
    margin-top: 0.5rem;
    color: $Slate;
    display: inline-block;
  }
  
  &--inverse {
    color: $Salt;
    border-radius: rem(2);
    &.form__label--error {
      color: $Salt;
      background: $Dragonfruit;
    }
  }
  
  &--disabled {
    color: $Ash;
    -webkit-transition: color 1s;
    transition: color 1s;
  }
}