.modules_library-site-category-stores {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  .modules_library-site-store-card {
    width: 280px;
    max-width: 280px;
    min-width: 280px;
    flex: 1;
    margin-right: 40px;
    border-radius: 2px;
    .screenshot {
      width: 280px;
      height: 200px;
      text-align: center;
      line-height: 200px;
      img {
        max-width: 280px;
        max-height: 200px;
        vertical-align: middle;
      }
    }
    .compareButton {
      top: 160px;
    }
    .name {
      padding-top: 12px;
    }
    .categories {
      display: inline-block;
      margin: 0;
      padding: 0;
    }
    .subscribed {
      display: block;
      font-size: .8em;
      margin: 0;
      padding: 0;
    }
    .fancyStarsLink {
      display: inline;
      .fancyStars {
        display: inline;
        text-align: left;
      }
    }
    .reviewCount {
      display: inline;
      padding-left: 12px;
      padding-top: 2px;
      padding-bottom: 12px;
    }
  }
}
