.category-mapper{
  .item{
    padding: 5px;
    a{
      color: $RRPurple;
      &:hover{
        color: $RRPurple;
      }
    }
  }
}