.data-table {
  background: $Salt;
  
  &__divider {
    position: absolute;
    bottom: 0;
    width: 100%
  }
  
  &__text-align {
    &-center {
      .data-table__cell { 
        text-align: center;
      }
    }
    
    &-right {
      .data-table__cell { 
        text-align: right;
      }
    }
  }
  
  &__head {
    .data-table__row { border-bottom: none; }
    .data-table__cell { 
      padding: 0rem;
      padding-right: 1rem;
    }
  }
  
  &__content {
    width: 100%;
  }
  
  &__row {
    .data-table__head & {
      .data-table__cell {
        text-align: left;
      }
    }
    
    .data-table__body & {
      .data-table__cell {
        padding: 0.5rem 1rem;
        padding-left: 0;
        line-height: 2.5rem;
        position: relative;
        
        > div:first-child {
          height: 100%;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}