.reviewItemWrapper{
  background: #EDEDED;
  padding: 25px;
  border-radius: 5px;
  border: 1px solid #EBEBEB;
}
.reviewItemShowCase{
  .authorContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-top: 15px;
    font-size: 1.25rem;

    a, span, i{
      margin-right: 6px
    }

    i{
      cursor: pointer;
    }
  }

  .verifiedContainer{
    color: #8dc63f;
    font-weight: bold;
    i{
      color: #8dc63f;
    }
  }

  .reviewTitle{
    font-weight: bold;
    padding: 0;
    margin: 10px 0;
  }

  .reviewBody{
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .rightAssetDetails{
    span{
      margin-right: 10px;
      i{
        margin-right: 5px;
      }
    }
  }
}