.site-user-avatar {
  img {
    display: none;
  }
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}
