.tabs {
  	@import "default";
  	@import "stacked";
  	@import "card";
  	@import "respondent";
	  @import "underline";
	  @import "highlight";

  &--flex {
    display: flex;
  }

	&--no-wrap {
		white-space: nowrap;
	}

	&__iconbar {
	  position: absolute;
	  right: 0;
	  top: 0;
	  height: 100%;
	  &__icon {
	    &:first-of-type{
	      border-left: rem(1) solid $Quartz;
	    }
	    height: 100%;
	    border-right: rem(1) solid $Quartz;
	    float: left;
	    font-size: rem(15);
	    button {
	      color: $Slate;
	      border: none;
	      padding: 0 rem(25);
	      height: 100%;
	      &:hover {
	        color: $Jellyfish;
	        border: none;
	      }
	    }
	  }
	}
	&__tabitem {
		&--before-icon {
			margin-right: 0.5rem;
		}
		&--after-icon {
			margin-left: 0.5rem;
		}
		&__radio {
		  margin-right: 0.5rem;
		  &--active {
		  	color: $Kiwi;
		  }
		  &--inactive {
		  	color: $Quartz;
		  }
		}
	}
}
