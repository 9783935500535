.summaryCard {
  width: 100%;
  overflow: auto;
  min-height: 84px;
  text-align: left;
  position: relative;

  .site-button-wrapper{
    margin: 0 10px 0 0;
    a.site-button{
      padding: 8px 15px;
      background: white;
      background-image: none;
      color: #6741E8;
      box-shadow: none;
      font-weight: 500;
      border-radius: 5px;
      border: 2px solid #6741E8;
      font-size: 1em;
      img {
        width: 20px;
        vertical-align: middle;
      }

      &.grey {
        background: #d5d5d5;
      }
    }
  }

  h4.normal{
    font-weight: normal;
  }

  .bigAvatarAlt{
    margin: 10px;
    height: 150px;
    background: #E0E0E0;
    width: 200px;
    text-align: center;
    padding: 50px 10px 10px;

    a{
      color: $RRMainDark;
      font-size: 1.1rem;
      font-weight: 700;
    }
  }

  span.bigAvatar{
    float: left;
    padding: 0;
  }

  .summaryCardBigAvatar{
    float: left;
    margin: 0 30px 0 0;
  }

  .underLink{
    font-size: 1.3rem;
    text-align: center;
    width: 100%;
    display: block;
  }

  .icon{
    color: $PinkAction;
    padding: 1.0rem 2rem 1.0rem 1.0rem;
    cursor: pointer;

    &:hover{
      color: $Coal;
    }
  }

  .imageIcon{
    margin-right: 15px;
    opacity: 0.8;
    width: 120px;

    &:hover{
      cursor: pointer;
      opacity: 1;
    }
  }

  &.big{
    &.bigAvatarContainer{

      .ratings-distribution{
        .ratings-bar{
          width: 280px;
          padding: 5px;
        }
        .progress::before{
          background-color: transparent;
        }
      }

      .reviewShowCase{
        .fancyStars{
          float: none;
        }
      }

      .imageIcon{
        width: 75px;
      }

      .align-middle{
        float: left;
      }
    }
  }

  &.avatarContainer{
    div.container{
      ul.listContent{
        margin-top: 20px;

        li{
          font-size: 1.5rem;
          i.fa-check{
            color: $Leaf;
          }
        }
      }
    }
  }

  &.bigAvatarContainer, &.big.bigAvatarContainer{
    div.container{
      padding: 10px 0 20px;
      overflow: auto;
      border-radius: 5px;

      ul{
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 145px;
        display: flex;
      }
    }

    &.vertical{
      div.container{
        ul{
          padding: 25px 0 10px;
          display: table;
          max-width: 265px;

          li{
            display: block;
          }
        }

        span{
          display: inline;
        }
      }
    }
  }

  div.container {
    background-color: white;
    padding: 10px 0 10px 0px;

    h5 {
      font-weight: normal;
      font-size: 12px;
      display: inline-block;
      margin: 0 10px 0 0;
    }

    ul {
      list-style: none;
      display: inline-block;
      margin: 0;

      &.items{
        vertical-align: top;
        padding-top: 15px;
      }
      &.iconItems{
        background-color: #da8bda78;
        padding: 10px 0 0 15px;
        border-radius: 10px;
      }
    }

    li {
      font-weight: 500;
      display: inline-block;
      margin-right: 25px;

      &.rightBlockContent{
        margin: 5px 0 0;

        .site-button:hover{
          background: #d5d5d5;
        }
      }
      
      &.itemIcon{
        cursor: pointer;
        padding-bottom: 0.765rem;
        border-bottom: 0.255rem solid transparent;

        span{
          color: $LightPinkAction;
          .pastelIcon{
            margin-left: -5px;
          }
        }

        &.selected, &:hover{
          border-bottom: 0.255rem solid #6740E8;
        }
      }
    }

    span {
      font-size: 22px;
      display: block;
      line-height: 28px;
      font-weight: 500;
    }
  }

  &.big {

    div.container {
      padding: 20px 0 20px 40px;

      span{
        font-size: 30px;
      }

      li{
        margin-right: 45px;
        font-size: 18px;
      }
    }

    &.vertical{
      div.container {
        li, span{
          font-size: 15px;
        }

        .reviewsCount{
          span{
            font-size: inherit;
            color: inherit;
          }
        }
      }
    }
  }


  .inlineAvatar{
    margin-top: 20px;
    max-width: 300px;

    h6{
      font-size: 1.5rem;

      &.email{
        font-size: 1.3rem;
      }
    }

    .underLink{
      text-align: left;
      margin-top: 10px;
      font-size: 1.2rem;
      margin-left: 5px;
    }
  }

  &.reviewSummary {

    div.container {
      h5 {
        font-size: 1.2rem;
        font-weight: 500;
      }

      p {
        font-size: 1.1rem;
      }
    }

    span a i {
      color: $RRMain;
    }

    .reviewAvatar {
      .underLink{
        text-align: left;
        margin-top: 5px;
        font-size: 1rem;
        margin-left: 0;
      }
    }

    .postedDate span {
      font-size: 1rem;
    }

    table.responsesTable {
      td {
        padding: 0 5px;

        span {
          display: inline-block;
        }
      }
    }
  }

  .reviewShowCase{
    padding: 0;

    .rating.huge{
      color: inherit;
      font-size: 24px;
      margin-bottom: 10px;
      font-family: 'Montserrat',sans-serif;
      font-weight: bold;
    }
  }
}

.tagsSummaryCard{
  .reactTagList.selectable{
    margin: 0 15px 20px;

    ul{
      margin:0;
    }
  }
}