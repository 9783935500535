
.categorySelector{
    min-height: 100px;

    label {
        display: inline;
    }

    .tag-remove {
        font-size: 1.5rem;
        margin-left: 0.5rem;
    }

    .tag{
        font-size: 1.2rem;
        padding: .2rem 1.0rem;
        color: $RRMain;
    }

    .tag-item{
        input{
            margin-bottom: 0;
        }
    }

    .Select{
        padding: 6px;
        border-radius: 4px;
        border: 1px solid #ccc;

        .Select-control{
            border: none;
        }
    }

    .dropdown-trigger{
        border-radius: 4px;
    }

}