.reactSideBarMenu {
      padding: 0 0 32px;
      width: 320px;

    &.light{
        background-color: $Salt;
    }

    &.left{
        position: absolute;
        top: 40px;
        bottom: 0;
        height: calc(100% - 93px);

        &.new{
            top: 122px;
            height: calc(100% - 122px);
        }

        &.scrollable{
            overflow-y: auto;
            height: auto;
        }
    }

    &.rightMenu{
        background: $Salt;
        position: absolute;
        top: 40px;
        bottom: 0;
        right: 0;
        height: calc(100% - 93px);

        &.scrollable{
            overflow-y: auto;
            height: auto;
        }
    }

    .sales-stack{
        display: none;
    }

    &.leftMenu, &.rightMenu{
        width: inherit;
        top: 58px;
        i{
            margin-top: 4px;
        }
    }

    &.rightMenu{
        top: 60px;
    }

    &.leftMenuFixed.leftMenu{ /* newer menu will eventually replace list menu */
        max-width: 325px;
        .listMenu{
            li a{
                display: block;
            }
        }

        .clearAble{
            top: 19px;
        }
    }

    a{
      font-size: 15px;
      color: $Obsidian;
    }

    .reactSeparator{
        position: relative;
    }

    .clearAble{
        position: absolute;
        right: 5px;
        top: 34px;
        opacity: 0.7;
        cursor: pointer;

        &:hover{
            opacity: 1.0;
        }
    }

    .listMenu{
        margin: 1rem 0 0 1.5rem;

        &.scrollable{
            overflow-y: auto;
            height: 100%;
        }

        li{
            padding: .5rem 1.2rem .5rem 1.0rem;
            cursor: pointer;

            a{
                color: inherit;
                display: inline-block;
            }

            &.disabled{
                background-color: inherit !important;
                cursor: initial;

                a,i,div{
                    color: $Ash !important;
                }
            }

            div.disabled{

                border-bottom: none!important;
            }

            span{
                float: left;
                font-weight: 600;
                font-size: 1rem;
                line-height: 2.3rem;
            }

            &:hover, &.selected{
                background-color: $LightGray;

                a,i{
                    color: $PinkAction;
                }
            }

            &.header{
                background-color: transparent;
                cursor: inherit;
                font-size: 2rem;
                margin-top: 1em;

                a{
                    color: $Obsidian;
                    cursor: default;
                    span{
                        margin-left: 3.0rem;
                    }
                }
            }

            &.subMenuItem{
                &.disabled{
                    a{
                        color: #888;
                    }
                }

                i{
                    margin-top: 3px;
                }
            }

            .dropdown{
                .dropdown_toggle{
                    border: 1px solid $PinkAction;

                    &:hover{
                        border: 1px solid $DarkPinkAction;
                    }
                }
            }

            .reactInput{
                border: 1px solid $PinkAction;

                &:hover{
                    border: 1px solid $DarkPinkAction;
                }
            }


        }

        &.wide{
            background: $Salt;
            margin: 10px 1.0rem 0;

            li{
                padding: .7rem 0.5rem .7rem 0.7rem;
                height: 3.0rem;

                span{
                    font-weight: bold;
                    width: 220px;
                    line-height: 1.5rem;
                }

                span + span{
                    width: 55px;
                    text-align: right;
                }
            }
        }

        &.secondaryMenu{
            margin-left: 0;

            li{
                margin-top: 0;
                .row{
                    .column{
                        .select__item{
                            font-size: 1.2rem;
                        }
                    }
                }
            }

            .popover .popover__label{
                color: #6741e8;
            }
        }
    }

    .purple-gradient-dropdown{
        padding: 10px 10px 5px;
        border-radius: 5px;
        background-image: -webkit-gradient(linear, left top, right top, from(#a321dd), to(#c600ea));
        background-image: linear-gradient(to right, #a321dd, #c600ea, #c600ea, #a321dd);
        background-size: 300% 100%;
        letter-spacing: .25px;
        white-space: nowrap;
        -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
        box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
        text-transform: uppercase;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
        color: white !important;
        &.select__button-caret:after{
            top: 5%;
            right: 3.05rem;
        }
    }

    .purple-gradient-dropdown:hover{
        background-position: 100% 0;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
        .fa-crown{
            color: white;
        }
    }

    h4{
        font-weight: 400;
        margin-bottom: 0;
    }

    div a{
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;
    }

    .extraLink{
        font-size: 12px;
        margin-bottom: 15px;
        margin-left: 20px;
    }

    .horizontalMenu a.selected,
    .horizontalMenu a:hover,
    .extraLink:hover{
        color: $Jellyfish;
    }

    .horizontalMenu i{
        margin-right: 10px;
        margin-left: 10px;
        font-weight: bold;
    }

    .horizontalMenuContainer{
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .horizontalMenu{
        margin-bottom: 20px;
    }

    .rightMenuSection{

    }
}