.footer-wrapper {

  #footer {
    font-family: 'Varela Round', sans-serif;
    @media screen and (max-width: 1023px) {
      min-height: 758px;
    }
    a {
      font-size: 14px;
      line-height: 1px;
      color: $RRPurple;
      font-weight: 300;
      padding: 1em 0;
    }

    .social-btns {
      #facebook {
        color: #3b5998;

        &:hover {
          color: #183b55;
        }
      }

      #twitter {
        color: #1dcaff;

        &:hover {
          color: #0084b4;
        }
      }

      #linkedin {
        color: #4875B4;

        &:hover {
          color: #455a96;
        }
      }
    }

    &.bottom-triangles {
      padding: 10px 0 0;
      background: url("/static/images/bot-triangle.png"), url("/static/images/top-triangle.png");
      background-repeat: no-repeat, no-repeat;
      background-position: bottom, bottom;
      background-size: 100% 411px, 100% 411px;
    }

    &.centered {
      text-align: center;
    }

    .uppercase {
      text-transform: uppercase;
      font-weight: 300;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: 'Montserrat', sans-serif;
    }

    .btm-part {
      padding-top: 42px;
      text-align: center;
      border-top: solid 1px rgba(142, 104, 104, 0.45);
    }

    p {
      margin-top: 10px;
      margin-bottom: 0;
      color: #535A5F;
      line-height: 30px;
      padding: 0;
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 500;
    }

    div.policy-div a {
      padding: 0 8px;
      color: $RRMain;
    }

    .menu {
      margin-top: 10px;
    }

  }
}