.modules_library-site-search-page-empty,
.modules_library-site-search-page-errors {
  align-items: center;
  background-color: $Smoke;
  border: 1px solid $SeaSalt;
  border-radius: 2px;
  display: flex;
  font-size: .8em;
  font-style: oblique;
  height: 300px;
  margin-top: 18px;
  text-align: center;
  div {
    flex: 1;
  }
  .call-to-action{
    font-style: normal;
    font-weight: 400;
  }
}
