@import '../colors';
@import "./../../frameworks/social";
@import './../../new_react_foundation';
@import "../modules_library/site/base";

ul.foundation_site_nav {
  z-index: 2;
}

body, html {
  min-width: 320px;
}

@media (max-width: 1023px) {
  .fancyBackground {
    padding-top: 10px;
  }
}

html{
  body{
    .cx-component-library{
      .reactSideBarMenu{
        .Select-value-label, .Select-placeholder{
          font-size: 1.3rem;
          line-height: 3.4rem;
        }
      }
    }
  }
}

.apps-site {
  .ui {
    .top-bar {
      &#consumer-site-nav {
        padding: 20px;
      }
    }
    .compare {
      .overlay {
        background: $Smoke;
        border-top: 1px solid $SeaSalt;
        bottom: 0;
        left: 0;
        padding: 1vw;
        position: fixed;
        text-align: center;
        width: 100%;
        box-shadow: 0 0px 8px 0 rgba(0,0,0,.25);
        font-family: "Varela Round", sans-serif;
        .stores {
          align-content: center;
          display: flex;
          justify-content: center;
          .store {
            text-align: center;
            font-size: .5em;
            min-width: 50px;
            overflow: hidden;
            padding: 1vw;
            position: relative;
            width: 9vw;
            svg {
              cursor: pointer;
            }
            .img {
              background-color: $Whale;
              background-position: center center;
              background-size: cover;
              height: 5.5vw;
              margin-bottom: 6px;
              min-height: 35px;
              min-width: 50px;
              width: 7vw;
              img {
                display: none;
              }
            }
            .close {
              background: $RRPurple;
              border-radius: 50%;
              color: $Salt;
              height: 18px;
              position: absolute;
              right: 4px;
              top: 4px;
              width: 18px;
              padding-top: 4px;
              @media (max-width: 750px) {
                font-size: .5em;
              }
            }
          }
          .control {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .button {
              margin-bottom: 0;
            }
            @media (max-width: 750px) {
              .modules_library-site-button {
                font-size: .5em;
                margin-bottom: .5rem;                
              }
            }
          }
        }
      }
    }
    .ListHeading {
      padding: 18px 0 38px;
      .topHeading {
        padding: 0;
      }
    }
    .fa-slick-arrow-prev {
      left: -25px;
      position: absolute;
      top: 50%;
    }
    .fa-slick-arrow-next {
      position: absolute;
      right: -25px;
      top: 50%;
    }
    .container {
      color: $Black;
      padding-top: 20px;
    }
    .animate-fade-in {
      animation: fadein 3s;
      -moz-animation: fadein 3s;
      -o-animation: fadein 3s;
      -webkit-animation: fadein 3s;
      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      /* Firefox */
      @-moz-keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      /* Safari and Chrome */
      @-webkit-keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
    .top-bar {
      .modules_library-site-search-bar {
        input {
          max-width: 100%;
        }
      }
    }
    .title-bar {
    }
    .sellerCategories {
      margin-bottom: 0;
      li {
        display: inline;
        font-size: .8em;
        list-style: none;
        padding-right: 12px;
      }
    }
    .grid-y {
      .sellerStats {
        background-color: $Smoke;
        margin-top: 0;
        padding: 8px 0;
      }
    }
    .TopContent {
      padding-bottom: 20px;
    }
    .topHeading {
      color: $Black;
      font-family: 'Open Sans', Verdana, Geneva, sans-serif;
      font-size: 1.4em;
      font-weight: medium;
      margin-bottom: 0;
      padding: 18px 0 12px;
    }
  }
}

