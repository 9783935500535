$header_height: 3rem;
$day_size: 3.5rem;
$day_width: 4rem;
$day_height: 3.5rem;
$icon_size: 4rem;
$inputHeight: 3.5rem;
.datepicker {
    
    &__navigation {
      position: absolute;
      top: 0;
      z-index: 3;
      &--left {
        left: 0;
      }
      
      &--right {
        right: 0;
      }
    }

    &__control {
      
    }
    
    &__monthname {
      line-height: rem(30) !important;
    }
    
    &__month {
        display: inline-block;
    }
    
    &__calendar {
      transition: all 0.2s;
      white-space: nowrap;
    }

    &__day {
      position: relative;
      height: $day_size;
      width: $day_size;
      font-size: 1.4em;
      text-align:center;
      cursor:pointer;
      display:inline-block;
      border: .2rem solid transparent;
      border-radius: 50%;
      line-height: $day_size - .3rem !important;
      // @include transition();

      &--hovered-day {
        background: #ffffff;
        border: .2rem solid $Quartz;
        border-radius: 50%;
        line-height: $day_size - .3rem;
      }
      
      &--selected {
        background: #ffffff;
        border: .2rem solid $Jellyfish;
        border-radius: 50%;
        line-height: $day_size - .3rem;
        //@include transition(color, 400ms, ease-in);
      }
      
      &--disabled {
        color: $Quartz;
        border-color: transparent;
      }

      &--start, &--last {
        // color: $Jellyfish;
        background: #ffffff;
        border: .2rem solid $Jellyfish;
        border-radius: 50%;
        line-height: $day_size - .3rem;
        //@include transition(color, 400ms, ease-in);
      }

      &--different-month {
        visibility: hidden;
        cursor: default;
        pointer-events: none;
      }

      &--different-month, &--future-dates {
        // color: $Quartz;
        cursor: default;
        pointer-events: none;
      }
    }
    
    &__day-container {
      border-bottom: solid rem(1) rgba(97,112,133,0.20);
      text-align: center;
      width: $day_width;
      height: $day_height;
      float:left;

      &--header {
        border: 0;
      }
      
      &:first-child {
        .datepicker__range {
          border-top-left-radius: 2.5em;
          border-bottom-left-radius: 2.5em;
        }
      }
      &:last-child {
        .datepicker__range {
          border-top-right-radius: 2.5em;
          border-bottom-right-radius: 2.5em;
        }
      }
    }
    
    &__range {
      position: relative;
      background: $Jellyfish;
      width: calc(100% + 1px); //Need to adjust so that it overlaps the 1px border-left.
      height: 2.5rem;
      float: left;
      top: -3rem;
      z-index: -1;
      
      &--leftlimit {
        width: 50%;
        float: right;
        // background: pink;
      }
      &--rightlimit {
        width: 50%;
        // background: green;
      }
    }
}

.new-datepicker {
  // color: $Slate;
  display: flex;

  & + .tooltip__caret {
    display: none;
  }

  &__calendarOptions {
    display: inline-block;
    width: 50%;
    padding-left: 2rem;

    &--label {
      margin-bottom: 1rem;
    }
    &--applyDate {
      float: right;
      margin-top: 3rem;
      margin-right: 2rem;
    }
  }

  &__range-container {
    display: inline-block;
  }

  &__custom-input-container {
    margin-bottom: 2rem;
    margin-left: 3rem;
    margin-top: 0.5rem;

    &--label {
      position: relative;
      top: 1rem;
      font-size: 1.2rem;
      // color: $Slate;
    }

    &--input {
      float: left;
    }

    &--dash {
      margin: 0 1rem;
      font-family: $f-app-font-bold;
      font-size: 1.6rem;
      position: relative;
      bottom: 0.4rem;
      vertical-align: bottom;
    }

    &--daysLeft {
      margin-left: 1rem;
      font-size: 1.2rem;
      // color: $Obsidian;
      position: relative;
      bottom: 0.7rem;
      vertical-align: bottom;
    }
  }

  &__input-container {
    &--hidden {
      visibility: hidden;
    }
  }

  .form__control {
    border: rem(1) solid $Quartz;
    overflow:hidden;
    text-align: center;
    margin-top: 1rem;
  }

  &__menu {
    display: inherit;
    padding: 2rem 1rem 1rem;
    border-right: rem(1) solid $Quartz;
    max-height: 45.2rem;
    overflow-y: auto;
    overflow-x: hidden;

    li {
      padding: 0 2rem;
      &.fancy_select__item--selected {
        background-color: $Jellyfish !important;
        // color: $Salt;
      }
    }

    &--divider {
      border-top: rem(1) solid $Quartz;
    }
    &--button {
      margin-bottom: 0.5em;
    }
  }

  &__calendar {
    margin:0 1rem;
    overflow:hidden;
    display: inline-block;
    max-width: 66rem;
  }

  &__header {
    text-align: center;
    // color: $Obsidian;
    line-height: $inputHeight;
    span {
      display: inline-block;
      font-size: 1.6rem;
      text-align: center;
      font-family: $f-app-font-regular;
    }
  }

  &__week {
    float: left;
    width: 100%;

    &--names .new-datepicker__day{
      // color: $Slate;
      font-size: 1.2em;
      border: none;
      cursor: default;
    }
    
    &:last-child .new-datepicker__day-container {
      border: none;
    }
  }

  &__grid {
    height: 100%;
    width: rem(1);
    // background-color: $Quartz;
  }

  &__day-container {
    border-bottom: solid rem(1) rgba(97,112,133,0.20);
    text-align: center;
    width: $day_width;
    height: $day_height;
    float:left;

    &--header {
      border: 0;
    }

    &:first-child {
      .new-datepicker__day-hover-block {
        border-top-left-radius: 2.5em;
        border-bottom-left-radius: 2.5em;
      }
    }
    &:last-child {
      .new-datepicker__day-hover-block {
        border-top-right-radius: 2.5em;
        border-bottom-right-radius: 2.5em;
      }
    }
  }

  &__day-hover-block {
    position: relative;
    background: $Jellyfish;
    width: calc(100% + rem(1)); //Need to adjust so that it overlaps the 1px border-left.
    height: 2.5rem;
    float: left;
    top: -3rem;
    z-index: -1;

    &--left {
      width: 50%;
      float:left;
    }

    &--right {
      width: 50%;
      float: right;
      position: relative;
      left: rem(1);
    }
  }

  &__day {
    position: relative;
    height: $day_size;
    width: $day_size;
    font-size: 1.4em;
    text-align:center;
    cursor:pointer;
    display:inline-block;
    border: .2rem solid transparent;
    border-radius: 50%;
    line-height: $day_size - .3rem !important;



    &--selected, &--hovered {

      &.new-datepicker__day--future-dates {
        background: none;
      }
    }

    &--hovered-day {
      background: #ffffff;
      border: .2rem solid $Quartz;
      border-radius: 50%;
      line-height: $day_size - .3rem;
    }

    &--start, &--last {
      // color: $Jellyfish;
      background: #ffffff;
      border: .2rem solid $Jellyfish;
      border-radius: 50%;
      line-height: $day_size - .3rem;
      //@include transition(color, 400ms, ease-in);
    }

    &--different-month {
      visibility: hidden;
      cursor: default;
      pointer-events: none;
    }

    &--different-month, &--future-dates {
      // color: $Quartz;
      cursor: default;
      pointer-events: none;
    }
  }
}
