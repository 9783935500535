a.modules_library-site-button, button.modules_library-site-button {
  background: linear-gradient(to right, $Heliotrope, $RoyalBlue);
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.35);
  border: 1px solid transparent;
  border-radius: 2px;
  color: $Salt;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  margin-bottom: 1rem;
  padding: .85em 1em;
  text-align: center;
  i {
    padding: 8px;
  }
  svg {
    margin-right: .5em;
  }
  vertical-align: middle;
  &.large{
    font-size: 1.3em;
    padding: 1em 1.8em;
  }
  &:hover{
    background: linear-gradient(to right, $RoyalBlue, $Heliotrope);
  }
  &:active, &.selected{
    box-shadow: none;
  }
  &[disabled]{
    opacity: .5;
    &:hover{
      background: linear-gradient(to right, $Heliotrope, $RoyalBlue);
      cursor: not-allowed;
    }
  }
}
.purple-gradient{
  font-weight: 700;
  background-image: -webkit-gradient(linear, left top, right top, from(#a321dd), to(#c600ea));
  background-image: linear-gradient(to right, #a321dd, #c600ea, #c600ea, #a321dd);
  background-size: 300% 100%;
  display: inline-block;
  color: #fff !important;
  padding: 15px 24px;
  border-radius: 1000px;
  font-size: .85rem;
  letter-spacing: .25px;
  white-space: nowrap;
  -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
  box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
  text-transform: uppercase;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.purple-gradient:hover{
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
