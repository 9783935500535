.modules_library-site-search-bar-suggest {
  background-color: #fff;
  border: 1px solid #cacaca;
  border-radius: 2px;
  color: #07080a;
  display: flex;
  right: 252px;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 10px 0;
  position: absolute;
  top: 100px;
  width: calc(78vw - 1025px);
  min-width: 300px;
  z-index: 100000;
  @media (min-width: 1024px) and (max-width: 1390px) {
    display: none;
  }
  a {
    display: block;
    padding: 4px 12px;
    &:hover {
      background-color: $Smoke;
      cursor: pointer;
    }
  }
  li {
    ul {
      font-size: 0.8em;
      font-style: oblique;
    }
  }
  .selectHeading { 
    font-size: .6em;
    font-weight: bold;
    padding: 4px 12px;
    text-transform: uppercase;
  }
  @media (max-width: 1024px) {
    left: 50px;
    top: 42px;
    width: calc(100vw - 100px);
  }
}
