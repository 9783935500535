@import "noUiSlider/nouislider";

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

.reactSideBarMenu {
   .noUi-horizontal {
     height: 8px;

     .noUi-handle {
       width: 18px;
       height: 18px;
       right: -10px;
       top: -6px;
       border-radius: 9px;
       box-shadow: none;
       cursor: move; /* fallback if grab cursor is unsupported */
       cursor: grab;
       cursor: -moz-grab;
       cursor: -webkit-grab;
     }

     .noUi-handle:active {
       cursor: grabbing;
       cursor: -moz-grabbing;
       cursor: -webkit-grabbing;
     }

     .noUi-handle:after,
     .noUi-handle:before {
       content: "";
       display: block;
       position: absolute;
       height: 9px;
       width: 1px;
       background: #e8e7e6;
       left: 5px;
       top: 3px;
     }

     .noUi-handle:after {
       left: 10px;
     }
   }

    .sliderLimitLabels {
      height: 19px;
      width: 262px; //fallback
      margin-top: 4px;
      font-size: 10px;
      position: relative;
      @include calc(width, "100% - 2px"); // removing the 2px of the slider border, so labels match

      .step {
        position: absolute;
      }

      .max-label {
        float: right;
      }
    }
}