.dashboard__grid {
  &.grid {
    margin-left: 0;
  }
  .grid__item {
    padding-left: 0;
  }
}

.dashboard_detail {
  &__tools {
    border-left: 1px solid $Quartz;

    .tabs {
      padding: 0 2rem;
      &__tabitem {
          width: 50%;
      }
      &__tabitem + .tabs__tabitem {
        margin: 0;
      }
    }
  }

  &__menu {
    // display: inline-block;/
    float: right;
    .popover__content {
      width: 24rem;
      right: 0rem;
      top: 0rem;
      text-align: left;
    }
  }

  &__button-group {
    .button {
      border-radius: 0;
      border-left: 0;

      &:first-of-type {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        border-left: 1px solid $Ash;
      }

      &:last-of-type {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      color: $Coal;
    }

    &--active{
      background: $Quartz;
      color: $Slate;
    }
  }
}

.dashboard-card {

  border: rem(5) solid transparent;// TODO:
  background-color: transparent;
  border-radius: $border-radius;
  position: relative;
  transition: all 0.2s ease-in;
  &--preview{
    overflow: hidden;
  }

  // width: 50%;
  // @include breakpoint(desk) {
  //   width: 100%;
  // }
  // @include transition(all, 250ms);

  &--clickable:hover {
    // border-color: rgba(2,159,234,0.2);
    background-color: rgba(2,159,234,0.2);
    cursor: pointer;

    .dashboard-card__container {
      border-color: $Jellyfish;
    }
  }

  &--draggable {
    .card_header {
      cursor: move;
    }
  }

  &__container {
    border-radius: $border-radius;
    background: $Salt;

    border: rem(1) solid rgba(183, 192,204, 0.6); //TODO
    box-shadow: rem(0) rem(2) rem(4) rgba(58,71,91,0.1); // TODO:

    height: 46rem;

    &--full {
      height: initial;
    }

    padding-bottom: 1rem;
    // overflow: hidden;
    transition: border-color 0.2s ease-in;
    &--draggable{
      border: .1em dashed rgba(183,192,204,60);
      cursor:pointer;
      background-color: transparent;
      opacity: 30%;
      z-index: 1;
      min-height: 40rem !important;
    }
  }

  &__ribbon{
    background: $Coal;
    position: absolute;
    width: 200px;
    text-align: center;
    top: 16px;
    right: -67px;
    padding: 1rem;
    @include transform(rotate(45deg));
    z-index: 2;

    .text {
      font-size: 1.4rem
    }
  }
}
