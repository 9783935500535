.reviewShowCase{
    padding: 30px;

    .fancyStars{

        span{
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    .reviewsCount{
        margin-top: 10px;
        font-size: 15px;
        color: $PinkAction;
    }

    .avatar{
        margin-right: auto;
        margin-left: auto;
    }

    p{
        font-size:18px;
    }

    .public_reply{
        margin-top: 30px;

        p{
            font-size: 0.9rem;
            font-style: oblique;
        }
    }

    h5{
        margin-bottom: 12px;
    }

    h1.rating.huge{
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    &.percentage{
        font-family: 'Open Sans', serif;
        font-weight: 300;

        &.pos{
            color: $Leaf;
        }

        &.neg{
            color: $Dragonfruit;
        }
    }

    &.horizontalLabel{
        padding: 10px;

        h5{
            margin-top: 5px;
        }
    }
}

.stores_reviews_show_case{
    .reviewShowCase{
        padding-top: 10px;
    }
}


.siteContent{
    .featuredReviews{
        padding: 15px 0;
        background-color: white;

        .fancyStars, .reviewBody, .avatar{
            margin-top: 5px;
        }

        .inlineReviewShowCase{
            padding: 15px 0;

            &.isFeatured{
                background-color: #F5EEF6;
            }

            a{
                color: $RRMain;
                font-weight: bold;
            }

            .inlineActions{
                i{
                    float: left;
                    cursor: pointer;
                }
            }
        }

        margin-bottom: 150px;
    }
}

