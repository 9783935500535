.thumbnail {
  border-radius: $border-radius;
  margin-bottom: 1rem;
  background: $Salt;

  @include transition(all, 250ms);
  @include shadow(1);
  width: rem(195);
  height: rem(120);
  position: relative;
  float: left;
  margin-right: rem(20);
  margin-bottom: rem(20);
  
  &:hover {
    .thumbnail__preview {
      opacity: 1;
    }
  }
  
  &__image {
    width: 100%;
    height: rem(80);
    background-size: cover;
  }
  
  &__label {
    height: rem(80);
    line-height: rem(80);
    text-align: center;
    background: $Quartz;
    color: $Slate;
    font-size: rem(24);
    font-family: $f-app-font-regular;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  
  &__preview {
    opacity: 0;
    background: $NinetyCoal;
    @include transition(opacity, 300ms);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    text-align: center;
    height: rem(80);
    line-height: rem(100);
    cursor: pointer;
    
    .thumbnail__tool {
      color: $Salt;
      font-size: rem(36);
    }
  }

  &:hover {
      .thumbnail__tools {
          opacity: 1;
      }
  }
  
  &__details {
    border-top: rem(1) solid $Ash;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $Salt;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    @include transition(all, 300ms);
  }

  &__tools {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: right;
      margin-top: 0.5rem;
      opacity: 1;
  }

  &__tool {
      font-size: 2rem;
      margin-right: 0.5rem;
      cursor: pointer;
      color: $Slate;
  }

  &__name, &__subtext {
    width: rem(148);
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    padding: rem(9) rem(10);
  }
  
  &__subtext {
    margin-top: -2rem;
  }
}
