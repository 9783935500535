.form-text-area {
  border-radius: $border-radius;
  &--container {
  }
  &--default-height.form__control {
    height: auto;
  }
  &--label {
    font-size: 1.2rem;
  }
}