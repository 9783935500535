.title-editor-nav {
  background: $Coal;
  height: 5rem;

  &__title {
    display: inline-block;
    vertical-align: middle;
    // width: calc(100% - 8.5rem);
    
    & .form-input--material__line {
      border-color: transparent;
    }
    
    &--input {
      background-color: $Coal;
      color: $Salt;
      font-size: 1.6rem;
    }
      
    &--edit {
      margin-left: 0.5rem;
    }
  }
  &__title__bgtransparent {
    display: inline-block;
    vertical-align: middle;
    // width: calc(100% - 8.5rem);
    
    & .form-input--material__line {
      border-color: transparent;
    }
    
    &--input {
      background-color: $Salt;
      color: $Coal;
      font-size: 1.6rem;
      border:0;
      font-weight: bold;
      &:active, &:focus {
        border: none;
        box-shadow: none;
      }
    }
    
      
    &--edit {
      margin-left: 0.5rem;
    }
  }
  &__icon {
    display: inline-block;
    vertical-align: middle;

    &--edit {
      margin-left: 0.5rem;
    }
  }
  &__left-node {
    display: inline-table;
    height: 100%;
    
    &--container {
      display: table-cell;
      vertical-align: middle;
    }
  }
  &__right-node {
    float: right;
    display: table;
    height: 100%;

    &--container {
      display: table-cell;
      vertical-align: middle;
    }
  }
}
