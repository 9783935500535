.grid__menu-options {
  .cx-icon {
    color: $Ash;
  }

  &:hover {
    background-color: $Quartz;

    .cx-icon {
      color: $Ash;
    }
  }
}

.dropdown-flyout__header--active {
  .grid__menu-options {
    background-color: $Slate;

    .cx-icon {
      color: $Salt;
    }
  }
}

.grid__menu-option {
  padding: 1rem;
  cursor: pointer;
  font-weight: normal;
  text-align: left;

  &:hover {
    background-color: $Slate;
    color: $Salt;
    .text {
      color: $Salt;
    }
  }

  &__icon {
    margin-right: 1rem;
  }
}

.grid__dropdown-flyout {
  height: 100%;

  .dropdown-flyout__header {
    height: 100%;

    .text {
      display: block;
      height: 100%;

      .cx-icon {
        line-height: 4rem;
      }
    }
  }
}

.grid__flyout {
  .grid__menu-option:first-child:hover {
    ~ .flyout__caret:before {
      background-color: $Slate;
    }
  }
}

.grid--inline {
  display: inline-block;
}

.grid-wrapper {
  &__search {
    width: rem(200);
    margin: $gutter / 2 $gutter;
  }
}
.grid--padded {
  display: 1.4rem 0 !important;
}

.custom-grid--clickable .z-row {
  cursor: pointer;
}

.custom-grid--clickable .z-cell {
  cursor: pointer !important;
}

.custom-grid .z-show-menu {
  visibility: hidden;
}

.custom-grid--editable .z-show-menu{
  visibility: visible;
}

.react-datagrid .z-header-wrapper .z-header {
  padding-right:0 !important;
}

.react-datagrid .z-header-wrapper .z-header.z-has-vertical-scroller {
  padding-right: 15px !important;
}

.react-datagrid .z-header {
  width: 100%;
}

.react-datagrid .z-has-vertical-scroller {
  width: calc(100% - 14px) !important;
}

.react-datagrid .z-header-wrapper {
  background: #ffffff;
  border-top: 1px solid;
  tom: none;
  -webkit-transition: all, 250ms, ease;
  transition: all, 250ms, ease;
  box-shadow: 0 0.1rem 0.3rem #B7C0CC, 0 0.1rem 0.2rem #B7C0CC;
  box-shadow: 0 0.1rem 0.3rem #B7C0CC, 0 0.1rem 0.2rem #B7C0CC;
  z-index: 11;
}

.react-datagrid .react-load-mask {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  opacity: .7;
}

.react-datagrid  .react-load-mask--visible {
  display: block;
}

.react-datagrid .z-inner {
  border-bottom: 1px solid #eceff4;
}

.react-datagrid .z-header-wrapper .z-asc .z-content,
.react-datagrid .z-header-wrapper .z-desc .z-content{
  background: #f8f9fc;
}

.react-datagrid .z-header-wrapper .z-sortable .z-content:hover{
  background: #E9ECF2 !important;
}

.react-datagrid .z-header-wrapper .z-sortable .z-content {
  cursor: pointer;
}

.react-datagrid .z-header-wrapper,
.react-datagrid .z-header-wrapper .z-column-header,
.react-datagrid .z-header .z-column-header.z-cell:not(.z-last),
.react-datagrid .z-header .z-column-header.z-cell.z-last,
.react-datagrid .z-header-wrapper .z-column-header,
.react-datagrid .z-header-wrapper,
.react-datagrid .z-column-header .z-icon-sort-info,
.react-datagrid .z-column-header.z-asc .z-icon-sort-info,
.react-datagrid .z-column-header.z-desc .z-icon-sort-info,
.react-datagrid .z-column-header.z-drag,
.react-datagrid.z-with-column-menu .z-column-header .z-show-filter,
.react-datagrid.z-with-column-menu .z-column-header .z-show-menu{
  border-color:#eceff4;
}

.react-datagrid .z-column-header .z-content{
  font-size: 1.4rem;
  color:#363e4e;
  width: 100%;
  padding: 1rem 2rem !important;
  font-family: Jost, "ProximaNova-Semibold";
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-weight: normal;
}

.menu-row:hover{
  background-color:#fff !important;
}

.menu-row td {
  font-size:1.2em;
}

.react-datagrid .z-column-header.z-desc .z-icon-sort-info,
.react-datagrid .z-column-header.z-asc .z-icon-sort-info{
  border:none !important;
}

.react-datagrid.z-cell-ellipsis .z-row .z-cell .z-content{
  font-size:1.4rem;
  color:#3a475b;
  width: 100%;
  word-wrap: break-word;
}

.react-datagrid .z-row .z-cell .z-content{
  padding:7px 17px !important;
  height: 100%;
}

.react-datagrid .z-row .z-cell .z-content > div{
  margin-top:0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  height: 100%;
}

.react-datagrid .z-row .z-cell .z-content > div div[class^='score__bubble']{
  font-size: 16px;
  padding-top: 2px;
}

.react-datagrid .z-row .z-cell{
  border-bottom: 1px solid;
  border-right:1px solid;
  border-color:#eceff4;
}

.react-datagrid .z-row .z-cell:last-child {
  border-right: none;
}

/*
.react-datagrid .z-table .z-row{
  height: auto !important;
}*/


.react-datagrid.z-style-alternate .z-odd.z-row,
.react-datagrid .z-row{
  background: #ffffff;
}

.react-datagrid .z-row:hover,
.react-datagrid .z-row:focus {
  background: #f4f5f8 !important;
}

.custom-grid--no-hover .z-row:hover,
.custom-grid--no-hover .z-row:focus {
  background: transparent !important;
}

.react-datagrid .z-not-selectable:hover,
.react-datagrid .z-not-selectable:focus {
  background: #fff !important;
}

.react-datagrid .z-not-selectable .z-content {
  opacity: .6;
  cursor: default;
}

.z-show-menu{
  display: block !important;
  border: none! important;
  padding: 0 0px 0 4px;
}

.z-show-menu > svg{
  display: none;
}

.z-show-menu > i{
  color:#bac2ce;
  font-size: 20px;
  position: relative;
  top: 10px;
  right: 12px;
  font-weight: 100;
}

.z-content-wrapper-fix{
  width:100%;
  max-width:100% !important;
}

/*.z-vertical-scrollbar{
  display: none;
}*/

.z-header-menu-column.z-menu{
  border:none !important;
  transition: all, 250ms, ease;
  box-shadow: 0 0.1rem 0.3rem #B7C0CC, 0 0.1rem 0.2rem #B7C0CC;
  box-shadow: 0 0.1rem 0.3rem #B7C0CC, 0 0.1rem 0.2rem #B7C0CC;
  padding:15px !important;
}

.react-datagrid .z-row.z-selected.active,
.react-datagrid .z-row.z-selected.z-odd.active {
  background: #627085;
}
