.modules_library-site-search-bar-input {
  position: relative;
  .textInput {
    border-radius: 4px;
    margin: 0;
    padding: 24px 24px 24px 40px;
    width: 100%;
  }
  .fa-search {
    left: 12px;
    position: absolute;
    top: 16px;
  }
  .fa-filter {
    position: absolute;
    right: 12px;
    top: 13px;
    color: $DarkQuartz;
    &.open,
    &:hover {
      color: $Coal;
    }
  }
  @media (min-width: 501px) {
    .fa-filter {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    .textInput {
      padding: 14px 24px 14px 40px;
    }
    .fa-search {
      top: 13px;
    }
    .fa-w-16 {
      font-size: .8em;      
    }
  }
}
