@function rem($target, $context: $basefont) {
  @return ($target / $context) * 0.85rem;
}

.ReactModalPortal { 
  .development_mode{
    .mainAlert-column{
      .banner{
        padding: 20px;
      }
    }
  }
  .survey-alert{
    .break{
      height: 0;
      flex-basis: 100%;
    }
    .survey-alert-header{
      display: flex;
      align-items: center;
      padding: 30px;
    }
    .survey-alert-item{
      margin-bottom: 10px;
      padding: 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background: #e6e6e6;
      border-radius: 20px;
      font-weight: bold;
      p{
        font-size: 12px;
      }
      .title{
        font-size: 1.5em;
      }
      .data-item{
        .fa-crown{
          background: linear-gradient(to right, #ba57ff, #6740e8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
        }
        .data-card--gradient{
          justify-content: center;
          color: white;
          .fa-crown{
            background: transparent;
            -webkit-text-fill-color: currentColor;
            -moz-text-fill-color: currentColor;
          }
         &a{
            color: white;
          }
        }
        font-size: 1.5em;
        a{
          color: #58595b;
        }
        .data-text{
          color: #e8336e;
          font-weight: bold;
          font-size: .7em;
        }
      }
      &.survey-alert-warning{
        background-color: #d10027;
        color: white;
        .data-text{
          color: white;
        }
      }
      &.survey-alert-notice{
        background-color: #ffac07;
      }
      &.survey-alert-active{
        background-color: #7aca15;
      }
    }
  }
  .closeButton{
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 8px;
    top: 8px;
    color: $LightPinkAction;

    &:hover{
      color: $PinkAction;
    }
  }
  .store-welcome-form {
    .upload-file{
      input{
        width: auto;
      }
    }
    .validation-error {
      color: red;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .form-button {
      margin-top: 25px;
      &:hover{
        color: white;
      }
    }
    .upload-file{
      padding-top: 10px;
      .form-input{
        margin: auto;
        width: auto;
      }
    }
    .align-items-center{
      align-items: center;
    }
  }
  .rr-purple{
    color: $RRPurple;
  }
  .mobile-info{
    background: #f3f3f3;
  }
  .openbox {
    background-color: #fff;
    color: $PinkAction;
    border: 1px solid $PinkAction;
    font-size: 20px;
    box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: $PinkAction;
      color: #FFF;
      border: 1px solid $PinkAction;
    }
    &.button {
      padding: 0.85em 4em;
    }
  }
  .callout.banner {
    background-color: $PinkAction;
    color: #fff;
    h4, h6, a {
      color: #fff;
    }
    a {
      text-decoration: underline;
    }
    p {
      font-size: 1em;
    }
  }
  #rating-info-modal.cx-component-library {
    padding: 15px;
    .text--subtext {
      font-size: 1rem;
      line-height: 1.385rem;
      font-weight: 400;
    }
    .slick-desc{
      margin-left: auto;
      margin-right: auto;
      width: 300px;
      font-size: 1rem;
    }
  }
  .inputContainer{
    label {
      span {
        color: $PinkAction;
      }
    }
  }
  .social .survey .columns {
    @media screen and (min-width: 40em) {
      padding-left: 1.9375rem;
      padding-right: 1.9375rem;
    }
    a, .SocialMediaShareButton {
      width: 32px;
    }

  }
  #optionalQuestionsContainer {
    .fade-transition-enter {
      opacity: 0.01;
    }
    .fade-transition-enter.fade-transition-enter-active {
      opacity: 1;
      transition: opacity 250ms ease-in;
    }
    .fade-transition-leave {
      opacity: 1;
      position: absolute;
    }
    .fade-transition-leave.fade-transition-leave-active {
      position: absolute;
      opacity: 0;
    }
    .fancyStars {
      .ratingMessageSpan {
        text-align: center
      }
    }
  }
  .modules_library-site-button{
    &:hover{
      color: white;
    }
  }
  .settings {
    &__header {
      .settings__header-text {
        padding: rem(8) rem(10);
        border-bottom: 1px solid rgba(34, 43, 60, .5);
      }
    }
  }
  .features-modal{
    .settings {
      &__header {
        .settings__header-text {
          border-bottom: none;
        }
        padding-left: 0;
      }
    }
    .text{
      padding-top: 10px;
    }
    .button{
      border-radius: 10px;
    }
  }

}
.rfModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 85%;

  font-family: 'Open Sans',Verdana,Geneva,sans-serif;

  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling:     touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;

  .ReactTable{
    .rt-table{
      .rt-tbody{
        font-size: 18px;
        text-align: center;
      }
    }
  }

  h1, h2, h3, h4 {
    font-family: 'Montserrat', sans-serif;
    color: #444;
    font-weight: bold;
  }

  h1 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #58595b;
  }

  .rr-pink{
    color: #eb018b;
  }

  .surveyGoals{
    .checklist{
      align-items: center;
      &:before {
        content: "\f0c8";
      }
    }
    &:hover{
      .checklist{
        &:before {
          color: green;
          content:"\f14a";
        }
      }
    }
  }

  .yesNoDialog{
    margin: 15px;

    .button{
      float: right;
      margin-left: 25px;
    }
  }

  a{
    color: $PinkAction;

    &:hover{
      color: $DarkPinkAction;
    }
  }

  .rr_review_benefits {

    h3, h4, p {
      font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    }
    h3 {
      img {
        height: 37px;
        margin-top: -10px;
      }
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 20px;
      font-weight: normal;
    }

    h4 {
      font-size: 1.05rem;
    }
  }


}

.rfModalDialog{
  p{
    color: #333;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .button-modal{
    position: absolute;
    bottom: 5rem;

    .button-ok{
      position: fixed;
      right: 2rem;
    }

    .button-cancel{
      position: fixed;
      right: 6rem;
    }
  }
}

.reveal-modal {
  background: $Salt;
  visibility: hidden;
  display: none;
  top: 100px;
  left: 50%;
  margin-left: -260px;
  width: 520px;
  position: absolute;
  z-index: 41;
  padding: 30px;
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,.4);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,.4);
  box-shadow: 0 0 10px rgba(0,0,0,.4);

  &.large {
    width: 60%;
    margin-left: -30%;
  }

  .close-reveal-modal {
    font-size: 2.2rem;
    line-height: .5;
    position: absolute;
    top: 8px;
    right: 11px;
    color: #aaa;
    text-shadow: 0 -1px 1px rgba(0,0,0,.6);
    font-weight: 700;
    cursor: pointer;
  }
}
