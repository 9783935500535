.data-preview {
  &__title {
    background: $Quartz;
  }
  
  &__datum {
    min-height: rem(42);
    background: $HalfQuartz;
    border: 1px solid $Quartz;
    border-bottom: none;
    
    &:last-of-type {
      border-bottom: 1px solid $Quartz;
    }
  }
}
