.modules_library-site-review-speechBubble {
  .bubble {
    background: $SeaSalt;
    border-radius: 4px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 32%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top-color: $SeaSalt;
      border-bottom: 0;
      border-left: 0;
      margin-left: -10px;
      margin-bottom: -20px;
    }
    .blurOut {
      padding: 20px;
      position: relative;
    }
    .blurOut:after {
      background: linear-gradient(90deg,rgba(243,243,243,0),rgba(243,243,243,1));
      bottom: 2px;
      content: '';
      height: 42px;
      left: 0;
      position: absolute;
      width: calc(100% - 2px);
    }
    .icon {
      float: left;
      padding-right: 8px;
    }
  }
  &.featured {
    .bubble {
      background: $RRPurpleFaded;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 32%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top-color: $RRPurpleFaded;
        border-bottom: 0;
        border-left: 0;
        margin-left: -10px;
        margin-bottom: -20px;
      }
      .blurOut:after {
        background: linear-gradient(90deg,rgba(229, 197, 222, 0),$RRPurpleFaded);
        bottom: 2px;
        content: '';
        height: 42px;
        left: 0;
        position: absolute;
        width: calc(100% - 2px);
      }
    }
  }
}