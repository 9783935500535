.delta {
  .cx-icon {
    margin-right: 1rem;
  }
  
  &__value {
    display: inline-block;
    width: 2.5rem;
  }

  &__block {
    min-width: 7rem;
    min-height: 3.4rem;
    padding: $gutter / 4;
    text-align: center;
    display: inline-block;
    border-radius: $border-radius;
    &--positive {
      background: $Kiwi;
    }
    &--negative {
      background: $Dragonfruit;
    }
    &--neutral {
      background: $Quartz;
    }
  }
}