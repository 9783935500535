.range-slider {
  position: relative;
  width: 100%;
  margin-bottom: 3rem;
  
  &--rounded {
    .range-slider {
      &__dot {
        background: transparent;
      }

      &__thumb {
        height: 1.6rem;
        width: 1.6rem;
        border-radius: 50%;
        top: -.5rem;

        &:before {
          content: none;
        }
      }
    }
  }

  &__input {
    width: 100%;
    height: .6rem;
    cursor: pointer;
    background: $Quartz;
    border-radius: 3rem;
    position: relative;
    
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }
  }
  
  &__thumb {
    background: $Jellyfish;
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    position: absolute;
    top: -.4rem;
    margin-left: -.6rem;
    
    &:before {
      border-top: .9rem solid $Jellyfish;
      border-left: .6rem solid transparent;
      border-right: .6rem solid transparent;
      content: "";
      height: 0;
      left: 0;
      position: absolute;
      top: 1.2rem;
      width: 0;
    }
  }
  
  &__label {
    &-container {
      width: 100%;
      position: absolute;
      top: 1.5rem;
    }
    display: inline-block;
    position: absolute;
    
    &:first-child {
      margin-left: -.4rem;
    }
    &:last-child {
      margin-left: .4rem;
    }
  }
  
  
  &__dot {
    &-container {
      width: 100%;
      position: absolute;
      top: 0.1rem;
    }
    
    width: .4rem;
    height: .4rem;
    border-radius: 100%;
    background: $Water;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    margin-left: -0.2rem;
  }

}

.range-labels {
  position: relative;
  padding: 0;
  list-style: none;
}
.range-labels li {
  color: #637084;
  font-size: 10px;
  display: inline-block;
  position: absolute;
  text-transform: uppercase;
  text-align: center;

}

.range-labels li:first-child {
  text-align: left;
  left: 0 !important;
}


.range-labels li:first-child::after {
      margin-left: 0.4rem;
}

.range-labels li:last-child {
    text-align: right;
    left: auto !important;
    right: 0 !important;

}

.range-labels li:last-child::after {
    margin-right: 0.4rem;
}


.range-labels li::after{
  position: relative;
  content: "";
  width: 4px;
  height: 4px;
  background: #127DC7;
  border-radius: 50%;
  top: -3.1rem;
  left: 0;
  display: block;
  margin: auto;
}

.range-labels .active {
  color: #37adbf;
}
.range-labels .selected::before {
  background: #37adbf;
}
.range-labels .active.selected::before {
  display: none;
}
