.modules_library-site-search-bar-algo {
  border: 1px solid $Tangerine;
  border-radius: 3px;
  padding: 20px;
  position: relative;
  h1 {
    font-size: 1.2em;
    padding-top: 30px;
  }
  h2 {
    font-size: 1em;
  }
  label {
    line-height: 1;
    margin-bottom: 1rem;
    input {
      margin: .5rem 0 0;
    }
    span {
      font-size: .6em;
    }
  }
  p {
    font-size: .8em;
  }
  span {
    &.isAdmin {
      color: $Tangerine;
      font-size: 3em;
      font-weight: bold;
      line-height: .6;
      margin: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
