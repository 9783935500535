$react-dates-color-primary: #00a699 !default;

.DateRangePickerInput__arrow svg{
  width: 14px;
  height: 14px;
}

.DateRangePickerInput__clear-dates{
  margin-right: 0;
  margin-left: 0;
}

.CalendarDay, .CalendarDay__button {
  font-weight: bold;
}

.DateInput{
  font-size: 1.2rem;
  width: 110px;
  font-weight: 600;
}

.DateRangePicker__picker{
  z-index: 2;
}

.DateInput__display-text::after{
  z-index: 3;
}

.CalendarInfo{

  button {
    position: relative;
    height: 100%;
    text-align: center;
    background: none;
    border: 2px solid $react-dates-color-primary;
    color: $react-dates-color-primary;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow:visible;
    box-sizing: border-box;
    cursor: pointer;

    &:active {
      outline: 0;
    }

    &.selected, &:hover{
      color: $Salt;
      background: $react-dates-color-primary;
    }
  }

  .DateRangePresets{
    .panel {
      padding: 0 22px 11px 12px;
    }
  }
}

.dateRangeFilter{
  .DateRangePickerInput{
    min-width: 280px;
    border: 2px solid #C8C8C8;
  }

  &.center{
    .DateRangePicker{
      text-align: center;
    }
  }
}
