.score{
  margin-top: 1rem;

  &__bubble{
    margin-right: 1rem;
    padding: 0.1rem;
    border-radius: 50%;
    text-align: center;
    color: $Salt;
    display: inline-block;

    &--small {
      width: 2.7rem;
      height: 2.7rem;
      line-height: 2.5rem;
      font-size: 1.6rem;
    }

    &--medium {
      width: 3.5rem;
      height: 3.5rem;
      line-height: 3.3rem;
      font-size: 2rem;
    }

    &--large {
      width: 3.8rem;
      height: 3.8rem;
      line-height: 3.6rem;
      font-size: 2.2rem;
    }

    &--square{
      border-radius: 0.5rem;
      background: $Dragonfruit;

      &--stars{
        vertical-align: top;
        line-height: 0;
        margin-bottom: 0.3rem;
        margin-top: 0em;

        span{
          line-height: 0.2rem;
        }
        i{
          font-size:0.6rem;
        }
      }
    }

    &--positive {background: $Kiwi;}
    &--negative {background: $Dragonfruit;}
    &--warning {background: $Bananarama;}
  }


  &__label{
    display: inline;
    font-size: 2rem;

    &--small {
      font-size: 1.6rem;
    }

    &--medium {
      font-size: 2rem;
    }

    &--large {
      font-size: 2.2rem;
    }
  }
}
