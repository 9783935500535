.modules_library-site-category-page {
  padding: 0 24px;
  p,
  .bullets {
    font-size: .8em;
    font-style: normal;
  }
  &.grid-container {
    max-width: 100%;
  }
  section {
    padding-bottom: 30px;
    .scrollable {
      margin-top: 12px;
    }
  }
  .moreLink {
    padding-top: 34px;
  }
  .view-search-unified-empty {
    margin-top: 12px;
  }
  .content {
  }
  .bullets {
    ul {
      list-style: inside;
      padding: 12px 24px;
      li {
        list-style: inside;
      }
    }
  }
  @media (max-width: 500px) {
    padding: 0 12px;
    .topHeading {
      font-size: 1em;
    }
  }
}
