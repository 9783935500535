.modules_library-site-search-page-featured {
  .modules_library-site-store-card {
    padding-bottom: 40px;
    div {
      padding-bottom: 12px;
    }
    .screenshot {
      img {
        width: 100%;
      }
    }
    .compareButton {
      float: right;
      margin-right: 20px;
      margin-top: -3vw;
      position: relative;
      right: auto;
      top: auto;
    }
    .name {
      padding-top: 12px;
      word-wrap: break-word;
    }
    .categories {
      display: inline-block;
      margin: 0;
      padding: 0;
    }
    .subscribed {
      display: inline-block;
      font-size: .8em;
      margin: 0;
      padding: 0;
    }
    .fancyStarsLink {
      display: inline;
      &:before {
        content: "\A\A";
        white-space: pre;
      }
      .fancyStars {
        display: inline;
        text-align: left;
      }
    }
    .reviewCount {
      display: inline;
      padding-left: 12px;
      padding-top: 2px;
      padding-bottom: 12px;
    }
  }
}
