.modules_library-site-category-children {
  position: relative;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  .previewChild {
    font-size: .7em;
    text-transform: uppercase;
    width: 230px;
    margin-right: 12px;
    border-radius: 2px;
    position: relative;
    text-align: center;
    &:hover {
      div {
        &.previewChildName {
          background-color: $SeaSalt;
        }
      }
    }
    div {
      &.previewChildName {
        font-weight: bold;
        padding: 20px;
        border: 2px solid #949494;
        border-radius: 4px;
      }
      &.previewChildLink {
        font-weight: bold;
        padding: 0 0 6px;
        font-size: .6em;
      }
      &.previewChildImage {
        position: relative;
        img { 
          position: relative;
          width: 230px;
          height: 316px;
        }
      }
    } 
  }
}
