.pagination {
  color: $Obsidian;
  font-size:1.5rem;
  display: inline-block;
  &__cell {
    display:inline-block;
    vertical-align: middle;
    padding:0rem 1rem 0rem 0rem;
    line-height: 3.5rem;
  }
  &__button {
    cursor: pointer;
    color: $Obsidian;
    list-style: none;
    display: inline-block;
    vertical-align: middle;
    line-height: 3.5rem;
    li{
      display: inline-block;
      margin-left: 1rem;
    }
    &--disabled {
      color: $Ash;
      cursor: auto;
    }
  }
  .select__item{
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.3rem;
  }
}
