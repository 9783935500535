.modules_library-site-search-bar-selected {
  .select-selected {
    background-color: $Ash;
    color: $Coal;
    .sellerStarsImage {
      display: inline;
    }
  }
}
@media (max-width: 1024px) {
  .modules_library-site-search-bar-selected {
    display: none;
  }
}
