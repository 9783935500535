[class^="cx-icon--"], [class*=" cx-icon--"] {
	font-family: 'cx-icon-font';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	// Better Font Rendering ===========
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

    &.fal {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
    }

    &.far, &.fa {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
    }

    &.fas {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
    }

    &.fab {
      font-family: 'Font Awesome 5 Brands';
    }
}
.cx-icon-group,.cx-icon-group-default {
	li {
		display: inline-block;
		margin-right:1em;
		&:last-child {margin-right:0;}

    &.cx-icon--labelled{
      width: auto;

      button{
        padding: 0 1em;
        span:last-of-type{
          padding-left: 0.5em;
        }
      }
    }
	}
	&--toolbar {
		.cx-icon {
			color: $Coal;
      &:hover {
        color:$Jellyfish;
      }
      &.cx-icon--warning, &--attention, &--attention:hover {
        color: $Dragonfruit;
      }
		}
	}
	&--bordered {
		@extend .clearfix;
		li {
			display:block;
			float:left;
			margin: 0;
			border: rem(1) solid $Quartz;
			height: 3.3em;
			width: 3.3em;
			line-height:3;
			border-right:none;
			&:last-child {
				border-right: rem(1) solid $Quartz;
			}
			.cx-icon {
				line-height:normal;
				text-align: center;
				height:100%;
				width:100%;
			}
		}
	}
}

.cx-icon {
	background:none;
	font-size: rem(14,10);

    &.bold{
      font-weight: bold;
    }

    &--clickable {
      cursor: pointer;
    }
  
	&--list{
		position: relative;
		margin: 1rem;
		cursor: pointer;
		border-radius: rem(3);
		padding-top: 1rem;
		display: inline-flex;
		flex-direction: column;
		text-align: center;
		background: $Quartz;
	}
	
	&--align-text-bottom {
		vertical-align: text-bottom;
	}
	
	&--align-middle {
		vertical-align: middle;
	}

  &--inverse {color:$c-app-white;}
	&--button {
		height:3rem;
		width:3rem;
		text-align: center;
		&-reorder {
			height:3rem;
		}
	}
	&--small {
	  font-size: rem(12, 10);
	}
	&--medium {
		font-size: rem(15);
	}
	&--large {
	  font-size: rem(20);
	}
	&--xlarge {
		font-size:2.6rem;
	}
	&--display {
		font-size:3.4rem;
	}
	&--disabled{
    color: $Quartz;
    cursor: auto;
  }
	&--loader{
    animation: rotate 1.2s linear infinite;
    transform-origin: 50% 55%;
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &--username:before {
    content: "\e900";
  }
  &--history:before {
    content: "\e901";
  }
  &--arrow-down:before {
    content: "\e900";
  }
  &--arrow-left:before {
    content: "\e901";
  }
  &--arrow-right:before {
    content: "\e902";
  }
  &--arrow-up:before {
    content: "\e903";
  }
  &--attention:before {
    content: "\e904";
  }
  &--back-up:before {
    content: "\e905";
  }
  &--background:before {
    content: "\e906";
  }
  &--bar-chart:before {
    content: "\e907";
  }
  &--briefcase:before {
    content: "\e908";
  }
  &--calendar-alt:before {
    content: "\e909";
  }
  &--calendar:before {
    content: "\e90a";
  }
  &--chat-bubble:before {
    content: "\e90b";
  }
  &--chat-left-o:before {
    content: "\e90c";
  }
  &--chat-left:before {
    content: "\e90d";
  }
  &--chat-right:before {
    content: "\e90e";
  }
  &--check-circle:before {
    content: "\e90f";
  }
  &--check:before {
    content: "\e910";
  }
  &--checkbox-o:before {
    content: "\e911";
  }
  &--checkbox:before {
    content: "\e912";
  }
  &--chevron-down:before {
    content: "\e913";
  }
  &--chevron-left:before {
    content: "\e914";
  }
  &--chevron-right:before {
    content: "\e915";
  }
  &--chevron-up:before {
    content: "\e916";
  }
  &--clock:before {
    content: "\e917";
  }
  &--close_x:before {
    content: "\e918";
  }
  &--close-circle:before {
    content: "\e919";
  }
  &--cloud:before {
    content: "\e91a";
  }
  &--comment-right:before {
    content: "\e91b";
  }
  &--copy:before {
    content: "\e91c";
  }
  &--custom-question_icon:before {
    content: "\e91d";
  }
  &--cut:before {
    content: "\e91e";
  }
  &--data-sources:before {
    content: "\e91f";
    color: #627085;
  }
  &--delete:before {
    content: "\e920";
  }
  &--device-desktop-mac:before {
    content: "\e921";
  }
  &--device-desktop-win:before {
    content: "\e922";
  }
  &--device-mobile-android:before {
    content: "\e923";
  }
  &--device-mobile-iphone:before {
    content: "\e924";
  }
  &--device-mobile:before {
    content: "\e925";
  }
  &--device-tablet-android:before {
    content: "\e926";
  }
  &--device-tablet-ipad:before {
    content: "\e927";
  }
  &--donut-large:before {
    content: "\e928";
  }
  &--donut-small:before {
    content: "\e929";
  }
  &--double-arrow-left:before {
    content: "\e92a";
  }
  &--doublechevron-left:before {
    content: "\e92b";
  }
  &--doublechevron-right:before {
    content: "\e92c";
  }
  &--down-caret-icon:before {
    content: "\e92d";
  }
  &--download:before {
    content: "\e92e";
  }
  &--dropdown:before {
    content: "\e92f";
  }
  &--edit:before {
    content: "\e930";
  }
  &--email_outline:before {
    content: "\e931";
  }
  &--end:before {
    content: "\e932";
  }
  &--filter-solid:before {
    content: "\e933";
  }
  &--filter:before {
    content: "\e934";
  }
  &--fingerprint:before {
    content: "\e935";
  }
  &--gear-o:before {
    content: "\e936";
  }
  &--gear:before {
    content: "\e937";
  }
  &--happy_sentiment:before {
    content: "\e938";
  }
  &--happy:before {
    content: "\e939";
  }
  &--hierarchy-icon:before {
    content: "\e93a";
  }
  &--hierarchy-map:before {
    content: "\e93b";
  }
  &--hierarchy-tree-circle:before {
    content: "\e93c";
  }
  &--hierarchy-tree:before {
    content: "\e93d";
  }
  &--horizontal-icon:before {
    content: "\e93e";
  }
  &--info-o:before {
    content: "\e93f";
  }
  &--info:before {
    content: "\e940";
  }
  &--last-updated:before {
    content: "\e941";
  }
  &--lockpassword:before {
    content: "\e942";
  }
  &--mail-o:before {
    content: "\e943";
  }
  &--mail:before {
    content: "\e944";
  }
  &--model-question_icon:before {
    content: "\e945";
  }
  &--more-vert:before {
    content: "\e946";
  }
  &--more:before {
    content: "\e947";
  }
  &--neutral_sentiment:before {
    content: "\e948";
  }
  &--no-filter:before {
    content: "\e949";
  }
  &--open-end_icon:before {
    content: "\e94a";
  }
  &--parent:before {
    content: "\e94b";
  }
  &--paste:before {
    content: "\e94c";
  }
  &--person:before {
    content: "\e94d";
  }
  &--phone_outline:before {
    content: "\e94e";
  }
  &--phone:before {
    content: "\e94f";
  }
  &--pie-o:before {
    content: "\e950";
  }
  &--pie:before {
    content: "\e951";
  }
  &--play-bubble:before {
    content: "\e952";
  }
  &--play-icon:before {
    content: "\e953";
  }
  &--plus-circle-o:before {
    content: "\e954";
  }
  &--plus-circle:before {
    content: "\e955";
  }
  &--plus-square:before {
    content: "\e956";
  }
  &--plus:before {
    content: "\e957";
  }
  &--power:before {
    content: "\e958";
  }
  &--preview:before {
    content: "\e959";
  }
  &--quote-down:before {
    content: "\e95a";
  }
  &--quote-up:before {
    content: "\e95b";
  }
  &--radio-o:before {
    content: "\e95c";
  }
  &--radio:before {
    content: "\e95d";
  }
  &--remove:before {
    content: "\e95e";
  }
  &--replay_icon:before {
    content: "\e95f";
  }
  &--respondents-icon:before {
    content: "\e960";
  }
  &--respondents:before {
    content: "\e961";
  }
  &--sad_sentiment:before {
    content: "\e962";
  }
  &--sad:before {
    content: "\e963";
  }
  &--search:before {
    content: "\e964";
  }
  &--short-text:before {
    content: "\e965";
  }
  &--sort-az:before {
    content: "\e966";
  }
  &--sort-za:before {
    content: "\e967";
  }
  &--star-half:before {
    content: "\e968";
  }
  &--star-o:before {
    content: "\e969";
  }
  &--star:before {
    content: "\e96a";
  }
  &--start:before {
    content: "\e96b";
  }
  &--striped_box:before {
    content: "\e96c";
  }
  &--survey:before {
    content: "\e96d";
  }
  &--table-icon:before {
    content: "\e96e";
    color: #000;
  }
  &--text-area:before {
    content: "\e96f";
  }
  &--text:before {
    content: "\e970";
  }
  &--time:before {
    content: "\e971";
  }
  &--Toggle_Off:before {
    content: "\e972";
  }
  &--Toggle_On:before {
    content: "\e973";
  }
  &--treemap:before {
    content: "\e974";
  }
  &--trend-up:before {
    content: "\e975";
  }
  &--triangle_exclamation:before {
    content: "\e976";
  }
  &--trophy_icon:before {
    content: "\e977";
  }
  &--up-caret-icon:before {
    content: "\e978";
  }
  &--upload:before {
    content: "\e979";
  }
  &--user_icon:before {
    content: "\e97a";
  }
  &--vertical-icon:before {
    content: "\e97b";
  }
  &--video-cam:before {
    content: "\e97c";
  }
  &--video-chat:before {
    content: "\e97d";
  }
  &--video:before {
    content: "\e97e";
  }
  &--loader:before {
    content: "\e97f";
  }
  &--draganddrop:before {
    content: "\e982";
  }
}
