.reactTagList{
    margin-bottom: 20px;

    ul{
        margin: 0;
        overflow: auto;

        li{
            float: left;
            margin-right: 10px;
            position: relative;
        }
    }

    &.selectable{
        ul{
            margin: 20px 0 20px;
            list-style: none;
        }

        .add-border {
            padding: 1em;
            border: 3px solid purple;
            border-radius: 5px;
            margin-right: 2em;
        }

        li{
            background:#F2F2F2;
            border-radius: 3px;
            cursor: pointer;
            margin-bottom: 4px;
            margin-top: 4px;
            line-height: 20px;
            padding: 10px 15px;
            border: 1px solid black;

            .tag-icon{
                color: #503b61;
                font-size: 1.1rem;
                line-height: 0.65rem;
            }

            .tag-label-icon{
                padding-left: 2.0rem;
            }

            i{
                font-size: 8px;
                position: absolute;
                top: calc(50% - 3.5px);
            }

            span{
                font-size: 16px;
                font-weight: normal;
            }

            &:hover, &.selected{
                background: $GreenSelected;
                i, span{
                    color: $Salt;
                }
                &.red{
                    background: #FC320A;
                }
                &.green{
                    background: #64C818;
                }
            }

            &.disabled{
                cursor: inherit;
                background: $GreenInactive;
                color: $Salt;

                &:hover, &.selected{
                    background: $GreenInactive;
                }
            }

            &.intelligence{
                padding-right: 1.5em;
                i{
                    display: inline;
                    top: calc(50% - 20px);
                }
            }

            &.newAddMore {
                background: none;
                color: $PinkAction;

                span {
                    font-weight: 500;
                }

                &:hover {
                    text-decoration: underline;

                    span {
                        color: $PinkAction;
                    }
                }
            }
        }
    }



    &.removable{
        li{
            background: #707f86;
            border-radius: $border-radius;
            cursor: pointer;
            margin-bottom: 4px;
            margin-top: 4px;
            line-height: 20px;
            padding: 6px;

            span,i{
                margin-right: 7px;
                font-size: 14px;
                color: #fff;
                font-weight: 700;
            }

            i{
                cursor: pointer;
                &:hover{
                    color: #66e2da;
                }
            }
        }
    }

    li.newTag{
        cursor: pointer;
        font-weight: bold;

        span{
            margin-left: 7px;
        }

        i{
            font-size: 12px;
        }

        &:hover{
            i, &.selected, span{
                color: #66e2da;
            }
        }
    }
}