.button{
    font-weight: bold;
    border-radius: 3px;

    &.purple {
        background: #9376B1;

        &:hover {
            background: #624b7f;
        }
    }

    &.darkblue {
        background: #5988CD;

        &:hover {
            background: #51629b;
        }
    }

    &.darkpurple{
        background: $DarkPurple;

        &:hover {
            background: $DarkerPurple;
        }
    }

    &.darkred {
        background: $HalfDragonfruit;

        &:hover {
            background: $Dragonfruit;
        }
    }

    &.rr{
        background: $PinkAction;

        &:hover {
            background: $DarkPinkAction;
        }
    }

    &.link{
        background: transparent;
        color: $Atlantis;

        &:hover {
            color: $Water;
        }
    }

    &.disabled{
        background: $DarkQuartz;
        color: $Salt;
        cursor: default;

        &:hover{
            background: $DarkQuartz;
            color: $Salt;
        }
    }

    &.big{
        padding: 18px 30px;
    }

    &[disabled]:hover,{
        cursor: not-allowed!important;
        opacity: .75;
    }
}

.button-group{
    .button{
        font-size: 1.2rem
    }
}

.addIcon{
    color: $PinkAction;
    cursor: pointer;

    &:hover {
        color: $DarkPinkAction;
    }
}

.addIcon + .removeIcon{
    padding-left: 15px;
}

.removeIcon{
    color: $PinkAction;
    cursor: pointer;

    &:hover {
        color: $DarkPinkAction;
    }
}



