.breadcrumbs {
  position: relative;

  &__chevron {
    margin: 0 1rem;
  }

  &__item {
    display: inline-block;
  }
}