$arrowHeight: rem(48);
$circleHeight: 16rem;

.page-arrows {
  &__icon {
    position: absolute;
    top: 50%;
    
    color: $Salt;
    
    font-size: $arrowHeight;
    margin-top: -$arrowHeight / 2;
    
    &--left {
      right: 0;
      margin-right: 1rem;
    }
    
    &--right {
      margin-left: 1rem;
    }
  }
  
  &__left {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
  
  &__right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
  }
  
  &__arrow {
    position: absolute;
    top: 50%;
    
    cursor: pointer;
    
    background-color: rgba(0, 0, 0, .05);
    border-radius: 50%;
    height: $circleHeight;
    width: $circleHeight;
    margin-top: -($circleHeight / 2);
    
    &--left {
      @include transition(margin, 250ms);
      left: 0;
      
      margin-left: -12rem;
      &:hover {
        margin-left: -9rem;
      }
    }
    
    &--right {
      @include transition(margin, 250ms);
      right: 0;
      
      margin-right: -12rem;
      &:hover {
        margin-right: -9rem;
      }
    }
    
    &:hover {
      background-color: rgba(0, 0, 0, .15);
    }
  }
}