.side-nav{
  padding: 2rem;
}
.user-header{
  padding: 5rem
}
.alert-box span{
  background: green;
}
@media print, screen and (min-width: 40em) {
  .cell.user-card {
    border-radius: 5px;
    padding: 0 30px 30px;
    .no-padding{
      padding: 0;
    }
    margin: 20px 1.08375rem;
    &.white{
      background: white;
    }
  }
}