.subscriptionSummary{

  a.link{
    font-weight: bold;

    &:hover{
      text-decoration: underline;
    }
  }

  .tierLabel, .tierDescription, .productDescription{
    font-size: 1.0rem;

    &.isRRAdmin{
      font-size: 1.5rem;
    }

    .selected{
      font-weight: bold;
      color: $Leaf;
    }
  }

  .enabledProducts{
    margin-top:20px;
    display: flex;

    .productName{
      font-weight: bold;
      color: $DarkQuartz;
    }

    &.enabled{
      .productName{
        color: $PinkAction;
      }

      .productStatus{
        color: $Leaf;
      }
    }

    img{
      max-width: 35px;
    }

    div.column{
      align-self: center;
      margin-bottom: 10px;
    }
  }
}