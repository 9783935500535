
.radio-accordion {
  background: $c-app-white;
  margin-top: 0;
  &__heading {
    height: 3.5em;
    padding: 1.5rem 2rem;
    font-family: $f-app-font-regular;
    font-size: em(16, $basefont);
    font-weight: normal;
    color: $c-app-grey-80;
    cursor: pointer;
    &-label {
      text-transform: capitalize;
      font-size: 1.4rem !important;
    }
    &-icon {
      display: none;
      cursor: pointer;
      float: right;
      color: blue;
      font-size: 1.5rem;
      border-radius: em(15, $basefont);
      border: rem(1) solid blue;
      width: em(14, $basefont);
      height: em(14, $basefont);
      padding-right: em(1, $basefont);
      padding-top: em(1, $basefont);
      text-align: center;
      font-weight: normal;
      &--hide {
        display: none;
      }
    }
  }
  &__content {
    display: none;
    padding: 2rem 2rem;
    &--show {
      display: block;
      position: relative;
    }
  }
  &__toggle-text{
    line-height: 2.5em;
    padding-right: 0.5em;
    float: left;
    font-size: 0.8em;
    &--on{
      color: $Jellyfish;
    }
    &--off{
      color: $Ash;
    }
  }
  &--selected {
    & .radio-accordion__heading {
      border-bottom: rem(1) solid $c-card-rule;
    }
  }
  &__stepIcon {
    &--selected{
      background-color: $c-app-red;
    }
    margin-top:0.5rem;
    margin-right:1rem;
    background-color: $c-app-grey-50;
    float: left;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 2rem;
    text-align: center;
    line-height: 1.7rem;
    &__text{
      font-weight: bold;
      font-size: 1.4rem;
      color: white;
    }
  }
}