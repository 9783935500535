/**
*
* COLORS FOR CX SUITE PORTAL UI
*
**/

// WHITE
$c-app-white: #FFF;
$c-app-white-90: #E1EEFC;
$c-app-white-transparent-70: rgba(255, 255, 255, .7);

// BLUES
$c-app-blue: #165FD8;
$c-app-blue-10: #136AD5;
$c-app-blue-50: #9268ED;
$c-app-blue-80: #724ACA;
$c-app-blue-85: #124B91;
$c-app-blue-radio: #4D8DDE;
$c-app-sky-blue: #A08AD2;

// GREYS
$c-app-grey-10: #F9F9F9;
$c-app-grey-20: #EAEBEC;
$c-app-grey-30: #F2F3F4;
$c-app-grey-35: #D5D8DA;
$c-app-grey-40: #BFC3C8;
$c-app-grey-45: #AAAFB6;
$c-app-grey-50: #959BA3;
$c-app-grey-60: #808891;
$c-app-grey-65: #626262;
$c-app-grey-70: #6C747E;
$c-app-grey-80: #59606A;
$c-app-grey-85: #464C55;
$c-app-grey-90: #343941;
$c-app-grey-shadow-dark: rgba(0, 10, 30, .35);
$c-app-grey-shadow-light: rgba(0, 10, 30, .15);

// BLACKS
$c-app-black: #000;
$c-app-black-60: #59606A;
$c-app-black-70: #454B54;
$c-app-black-80: #343941;
$c-app-black-85: #2E323A;
$c-app-black-90: #1E2228;

//FORESEE RED
$c-app-fsred: #ee3124;

// TERTIARY
$c-app-red: #F24554;
$c-app-orange: #FA8536;
$c-app-yellow: #FCD60F;
$c-app-green: #29B140;
$c-app-light-green: #74E88F;
$c-app-blue-green: #11A2F3;
$c-app-purple: #EE469A;
$c-app-purple-50: #9268ED;
$c-app-purple-prime: #724ACA;
$c-app-yellow-20: #FFF8DC;
$c-app-yellow-50: #FDE770;

//RATING COLORS
$c-rating-1: #F56151;
$c-rating-2: #FF7E45;
$c-rating-3: #FFD828;
$c-rating-4: #44CB3B;
$c-rating-5: #28B13E;

//CHARTING COLORS 
$c-chart-1: #1CBDFF;
$c-chart-2: #627085;
$c-chart-3: #005A9A;
$c-chart-4: #3A475B;
$c-chart-5: #009FEA;
$c-chart-6: #69D3FF;
$c-chart-7: #222B3C;
$c-chart-8: #007BCA;
$c-chart-9: #47C9FF;
$c-chart-10: #B7C0CC;

//ANSWER DISTRIBUTION TABLES
$c-table-blue-1: #00bcff;
$c-table-blue-2: #009ded;

//FROM CX INTERACTION GUIDE
//PRIMARY COLORS
$TenthJellyfish: rgba(28,189,255,0.1);
$Jellyfish: #009FEA;
$Atlantis: #007BCA;
$Water: #005A9A;

$Tint: rgba(255, 255, 255, .7);
$Shade: rgba(0, 0, 0, .7);

$Salt: #FFFFFF;

$HalfQuartzSolid: #F6F7FB;
$HalfQuartz: rgba(233,236,242, 0.35);
$Quartz: #E9ECF2;
$HalfAsh: rgba(183,192,204, 0.5);
$Ash: #B7C0CC;
$HalfSlate: rgba(98, 112, 133, 0.5);
$Slate: #2a2a2a;
$Coal: #3A475B;
$NinetyCoal: rgba(58, 71, 91, 0.9);
$Obsidian: #222B3C;

//SECONDARY COLORS
$HalfDragonfruit: rgba(244,61,88, 0.85);
$Dragonfruit: #F43D58;
$Tangerine: #FF8635;
$Grape: #965CC5;
$Kiwi: #92CF26;
$Bananarama: #fdd800;
$ThirdBananarama: rgba(253,216,0,0.3);
$TenthBananarama: rgba(253,216,0,0.1);
$Peach: #FF8735;
$Bubblegum: #E44FA5;
$Mint: #28D0C9;
$Coconut: #8E5941;

//SHADOW COLOR
$ShadowColor: rgba(58, 71, 91, 0.5);
