.avatar {
  display: block;
  cursor: pointer;
  position: relative;
  &__badge {
    position: absolute;
    right: 0;
    bottom: 0;
    [class*="text"] {
      line-height: 1.2rem;
    }
  }
  &--inline { display: inline-block; }
  &__wrap {
    width: 3.5rem;
    display: inline-block;
    &--square {
      background: $Salt;
      width: 4.2rem;
      height:4.2rem;
      border-radius: $border-radius;
    }
    &--small {
      width: 2.5rem;
    }
  }
  &__image {
    width: 3.5rem;
    display: inline-block;
    border-radius: 3rem;
    text-align: center;
    .avatar__wrap--square & {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      border-radius: $border-radius;
    }
    &--small {
      width: 2.5rem;
    }
  }
  &__icon {
    height: 3.5rem;
    width: 3.5rem;
    line-height: 3.5rem;
    font-size: 1.4rem;
    position: relative;
    display: inline-block;
    border-radius: 3rem;
    text-align: center;
    color: $Salt;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-family: $f-app-font-semibold;
    vertical-align: middle;
    position: relative;
    bottom: 0.2rem;
    &--small {
      height: 2.5rem;
      width: 2.5rem;
      line-height: 2.5rem;
      .svg-icon--small {
        font-size: 1.8rem;
      }
      .avatar__badge {
        .svg-icon--small {
          font-size: 1.2rem;
        }
      }
    }
    &--bordered {
      border: 0.2rem solid $Salt;
      box-sizing: border-box;
      line-height: 3.2rem;
    }
  }
  &__name{
    margin-left: 1rem;
    font-family: $f-app-font-regular;
    font-size: 1.4rem;
    color: $Obsidian;
    position: relative;
    line-height: 2.5;
    .avatar--square & { line-height: 3; }
  }
}
