.paid_features{
  padding-bottom: 100px;
  max-width: 1600px;
  padding-top: 20px;
  iframe{
    border: none;
  }
  .flex-centered{
    display: flex;
    align-items: center;
    justify-content: center
  }
  .merchantsignupheader{
    a{
      font-size: 1.5rem;
    }
    a.button{
      font-size: 1.7rem;
    }
  }
  .cx-component-library {
    h1 {
      line-height: 1.6em;
    }

    h3 {
      line-height: 1.1em;
      padding-bottom: 30px;
    }

    h4 {
      font-size: 1.5em !important;
      font-weight: lighter;
      font-family: ProximaNova-Regular, serif;
      padding-bottom: 30px;
    }

    li {
      font-size: 1.5em !important;
      padding-bottom: 20px;
    }

    .callout{
      max-width: 125rem;
      h2{
        color: white;
      }
      background: #AE54FC;
      border-radius: 5px;

    }

    .button.purple-gradient {
      border-radius: 10px;
      text-transform: none;
    }

    .amplify_upsell {
      h3 {
        font-size: 1.7375rem
      }

      p {
        font-size: 16px;;
      }
    }
  }
}