.progress-bar {
  height: 100%;

  &__container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
    
    &--rounded {
      border-radius: 0.2rem; 
    }
    
    &--has-label {
      width: calc(100% - 4rem);
    }
  }
  
  &__range {
    position: absolute;
    top: -2.5rem;
    left: 0;
    right: 0;
    
    &-max {
      position: absolute;
      right: 0;
    }
    
    &-origin {
      position: absolute;
      left: 50%;
      margin-left: -.4rem;
    }
    
    &-min {
      position: absolute;
      left: 0;
    }
  }
  
  &__threshold {
    width: .1rem;
    height: 100%;
    background-color: $Water;
    position: absolute;
    z-index: 2;
  }
  
  &__origin {
    width: .1rem;
    height: 100%;
    background-color: $Obsidian;
    position: absolute;
    left: calc(50% - .05rem);
    z-index: 2;
  }
  
  &__background {
    background-color: $Quartz;
    display: inline-block;
    white-space: pre;
    width: 100%;
    height: 100%;
    height: 1rem;
    vertical-align: middle;
    
    &--large {
      height: 2rem;
    }
    
    &:after {
      content: ' ';
    }
  }

  &__completion {
    background-color: $Jellyfish;
    display: inline-block;
    @include transition(width, 0.6s, ease);
    white-space: pre;
    height: 100%;
    position: relative;
    
    &:after {
      content: ' ';
    }
  }

  &__label {
  	width: 4rem;
    height: 100%;
  }

  &--inline-label {
  	.progress-bar__label {
  		display: inline-block;
  	}
  }
  
  &--right {
    .progress-bar__label {
      float: right;
      margin: 0 0 0 0.1rem;
    }
  }
}