.image-radio {
  margin-bottom: 1rem;
  background: $Salt;
  @include transition(all, 250ms);
  position: relative;
  float: left;
  cursor: pointer;
  
  &--small {
    width: rem(53);
  }

  &--medium {
    width: rem(130);
  }

  &--wide {
    width: 100%;
  }

  &__content {
      border-radius: $border-radius;
      position: relative;
      border: 1px solid $Quartz;

      img {
        max-height: 100%;
        max-width: 100%;
      }
      
      &--small {
        height: rem(53);
      }

      &--medium {
        height: rem(130);
      }
  }
  
  &--active {
    .image-radio__content {
      border-color: $Jellyfish;
      box-shadow: 0 0 rem(3) $Jellyfish;

    }
  }
  
  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
