.upload-file{
  position: relative;
  &__input {
    &--hidden{
      opacity: 0;
      position: absolute;
      left: -999px;
    }
  }
  &__button{
    position: absolute;
    right: 0;
    top: 2.0rem;
    &--small{
      padding: 0.5rem 1.8rem;
    }
    &--medium{
      padding: 0.8rem 1.8rem;
    }
    &--large{
      padding: 0.7rem 1.8rem;
    }
  }
}
