&--default {
  .tabs {
    &__tabitem + .tabs__tabitem {
      margin: 0 0 0 4rem;
    }
    &__tabitem {
      @extend .noselect-all;
      display: inline-block;
      padding-top: rem(13);
      padding-bottom: rem(9);
      font-family: $f-app-font-semibold;
      font-size: rem(13);
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      @include transition;
      &:hover,
      &--active {
        color: $Jellyfish;
        @include transition;
      }
      &--active{
        font-family: $f-app-font-semibold;
      }
      &:after {
        display: block;
        content: attr(title);
        height: rem(1);
        font-family: $f-app-font-semibold;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      }
      &--selected {
        font-family: $f-app-font-semibold;
        cursor: default;
        color: $Jellyfish;
      }
      &--centered {
        margin: 0 !important;
      }
      &--disabled {
        color: $Ash !important;
        border-bottom: none !important;
        &:hover{
          color:$Ash;
          font-family:$f-app-font-semibold;
          cursor: default;
        }
      }

      &--icon_mode{
        padding-top: 0;
        padding-bottom: 0;
        float: right;
      }
    }

    &.card__tabs{
      border-bottom: rem(1) solid $Quartz;
      padding: 0 2rem;

      .tabs__tabitem + .tabs__tabitem {
        margin: 0 0 rem(-1) 0;
      }

      .tabs__tabitem{
        max-width: 30%;
        height: 6.5em;
        display: inline-table;
        text-align: left;
        text-transform: initial;
        border-right: rem(1) solid $Quartz;
        border-top: rem(1) solid $Quartz;
        border-bottom: 0;
        padding: rem(15) rem(10);
        margin-bottom: rem(-1);
        font-family: $f-app-font-semibold;
        color: $Ash;
        @include transition(all, 0ms);

        &:first-of-type{
          border-left: rem(1) solid $Quartz;
        }

        &--selected{
          border-bottom: rem(1) solid #FFF;
          color: $Obsidian;
          box-shadow: 0 rem(5) 0 #fff, 0 rem(-2) rem(8) rgba(0, 10, 30, 0.25);
        }

        &--score{
          padding-left: rem(45);
          position: relative;

          .timeline__card--rating{
            top: -0.4em;
          }
        }
      }
    }
  }
}
