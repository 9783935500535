.row-enter {
  opacity: 0.01;
  transition: opacity .1s ease-in;
}

.row-enter.row-enter-active {
  opacity: 1;
}

.row-leave {
  opacity: 1;
  transition: opacity .1s ease-in;
}

.row-leave.row-leave-active {
  opacity: 0.01;
}








.respondent-header-enter {
  opacity: 0.01;
  top: -5em;
  transition: top .35s, opacity .35s;
}

.respondent-header-enter.respondent-header-enter-active {
  opacity: 1;
  top: 1em;
}

.respondent-header-leave {
  opacity: 1;
  top: 1em;
  transition: top .35s, opacity .35s;
}

.respondent-header-leave.respondent-header-leave-active {
  opacity: 0.01;
  top: 6em;
}




.respondent-card-enter {
  opacity: 0.01;
  max-height: 0;
  transition: max-height .35s, opacity .35s;
}

.respondent-card-enter.respondent-card-enter-active {
  opacity: 1;
  max-height: 50em;
}

.respondent-card-leave {
  opacity: 1;
  max-height: 50em;
  transition: max-height .35s, opacity .35s;
}

.respondent-card-leave.respondent-card-leave-active {
  opacity: 0.01;
  max-height: 0;
}



.respondent-card-video-enter {
  opacity: 0.01;
  max-height: 0;
  transition: max-height .35s, opacity .35s;
}

.respondent-card-video-enter.respondent-card-video-enter-active {
  opacity: 1;
  max-height: 50em;
}

.respondent-card-video-leave {
  opacity: 1;
  max-height: 50em;
  transition: max-height .35s, opacity .35s;
}

.respondent-card-video-leave.respondent-card-video-leave-active {
  opacity: 0.01;
  max-height: 0;
}




.question-card-header-enter {
  opacity: 0.01;
  top: -1.3em;
  @include transition(all, 250ms);
}

.question-card-header-enter.question-card-header-enter-active {
  opacity: 1;
  top: 0;
  @include transition(all, 250ms);
}

.question-card-header-leave {
  opacity: 1;
  top: 0;
  @include transition(all, 250ms);
}

.question-card-header-leave.question-card-header-leave-active {
  opacity: 0.01;
  top: -1.3em;
  @include transition(all, 250ms);
}



.question-card-enter {
  @extend .respondent-card-enter;
  @include transition(all, 250ms);
}

.question-card-enter.question-card-enter-active {
  @extend .respondent-card-enter-active;
  @include transition(all, 250ms);
}

.question-card-leave {
  @extend .respondent-card-leave;
  @include transition(all, 250ms);
}

.question-card-leave.question-card-leave-active {
  @extend .respondent-card-leave-active;
  @include transition(all, 250ms);
}





.sidebar-enter {
  opacity: 0.01;
  transition: opacity .1s ease-in;
}

.sidebar-enter.sidebar-enter-active {
  opacity: 1;
}

.sidebar-leave {
  opacity: 1;
  transition: opacity .1s ease-in;
}

.sidebar-leave.sidebar-leave-active {
  opacity: 0.01;
}


.flyout-enter {
  opacity: 0.01;
  margin-right: -15em;
  transition: opacity .2s ease-in, margin-right .5s ease-in;
}

.flyout-enter.flyout-enter-active {
  opacity: 1; 
  margin-right: 0;
}

.flyout-leave {
  margin-right: 0em;
  transition: margin-right .5s ease-in;
}

.flyout-leave.flyout-leave-active {
  margin-right: -15em;
}


.disabledOverlay-enter {
  opacity: 0.01;
  transition: opacity .1s ease-in;
}

.disabledOverlay-enter.disabledOverlay-enter-active {
  opacity: 1;
}

.disabledOverlay-leave {
  opacity: 1;
  transition: opacity .1s ease-in;
}

.disabledOverlay-leave.disabledOverlay-leave-active {
  opacity: 0.01;
}



.carousel-arrow-enter {
  visibility: hidden;
  opacity: 0.01;
  @include transition(all, 350ms);
}

.carousel-arrow-enter.carousel-arrow-enter-active {
  visibility: visible;
  opacity: 1;
  @include transition(all, 350ms);
}

.carousel-arrow-leave {
  visibility: visible;
  opacity: 1;
  @include transition(all, 350ms);
}

.carousel-arrow-leave.carousel-arrow-leave-active {
  visibility: hidden;
  opacity: 0.01;
  @include transition(all, 350ms);
}

