@import '../colors';

.fullHeight{
    height: 100%;
}

.relative{
    position: relative;
}

.siteContent {
    position: relative;
    bottom: 0;
    padding-left: 0;

    &.leftPanelContainer{
        width: calc(100% - 320px);
        left: 320px;
        min-width: 1000px;
    }

    .clip-circle {
        -webkit-clip-path: circle(20px at center);
        clip-path: circle(20px at center);
        float: left;
        height: 100%;
        width: 100%;
    }

    .leftPanel{
        width: calc(100% - 480px);
    }

    .rightPanel{
        width: 475px;
    }

    .headerTitle{
        font-weight: 400;
        font-size: 22px;
        padding: 12px 0 10px 20px;
        border-bottom: solid 1px #e7e7e2;
    }

    .socialPullSubtitle{
        font-family: Jost, "ProximaNova-Regular", Helvetica, Arial, sans-serif;
        font-size: 1.19rem;
        font-weight: 400;
        line-height: 1.785rem;
        color: #627085;
    }

    .socialPullText{
        font-size: 13px;
        font-family: Jost, "ProximaNova-Regular", Helvetica, Arial, sans-serif;
        color: #58595b;
        font-size: 1.1em;
        font-weight: 700;
        line-height: 1.618;
    }

    .socialPullLink{
        color: rgba(234, 14, 234, 1);
        font-weight: 700;
        padding: 0;
    }

    .socialPullLink:hover{
        text-decoration: underline;
        color: rgba(234, 14, 234, 1);
    }


    .warningMessage {
        background: darkkhaki;
        width: fit-content;
        padding: 1em;
        color: white;
        border-radius: 7px;
        font-weight: 500;
        margin-left: 1em;
    }

    .cx-content-container{
        margin: 0 20px;
    }

    .contentContainer {
        margin: 0 20px;
	 > h2, h3, h4{
            padding-top: 20px;
	}
	
	
	.cardWithShadows{
            background-color: #fff;
            box-shadow: 0 1px 1px;

            h2{
                margin-bottom: 40px;
            }

            h3{
                a{
                    font-weight: normal;
                    font-size: 12px;
                }
            }

            input.button{
                margin-right: 20px;
            }
        }	    }

    .contentContainer{
        .card{
            h2, h3, h4 {
                font-weight: 400;
            }
        }
    }

    p.subHeader{
        color: rgba(98, 112, 133, 0.5);
        font-weight: 700;
    }

    h2, h3, h4 {
        margin-bottom: 10px;
        font-weight: 700;

        &.subheader{
            font-weight: 300;
        }

        a {
            color: $Water;
        }

        small{
            font-size: 60%;
            color: #6f6f6f;
        }
    }

    p{
        a{
            color: $Water;
        }
    }

    .contentContainer{
        .card {
            background-color: white;
            padding: 40px;
            margin-bottom: 40px;
            overflow: auto;

            &.noPadding{
                padding: 0;
            }

            .row {
                margin: 0;
            }

            .panel {
                background: #f2f2f2;
                border: solid 1px #e6e6e6;
                margin: 0 0 22px;
                padding: 20px;
            }

            table {
                width: 100%;
                border: 1px solid #e0e5e9;
            }

            a {
                color: $PinkAction;
            }

            a.button{
                color: $Salt;
            }

            .quote{
                color: $Obsidian;
            }


            label{
                font-size: 1.1rem;
                margin-bottom: 0.7rem;
                font-weight: 700;
                font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
                color: #444;
            }
        }
    }



    .miniCardContainer {
        overflow: auto;
        width: 100%;

        $miniCard-margin: 20px;

        .miniCard:first-child,
        .miniCard:first-child ~ .miniCard {
            width: 100%;
            margin-left: 0;
        }

        @for $i from 2 through 5 {
            .miniCard:first-child:nth-last-child(#{$i}),
            .miniCard:first-child:nth-last-child(#{$i}) ~ .miniCard {
                width: -webkit-calc((100% / #{$i}) - #{$miniCard-margin});
                width: -moz-calc((100% / #{$i}) - #{$miniCard-margin});
                width: calc((100% / #{$i}) - #{$miniCard-margin});
                margin-left: $miniCard-margin + ($miniCard-margin / ($i - 1));
            }
        }

        .miniCard {
            height: 100%;
            float: left;
            padding: 10px 10px 0;
            margin-bottom: 20px;
            background-color: white;
            box-shadow: 0 1px 1px;

            &:first-child {
                margin-left: 0 !important;
            }

            &.no-shadow {
                box-shadow: none;
            }
        }
    }

    .row{
        width: 100%;
        max-width: 100%;
    }
}
