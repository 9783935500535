.footer{
  line-height: 155%;
  .wrappertrist{
    color: #40454f;
    text-align: center;
    justify-content: space-between;
    align-self: auto;
    align-items: center;
    width: 85%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 80px;
    display: flex;
    position: relative;
  }
  .wrappertrist.footer-wrapper {
    border-bottom: 1px solid #40454f;
    flex-direction: column;
    align-items: stretch;
    padding-top: 80px;
    padding-bottom: 0;
  }
  .footer-up {
    grid-column-gap: 120px;
    grid-row-gap: 16px;
    border-bottom: 1px solid #40454f;
    grid-template-rows: auto;
    grid-template-columns: 1fr 2.75fr;
    grid-auto-columns: 1fr;
    padding-bottom: 45px;
    display: grid;
    @media screen and (max-width: 1023px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 60px;
    }
    .footer-links-container {

      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-columns: 1fr;
      display: grid;
      @media screen and (max-width: 1023px) {
        grid-area: span 1 / span 3 / span 1 / span 3;
        grid-row-gap: 50px;
        grid-template-columns: 1fr;
      }

      .footer-links-row {
        grid-column-gap: 0;
        grid-row-gap: 0;
        flex-direction: column;
        align-items: flex-start;
        display: flex;

        .footer-link {
          color: #40454f;
          text-align: left;
          background-color: rgba(255, 0, 229, 0);
          margin-top: 10px;
          margin-bottom: 10px;
          font-family: Lato, sans-serif;
          font-size: 14px;
          transition: opacity .2s;
          display: block;
        }
      }
    }
  }
  .footer-down{
    color: #40454f;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 16px;
    display: flex;
  }
}