html {
  font-size: $basefont * 1px;
}

body, * {
  font-family: $f-app-font-regular, Helvetica, Arial, sans-serif;
  line-height: $base-lineheight;
}

html, body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

a {
  color: $PinkAction;
  cursor: pointer;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

a.highlight{
  text-decoration: underline;
  font-weight: bold;
}

a:focus,
a:hover {
  color: $DarkPinkAction;

  &.txt--PinkAction:hover{
    color: $DarkPinkAction;
  }

}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
  font-family: $f-app-font-bold, Helvetica, Arial, sans-serif;
  margin-bottom: rem(3);
  padding-top: rem(5);
}

.semi_bold{
  font-family: $f-app-font-semibold, Helvetica, Arial, sans-serif;
}

.bold{
  font-family: $f-app-font-bold, Helvetica, Arial, sans-serif;
}

h1, .h1,
h2, .h2 {
  line-height: $base-lineheight;
}

h1, .h1 {
  font-size: rem($h1font);
}

h2, .h2 {
  font-size: rem($h2font);
}

h3, .h3 {
  font-size: rem($h3font);
}

h4, .h4 {
  font-size: rem($h4font);
}

.h5 {
  font-size:1.2rem;
  font-family: $f-app-font-bold;
  text-transform: uppercase;
}

p {
  font-size: rem($bodyfont);
  margin-bottom: rem(3);
  padding-top: rem(5);
  line-height: 1.2;
}

.txt-smaller{font-size: .9em}
.txt-larger{font-size: 1.1em}

.heading--primary {
  font-size:2.4rem;
  line-height:1;
}

.txt--base, %txt--base {font-size:1.4rem;}
.txt--large, %txt--large {font-size:1.6rem;}
.txt--small, %txt--small {font-size:1.2rem;}

.txt--Bananarama{color: $Bananarama;}
.txt--Peach{color: $Peach;}
.txt--Jellyfish{color: $Jellyfish;}
.txt--Atlantis{color: $Atlantis;}
.txt--Water{color: $Water;}
.txt--Salt{color: $Salt;}
.txt--Quartz{color: $Quartz;}
.txt--Ash{color: $Ash;}
.txt--Slate{color: $Slate;}
.txt--Coal{color: $Coal;}
.txt--Obsidian{color: $Obsidian;}
.txt--Dragonfruit{color: $Dragonfruit;}
.txt--Tangerine{color: $Tangerine;}
.txt--Grape{color: $Grape;}
.txt--Kiwi{color: $Kiwi;}
.txt--Shade{color: $Shade;}
.txt--Tint{color: $Tint;}
.txt--PinkAction{color: $PinkAction;}
.txt--Bubblegum{color: $Bubblegum;}
.txt--Mint{color: $Mint;}
.txt--Coconut{color: $Coconut;}

.bg--Bananarama{background: $Bananarama;}
.bg--Peach{background: $Peach;}
.bg--Jellyfish{background: $Jellyfish;}
.bg--Atlantis{background: $Atlantis;}
.bg--Water{background: $Water;}
.bg--Salt{background: $Salt;}
.bg--Quartz{background: $Quartz;}
.bg--HalfQuartz{background: $HalfQuartz;}
.bg--Ash{background: $Ash;}
.bg--Slate{background: $Slate;}
.bg--Coal{background: $Coal;}
.bg--Obsidian{background: $Obsidian;}
.bg--Dragonfruit{background: $Dragonfruit;}
.bg--Tangerine{background: $Tangerine;}
.bg--Grape{background: $Grape;}
.bg--Kiwi{background: $Kiwi;}
.bg--Bubblegum{background: $Bubblegum;}
.bg--Mint{background: $Mint;}
.bg--Coconut{background: $Coconut;}

.border--Jellyfish{border-color: $Jellyfish !important;}
.border--Atlantis{border-color: $Atlantis !important;}
.border--Water{border-color: $Water !important;}
.border--Salt{border-color: $Salt !important;}
.border--Quartz{border-color: $Quartz !important;}
.border--Ash{border-color: $Ash !important;}
.border--Slate{border-color: $Slate !important;}
.border--Coal{border-color: $Coal !important;}
.border--Obsidian{border-color: $Obsidian !important;}
.border--Dragonfruit{border-color: $Dragonfruit !important;}
.border--Tangerine{border-color: $Tangerine !important;}
.border--Grape{border-color: $Grape !important;}
.border--Kiwi{border-color: $Kiwi !important;}
.border--Bubblegum{border-color: $Bubblegum;}
.border--Mint{border-color: $Mint;}
.border--Coconut{border-color: $Coconut;}

.fill--Bananarama{fill: $Bananarama;}
.fill--Peach{fill: $Peach;}
.fill--Jellyfish{fill: $Jellyfish;}
.fill--Atlantis{fill: $Atlantis;}
.fill--Water{fill: $Water;}
.fill--Salt{fill: $Salt;}
.fill--Quartz{fill: $Quartz;}
.fill--Ash{fill: $Ash;}
.fill--Slate{fill: $Slate;}
.fill--Coal{fill: $Coal;}
.fill--Obsidian{fill: $Obsidian;}
.fill--Dragonfruit{fill: $Dragonfruit;}
.fill--Tangerine{fill: $Tangerine;}
.fill--Grape{fill: $Grape;}
.fill--Kiwi{fill: $Kiwi;}
.fill--Shade{fill: $Shade;}
.fill--Tint{fill: $Tint;}
.fill--Bubblegum{fill: $Bubblegum;}
.fill--Mint{fill: $Mint;}
.fill--Coconut{fill: $Coconut;}
