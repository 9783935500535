@mixin margin($space) {
  margin: $space;
    
  &--bottom {
    margin-bottom: $space;
  }
  &--top {
    margin-top: $space;
  }
  &--left {
    margin-left: $space;
  }
  &--right {
    margin-right: $space;
  }
  
  &--vertical {
    margin: $space 0;
  }
  &--horizontal {
    margin: 0 $space;
  }
}

@mixin padding($space) {
  padding: $space;
  
  &--bottom {
    padding-bottom: $space;
  }
  &--top {
    padding-top: $space;
  }
  &--left {
    padding-left: $space;
  }
  &--right {
    padding-right: $space;
  }
  
  &--vertical {
    padding: $space 0;
  }
  &--horizontal {
    padding: 0 $space;
  }
}

@mixin gutter($whole, $half) {
  @include margin($whole);
  
  &-half {
    @include margin($half);
  }
  
  &-quarter {
    @include margin($half/2);
  }
  
  &-p {
    @include padding($whole);
    
    &-half {
      @include padding($half);
    }
  }
}

.gutter {
  @include gutter($gutter, $gutter / 2);  
}

.gutter-i {
  @include gutter($gutter !important, $gutter / 2 !important);  
}
