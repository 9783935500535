@charset "UTF-8";

.social_share_box {
    position: absolute;
    top: 20px;
    right: 20px;
    margin-top: 0 !important;
    z-index: 1;
}

.social_share_box ~ .social_share_box {
    position: relative;
    top: auto;
    right: auto;
    float: right;
}

.social_share_box.login-style {
    position: static;
}

.social_share_box.quiz {
    float: none;
    margin-top: 30px !important;
}

.social_share_box.quiz .share {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
}

@media (max-width: 799px) {
    .social_share_box.quiz .share {
        padding: 0 10px;
    }
}

.social_share_box.quiz .item {
    width: 32.85%;
    height: 35px;
    border-radius: 3px;
    padding: 8px;
}

@media (max-width: 799px) {
    .social_share_box.quiz .item {
        border-radius: 0;
        width: 33.3%;
        height: 52px;
        padding: 15px;
    }
}

.social_share_box_wrapper .fb_login,
.social_share_box_wrapper .facebook {
    background-color: #3b5998;
}

.social_share_box_wrapper .fb_login:hover,
.social_share_box_wrapper .facebook:hover {
    background-color: #344e86;
}

.social_share_box_wrapper .twitter_login,
.social_share_box_wrapper .twitter {
    background-color: #00acee;
}

.social_share_box_wrapper .twitter_login:hover,
.social_share_box_wrapper .twitter:hover {
    background-color: #009ad4;
}

.social_share_box_wrapper .pinterest_login,
.social_share_box_wrapper .pinterest {
    background-color: #c92228;
}

.social_share_box_wrapper .pinterest_login:hover,
.social_share_box_wrapper .pinterest:hover {
    background-color: #b31e24;
}

.social_share_box_wrapper .linkedin_login,
.social_share_box_wrapper .linkedin {
    background-color: #0e76ab;
}

.social_share_box_wrapper .linkedin_login:hover,
.social_share_box_wrapper .linkedin:hover {
    background-color: #0c6693;
}

.social_share_box_wrapper .reddit_login,
.social_share_box_wrapper .reddit {
    background-color: #5f6165;
}

.social_share_box_wrapper .reddit_login:hover,
.social_share_box_wrapper .reddit:hover {
    background-color: #535458;
}

.social_share_box_wrapper .stumbleupon_login,
.social_share_box_wrapper .stumbleupon {
    background-color: #f74425;
}

.social_share_box_wrapper .stumbleupon_login:hover,
.social_share_box_wrapper .stumbleupon:hover {
    background-color: #f62f0c;
}

.social_share_box_wrapper .tumblr_login,
.social_share_box_wrapper .tumblr {
    background-color: #34526f;
}

.social_share_box_wrapper .tumblr_login:hover,
.social_share_box_wrapper .tumblr:hover {
    background-color: #2c455e;
}

.social_share_box_wrapper .item {
    position: relative;
    cursor: pointer;
    height: 30px;
    float: left;
    text-align: center;
    width: 30px;
    -webkit-transition: background-color 0.15s linear;
    -moz-transition: background-color 0.15s linear;
    transition: background-color 0.15s linear;
}

.social_share_box_wrapper .item:before {
    content: "";
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    color: #fff;
}

.social_share_box_wrapper .item + .item {
    margin-left: 5px;
}

.social_share_box_wrapper .item.facebook:before {
    content: "\f09a";
}

.social_share_box_wrapper .item.googleplus {
    background-color: #dd4b39;
}

.social_share_box_wrapper .item.googleplus:before {
    content: "\f0d5";
}

.social_share_box_wrapper .item.googleplus:hover {
    background-color: #d73925;
}

.social_share_box_wrapper .item.twitter:before {
    content: "\f099";
}

.social_share_box_wrapper .item.pinterest:before {
    content: "\f0d2";
}

.social_share_box_wrapper .item.linkedin:before {
    content: "\f0e1";
}

.social_share_box_wrapper .item.reddit:before {
    content: "\f1a1";
}

.social_share_box_wrapper .item.tumblr:before {
    content: "\f173";
}

.social_share_box_wrapper .social_button {
    position: relative;
    height: 45px;
    padding: 0 48px 0 12px;
    text-align: left;
    line-height: 45px;
    border-color: transparent;
    border-style: hidden;
    border-width: 0px;
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 799px) {
    .social_share_box_wrapper .social_button {
        display: block;
        width: 100%;
    }
}

.social_share_box_wrapper .social_button:hover, .social_share_box_wrapper .social_button:active {
    border-color: transparent;
    border-style: hidden;
    border-width: 0px;
}

.social_share_box_wrapper .social_button:active {
    -webkit-box-shadow: 0px 1px 2px 0px rbga(#373a3e, 0.7) inset;
    -moz-box-shadow: 0px 1px 2px 0px rbga(#373a3e, 0.7) inset;
    box-shadow: 0px 1px 2px 0px rbga(#373a3e, 0.7) inset;
    filter: null;
}

.social_share_box_wrapper .social_button .fa {
    position: absolute;
    right: 15px;
    top: 7px;
    line-height: 32px;
    font-size: 20px;
}

@media (max-width: 799px) {
    .social_share_box_wrapper .social_button + .social_button {
        margin-top: 7px;
    }
}

@media (max-width: 799px) {
    .index_article .social_share_box {
        position: static;
    }

    .index_article .social_share_box .shares {
        width: 100%;
        background: #00acee;
    }

    .index_article .social_share_box .item {
        width: 33.33%;
        height: 52px;
        padding: 15px;
    }

    .index_article .social_share_box .item + .item {
        margin-left: 0;
    }

    .index_article .social_share_box .item:before {
        font-size: 24px;
    }
}

.stat_box {
    float: left;
        direction: ltr;
    display: inline-block;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    margin-top:5px;
    margin-left: 16px;
    margin-right: 12px;
    min-width: 15px;
    padding-bottom: 0px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 0px;
    text-align: center;
    white-space: nowrap;
    color:#1d83da;
}
