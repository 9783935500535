@mixin formSizes() {
  &--xlarge {
    height: rem(50);
    line-height: rem(40);
    font-size: rem(16);
  }
  &--large {
    height: rem(40);
    line-height: rem(30);
  }
  &--medium {
    height: rem(35);
    line-height: rem(25);
  }
  &--small {
    height: rem(30);
    line-height: rem(20);
  }
}

@import "button";
@import "checkbox";
@import "radio";
@import "input/base";
@import "date";
@import "label";
@import "select";
@import "text-area";
@import "upload"; 

button {
  outline: none;
}
textarea, input {
  outline: none;
  resize: none;
  &[disabled] {
    opacity: 0.3;
  }
}

$labelfont: 1.2rem;
$inputHeight: 3.5rem;

.formSelectComponent,.formTextComponent,.formTextareaComponent {
  max-width:rem(300);
}

%control {
  -webkit-appearance: none;
  background: $Salt;
  width: 100%;
  padding: em($form-element-padding-unit / 2, $bodyfont) em($form-element-padding-unit, $bodyfont);
  border: rem(1) solid $Ash;
  border-radius: $border-radius;
  display: block;
  font-size: em($bodyfont);
  height: $inputHeight;
  /*  line-height: 1;*/
}

.form {

  &__label-text {
    /*width: calc(100% - 4rem);*/
    font-size: 1.3rem;
  }

  &__group {
    position: relative;
    padding: 1rem;
    box-sizing: border-box;
    &--keyline {
      border-bottom: rem(1) solid $Obsidian;
    }
    &--keyline-inset {
      border-bottom:none;
      position: relative;
      &:after {
        display:block;
        content:'';
        border-bottom:rem(1) solid $Obsidian;
        position: relative;
        bottom: -2rem;
        left:0;
      }
    }
    &__description {
      color: $Slate;
      padding: 0;
      margin: .5rem 0;
      font-size: $labelfont;
    }
    &:first-child {
      padding-top: 0;
    }
  }
  &__label {
    line-height: 2;
    font-size: $labelfont;
    vertical-align: middle;
    font-family: $f-app-font-semibold;
    text-transform: uppercase;
    color: $Coal;
    -webkit-transition: color 1s;
    transition: color 1s;
    &--indent {
      padding: $gutter/2 $gutter/2 0;
      display: inline-block;
    }
    > .form__control {
      font-size: $labelfont;
    }
    &--checkbox,
    &--radio {
      font-size: em($bodyfont);
      text-transform: none;
    }
    &--checkbox {
      .form__label-text {
        margin-top: rem(-1);
      }
    }
    &--radio {
      float: left;
    }
    &--error {
      color: $Dragonfruit;
    }
    &--highlighted{
      color: $Grape;
    }
    &--nocaps {
      text-transform: none;
    }
    &--keyline{
      position: relative;
      display: block;
      z-index: 2;

      &:before {
        border-top: rem(1) solid $Coal;
        content:"";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      span{
        background-color: $Obsidian;
        padding-right: 0.5rem;
      }
    }
    &--bold{
      font-family: $f-app-font-bold;
    }
    &--description {
      margin-top: 0.5rem;
      color: $Slate;
      display: inline-block;
    }
    &--inverse {
      color: $Salt;
      border-radius: rem(2);
      &.form__label--error {
        color: $Salt;
        background: $Dragonfruit;
      }
    }
    &--disabled {
      color: $Ash;
      -webkit-transition: color 1s;
      transition: color 1s;
    }
  }
  &__required{
    text-transform: initial;
    color: $Dragonfruit;
    font-family: $f-app-font-regular;
    padding-left: 1rem;
  }
  &__label2 {
    color: $Ash;
  }
  &__control {
    @extend %control;
    &:hover{
      // border-color: $Slate;
    }
    &:active,
    &:focus {
      outline: none;
      border: rem(1) solid $Atlantis ;
      box-shadow: 0 0 rem(3) $Atlantis;
      @include transition();
    }
    &--error{
      border: rem(1) solid $Dragonfruit;

      &:active, &:focus {
        border: rem(1) solid $Dragonfruit ;
        box-shadow: 0 0 rem(3) $Dragonfruit;
      }
    }
    &--inverse {
      color: $Slate;
      background: transparent;
      border: rem(1) solid $Obsidian;
      box-shadow: none;
      //&:active,
      //&:focus {
      //  border: rem(1) solid $Obsidian;
      //}

      .form__label{
        color: $Quartz;
        font-family: $f-app-font-bold;
      }

      &.select__button:after{
        color: $Salt;
      }
    }
    &--transparent {
      background: transparent;
    }
    &--inline {
      @extend %control;
      display: inline-block;
      width:auto;
    }
    &--sidebar {
      border: rem(0);
      width: 100%;
    }
  }
  &__input-icon{
    position: absolute;
    right: 0;
    top: 0;
    border-left: rem(1) solid $Ash;
    font-size: 1.5rem !important;
    height: $inputHeight;
    line-height: $inputHeight !important;
    width: 4.5rem !important;
    text-align: center;
    cursor: pointer;

    &--disabled{
      opacity: 0.3;
      cursor: initial;
    }
  }
  &__submit {
    margin: 2rem 0 2rem;
  }
}

.form__control--login + .form__control--login {
  margin-top: 0.5rem;
}

/* Input with neighbour button

    <label class="form__label" for="filter-name">Filter name</label>
    <div class="input-group">
        <input name="filter-name" id="filter-name" class="form__control--inline" type="text" placeholder="Type here">
        <span class="input-group__button">
        <button class="button">Click here</button>
        </span>
    </div>


*/

.input-group {
  position: relative;
  display: table;
  width: 100%;
  .form__label {
    display: block;
  }
  // .form__control,
  .form__control--inline,
  .input-group__button {
    display: table-cell;
    vertical-align: middle;
    .button {
      margin: 0;
    }
  }
  .input-group__button {
    padding-left: 1rem;
    width: 7%;
  }
}

.sidebar{
  .fancy_select, .fancy_select__dropdown{
    width: 100%;
  }
}
