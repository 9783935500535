.modules_library-site-category-popular {
  padding-bottom: 30px;
  .card-user-profile-name {
    font-size: 1rem;
  }
  .heading {
    padding-bottom: 20px;
  }
  .large-2 {
    padding: 0 12px;
  }
}
