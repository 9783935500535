.request{
    display: inline-block;
    margin-right: -10px;

    &_button{
        display: inline-flex;
        justify-content: center;
        position: relative;
        height: 44px;
    }

    &_blanked{
        color: #b7c0cc !important;
    }

    &_content{
        width: 245px;
    }

    &_revise{
        width: 150px;
    }

    & .preloader, & .dot_circle{
        margin: 0 !important;
        width: fit-content;
    }

    & .preloader {
        position: absolute;
        top: 5%;
        left: 40%;
    }

    &_tooltip{
        position: absolute;
        width: fit-content;
        background: rgb(255, 255, 255);
        height: fit-content;
        top: -170%;
        left: 0;
        border-radius: 3px;
        padding: 10px 5px;
        color: #dd0000;

        & .flyout__caret {
            transform: rotate(-45deg);
            position: absolute;
            bottom: -15%;
            left: 50%;
        }
    }

    &_icon{
        &_fail{
            color: #000 !important;
            filter: invert(22%) sepia(24%) saturate(7416%) hue-rotate(349deg) brightness(105%) contrast(86%);
        }

        &_success{
            filter: invert(53%) sepia(91%) saturate(1008%) hue-rotate(75deg) brightness(89%) contrast(111%);
        }
    }

    &_span{
        margin-left: 5px;
    }
}
