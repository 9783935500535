#paidFeatures {
  .htmlContainer, .fancyBackground{
    background: white;
  }

  .row{
    max-width: 85rem !important;
  }
  strong{
    font-weight: bold !important;
  }
  p {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 150%;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-style: normal;
    color: black;
  }

  h4 {
    font-size: 24px;
    line-height: 147%;
    font-weight: 300;
    color: #4d4d4d;
    margin-bottom: 20px;
  }

  .subheader{
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    font-weight: normal;
    line-height: 1.4;
    color: #535A5F;
  }

  #discover_rxsuite{
    padding-top: 30px;
  }

  .card-user-profile {
    position: relative;
    z-index: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1rem;
    border: 0;
    border-radius: 0;
    background: #fefefe;
    box-shadow: none;
    overflow: hidden;
    color: #0a0a0a;
  }


  @media screen and (min-width: 60em) {
    #discover  {
      padding-top: 50px;
    }
  }

  @media screen and (min-width: 60em) {
    #discover section.discover_content:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 43px;
      background: #fff;
      -ms-transform: skewY(-4deg);
      transform: skewY(-4deg);
      top: 05px;
      z-index: 1;
      box-shadow: rgba(112,128,175,.2) 0 -14px 22px;
    }
  }

  #discover section.discover_content {
    position: relative;
    padding-top: 50px;
  }

  #rx-suitetabs > .tabs-title>a:focus{
    background: none !important;
  }

  .tabs > .is-active > a > h5 {
    color:purple;
  }

  .top-bar ul {
    background-color: #fff;
  }

  .customer-screens{
    .tabs {
      margin: 0;
      list-style-type: none;
      background: #fefefe;
      border: 0 solid #e6e6e6 !important;
    }
  }

  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    line-height: 1;
    font-size: 1rem;
  }

  .tabs-content {

    background: #fefefe;
    transition: all 0.5s ease;
    border: 0px solid #e6e6e6;
    border-top: 0;
  }

  @media screen and (max-width: 1023px){
    .orbit-slide > img{
      max-height: 300px;
    }
  }

  .orbit-slide > h3{
    color: white;
    text-shadow: 1px 3px 13px rgba(0,0,0,0.8);
  }

  .orbit-slide {
    text-align: center;
  }

  .tabs-panel{
    padding-right: 0;
    padding-left: 0;
  }

  .tabs-title>a[aria-selected=true]>h4{
    color: purple;
  }

  .tabs-title>a[aria-selected=true] {
    background: #ffffff;
  }

  .orbit-next, .orbit-previous{
    color: black;
    top: 15rem;
  }

  #imageSlide1{
    height: 32rem !important;
    img{
      max-width: 500px;
    }
  }

  #imageSlide2{
    height: 100% !important;
    img{
      max-width: 500px;
    }
  }

  #imageSlide3{
    height: 100% !important;
    img{
      max-width: 500px;
    }
  }

  .openbox {
    background-color:#fff !important;
    color:purple !important;
    border:1px solid purple;
    font-size:20px;
    box-shadow: 1px 2px 2px 2px rgba(0,0,0, 0.1);
  }

  .openbox:hover {
    background-color: purple !important;
    color: #FFF !important;
    border: 1px solid purple;
  }

  @media screen and (min-width: 1025px) {
    #home section.video:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 350px;
      background: white;
      -ms-transform: skewY(-5deg);
      transform: skewY(-5deg);
      bottom: -170px;
      z-index: 1;
      box-shadow: rgba(112, 128, 175, 0.2) 0 -16px 24px;
    }
    #home section.video {
      position: relative;
      padding-top: 0;
    }
  }

  .full-width-testimonial-content {
    width: 100%;
  }




  .footer-card{
    transition: margin-top ease .5s;
  }

  .footer-card:hover{
    margin-top: -20px;
  }


  .z-10 { z-index: 10; }
  .pos-rel { position: relative; }
  section.pillars { position: relative; }
  .fill-titan { background-color: #f8faff !important; }
  .h500 { height: 500px; }

  #offCanvasRight{
    background-color: #2c3840;
    height: 100%;
  }

  #userImg, .fa-bars{
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 5px;
  }

  ul > li > ul a{
    padding-left: 15px;
  }

  .no-border {
    .has-tip {
      border:none;
    }
  }

  #demoButton {
    color: #FFF !important;
    font-weight: 400;
  }

  #demoButton:hover {
    background-color: #793d7a;
  }

  .button.large {
    font-size: 1.25rem;
  }

  .btn-static-secondary.btn-shadow {
    box-shadow: 0 2px 6px 0 rgba(51,105,231,0.4);
  }

  .btn-static-secondary.btn-shadow:hover {
    box-shadow: 0 4px 6px 0 rgba(93, 66, 83, 0.65);
    color:#FFF;
  }

  .btn-static-secondary {
    border: none;
    color: #fff;
    background-image: linear-gradient(80deg, #00aeff, #3369e7);
  }

  h4 {
    font-size: 24px;
    line-height: 117%;
    font-weight: 300;
    color: #4d4d4d;
    margin-bottom: 20px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;
    color: #51595D;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
  }

  .sellerSalesMinHeight{
    min-height: 120px;
    font-size: 15px;
  }

  .clientsContainer{
    p{
      font-size: 15px;
    }
  }

  .column_reel {
    background-color:#eee;
    height:100px;
  }

  .separator {
    clear:both;
    height:10px;
  }

  .mid { height:15px;}
  .rlarge { height:25px;}
  .xlarge { height:30px;}


  .zurb-footer-top.bg-fblue {
    background: #074e68;
  }

  .zurb-footer-top {
    padding: 40px 0;
  }


  #pricing-questions .pricing-question h4, #pricing-questions .pricing-question h5 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #363945;
    padding: 0;
  }

  .form-style-2 input.input-field, .form-style-2 .tel-number-field, .form-style-2 .textarea-field, .form-style-2 .select-field {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid #c2c2c2;
    box-shadow: 1px 1px 4px #ebebeb;
    -moz-box-shadow: 1px 1px 4px #ebebeb;
    -webkit-box-shadow: 1px 1px 4px #ebebeb;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 7px;
    outline: 0;
  }

  .menu-centered{
    @media screen and (max-width: 39.9375em) {
      .expand li {
        padding-bottom: 5px;
        display: block;
      }
    }

    .menu a{
      line-height: 1;
      text-decoration: none;
      display: block;
      padding: .7rem 1rem;
    }

    .expand li{
      padding-left: 20px;
      padding-right: 20px;
    }

    .expand li a {
      color:#464646;
      opacity: .5;
      transition: opacity .25s ease-in-out;
      -moz-transition: opacity .25s ease-in-out;
      -webkit-transition: opacity .25s ease-in-out;
    }
    .expand li a:hover { border-bottom:3px solid #954096;color:#954096;opacity: 1}

    .expand li a.menu_active_link {
      color:#954096;
      border-bottom:3px solid #954096;
      opacity: 1;

      &.button{
        border-bottom: none;
      }
    }
  }

  .menu_active_link {
    color:#954096;
    border-bottom:3px solid #954096;

    &.button{
      border-bottom: none;
    }
  }

  .white_text_pop {color:#fff; text-shadow: 2px 2px 9px black;}

  .label_signup {color:#FFF;font-size:1.1em; text-shadow: 1px 1px 4px black;}

  .purple {
    display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color 0.55s ease-out, color 0.25s ease-out;
    vertical-align: middle;
    border: 2px solid #f1eff1;
    border-radius: 3em;
    padding: 0.85em 1em;
    margin: 0 0 1rem 0;
    font-size: 0.9rem;
    background-color: #b32962;
    color: #fefefe;
  }

  a{
    color: #954096;
  }

  .button{
    background-color: #954096;
    color: white;

    &:hover{
      background-color: #793d7a;
    }
  }

  .purple:hover {
    background-color: #79143e;
  }

  .top-bar, .top-bar ul {
    background-color: #fff;
  }

  div.bottom-triangles {
    padding: 10px 0 0;
    background: url("/static/images/merchantSolutions/bot-triangle.png") url("/static/images/merchantSolutions/top-triangle.png") no-repeat, no-repeat bottom, bottom;
    background-size: 100% 411px, 100% 411px;
  }

  div.content-container.border-grey {
    border: solid 1px #f2f2f2;
    padding: 20px 10px;
    margin: 42px auto;
  }

  div.content-container.border-grey p {
    font-size: 16px;
    line-height: 125%;
    font-weight: 300;
  }

  .centered {
    text-align:center;
  }

  .btm-part {
    padding-top: 42px;
    text-align: center;
    border-top: solid 1px rgba(142, 104, 104, 0.45);
  }

  #footer ul li a {
    font-size: 14px;
    line-height: 1px;
    color: #AF3FA1;

  }
  #footer a {
    font-weight: 300;
  }

  div.policy-div a {
    padding: 0 8px;
    color: #AF3FA1;
  }

  #selectors {
    background-size: cover;
    color:#fff;
    background: url("/static/images/merchantSolutions/trust_cover.jpg") no-repeat fixed 50% 50%;
  }

  div.benefit-section {
    padding: 112px 0;
  }

  .benefit-section p:first-child {
    font-size: 14px;
  }

  .uppercase {
    text-transform: uppercase;
    font-weight:300;
  }

  div.bottom-triangles {
    padding: 10px 0 0;
    background: url(/static/images/merchantSolutions/bot-triangle.png), url(/static/images/merchantSolutions/top-triangle.png);
    background-repeat: no-repeat, no-repeat;
    background-position: bottom, bottom;
    background-size: 100% 411px, 100% 411px;
  }

  #merchantsignup{

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    &.merchantsignupheader7 {
      background-image: url("/static/images/merchantSolutions/signup_banner_07.jpg");
    }

    &.merchantsignupheader {
      background-image: url("/static/images/merchantSolutions/visual_marketing/vm_hero.jpg");
      @media screen and (max-width: 1075px) {
        .hide-image-for-large{
          display: none !important;
        }
      }
      @media screen and (min-width: 1025px) {
        height: 600px;
      }
    }

    &.merchantsignupheader2 {
      background-image: url("/static/images/merchantSolutions/qa/hero_qa_sales.jpg");
      @media screen and (min-width: 60rem){
        height: 600px;
      }
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.merchantsignupheader3 {
      background-image: url("/static/images/merchantSolutions/location/location_reviews.jpg");
    }

    &.merchantsignupheader4 {
      background-image: url("/static/images/merchantSolutions/developer/hero_api_connect.jpg");
    }

    &.merchantsignupheader5 {
      background-image: url('/static/images/merchantSolutions/sellerratings/hero_seller_ratings.jpg');
      @media screen and (min-width: 60rem){
        height: 600px;
      }
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.merchantsignupheadeHeroContact {
      background-image: url('/static/images/merchantSolutions/hero_contact_us.jpg');
    }

    &.merchantsignupheader8 {
      background-image: url('/static/images/merchantSolutions/signup_banner_08.jpg');
    }

    &.merchantsignupheader9 {
      background: url('/static/images/merchantSolutions/signup_banner_09.jpg') no-repeat fixed center;
      background-size: cover;
    }

    &.merchantsignupheader10 {
      background-image: url('/static/images/merchantSolutions/clients/fatbraintoys/fatbraintoys_cover.png');
    }

    &.merchantsignupheader11 {
      background-image: url('/static/images/merchantSolutions/clients/barnes/barnes_and_noble_cover.png');
    }
    background-color: purple;

  }


  .btn-scroll:not(.pull-left):not(.pull-right) {
    -webkit-transform: translate3d(-50%,0,0);
    transform: translate3d(-50%,0,0);
    display: inline-block;
    left: 0;
    margin-left: 50%;
    position: absolute;
  }


  .btn-scroll-arrow {
    background-color: #FFF;
    display: block;
    width: 52px;
    height: 52px;
    -webkit-transition: fill .3s ease-in-out,transform .3s ease-in-out,color .3s ease-in-out;
    -o-transition: fill .3s ease-in-out,transform .3s ease-in-out,color .3s ease-in-out;
    transition: fill .3s ease-in-out,transform .3s ease-in-out,color .3s ease-in-out;
    border-radius: 50%;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.13);
    margin: -26px auto;
  }

  .btn-scroll-arrow svg {
    display: block;
    height: 47%;
    width: 100%;
    position: relative;
    fill: currentColor;
  }

  .btn-scroll {
    z-index: 23;
  }
  .btn-scroll {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    font-size: 16px;
    line-height: 1;
  }
  .btn-scroll-aquamarine {
    color: #9bf0e1;
  }


  header#merchantsignup {
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 10px 0 0;
  }

  .purple {
    display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color 0.55s ease-out, color 0.25s ease-out;
    vertical-align: middle;
    border: 2px solid #f1eff1;
    border-radius: 3em;
    padding: 0.85em 1em;
    margin: 0 0 1rem 0;
    font-size: 0.9rem;
    background-color: #b32962;
    color: #fefefe;
  }

  .purple:hover {
    background-color: #79143e;
  }

  .title_pop {
    color:#FFF;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    0px 8px 13px rgba(0,0,0,0.1),
    0px 18px 23px rgba(0,0,0,0.1);
  }
}

