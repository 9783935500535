/*===========================================
=            Config and settings            =
===========================================*/

$basefont: 10 !default;
// Base font size HTML element is set to
$bodyfont: 13 !default;
// Font size for regular body text

$h1font: 18 !default;
$h2font: 18 !default;
$h3font: 16 !default;
$h4font: 14 !default;
$h5font: 10 !default;

$base-lineheight: 1.618;

@function em($target, $context: $basefont) {
  @return ($target / $context) * 1em;
}

$use-markup-fix: false;

$sidebar-width: 320px;
$border-radius: 3px !default;
$borderTransitionSpeed: 0.2s;
$menu-height: 40px;

$fa-var-download: "\f019";
$fa-var-calendar: "\f073";
$fa-var-calendar-o: "\f133";
$fa-var-caret-down: "\f0d7";
$fa-var-caret-left: "\f0d9";
$fa-var-caret-right: "\f0da";

