body{
  color: #58595b;
  font-family: 'Open Sans',Verdana,Geneva,sans-serif;

  .fr {
    float: right;
  }

  .fl {
    float: left;
  }

  a {
    color: #58595b;
  }

  ul {
    list-style: none outside none;
    margin-left: 0;
  }

  hr{
    max-width: none;
  }

  p{
    font-size: 1.2rem;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Montserrat', sans-serif;
    color: #444;
  }

  .separator{clear:both;height:20px;font-size:1px;border:0;background:0 0;margin:0;padding:0}
  .separator-big{clear:both;height:40px;font-size:1px;border:0;background:0 0;margin:0;padding:0}
  .separator-medium{clear:both;height:10px;font-size:1px;border:0;background:0 0;margin:0;padding:0}
  .separator-small{clear:both;height:5px;font-size:1px;border:0;background:0 0;margin:0;padding:0}

  .button{
    font-size: 1.2rem;

    &.large{
      font-size: 1.4rem;
    }

    &.small {
      font-size: .95rem;
    }
  }

  .orange {
    color: orange;
  }

  .blue{
    color:#4D90E7;
    text-decoration:underline;
  }

  .label.round {
    padding: 6px 10px 5px;
    margin-left: 10px;
  }

  .label.round {
    -webkit-border-radius: 1000px;
    -moz-border-radius: 1000px;
    -ms-border-radius: 1000px;
    -o-border-radius: 1000px;
    border-radius: 1000px;
  }

  .stars {
    background: url('/static/images/icons/stars/star-sprite.png') no-repeat;
    height: 16px;
    margin: 0;
    padding: 0;
    vertical-align: top;
    width: 80px;
    border: 0;

    &.zero{background-position:0 0}
    &.half{background-position:0 -16px}
    &.one{background-position:0 -32px}
    &.onehalf{background-position:0 -48px}
    &.two{background-position:0 -64px}
    &.twohalf{background-position:0 -80px}
    &.three{background-position:0 -96px}
    &.threehalf{background-position:0 -112px}
    &.four{background-position:0 -128px}
    &.fourhalf{background-position:0 -144px}
    &.five{background-position:0 -160px}
  }

  .admindispute {
    color: #1d83da;
  }

  .has-tip, .has-tip:hover {
    border-bottom: none;
  }

  .has-tip {
    cursor: auto;
    font-weight: 400;
    color: inherit;
  }

  .modified {
    font-weight:bold;
    color: #0D6BBF;
  }

  code, pre {
    font-family: Monaco,courier,monospace;
    line-height: 1.4;
  }
  pre {
    background: #0b1022;
    overflow: scroll;
    padding: 0 10px 12px;
    margin: 0 0 20px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  pre, table.halfwidth thead tr.header a, table.halfwidth thead tr.header a:hover .blue {
    color: #fff;
  }

  tt {
    font-family: Monaco,courier,monospace;
    line-height: 1.0;
    background: #0B1022;
    margin: 0;
    padding: 0 3px;
    color: #fff;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    font-weight: normal;

    .comment {
      color: #727272;
    }
  }

  pre .comment {
    color: #727272;
  }

  tt .constant, pre .constant {
    color: #D8FA3C;
  }

  tt .storage, pre .storage {
    color: #FBDE2D;
  }

  tt .string {
    color: #61CE3C;
  }

  pre {
    .comment.docstring {
      color: #61CE3C;
    }

    .string {
      color: #61CE3C;

      &.regexp {
        color: #fff;
      }
    }

    .support.tag {
      &.script, &.style {
        color: #fff;
      }
    }
  }

  tt .keyword {
    color: #FBDE2D;
  }

  pre {
    .keyword, .selector {
      color: #FBDE2D;
    }
  }

  tt .inherited-class, pre .inherited-class {
    font-style: italic;
  }

  tt .entity, pre .entity {
    color: #FF6400;
  }

  [data-language="c"] .function.call, tt .support, pre .support {
    color: #8DA6CE;
  }

  tt {
    .variable.class, &.variable.global, .variable.instance {
      color: #FF6400;
    }
  }

  pre .variable {
    &.class, &.global, &.instance {
      color: #FF6400;
    }
  }

  div.alert-box.alert, div.callout.alert {
    background-color: #e0512b;
  }
  div.alert-box, div.alert-box.alert, div.alert-box.success, div.callout.alert  {
    color: #fff;
    text-shadow: 0 -1px rgba(0,0,0,.3);
    font-size: 1.2rem;
  }

  div.callout.success{
    color: #000;
    text-shadow: 0 -1px rgba(0,0,0,.3);
    font-size: 1.2rem;
  }

  div.alert-box, div.callout.alert {
    font-weight: 400;
    border-radius: 0;
  }

  fieldset.rrAdminOnly {
    background-color: #fee;
  }

  fieldset {
    border: solid 1px #ddd;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    padding: 12px;
    margin: 18px 0;
  }

  .iconCategories {
    background: url("/static/images/icons/icons_category.png") no-repeat;
    height: 12px;
    margin: 0;
    padding: 0;
    vertical-align: top;
    width: 12px;
    border: 0;
  }

  .icons {
    &.books-and-magazines {
      margin-top: 3px;
      background-position: 0 0;
    }

    &.computer-hardware-and-software {
      margin-top: 3px;
      background-position: -12px 0;
    }

    &.music-instruments-cds {
      margin-top: 3px;
      background-position: -24px 0;
    }

    &.DVD-and-VHS-Videos {
      margin-top: 3px;
      background-position: -36px 0;
    }

    &.consumer-electronics {
      margin-top: 3px;
      background-position: -48px 0;
    }

    &.toys-games-hobbies {
      margin-top: 3px;
      background-position: -60px 0;
    }

    &.sports-and-outdoors {
      margin-top: 3px;
      background-position: -72px 0;
    }

    &.apparel-and-jewelry {
      margin-top: 3px;
      background-position: -84px 0;
    }

    &.automotive {
      margin-top: 3px;
      background-position: -96px 0;
    }

    &.pets {
      margin-top: 3px;
      background-position: -108px 0;
    }

    &.food-and-gourmet, &.gifts-and-flowers, &.office-furniture-machines-supplies, &.cellular-and-accessories, &.aviation-and-marine, &.micellaneous, &.home-and-garden, &.health-fitness-beauty, &.services, &.tools-and-machinery {
      margin-top: 3px;
      background-position: 0 0;
    }

    background: url("/static/images/icons/icons.png") no-repeat;
    height: 12px;
    margin: 0;
    padding: 0;
    vertical-align: top;
    width: 12px;
    border: 0;

    &.topRev {
      margin-top: 3px;
      background-position: 1px 0;
    }

    &.totalReviews {
      margin-top: 3px;
      background-position: -12px 0;
    }

    &.helpfulReviews {
      margin-top: 3px;
      background-position: -24px 0;
    }

    &.speech {
      margin-top: 3px;
      background-position: -36px 0;
    }

    &.forumPosts {
      margin-top: 3px;
      background-position: -48px 0;
    }

    &.homePage {
      margin-top: 3px;
      background-position: -60px 0;
    }

    &.memberSince {
      margin-top: 3px;
      background-position: -72px 0;
    }

    &.lastVisit {
      background-position: -83px 0;
    }

    &.ratings {
      background-position: -95px 0;
    }

    &.flag {
      margin-top: 3px;
      background-position: -106px 0;
    }

    &.date {
      margin-top: 3px;
      background-position: -119px 0;
    }
  }

  .swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
  }

  .swipe-wrap {
    overflow: hidden;
    position: relative;

    > div {
      float: left;
      width: 100%;
      position: relative;
    }
  }

  .ui-datepicker th {
    font-size: 12px;
  }

  .offscreen {
    position: fixed;
    right: 101%;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .reveal-modal-bg {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #000;
    background: rgba(0,0,0,.45);
    z-index: 40;
    display: none;
    top: 0;
    left: 0;
  }

  table {
    tbody{
      border: none;
      background: none;
    }

    td {
      color: #333;
      .success {
        display: block;
        width: 100%;
        height: 23px;
        background: url('/static/images/tickscrosses.png') no-repeat center -313px;
        overflow: hidden;
        text-indent: -9000px;

        &.true {
          background-position: center -13px;
        }

        &.false {
          background-position: center -113px;
        }

        &.info {
          background-position: center -213px;
        }
      }

      .info:hover, a.info:hover {
        background-position: center -263px;
      }

      .success {
        &.true:hover {
          background-position: center -63px;
        }

        &.false:hover {
          background-position: center -163px;
        }
      }
    }

    &.halfwidth {
      float: left;
      width: 100%;
      border-bottom: 1px solid #e0e5e9;
      font-family: "Helvetica Neue",Arial,sans-serif;
      font-size: 1.2em;
      line-height: 1.5em;
      color: #8e959c;

      .nohover.hover {
        background: 0 0;
      }

      td {
        border-bottom: 1px dotted #e0e5e9;
        padding: 10px;
        text-align: center;

        &.element-style {
          text-align: left;
          width: 12.5%;
        }

        &.element-header {
          &.hover, &:hover {
            background-color: #fff;
          }
        }
      }

      tr.hover td.element-header {
        background-color: #fff;
      }

      .hover, tr:hover {
        background: #effafd;
        color: #2f353e;
      }

      .hover td .success.true, tr:hover td .success.true {
        background-position: center -63px;
      }

      .hover td .success.false, tr:hover td .success.false {
        background-position: center -163px;
      }

      thead {
        background: #242b33;
        color: #fff;
        border-bottom: 2px solid #fff;

        td {
          padding-top: 15px;
          padding-bottom: 15px;
          border: 0;
        }

        tr {
          &.header {
            a {
              color: #fff;

              &:hover {
                color: #72d0eb;

                .blue {
                  color: #fff;
                }
              }
            }

            td.element-header {
              text-align: center;
              color: #fff;
              width: 12.5%;
              border: 0;
              background: inherit;
              padding: 15px;
              font-size: 1.2rem;
            }
          }

          &:hover {
            background: 0 0;
            color: #fff;
          }
        }

        .blue, a {
          color: #72d0eb;
        }
      }

      td.element-header {
        text-align: left;
        background: url('/images/resources/teeth.gif') bottom repeat-x;
        width: 100%;
        border: 0;
        padding: 10px 10px 18px;
        font-weight: 700;
        color: #2f353e;
      }

      .label {
        display: inline;
        background: #ffeeab;
        font-size: .9em;
        padding: 2px 3px;
        -khtml-border-radius: 2px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        behavior: url('http://campaignmonitor.com/assets/style/PIE.htc');
        color: #937f38;
        margin: 0 0 0 2px;
      }
    }

    &.video {
      thead td.header td.element-header, tr td.element-style {
        width: 16%;
      }

      td {
        width: 12%;
      }
    }

    &.images {
      td {
        width: 25%;

        &.element-style {
          width: 25%;
        }
      }

      thead td.header td.element-header, tr td.element-style {
        width: 25%;
      }
    }

    &.forms {
      color: #8e959c;

      thead tr.header td.client {
        text-align: center;
        font-size: 1.2em;
        color: #2f353e;
      }
    }

    &.halfwidth.wide {
      width: 80%;
    }
  }

  #stickyNav {
    display: none;
    position: fixed;
    top: 0;
    margin-top: 0;
  }

  @media screen and (max-width:1250px){
    table.halfwidth.wide{
      width:98%;margin:0 1% 90px
    }
    table.halfwidth.wide .label{
      display:none
    }
  }

  .warning {
    margin-bottom: .5em;
    background: darkkhaki;
    width: 23em;
    padding: 1em;
    font-size: 1.4rem;
    color: white;
    border-radius: 7px;
  }

  .threetabprogress {
    margin-top: 10px;
    float: left;
    min-width: 10px;
    color: #000000;
    text-align: center;
    font-size: .7em;
    border: .5px solid #ccc;
    line-height: 11px;
    padding: 0;
  }
}