.reactPanel {
    padding: 1.6em;
    width: 100%;

    h1,h2,h3,h4,h5 {
        font-family: 'Open Sans',Verdana,Geneva,sans-serif;
    }

    h2{
        font-size: 18px;
    }

    h3{
        font-size: 16px;
    }

    h4{
        font-size: 14px;
    }

    h5{
        font-size: 12px;
    }

    .no-padding-left{
        padding-left: 0;
    }

    .no-padding-right{
        padding-right: 0;
    }

    &.dark{
        color: $Salt;
        background: $HardObsidian;

        .reactLabel{
            color: $Quartz;
        }

    }

    &.light{
        color: $DarkSlate;
        background: $Salt;

        .reactLabel{
            color: $DarkSlate;
        }

    }
}

