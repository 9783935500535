&--card {
  border-bottom: rem(1) solid $Ash;
  padding: 0;
  padding-left: 2em;

  .tabs{
    &__tabitem + .tabs__tabitem {
      margin: 0 0 rem(-1) 0;
    }

    &__tabitem {
      display: inline-block;
      font-size: 1.4em;
      text-align: center;
      cursor: pointer;
      line-height: 1em;
      border-right: rem(1) solid $Ash;
      border-top: rem(1) solid $Ash;
      padding: 1.25rem 2rem;
      margin-bottom: rem(-1);
      font-family: $f-app-font-semibold;
      color: $Slate;
      @include transition(all, 150ms, ease);
      font-weight: 500;

      &:hover{
        border-bottom: none;
      }

      &:first-of-type{
        border-left: rem(1) solid $Ash;
      }

      &--selected {
        border-bottom: rem(1) solid $Salt !important;
        color: $Obsidian;
      }
    }
  }

  &.tabs--questions{
    .tabs__tabitem{
      max-width: 30%;
      height: 6.5em;
      line-height: inherit;
      display: inline-table;
      text-align: left;
    }
  }
}