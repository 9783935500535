.storePicker{
	font-weight: 600;

	.selectHeading{
		font-weight: 600;
		line-height: 1.5;
		color: #58595b;
	}

	.grid-x{
		display: flex;
		align-items: center;
	}
	.store_name{
		color:purple;
	}

	.add-store-button{
		color:purple;
		cursor: pointer;
	}

	

}