@import "../config";

.dropdown {
  position: relative;
  cursor: pointer;

  .dropdown_toggle {
    background: $Salt;
    border: 2px solid #C8C8C8;
    padding: 7px 20px 7px 10px;
    color: $Obsidian;
    transition: border-color $borderTransitionSpeed;

    span{
      line-height: 1.5rem;
      font-size: 1rem;
    }

    .fa, .fal, .far, .fas{
      margin-top: 2px;
      position: absolute;
      font-size: 16px;
      right: 8px;
      top: 8px;
    }

    &:focus{
      border-color: $HalfSlate;
    }

  }

  .dropdown-container {
    overflow-y: scroll;
    background: $Salt;
    border: 2px solid #C8C8C8;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    min-width: 102%;
    z-index: 20;
    transition: background-color $borderTransitionSpeed;

    &.scrollable{
      height: 400px;
      overflow-y: scroll;
    }

    .scrollbox{
      margin: 0;
      padding: 0;
      width: 95%;

      .menu-option {
        padding: 10px 14px 14px 10px;
        line-height: 1.5;
        font-size: 1rem;
        color: $Obsidian;

        &:hover{
          background-color: $HalfQuartz;
        }
      }

      .menu-option:last-child{
        margin-bottom: 0;
      }
    }
  }

  &.dark{
    .dropdown_toggle{
      border-color: $Slate;
      background-color: $Slate;
      color: $Salt;
      border-radius: $border-radius;

      &:focus{
        border-color: $Ash;
      }

      &:hover {
        border-color: $Ash;
      }
    }

    .dropdown-container{
      border-radius: $border-radius;
      border-color: $Ash;

      .menu-option{
        background-color: $Slate;
        color: $Salt;

        &:hover{
          background-color: $Coal;
        }
      }
    }
  }
}


.dropDownLabelContainer{
  background: #fff;
  border: 1px solid #e9ecf2;
  padding: 7px 10px;
  min-width: 50px;
  text-align: center;
  color: #222b3c;
  transition: border-color .2s;

  &.noBorder{
    border: none;
  }
}

.collapsibleList i:hover{
  color: #666;
}


.flexCard{
  .dropdown_toggle{
    display: flex;
  }

  .dropdown-container{
    display: flex;
  }
}


