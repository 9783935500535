.modules_library-site-store-alternatives {
  padding: 20px 4vw;
  max-width: 1200px;
  margin: auto;
  .description {
    font-size: .8em;
    span {
      font-style: oblique;
    }
  }
  .mainStore {
    border-bottom: 1px solid #ccc;
    padding-bottom: 18px;
    margin-bottom: 18px;
  }
  .alternativeStore {
    border-bottom: 1px solid #ccc;
    &:last {
      border: none;
    }
  }
  .reviews {
    .review {
      padding: 20px;
      font-size: .7em;
      .modules_library-site-user-user {
        padding-top: 16px;
        .username {
          padding-top: 8px;
          font-size: .5em;
        }
      }
      .bubble {
        &:after {
          left: 80px;
        }
      }
      .blurOut{
        &:after {
          display: none;
        }
      }
    }
  }
  .modules_library-site-store-card {
    border-bottom: none;
  }
}
