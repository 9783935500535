.modules_library-site-search-page {
  padding: 0 30px;
  @media (max-width: 500px) {
    padding: 0 12px;
  }
  .content {
    display: flex;
    .LeftContent {
      padding-right: 60px;
      width: 75vw;
    }
    .RightContent {
      width: 25vw;
    }
    @media (max-width: 1199px) {
      display: block;
      .LeftContent {
        width: auto;
        padding-right: 0;
      }
      .RightContent {
        width: auto;
        .modules_library-site-search-page-featured {
          display: flex;
          .modules_library-site-store-card {
            padding-bottom: 40px;
            max-width: 300px;
            padding-right: 30px;
          }
        }
      }
    }
  }
}
