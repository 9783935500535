.topNav{
	font-family: 'Varela Round', sans-serif;
	padding: 0 0 15px;
	border-bottom:0.25em solid #f0f0f0;

	a {opacity: 0.6;
		color: black !important;
			-o-transition:.5s;
	      	-ms-transition:.5s;
	      	-moz-transition:.5s;
	      	-webkit-transition:.5s;
	      	font-size: 1.2em;
		       &:hover {
		    		opacity: 100;
		    		color: #954096 !important;
		    		opacity: 1;
				}
			}

	li{
		display:inline-block;
		padding-right: 2em;
	}



	.selected{
		opacity: 1;
		color: #954096 !important;
		border-bottom: 3px solid #954096;
	}
}