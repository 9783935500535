.modules_library-site-store-store-add{
  #storeAddGrid {
    max-width: 34em;
    #storeAddForm{
      .inputContainer{
        padding-bottom: 10px;
        input {
          height: 3em;
        }
      }
    }
  }
  display: flex;
  align-items: center;
}