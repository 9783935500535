@import "colors";
@import "config";
@import "generics";
@import "variables";
@import './../../../node_modules/react-dates/css/styles.scss';
@import './../../../node_modules/react-select/scss/default.scss';


@import 'apps/site';
@import './../overwrites/rr_react_dates';
@import "modules_library/site_modal";
@import "cx-component-library";
@import 'modules_library/slick/slick';
@import 'modules_library/slick/slickTheme';
@import 'modules_library/react-bnb-gallery/react-bnb-galley';

html {
    &.spa{
        h1, h2, h3, h4, h5, h6{
            font-family: 'Raleway', Arial-bold, arial, sans-serif;
        }
        font-size: 80%;
        height: 100%;
        min-width: 1400px;

        body{
            height: 100%;
            width: 100%;
            overflow: hidden;
            position: static;

            ._view__common{
                height: 100%;
            }
        }

        @media (max-width: 1300px) {
            body {
                overflow-x: scroll;
            }
        }

        body ._view__common, #container {
            height: 100%;
            background-color: white;
            overflow: hidden;

            &.ui-draggable{
                height: auto;
            }
        }

        html.spa #container{
            padding-top: 54px;
        }

        .nothingToDisplay{
            margin-top: 200px;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
            float: none;
            width: 500px;
            position: relative;

            span{
                font-size: 16px;
                position: absolute;
                left: 70px;
                top: 18px;
            }

            i{
                color: $PinkAction;
                width: 50px;
                position: absolute;
                left: 0;
                top: 12px;
            }
        }
    }

    body{
        .top-bar{
            background: $Salt;
            ul{
                background: $Salt;
            }
            &#consumer-site-nav{
                padding: 20px 20px 40px;
            }
        }

        .rrLogoContainer{
            padding: 0;

            a{
                padding: 0;
            }
        }

        #emailPreviewModal, #email_preview,  #mailer_text_preview{

             .seller_header{
                img{
                        max-width: 800px!important;
                    }
                table{
                    tbody{
                        td{
                            padding: 0!important;
                            margin: 0!important;
                        }
                 
                    }
                }
            }
            table{
                width: inherit;
                margin: 0;

                tr{
                    background-color: inherit;
                }




                tbody{
                    tr {
                        td{
                            img{
                                max-width: 200px;
                                margin-right: auto;
                                margin-left: auto;
                            }
                        }
                    }
                }

                .tracking_pixel{
                    img{
                        width: auto !important;
                    }
                }
            }
            table[role=presentation]
            {
                width: 100%;
            }
        }

        .clickable {
            cursor: pointer;
        }

        #mailer_text_preview{

            div.mj-column-px-180{
               width: 300px !important;
               max-width: 350px;
            }

            .mj-column-per-20{
              table {
                width: 100%;
              }
            }

            .mj-column-per-20{
              img{
                width: 50px !important;
                text-align: center;
                margin-right: auto;
                margin-left: auto;
              }
            }
        }

        .rfModal {
            position: absolute;
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            max-width: 85%;

            font-family: 'Open Sans',Verdana,Geneva,sans-serif;

            background: #fff;
            overflow: auto;
            -webkit-overflow-scrolling:     touch;
            border-radius: 4px;
            outline: none;
            padding: 20px;

            .pricing_table{
                height: 100%;
                width: 100%;
            }

            .ReactTable{
              .rt-table{
                .rt-tbody{
                  font-size: 18px;
                  text-align: center;
                }
              }
            }

            h1, h2, h3, h4 {
                font-family: 'Montserrat', sans-serif;
                color: #444;
                font-weight: bold;
            }

            h1 {
                font-size: 28px;
                text-align: center;
                margin-bottom: 15px;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: #58595b;
            }

            .modal-transition-enter {
                opacity: 0.01;
            }
            .modal-transition-enter.modal-transition-enter-active {
                opacity: 1;
            }
            .modal-transition-leave {
                opacity: 1;
            }
            .modal-transition-leave.modal-transition-leave-active {
                opacity: 0.01;
                transition: opacity 500ms ease-in;
            }

            .yesNoDialog{
                margin: 15px;

                .button{
                    float: right;
                    margin-left: 25px;
                }
            }

            a{
                color: $RRMain;

                &:hover{
                    color: $RRMainDark;
                }
            }

            .rr_review_benefits {

                h3, h4, p {
                    font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
                }
                h3 {
                    img {
                        height: 37px;
                        margin-top: -10px;
                    }
                    font-size: 1.5rem;
                    text-align: center;
                    margin-bottom: 20px;
                    font-weight: normal;
                }

                h4 {
                    font-size: 1.05rem;
                }
            }


        }

        .rfModalDialog{
            p{
                color: #333;
                font-weight: 300;
                font-size: 20px;
                margin-bottom: 30px;
            }

            .button{
                float: right;
            }

            .button + .button{
                margin-right: 40px;
            }
        }

        .reveal-modal {
            background: $Salt;
            visibility: hidden;
            display: none;
            top: 100px;
            left: 50%;
            margin-left: -260px;
            width: 520px;
            position: absolute;
            z-index: 41;
            padding: 30px;
            -webkit-box-shadow: 0 0 10px rgba(0,0,0,.4);
            -moz-box-shadow: 0 0 10px rgba(0,0,0,.4);
            box-shadow: 0 0 10px rgba(0,0,0,.4);

            &.large {
                width: 60%;
                margin-left: -30%;
            }

            .close-reveal-modal {
                font-size: 2.2rem;
                line-height: .5;
                position: absolute;
                top: 8px;
                right: 11px;
                color: #aaa;
                text-shadow: 0 -1px 1px rgba(0,0,0,.6);
                font-weight: 700;
                cursor: pointer;
            }
        }

        div.privacy_policy_content {
            h2 small {
                color: #6F6F6F;
                font-size: 22px;
            }

            ol, ul {
                list-style: none;
            }
        }

        button, a, input{
            outline: none;
        }

        .off-canvas-wrapper {
            .title-bar{
                width: 100%;
                background-color: #fefefe;
                margin-top: 0;
                position: fixed;
                left: 0;
                z-index: 50;
                .close-button{
                    top: 0
                }
            }
            .off-canvas{
                position: fixed;
                z-index: 12;
                transition: transform 0.5s ease;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                &.position-top {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 330px;
                    overflow-x: auto;
                    -ms-transform: translateY(-330px);
                    transform: translateY(-330px);
                }
                &.is-open{
                    -ms-transform: translate(0, 50px);
                    transform: translate(0, 50px);
                }
                &.position-top.is-transition-push {
                    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }
                &.position-top.is-open {
                    z-index: 15;
                    width: 100%;
                }
                .is-accordion-submenu-parent>a {
                     &:after{
                         border-color: #0c0c0c transparent transparent;
                     }
                }
                &.foundation_site_nav_mobile{
                    > div > ul > ul {
                        margin: 10px;
                        border-bottom: 1px solid #eaeaea;
                    }
                    > div > ul > li {
                        margin: 10px;
                        border-bottom: 1px solid #eaeaea;
                    }
                    .menu.vertical {
                        background: #fff;
                    }
                    padding-left: 10px;
                    padding-right: 10px;
                    .social{
                        display: inline-block;
                    }
                }
                background-color: $Salt;
                ul{
                    li{
                        a{
                            font-weight: 700;
                            padding: .4em 1rem;
                            font-size: 14px;
                            color: #627085;
                            font-family: 'Raleway',sans-serif;
                        }
                        ul{
                            li{
                                a{
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                        }

                        h4{
                            color: #0c0c0c;
                            font-size: 17px;
                            font-weight: 300;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .title-bar {
                background-color: $Salt;

                .menu-icon-wrapper {
                    padding: 5px;
                    width: 40px;
                    margin-left: auto;
                    background: black;
                    .menu-icon::after {
                        color: white;
                        box-shadow: 0 7px 0 white, 0 14px 0 white;
                        background: white;
                    }
                }
            }

            #menu_avatar_dropdown {
                & > a {
                    padding-right: 0;
                    &::after {
                        content: none;
                    }
                }
            }

            @media screen and (max-width: 1023px) {
                #foundation_site_header{
                    > div > ul > ul {
                        margin: 10px;
                        border-bottom: 1px solid #eaeaea;
                    }
                    > div > ul > li {
                        margin: 10px;
                        border-bottom: 1px solid #eaeaea;
                    }
                    .menu.vertical {
                        background: #fff;
                    }
                    padding-left: 10px;
                    padding-right: 10px;
                    .menu.vertical {
                        background: #fff;
                    }
                    li {
                        a {
                            color: white;
                        }

                        ul a {
                            color: white;
                        }
                    }
                    &.solutions-menu li{
                        a{
                            font-weight: bold;
                            color: $Slate;
                        }
                        ul a{
                            font-weight: bold;
                            color: $Slate;
                        }
                    }
                }

            }

            .top-bar-left{
                #topBarLogo{
                    margin-bottom: 0;
                }
            }

            #foundation_site_header{
                #demoButton{
                    display: inline-block;
                    text-align: center;
                    font-weight: normal;
                    line-height: 1;
                    cursor: pointer;
                    -webkit-appearance: none;
                    transition: background-color .55s ease-out,color .25s ease-out;
                    vertical-align: middle;
                    border: 2px solid #C127AB;
                    font-size: .9rem;
                    background-color: #8F256F;
                    color: #fefefe;
                }
                @media screen and (min-width: 1024px) {
                    .separator_top{
                        border-top: 1px solid;
                        border-color: lightgrey transparent transparent;
                    }
                }
                #demoButton:hover{
                    background-color: #C127AB;
                    border: 2px solid #8F256F;
                }
                li > i{
                    padding-top: 15px;
                    padding-left: 15px;
                    padding-bottom: 15px;
                }
                li > i > a{
                    padding-left: 15px;
                }
                a{
                    font-size: 14px;
                    color: $Slate;
                    font-family: 'Raleway',sans-serif;
                }
                .call-to-action{
                    color: #fff;
                }
                .dropdown.menu>li.is-dropdown-submenu-parent>a::after {
                    border-color: $Slate transparent transparent !important;
                }
            }

            .foundation_site_nav {
                color: #58595b;
                font-family: 'Varela Round',sans-serif;
                padding-top: 6px;
                padding-right: .7rem;
                li {
                    a {
                        font-weight: normal;
                        &:hover {
                            background-color: $Slate;
                            color: $Salt;

                            h4{
                                color: $Salt;
                            }
                        }

                    }

                    &#careDropdown{
                        a:after{
                            display: none;
                        }
                    }

                    &.user-buttons{
                        a {
                            &:hover {
                                background: none;
                                color: #627085;
                                text-decoration: underline;
                            }
                        }
                    }

                    & > a {
                        font-size: 14px;
                        font-weight: normal;
                        color: $Slate;
                    }

                    &.is-dropdown-submenu-parent {
                        &.is-active > a {
                            background-color: $Slate;
                            color: $Salt;
                        }

                        > a {
                            &::after {
                                border-color: $Slate transparent transparent !important;
                            }
                        }

                        & > ul.is-dropdown-submenu {
                            top: 85%;
                            opacity: 0;
                            &.js-dropdown-active{
                                opacity: 1;
                                animation-name: fadeInOpacity;
                                animation-iteration-count: 1;
                                animation-timing-function: ease-in;
                                animation-duration: .1s;

                                @keyframes fadeInOpacity {
                                    0% {
                                        opacity: 0;
                                    }
                                    100% {
                                        opacity: 1;
                                    }
                                }
                            }
                            li {
                                a {
                                    font-weight: 400;
                                    h4{
                                        font-size: 17px;
                                        font-weight: 300;
                                        margin-bottom: 10px;
                                        color: $Slate;

                                    }
                                    p{
                                        font-size: 16px;
                                    }

                                    &:hover{
                                        h4{
                                            color:$Salt;
                                        }
                                    }
                                }

                                &.separator_top {
                                    border-top: 1px solid $Slate;
                                }

                                &.dd_menu_button {
                                    a {
                                        color: $Salt;
                                        background-color: $RRMain;

                                        &:hover {
                                            text-shadow: 0 0 1px $Salt;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.accountNotActive {
                        a {
                            color: $Salt;
                            background-color: #FF9800;

                            &:hover {
                                text-shadow: 0 0 1px $Salt;
                            }
                        }
                    }
                }
            }

        }

        .site-button-wrapper{
            border-bottom: none;
            padding: 0 5px;
            .site-button{
                font-weight: 700;
                background-image: -webkit-gradient(linear, left top, right top, from(#a321dd), to(#c600ea));
                background-image: linear-gradient(to right, #a321dd, #c600ea, #c600ea, #a321dd);
                background-size: 300% 100%;
                display: inline-block;
                color: #fff;
                padding: 15px 24px;
                border-radius: 1000px;
                font-size: .85rem;
                letter-spacing: .25px;
                white-space: nowrap;
                -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
                box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
                text-transform: uppercase;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;

                &:hover{
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                }
            }
        }

        .separator {
            clear: both;
            height: 10px;

            &.large { height: 20px; }
            &.rlarge { height: 25px; }
            &.xlarge {
                height: 30px;
            }
        }

        #footer {
            font-family: 'Varela Round', sans-serif;

            a {
                font-size: 14px;
                line-height: 1px;
                color: $RRPurple;
                font-weight: 300;
                padding: 1em 0;
            }

            .social-btns{
                #facebook {
                    color: #3b5998;
                    &:hover {
                        color: #183b55;
                    }
                }
                #twitter {
                    color: #1dcaff;
                    &:hover {
                        color: #0084b4;
                    }
                }
                #linkedin{
                    color: #4875B4;
                    &:hover{
                        color: #455a96;
                    }
                }
            }

            &.bottom-triangles {
                padding: 10px 0 0;
                background: url("/static/images/bot-triangle.png"), url("/static/images/top-triangle.png");
                background-repeat: no-repeat, no-repeat;
                background-position: bottom, bottom;
                background-size: 100% 411px, 100% 411px;
            }

            &.centered {
                text-align: center;
            }

            .uppercase {
                text-transform: uppercase;
                font-weight: 300;
            }

            h1, h2, h3, h4, h5, h6 {
                font-family: 'Montserrat', sans-serif;
            }

            .btm-part {
                padding-top: 42px;
                text-align: center;
                border-top: solid 1px rgba(142, 104, 104, 0.45);
            }

            p {
                margin-top: 10px;
                margin-bottom: 0;
                color: #535A5F;
                line-height: 30px;
                padding: 0;
                font-family: 'Lato', sans-serif;
                font-size: 16px;
                font-weight: 500;
            }

            div.policy-div a {
                padding: 0 8px;
                color: $RRMain;
            }

        }

        table.halfwidth {
            border-collapse: collapse;

            thead {
                background: #242B33;
                color: $Salt;
                border-bottom: 2px solid $Salt;

                th {
                    text-align: center;
                    color: $Salt;
                    border: 0;
                    background: inherit;
                    padding: 15px;
                    font-weight: 700;
                }
            }
        }
        .siteContent {
            form {
                .inputContainer {
                    &.checkboxInput {
                        label {
                            display: initial;
                        }
                    }

                    .validation-error {
                        color: red;
                    }

                    label {
                        span {
                            color: $PinkAction;
                            font-size: 0.9em;
                        }
                    }

                }

                div.buttonsContainer.row {
                    margin-top: 15px;
                }

                p {
                    margin-bottom: 5px;
                }
            }


            &.scrollable{
                height: 90%;
                overflow:auto;
                padding-bottom: 50px;
            }

            .cardContainer{
                margin: 0 2rem 3rem 2rem;
            }

            .tableHint{
                font-size: 1.2rem;
                font-weight: 500;
                margin-top: 1rem;
                margin-bottom: .5rem;
            }

            #topbar {
                background-color: $PinkAction;
                color: white;
                padding: 15px;
                overflow: auto;
                width: 100%;
                margin-bottom: 20px;

                h4 {
                    margin-bottom: 18px;
                    small{
                        color: white;
                    }
                }

                p {
                    margin: 0;
                    font-size: 20px;
                    font-weight: normal;

                    strong {
                        font-weight: bold;
                    }
                }

                p, h4, h5, h2.subheader {
                    color: white;
                }

                h3{
                    font-size: 1.95rem;
                }
            }
        }

        .purple-gradient{
            font-weight: 700;
            background-image: -webkit-gradient(linear, left top, right top, from(#a321dd), to(#c600ea));
            background-image: linear-gradient(to right, #a321dd, #c600ea, #c600ea, #a321dd);
            background-size: 300% 100%;
            display: inline-block;
            color: #fff !important;
            padding: 15px 24px;
            border-radius: 1000px;
            font-size: .85rem;
            letter-spacing: .25px;
            white-space: nowrap;
            -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
            box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
            text-transform: uppercase;
            moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
        }

        .purple-gradient:hover{
            background-position: 100% 0;
            moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
        }


        .color{
            &.velvet{
                color: $velvet;
            }

            &.leaf{
                color: $Leaf;
            }

            &.rrMain{
                color: $RRMain;
            }

            &.rrDarkMain{
                color: $RRMainDark;
            }

            &.darkGrey{
                color: $Coal;
            }

            &.amber{
                color: $Amber;
            }

            &.orange{
                color: $Tangerine;
            }

            &.salt{
                color: $Salt;
            }

            &.transparent{
                opacity: 0.5;
            }

            &.pinkAction{
                color: $PinkAction;
            }

            &.atlantis{
                color: $Atlantis;
            }

            &.hover{
                &:hover{
                    color: $RRMain;
                }
            }
            &.rr-pink {
                color: $RRHotPink;
            }
        }

        .validation-error{
            color: $velvet;
            font-weight: bold;
        }
    }

    .stickyheader {
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
    }

    #footer{
        .menu{
            margin-top:10px;
        }
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: 'Montserrat', sans-serif;
    }

    .rrMainColor {
        color: $RRMain;
    }

    a.rrMainColor {
        color: $RRMain;

        &:hover {
            text-decoration: underline;
        }
    }

    a.underline{
        text-decoration: underline;
    }
    a.underline-hover:hover{
        text-decoration: underline;
    }

    input:checked~.switch-paddle{
        background: $RRMain;

        .switch-active{
            color: $Salt;
        }

    }

    button.disabled:hover {
        cursor: not-allowed;
    }

    .appearFade-enter {
        opacity: 0.01;
    }

    .appearFade-enter-active {
        opacity: 1;
        transition: opacity 500ms ease-in;
    }

    .appearFade-exit {
        opacity: 1;
    }

    .appearFade-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }

    textarea#generatedCodeCopied{
        width: 1px;
        height: 1px;
        padding: 0;
        margin: 0;
        background: white;
        border: white;
        box-shadow: none;
    }

    .vTab{
        padding: 10px 10px 5px 10px;
        text-align: left;
        font-weight: normal;
        color: #982369;
        font-size: 12px;
        border-bottom: 3px solid $RRPurpleFaded;
        cursor: pointer;
        &:hover{
            background-color: #e7e7e7;
        }
    }
    .vTab.selected{
       background-color:  $RRPurpleFaded;
       font-weight: 800;

    }

}

