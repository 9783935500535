.modules_library-site-comparison_suggested {
  position: absolute;
  padding: 20px;
  border: 1px solid #cacaca;
  border-radius: 2px;
  overflow-y: scroll;
  z-index: 100000;
  background: #fff;
  width: 60vh;
  @media screen and (max-width: 600px) {
    width: 40vh;
  }
}