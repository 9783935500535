@import "../../colors";

#error404Container{
  p{
    font-size: 1rem;
  }
  a{
    color: #D83FFC;
    text-decoration: underline;
  }
  .centered{
    text-align: center;
  }
}

#error503Container{

  a{
    color: $RRPurple;
  }
  .centered{
    text-align: center;
  }
  ul{
    a{
      color: $RRPurple;
    }
  }
}