.avatar-list {
  &__item {
    display:inline-block;
    position: relative;
    margin-left: -1rem;
    &:first-child, &--more {
      margin-left:0;
    }
  }
}
