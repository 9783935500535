@import "../cx-component-library/components/plan-grid";

.site-nav {
  background: linear-gradient(to right, #2d2d2d ,#6740e8);
  color: #FFFFFF;
  font-family: Jost, Helvetica,Georgia,"Times New Roman",Arial,sans-serif;
  height: 54px;
  width: 100%;
  position: static;
  top:0;

  &.admin{
    background: linear-gradient(to right, #7736a3, #38237d);
  }

  .site-nav__collapseable{
      margin: 0;
  }

  .site-nav__branding {
      float: left;
      font-size: 1.6em;
      height: 100%;
      text-align: center;
      margin-left: 1rem;
      margin-right: 1rem;
  }

  .site-nav__navitem {
    padding: 0;
    float: left;
    display: block;
    height: 54px;


    &:hover, &--active {
      background: $Salt;
      .site-nav__link{
        i.site-nav__icon{
          color: $DarkPinkAction;
        }
      }
    }

    &.site-nav__navitem{
      &[data-title="Install Javascript"] {
        @media only screen and (min-width: 1565px) {
          /* Default tooltip to off (no display) */
          .__react_component_tooltip {
            display: none
          }
        }
        @media only screen and (max-width: 1564px) {
          /* Then, if we need a tooltip, we can show it */
          .__react_component_tooltip{
            display: inline-block;
          }
          .site-nav__text{
            display: none;
          }
        }
      }
      .collapsible{
        margin: 0;
        position: relative;

        h5{
          font-size: 1.2rem;
          line-height: 4.3rem;
          font-family: Helvetica,Georgia,"Times New Roman",Arial,sans-serif;
          font-weight: 700;
        }

        .searchContainer{
          position: absolute;
          background: #9d8da7;
          width: 265px;
          left: -65px;
          top: 53px;
          padding: 20px;
          z-index: 100;
          border-radius: 3px;
          background: linear-gradient(to right,#535353,#6740e8);
          padding-bottom: 30px;
          box-shadow: black 1px;

          &:focus{
            border: inherit;
          }

          .Select-control{
            border-radius: 0;
            height: 42px;
            border: 1px solid transparent !important;
            border-top: 1px solid #ccc !important;
            border-right: 1px solid #ccc !important;

            .Select-placeholder{
              top: 3px;
            }
          }
        }
      }

      .arrowContainer{
        margin-bottom: 0;
        height: 52px;
      }
    }

    &.no_hover:hover{
      background: transparent;
    }

    .collapsible{
      min-width: 200px;
      vertical-align: middle;
      h5{
        color: $Salt;
        font-weight: bold;
      }
    }
  }

  .site-nav__navitem:hover, .site-nav__navitem--active {
    background: #FFFFFF;

    .site-nav__link {
      color: $DarkPinkAction;
    }

    &.notification
    {
      .site-nav__link{
        i.site-nav__icon, .fa-star.i.site-nav__icon{
          color: currentColor;
        }
      }
      background: transparent;
    }
  }

  .site-nav__navitem:after {
    display: block;
    content: attr(title);
    height: 1px;
    font-family: Helvetica,Georgia,"Times New Roman",Arial,sans-serif;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }

  .site-nav__dd {
    position: relative;

    span{
      font-size: 14px;
      float: left;
    }
  }

  .site-nav__navitem--right {
    float: right;
  }

  .site-nav__branding__button {
    width: 3rem;
    height: 100%;
    box-sizing: border-box;
    background: url("/static/images/rr_logo_dashboard.png") no-repeat center;
    background-size: 100%;
    display: inline-block;
    color: #FFF;
  }

  .site-nav__navitem--active .site-nav__link {
    color: #222B3C;
    font-family: Helvetica,Georgia,"Times New Roman",Arial,sans-serif;
    font-weight: bold;
  }

  .site-nav__link {
    display: block;
    color: $Salt;
    vertical-align: middle;
    padding: 1px 1.7rem;
    text-transform: capitalize;
    font-size: 1.34rem;
    line-height: 4rem;
    cursor: pointer;
    font-weight: bold;
    font-variant-caps: all-petite-caps;
    .sales-tooltip{
      cursor: default;
    }

    .storeName{
      max-width: 218px;
      overflow: hidden;
      height: 53px;
    }

    @media only screen and (max-width: 1500px)  {
      .storeName{
        display: none;
      }
    }
  }

  .site-nav__link:hover {
    color: $DarkPinkAction;
  }

  .site-nav__navitem--icononly{
    .site-nav__link i {
      vertical-align: middle;
      font-size: 1.2rem;
      margin-top: 0;
    }

    span.halo{
      font-size: 16px;
      border-radius: 15px;
      padding: 2px;
    }
  }

  .notification{
    .__react_component_tooltip{
      z-index: 99999999;
    }
    a{
      padding-left: 10px;
      padding-right: 10px;

      &.site-nav__link.green{
        background-color: $Leaf;
        color: white;
        i:before {
          content: "\f611";
        }
      }

      &.site-nav__link.yellow{
        background-color: yellow;
        color: black;
        i:before {
          content: "\f06a";
        }
      }

      &.site-nav__link.red{
        background-color: $Dragonfruit;
        color: white;
        i:before {
          content: "\f071";
        }

        span.halo{
          padding: 3px 0 0;
          border-radius: 2px;

        }
      }

      &.site-nav__link.teal{
        background-color: #28fff7;
        color: black;
        i:before {
          content: "\f013";
        }
      }

      &.site-nav__link.gray{
        background-color: #7A7979;
        color: white;
        i:before {
          content: "\f05e";
        }
      }
    }
  }

  .site-nav__dd__toggle {
    cursor: pointer;
    margin-right: 0;
    margin-left: 0;
  }

  .site-nav__user-avatar {
    margin: 0 0 0 .75em;
    width: 3em;
    height: 3.30769em;
    text-align: center;
    display: inline-block;

    img{
      -webkit-clip-path: circle(20px at center);
      clip-path: circle(20px at center);
    }
  }

  .site-nav__navitem .site-nav__dd__toggle--icon {
    visibility: hidden;
  }

  .site-nav__dd__toggle--icon {
    position: relative;
    left: 0.5em;
    color: $Salt;
  }

  .site-nav__dd--left .site-nav__dd__menu {
    left: auto;
    right: 0;
  }

  .site-nav__dd--close .site-nav__dd__menu {
    opacity: 0;
    height: 0;
    overflow: hidden;
    display: none;
  }

  .site-nav__dd--open{
    background: $Salt;

    .site-nav__dd__toggle{
      color: $DarkPinkAction;
    }
  }



  #avatar{
    &.site-nav__dd--open, &.site-nav__navitem--active {
      height: 54px;
    }
  }

  .site-nav__dd__menu {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 4.2rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background: $Salt;
    box-shadow: 0 3px 6px #B7C0CC, 0 3px 6px #B7C0CC;
    -webkit-transition: opacity, 250ms, ease-in;
    -moz-transition: opacity, 250ms, ease-in;
    -ms-transition: opacity, 250ms, ease-in;
    -o-transition: opacity, 250ms, ease-in;
    transition: opacity, 250ms, ease-in;
    height: auto;
    min-width: 306px;
    overflow: hidden;
    padding-bottom: 1em;
    padding-top: 1em;
    z-index: 1000;

    a {
      color: #3A475B;
      font-size: 1.0rem;
      display: block;
      padding: .60rem 1.93rem;
    }

    .site-nav__dd__name {
      text-transform: capitalize;
      line-height: 2.0rem;

      span{
        margin-top: 0.5rem;
        margin-right: 0.3rem;
      }
    }

    .site-nav__dd__description {
      color: gray;
      font-size: 0.85em;
    }

    .site-nav__dd__separator {
      margin: 1.0em 1.5em;
      border-top: 0.1em solid #E9ECF2;
    }
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }

    2% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
    }

    4% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    6% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    8% {
      -webkit-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }

    10% {
      -webkit-transform: rotate(22deg);
      transform: rotate(22deg);
    }

    12% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    14% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    16% {
      -webkit-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }

    18% {
      -webkit-transform: rotate(12deg);
      transform: rotate(12deg);
    }

    20% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }

    2% {
      -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg);
    }

    4% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    6% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    8% {
      -webkit-transform: rotate(-22deg);
      -ms-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }

    10% {
      -webkit-transform: rotate(22deg);
      -ms-transform: rotate(22deg);
      transform: rotate(22deg);
    }

    12% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    14% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    16% {
      -webkit-transform: rotate(-12deg);
      -ms-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }

    18% {
      -webkit-transform: rotate(12deg);
      -ms-transform: rotate(12deg);
      transform: rotate(12deg);
    }

    20% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .faa-ring.animated,
  .faa-ring.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-ring {
    -webkit-animation: ring 2s ease infinite;
    animation: ring 2s ease infinite;
    transform-origin-x: 50%;
    transform-origin-y: 0px;
    transform-origin-z: initial;
  }


}