@import '../colors';

.simpleTable{
  border: 1px solid #ddd;

  thead{
    background: $Charcoal;
    font-size: 14px;
      th{
        color: $Salt;
        font-size: 14px;
        font-weight: 400;
        padding-top: 15px;
        padding-bottom: 15px;
      }

    &.bold{
      th{
        font-weight: 700;
        text-align: center;
      }
    }
  }

  table{
    border: none;
    border-collapse: collapse;
    margin-bottom: 0;
  }

  .tableContentContainer{
    overflow-y: auto;
  }

  .sortableListItem{
    .fa-bars{
      font-size: 16px;
      cursor:grab;
      cursor: -webkit-grab;
    }

    p{
      padding-bottom: 0;
    }
  }

  .actionButton {
    font-weight: bold;
    padding:2px 5px;
    color: $RRMain;
    cursor: pointer;

    &.red {
      color: $HalfDragonfruit;
    }
    &.blue {
      color: $Water;
    }
    &.green {
      color: $Leaf;
    }
    &.grey {
      color: $Slate;
    }
    &.disabled {
      cursor: none;
      opacity: .3;
    }
    &:last-child {
      padding-right: 10px;
    }
  }
}