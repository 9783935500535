.ratings-distribution{
  .ratings-bar{
    align-items: center;
    padding: 10px;
    font-family: 'Roboto',sans-serif;
    width: 400px;
  }
  .progress{
    margin-bottom: 0;
    height: 1em;
  }
  .progress, .progress-meter{
    border-radius: 3px;
  }
  .percent{
    text-align: right;
  }
  .progress-meter-not-zero{
    .progress-meter {
      min-width: 3%;
    }
  }
  .progress-meter{
    background: $RRPurple;
  }
}