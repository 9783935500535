/*===========================================
=            Config and settings            =
===========================================*/

$basefont: 10 !default;
// Base font size HTML element is set to
$bodyfont: 13 !default;
// Font size for regular body text

$displayfont: 36 !default;
$h1font: 18 !default;
$h2font: 18 !default;
$h3font: 16 !default;
$h4font: 14 !default;
$h5font: 10 !default;
$labelfont: 13 !default;

$base-lineheight: 1.618;

$inputHeight: 3.5rem;
$xLargeInputHeight: 5.0rem;

$curve: cubic-bezier(.23,1,.32,1);
$duration: 400ms;

@function rem($target, $context: $basefont) {
  @return ($target / $context) * 0.85rem;
}

@function em($target, $context: $basefont) {
  @return rem($target, $context: $basefont);
}

$use-markup-fix: false;

$sidebar-width: rem(260);

/**
*
* FONTS FOR CX SUITE UI
*
**/

$f-app-font-family: Jost, 'ProximaNova-Regular', Helvetica, Arial, sans-serif;
$f-app-login-font-family: Jost, 'ProximaNova-Regular', sans-serif, Arial;

$f-app-font-light: Jost, 'ProximaNova-Light';
$f-app-font-regular: Jost, 'ProximaNova-Regular';
$f-app-font-semibold: Jost, 'ProximaNova-Semibold';
$f-app-font-bold: Jost, 'ProximaNova-Bold';

/**
*
* PADDING AND MARGINS
*
**/

$form-element-padding-unit: 10 !default;
$border-radius: rem(3) !default;
$grid-padding: 2rem;
$gutter: $grid-padding;

/**
*
* SIZES
*
**/

$flex-panel-resizer-width: rem(7);

/**
*
* ELEMENT COLORS
*
**/

$c-app-blue-20: lighten($c-app-blue, 50%);
$c-app-blue-40: lighten($c-app-blue, 10%);
$c-app-blue-90: darken($c-app-blue, 20%);

$c-app-dark-red: darken($c-app-red, 10%);

// MAIN BACKGROUND

$c-main-bg: $c-app-grey-20;

// NAV
$c-site-nav-bg: $Coal;
$c-site-nav-hover: $Slate;
$c-site-nav-hover-text: $Salt;
$c-site-nav-active: $Salt;
$c-site-nav-active-text: $Obsidian;
$c-nav-divider: darken($c-app-grey-50, 10%);
$c-site-nav-separator: $Quartz;
$c-site-nav-submenu-bg: $c-app-white;
$c-site-nav-submenu-shadow: $c-app-grey-shadow-light;
$c-site-nav-submenu-text: $Coal;
$c-site-nav-submenu-hover-text: $Salt;
$c-site-nav-icononly: $Salt;
$c-site-nav-inputbox: $Slate;

// TABS
$c-tabs-border-bottom: $c-app-purple-50;
$c-tabs-color-disabled: $c-app-grey-45;

// TEXT / LINKS
$c-body-text: $Salt;
$c-text: $c-app-black-80;
$c-text-invert: $c-app-white;
$c-link: $c-app-blue-90;

// BREADCRUMBS
$c-breadcrumb-separator: $c-app-black-80;
$c-breadcrumb-link: $c-app-purple-50;
$c-breadcrumb-link-active: $c-app-black-90;

// MESSAGES
$c-app-message-error: $c-app-red;
$c-app-message-success: $c-app-green;
$c-app-message-bg: $c-app-white-90;

// DROPDOWN MENUS
$c-dropdown-menu-bg: $c-app-white;
$c-dropdown-menu-border: $c-app-grey-50;
$c-dropdown-caret: $c-app-grey-90;
$c-dropdown-caret-invert: $c-app-white;

// FLEX PANEL
$c-flex-panel-bg: $Coal;
$c-flex-panel-head-bg: $c-app-black-85;
$c-flex-panel-head-text: $c-app-white;
$c-flex-panel-chevron: $c-app-blue-50;
$c-flex-panel-chevron-hover: $c-app-orange;
$c-flex-panel-tab: lighten($c-app-blue-40, 9%);
$c-flex-panel-tab-active: $c-app-grey-50;
$c-flex-tab-border: $c-app-grey-90;
$c-flex-panel-header-border: $c-app-grey-65;
$c-flex-panel-resizer: $c-app-grey-65;
$c-flex-panel-divider: $c-app-black;

// SIDEBAR MODULE
$c-sidebar-module-border: $c-app-black;
$c-sidebar-module-bg: $c-app-black-90;
$c-sidebar-module-bg-hover: $c-app-black-70;
$c-sidebar-module-label: $c-app-black-60;

// FORMS
$c-form-element-disabled: $c-app-grey-50;
$c-form-input-border: $c-app-grey-40;
$c-form-input-placeholder: $c-app-blue;

// FORM CARD
$c-form-card-bg: $c-app-white;
$c-form-card-header-light: $c-app-white;
$c-form-card-header-dark: $c-app-grey-90;
$c-form-card-border: $c-app-grey-35;
$c-form-card-drag-bg: $c-app-grey-35;
$c-form-card-border-dark: $c-app-grey-50;
$c-form-card-border-shadow: $c-app-purple-50;
$c-form-card-caret: $c-app-grey-50;
$c-form-card-caret-hover: $c-app-white;
$c-form-card-ellipsis: $c-app-grey-50;
$c-form-card-ellipsis-hover: $c-app-white;
$c-form-card-add-topic: $c-app-grey-50;

// PANEL
$c-panel-head: $c-app-grey-10;
$c-panel-head-border: $c-app-grey-20;
$c-panel-bg: $c-app-grey-20;

// TABLE ROW
$c-table-row-border: $c-app-grey-20;

// RULES
$c-card-rule: $c-app-grey-20;

// BUTTONS
$c-button-grad-top: $c-app-grey-20;
$c-button-grad-bottom: $c-app-grey-50;
$c-button-border: $c-app-grey-50;
$c-button-text: $c-app-grey-90;

// PRIMARY BUTTON
$c-app-button-primary-bg: $c-app-blue-50;
$c-app-button-primary-bg-hover: $c-app-blue-80;
$c-app-button-primary-bg-active: $c-app-blue-80;
$c-app-button-primary-color: $c-app-white;
$c-app-button-primary-color-hover: $c-app-white;
$c-app-button-primary-border: $c-app-blue-80;
$c-app-button-primary-border-hover: $c-app-blue-80;

// SECONDARY BUTTON
$c-app-button-secondary-bg: $c-app-black-60;
$c-app-button-secondary-bg-hover: $c-app-black-80;
$c-app-button-secondary-bg-active: $c-app-black-80;
$c-app-button-secondary-color: $c-app-white;
$c-app-button-secondary-color-hover: $c-app-white;
$c-app-button-secondary-border: $c-app-black-60;
$c-app-button-secondary-border-hover: $c-app-black-60;

// TERTIARY BUTTON
$c-app-button-tertiary-bg: transparent;
$c-app-button-tertiary-bg-hover: $c-app-grey-20;
$c-app-button-tertiary-bg-active: $c-app-grey-45;
$c-app-button-tertiary-color: $c-app-black-80;
$c-app-button-tertiary-color-hover: $c-app-black-80;
$c-app-button-tertiary-border: $c-app-black-60;
$c-app-button-tertiary-border-hover: $c-app-black-60;

// PICKER BUTTON
$c-app-button-picker-bg: $c-app-white;
$c-app-button-picker-bg-hover: $c-app-white;
$c-app-button-picker-color: $c-app-grey-45;
$c-app-button-picker-color-hover: $c-app-grey-85;
$c-app-button-picker-border: $c-app-grey-45;
$c-app-button-picker-border-hover: $c-app-grey-85;

// DISABLED BUTTON
$c-app-button-disabled-bg: $c-app-grey-20;
$c-app-button-disabled-color: $c-app-grey-45;
$c-app-button-disabled-border: $c-app-grey-45;

// ADD BUTTON
$c-app-button-add-bg: $c-app-white;
$c-app-button-add-bg-hover: $c-app-blue-80;
$c-app-button-add-color: $c-app-blue-80;
$c-app-button-add-color-hover: $c-app-white;
$c-app-button-add-border: $c-app-blue-80;
$c-app-button-add-border-hover: $c-app-blue-80;

// RADIO BUTTON
$c-app-radio-primary-bg: $c-app-grey-35;
$c-app-radio-primary-outline: $c-app-grey-40;
$c-app-radio-fill-bg: $c-app-blue-50;
$c-app-radio-fill-outline: $c-app-blue-80;

// CARET
$c-app-dropdown-caret: $c-app-white;

// STAR RATING
$c-app-rating-star: $c-app-yellow;
$c-app-rating-star-disabled: $c-app-grey-20;

// OPEN ENDS RATINGS
$c-app-rating-openend-neutral: #999;
