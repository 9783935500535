p.speech {
  width: 67%;
  position: relative;
  min-width: 116px;
  max-width: 150px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background-color: #EEE;
  color: #3A475B;
  //border: 1px solid #666;
  //box-shadow: 2px 2px 4px #888;
}

p.speech:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 100%;
  top: 20px;
  border: 10px solid;
  border-color: transparent transparent transparent #EEE;
}

.speech {
  position: relative;
  min-width: 116px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background-color: rgba(255,255,255,0.8);
  color: #3A475B;
  //border: 1px solid #666;
  //box-shadow: 2px 2px 4px #888;


  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 100%;
    top: 20px;
    border: 10px solid;
    border-color: transparent transparent transparent rgba(255,255,255,0.8);
  }
}

.left{
  .speech{
    margin-left: 15px;
  }


  .speech:before{
    right: 100%;
    left: auto;
    border-color: transparent rgba(255,255,255,.8) transparent transparent;
  }
}
