.datalist {
  width: 100%;
  background: $Salt;
  border: rem(1) solid $Quartz;

  &__row {
    height: rem(70);
    border-top: rem(1) solid $Quartz;
    &:first-of-type {
      border-top: none;
    }

    &--empty.text {
      line-height: rem(70);
      background: $HalfQuartz;
      padding: 0 $gutter;
    }
  }

  &__cell {
    vertical-align: middle;
  }

  &__name {
    width: 33%;
    &--full {
      width: 100%;
    }
  }

  &__tools {
    white-space: nowrap;
    width: 1%;
    text-align: center;
  }

  &__tool {
    display: inline-block;
    cursor: pointer;
  }

  &__icon {
    color: $Ash;
    &:hover {
      color: $Slate;
    }
  }

  &__leftIcon{
    width: 1%;
    padding-left: 3rem;
  }
  &__dragIcon{
    width: 0.1%;
    border-right: rem(1) solid $Quartz;
  }

  &__tooltip {
    color: $Ash;
    &:hover {
      color: $Slate;
    }
  }
  .drag-source {
    border: rem(1) solid $Quartz;
    cursor: pointer;
  }
}
