.image {
  &--small {
    max-width: rem(60);
  }
  &--medium {
    max-width: rem(100);
  }
  &--large {
    max-width: rem(200);
  }
  &--xlarge {
    max-width: rem(300);
  }
  &--small-height {
    max-height: rem(60);
  }
  &--medium-height {
    max-height: rem(100);
  }
  &--large-height {
    max-height: rem(200);
  }
  &--xlarge-height {
    max-height: rem(300);
  }
}
