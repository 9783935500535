@import "../../colors";

.underline{
  text-decoration: underline;
}

.right{
  text-align: right;
}
.left{
  text-align: left;
}

.no-rating{
  color: green;
}

.social{
  a, .SocialMediaShareButton {
    cursor: pointer;
  }
  .SocialMediaShareButton{
    &:hover{
      opacity: .75;
    }
    padding: 5px;
    -webkit-transition: opacity .15s linear;
    -moz-transition: opacity .15s linear;
    transition: opacity .15s linear;
  }
  -webkit-transition: background-color .15s linear;
  -moz-transition: background-color .15s linear;
  transition: background-color .15s linear;
  .copy-icon{
    background-color: rgb(127, 127, 127);
    color: #ffffff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    &:hover{
      opacity: .75;
    }
  }
  #facebook {
    color: #3b5998;
    &:hover {
      color: #183b55;
    }
  }
  #twitter {
    color: #1dcaff;
    &:hover {
      color: #0084b4;
    }
  }
  #googleplus {
    color: #d34836;
    &:hover {
      color: #ba4030;
    }
  }
  .stat-value{
    text-align: center;
  }
}

.slick-slide{
  .card-user-profile{
    padding: 0 20px 20px 0;
  }
}

.fa.fa-star, .fa.fa-star-half-o, .fa-star-half, .fa-star-half-alt{
  &.good, &.neutral, &.bad {
    color: $RRPurple;
  }
  color: lightgrey;
}

.index-username{
  padding-left: 5px;
}

.review{
  .blur {
    color: transparent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    opacity: 0.75;
    &.benchmarks{
      .blur-button {
        margin-top: -56px;
        position: absolute;
      }
    }
    &.comments{
      .blur-button {
        margin-top: -58px;
        position: absolute;
      }
    }
  }
  .blur-button {
    font-size: .8em;
    color: $RRPurple;
    padding: .8em;
    cursor: pointer;
    z-index: 9000;
  }
  @media screen and (max-width: 1140px) and (min-width: 1024px) {
    .store-link {
      font-size: .8em;
    }
  }
  .blur-title{
    display: inline-block;
    padding-right: 10px;
    font-size: 1.1em;
  }
  .blur-grid{
    align-items: center;
  }
}

.seller-info{
  .claim {
    color: #A75DA3;
    font-weight: bold;
    text-decoration: underline;
  }
}

.small-font{
  font-size: .75em;
}

.centered{
  text-align: center;
}

.social_share_box_wrapper .item{
  padding: 4px;
}

.social_share{
  padding: .1em;
}

img.thumbnail {
  margin: 5px;
  line-height: 20px;
  border: none;
  box-shadow: none;
}

.card-user-profile {
  position: relative;
  z-index: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 0;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a;
}

.mobile-card-wrapper{
  width: 50%;
  @media only screen and (max-width: 1023px) and (min-width: 800px) {
    width: 30%;
  }
  .card-user-profile{
    padding-left: 20px;
  }
}

.card-category-title{
  font-weight: 300;
  font-size:18px;
  color:black;
}

.card-user-profile > :last-child {
  margin-bottom: 0;
}

.card-user-profile:hover .card-user-profile-img, .slick-active .card-user-profile-img {
  opacity: 1;
}

.card-user-profile-content {
  position: relative;
}

.card-user-profile-img {
  max-width: 100%;
  margin: 10px;
  opacity: .85;
  transition: all .25s ease;
  border: 3px solid #E2E2E2;
  border-radius: 10px;
}

.card-user-profile-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 120px;
  left: 1rem;
  z-index: 2;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
}

.card-user-profile-avatar svg {
  color: $RRPurple;
}

.card-user-profile-avatar-icon {
  border-radius: 50%;
  background-color: $Salt;
  overflow: hidden;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .modules_library-site-icon{
    color: $RRPurple;
  }
}

.card-user-profile-avatar:hover {
  background-color: $Salt;
  box-shadow: 0 0 7px rgba(10, 10, 10, 0.4);
  transition: background-color 0.5s ease;
  .card-user-profile-avatar-icon {
    .modules_library-site-icon {
      color: $RRPurple;
    }
  }
}

.card-user-profile-name {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 1.2rem;
  color: $Black;
  padding-bottom: 40px;
}

.card-user-profile-button {
  margin-bottom: 0;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
}

.card-user-profile-button + .card-user-profile-button {
  margin-left: 1rem;
}

.card-user-profile-info {
  font-size: 0.875rem;
  letter-spacing: 1px;
  opacity: 0.8;
}

.card-user-profile-content::before {
  position: absolute;
  content: '';
  top: -3.4375rem;
  left: -1.875rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3.4375rem 0 0 31.25rem;
  z-index: 0;
  border-color: transparent transparent transparent #fefefe;
}

.card-category-profile-content {
  position: relative;
}

.card-category-profile-content::before{
  position: absolute;
  content: '';
  top: -5.4375rem;
  left: -0.875rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5.4375rem 0 0 31.25rem;
  z-index: 0;
  border-color: transparent transparent transparent #fefefe;
}

.review {
  img.thumbnail {
    &.small {
      max-width: 100px;
      max-height: 100px;
    }

    &.smallest {
      max-width: 90px;
      max-height: 90px;
    }
  }
  .user-name{
    word-wrap: break-word;
  }
  .user-avatar{
    img{
      border-radius: 50%;
    }
  }
  .preloader + img.thumbnail{
    display: none;
  }
  .preloader {
    display: inline-block;
    margin: 0;
    height: 20px;
    padding-bottom: 30px;
    padding-top: 30px;
    .spacer {
      height: 0;
    }
  }
  .accordion-title {
    padding: 1.25rem 1rem;
    border: 0;
    font-size: 0.75rem;
    line-height: 1;
    border-bottom: 1px solid #e6e6e6;
  }
  @media only screen and (min-width: 1024px) {
    .accordion-title {
      width: 50%;
    }
  }
  .accordion-content {
    padding: 1rem;
    border: none;
    &:last-child {
      border-bottom: 1px solid #e6e6e6;
    }
  }
  &.site-review{
    .siteStars {
      justify-content: center;
    }
  }
  &.store-review{
    p{
      margin-bottom: 0;
    }
  }
}
.fade-transition-enter {
  opacity: 0.01;
}
.fade-transition-enter.fade-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-transition-exit {
  opacity: 1;
  position: absolute;
}
.fade-transition-exit.fade-transition-exit-active {
  position: absolute;
  opacity: 0.01;
}
.slide-transition-enter {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}
.slide-transition-enter.slide-transition-enter-active {
  max-height : 400px;
  transition : all .5s ease-in;
  opacity: 1;
}
.slide-transition-exit {
  max-height : 400px;
  opacity: 1;
}
.slide-transition-exit.slide-transition-exit-active {
  max-height : 0;
  opacity: 0;
  transition : all .3s ease-out;
}

i {
  font-style: normal;
}

.siteStars{
  display: flex;
  align-items: center;
}

.copyToClipBoard{
  padding: 5px 10px 10px 5px;
  input{
    width: 80%;
    @media only screen and (max-width: 600px){
      width: 75%;
    }
    box-shadow: 0 0 0 .7px rgba(0,0,0,.04);
  }
  button{
    cursor: pointer;
  }
}

input[readonly]{
  background: white;
  cursor: text;
}

.clip-circle {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

#merchant-ads ul li {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  width: 150px;
}

.tabs-content {
  > {
    .content-wrapper {
      display: none;
      opacity: 0;
    }
    .content.active .content-wrapper {
      -webkit-animation: fadein 3s;
      -moz-animation: fadein 3s;
      -o-animation: fadein 3s;
      animation: fadein 3s;
      opacity: 1;
    }
  }
  border: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari and Chrome */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.review-content{
  .reactTagList.selectable{
    li{
      .tag-label-icon{
        padding-left: 10px;
      }
    }
  }
  .star-filter-ratings{
    display: inline-block;
    .fancyStars{
      display: inline-block;
      padding-left: 5px;
    }
  }
  .dropdown{
    width: 83px;
  }
}

.hero-review-list{
  overflow-x: hidden;
  flex-flow: nowrap;
  .hero-review{
    background: linear-gradient(to right, #ba57ff, #6740e8);
    box-shadow: 0 2px 0 rgba(0,0,0,0.35);
    align-items: center;
    padding: 20px;
    margin: 20px;
    color: #fff;
    transition: 0.5s ease-out;
    &:hover{
      box-shadow: none;
      transition: 0.5s ease-out;
    }
    .fancyStars {
      .fa.fa-star.unselected {
        color: rgb(0, 0, 0)!important;
      }
      text-align: left;
    }
    .hero-review-title{
      font-style: italic;
    }
    a{
      color: #fff;
    }
    border-radius: 10px;
    &.empty{
      background: linear-gradient(to right, $Heliotrope, $RoyalBlue);
      opacity: .2;
    }
    .hero-review-avatar{
      border-radius: 30px;
      width: 80%;
    }
  }
  &.shift-right{
    .first{
      margin-left: 100px;
    }
    .last{
      margin-right: -100px;
    }
  }
  &.shift-left{
    .first{
      margin-left: -100px;
    }
    .last{
      margin-right: 100px;
    }
  }
}

.stars {
  vertical-align: middle;
}

.verified {
  color: #476B29;
  a{
    color: #476B29;
    &:hover{
      text-decoration: underline;
    }
  }
}

.right-bar {
  .site-cell-gradient{
    h3, h4, h5, h6, a, p{
      color: white;
    }
    background: linear-gradient(to right, #8e60fc, #6740e8);
    border-radius: 5px;
    color: white;
  }
  .right-bar-info {
    h4 {
      font-size: 1em;
      font-weight: 700;
      font-family: 'Raleway', sans-serif;
    }
    h5, h6 {
      font-size: 90%;
    }
    .modules_library-site-button{
      width: 100%;
    }
  }
  .social{
    @media only screen and (max-width: 1023px) {
      justify-content: center;
    }
  }
  .right-bar-admin{
    .right-bar-admin-item{
      padding: 20px;
      border-top: 1px solid lightgrey;
      font-weight: bold;
      align-items: center;
      justify-content: center;
      display: flex;
      &:hover{
        color: $RRPurple;
      }
    }
  }
  .morph-user{
    padding: 5px 20px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

#user-profile-manage{
  background: #ECECEC;
  padding: 20px 0;
}

.rr-gradient{
  background: linear-gradient(to right, $Heliotrope, $RoyalBlue);
  color: white;
  &.button {
      color: white;
      border: none;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .61);
      &:hover {
        background: linear-gradient(to right, $RoyalBlue, $Heliotrope);
      }
      &:active {
        box-shadow: none;
      }
  }
}

.rr-purple {
  color: $RRPurple;
  &.store-link:hover {
    color: rebeccapurple;
  }
  &.store-link:active{
    color: rebeccapurple;
  }
  &.store-title{
    font-size: 1.2em;
  }
  &.button{
    background: $RRPurple;
    color: white;
  }
  &.button:hover{
    color: white;
    background: rebeccapurple;
  }
}
.rr-pink {
  color: $RRHotPink;
  &.store-link:hover {
    color: #91014B;
  }
  &.store-link:active{
    color: #91014B;
  }
  &.store-title{
    font-size: 1.2em;
  }
  &.button{
    background: $RRHotPink;
    color: white;
  }
  &.button:hover{
    color: white;
    background: #91014B;
  }
}

.participating-list-table {
  .participating-list-row {
    .participating-list-img {
      padding-right: 5px;
    }
  }
}

.rr-purple-background {
  background: $RRPurple;
}

.store-link {
  font-weight: 700;
}

.rr-header {
  color: #8c42ff;
  font-weight: 700;
  text-shadow: 0 1px 0 #5500a1;
}

.callout.banner{
  background: linear-gradient(to right, $Heliotrope, $RoyalBlue);
  color: #fff;
  border: none;
  h4, h6, a{
    color: #fff;
  }
  a{
    text-decoration: underline;
  }
  p{
    font-size: 1em;
  }
}

.ratingLabel {
  font-weight: bold;
  font-size: 13px;
}

.centered{
  text-align: center;
}

#userImg{
  padding-top: .5rem;
}

section.discover_content{
  .grid-container{
    margin-bottom: 40px;
  }
  #home-content{
    @media only screen and (min-width: 1024px) {
      margin-top: 66px;
    }
    @media only screen and (max-width: 1023px) {
      .index-category-blurb {
        padding: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .date{
    color: #666;
    font-size: 80%;
    font-weight: 300;
  }
}
@media screen and (max-width: 63.9375em) {
  .centered-mobile {
    text-align: center;
  }
}
@media screen and (min-width: 63.9376em) {
  .centered-large {
    text-align: center;
  }
}
@media only screen and (max-width: 63.9375em) and (min-width: 40em) {
  .centered-medium{
    text-align: center;
  }
  .siteStars{
    justify-content: center;
  }
}

@media only screen and (max-width: 39.9375em) {
  #store-label{
    .siteStars{
      justify-content: center;
    }
  }
  .siteStars{
    &.write-a-review{
      justify-content: center;
    }
  }
}

.bestStores {
  padding: 15px;
  .store{
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }
}

.tabs-title>a, .accordion-title {
  font-size: 1em;
  color: #9e139e;
  font-family: 'Raleway', serif;
}

.tabs-title>a:focus, .tabs-title>a[aria-selected=true]{
  background: none;
  color: #639;
  border-bottom: 3px #639 solid;
}

.accordion-title:focus{
  background: none;
  color: #639;
}

.participant-link{
  color: lightgrey;
  &:hover{
    text-decoration: underline;
  }
}
.top-header {
  font-size: 1.4em;
  &.top-header-fill {
    color: white;
  }
}

.tabs {
  border: none;
  border-bottom: 1px solid #e6e6e6;
}

.red{
  color: red;
}

.fancyStars span, .starRating{
  margin: 2px;
}
.modules_library-site-store-card{
  .fancyStars span, .starRating{
    margin: 0;
  }
}

.discover_content {
  .review{
    white-space: pre-line;
  }
  .featured-review{
    .review-body{
      display: flex;
    }
  }
}

.review {
  font-family: 'Varela Round', sans-serif;
  font-weight: 400;
  color: #4e4a4a;
  line-height: 1.6;
  margin-bottom: 1rem;
  .review-title {
    font-size: 1.4em;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
  }
  .review-body {
    font-size: 1em;
    font-family: 'Varela Round', sans-serif;
  }
  .user-column {
    font-size: 12px;
  }
  .verified {
    font-size: 1em;
  }

  .review-title, .review-body{
    white-space: normal;
    word-break: break-word;
    overflow: visible;
  }
}

.accordion-title:hover {
  background: none;
  color: #639;
}

.accordion-title:hover {
  background: none;
  color: #639;
}

.tooltip.top::before {
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent;
  top: 100%;
  bottom: auto;
}

#StartReviewStars{
  display: inline-block;
}

hr {
  border-bottom: 1px solid rgb(246, 247, 249);
}

.navigateLink:hover {
  text-decoration: underline;
}

#contactContainer{
  background-image: url(/static/images/merchantSolutions/signup_banner_07.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 59.999rem) {
    .contact-us-text{
      font-size: 200%;
    }
  }
  @media screen and (min-width: 60rem) {
    min-height: 800px;
    .align-self-middle{
      margin-top: 400px;
    }
  }

  .contact-header {
    color: white;
  }

  label{
    color: white;
  }

  .recaptcha-container{
    text-align: center;
  }
}

.line-spacer{
  padding-left: 10px;
}

.hero-search-filter {
  /* padding: 7rem; */
  @media screen and (max-width: 1023px) {
    padding: 2rem;
    background-size: contain;
    min-height: 1008px;
    @media screen and (max-width: 908px) {
      min-height: 500px;
    }
    @media screen and (max-width: 762px) {
      min-height: 450px;
    }
    @media screen and (max-width: 600px) {
      min-height: 350px;
    }
    @media screen and (max-width: 464px) {
      min-height: 250px;
    }
  }
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation: fadein 3s;
  -moz-animation: fadein 3s;
  -o-animation: fadein 3s;
  animation: fadein 3s;
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Firefox */
  @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  /* Safari and Chrome */
  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  .hero-search-filter-content {
    padding-left: 35em;
    padding-right: 35em;
    @media (max-width: 1715px) and (min-width: 1301px) {
      padding-left: 25em;
      padding-right: 25em;
    }
    @media (max-width: 1300px) and (min-width: 1024px) {
      padding-left: 15em;
      padding-right: 15em;
    }
    .blurb {
      font-family: Raleway, sans-serif;
      margin-top: 120px;
      @media (min-width: 1024px) and (max-width: 1390px) {
        margin-top: 10px;
      }
    }
    .discover-text{
      font-size: 2.4em;
      padding-bottom: 10px;
    }
    @media screen and (max-width: 1023px) {
      padding-left: 0;
      padding-right: 0;
      .blurb {
        display: none;
      }
      @media screen and (min-width: 400px) {
        .powered-text {
          width: 75%;
        }
      }
      .discover-text{
        background-color: purple;
        color: white;
        padding: 5px;
      }
    }
    h3 {
      font-weight: 300;
      text-rendering: optimizeLegibility;
    }
  }
}
.hero-search-filter-menu:hover a{
  opacity: .5;
}
.hero-search-filter-menu{
  a{
    color: #763d8e;
    font-weight: lighter;
    &:hover {
      opacity: 1;
    }
  }
  li {
    display: inline-block;
  }
}

#contactFooter{
  a{
    color: #444;
    &:hover{
      color: $RRMain;
    }
  }
}

.rr-icon-wrapper{
  border-radius: 50%;
  background: linear-gradient(to right, $Heliotrope, $RoyalBlue);
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.61);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  .rr-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    width: 65px;
    height: 65px;
  }
}
.review-button {
  transition: background-color 0.5s ease;
  background: #f6f7f9;
  padding: 8px;
  @media only screen and (max-width: 40em) {
    font-size: .9em;
    margin-right: 3px;
  }
  @media only screen and (max-width: 28.75em) {
    font-size: .8em;
    padding: 7px;
  }
  border: 1px solid #e4eaf3;
  color: #000;
  border-radius: 20px;
  margin-right: 5px;
  &.admin{
    background: $RRPurple;
    color: white;
    &:hover, &:focus {
      background: rebeccapurple;
      color: white;
    }
  }
  &.large{
    padding: 16px;
  }
  &.filter{
    border-radius: 4px;
  }
  &:hover, &:focus {
    cursor: pointer;
    transition: background-color 0.5s ease;
    background: #CCC;
    color: #000;
  }
  &[disabled]{
    color: #ababab;
    &:hover{
      color: grey;
      cursor: not-allowed;
    }
  }
}
.merchant-buttons a{
  background: #86898e;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
  margin: 5px 5px 5px 0;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  list-style-type: none;
  &:hover{
    background: #545f66;
  }
}
/*preload hover images */
.review-title { background: url('/static/images/review_buttons/helpful_review_icon-hover.png') no-repeat -9999px -9999px; }
.review-body { background: url('/static/images/review_buttons/cool_review_icon-hover.png') no-repeat -9999px -9999px; }
.review-buttons{
  .grid-x{
    align-items: center;
  }
  .review-button-icons {
    .review-button-helpful-icon {
      width: 30px;
      height: 30px;
      display: inline-block;
      background-size: contain;
      background-image: url('/static/images/review_buttons/helpful_review_icon.png');
      transition: background 0.5s ease;
      &:hover, &:focus, &:active, &:visited, &.clicked {
        background-image: url('/static/images/review_buttons/helpful_review_icon-hover.png');
      }
    }
    .review-button-cool-icon {
      width: 30px;
      height: 30px;
      display: inline-block;
      background-size: contain;
      background-image: url('/static/images/review_buttons/cool_review_icon.png');
      transition: background 0.5s ease;
      &:hover, &:focus, &:active, &:visited, &.clicked {
        background-image: url('/static/images/review_buttons/cool_review_icon-hover.png');
      }
      a:hover{
        color: inherit;
      }
    }
    .helpful-amount{
      padding-right: 5px;
    }
  }
  .review-button-right{
    text-align: right;
  }
  padding-bottom: 20px;
}
.align-right{
  text-align: right;
}
.compare-stores{
  .compare-header{
    padding: 0.5em;
  }
  .compare-row {
    align-items: center;
    cursor: pointer;
    padding: 0.5em;
    font-family: 'Varela Round', sans-serif;
    font-weight: bold;
    .header{
      font-family: 'Raleway', sans-serif;
    }
    .seller-name{
      font-weight: 400;
      word-break: break-word;
    }
    .rr-icon {
      -o-transition: .5s;
      -ms-transition: .5s;
      -moz-transition: .5s;
      -webkit-transition: .5s;
    }
    &:hover {
      .rr-icon {
        background: linear-gradient(to right, $Heliotrope, $RoyalBlue);
        color: white;
      }
    }
    .rr-icon-wrapper {
      @media screen and (min-width: 1200px) {
        margin: 0 14px;
      }
      @media screen and (min-width: 1128px) and (max-width: 1199px) {
        margin: 0 10px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1127px) {
        margin: 0 4px;
      }
    }

    @media screen and (max-width: 1023px) {
      .rr-icon-wrapper {
        margin: 0 10px;
        width: 60px;
        height: 60px;
        .rr-icon {
          width: 50px;
          height: 50px;
        }
      }
    }
    p {
      font-weight: 600;
      font-size: 0.9em;
    }
    img {
      width: 100%;
    }

    .grid-x {
      display: flex;
      align-items: center;
    }
  }
}
