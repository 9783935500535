$RRMain: #982369;
$RRMainDark: #8b4466;
$RRMainAdmin: #8b4466;
$RRPurple: #6157f3;
$RRHotPink: #eb018b;
$RRPurpleFaded: #E5C5DE;
$Jellyfish: #1CBDFF;
$Atlantis: #009FEA;
$Water: #007BCA;
$Whale: #005A9A;
$SeaSalt: #F3F3F3;
$Salt: #FFFFFF;
$Smoke: #FAFAFA;

/* New CX Components Colors Begin */
$LightPinkAction: #ba57ff;
$PinkAction: #6740E8;
$DarkPinkAction: #4b31a8;
$LightGray: rgb(235, 236, 237);

/* New CX Components Colors End */

$GreenInactive: #BABDC2;
$GreenSelected: #707F86;

$HalfQuartz: rgba(233,236,242, 0.35);
$Quartz: #E9ECF2;
$DarkQuartz: #929292;
$Ash: #B7C0CC;
$HalfSlate: rgba(98, 112, 133, 0.5);
$DarkSlate: #333;
$Slate: #627085;
$Coal: #3A475B;
$Charcoal: #2e3540;
$Obsidian: #222B3C;
$HardObsidian: #252c35;
$SoftBlack: #1E2228;
$Black: #07080a;

//SECONDARY COLORS
$AquaSneeze: #f7f9f9;
$HalfDragonfruit: rgba(244,61,88, 0.85);
$Dragonfruit: #F43D58;
$velvet: #DD3921;
$Tangerine: #FF8635;
$Grape: #965CC5;
$DarkPurple: #65318F;
$DarkerPurple: #44316e;
$Purple: #AB1978;
$Kiwi: #92CF26;
$Leaf: #18A340;
$DarkLeaf: #0c5220;
$Bananarama: #fdd800;
$Mustard: #BD9337;
$Amber: #FFC200;
$Emerald: #3FB8AF;
$LightEmerald: #66FCF6;
$LightWhale: #ceeaff;

$Heliotrope: #8e60fc;
$RoyalBlue: #6740e8;


//STATUS COLORS
$Published: rgb(104,225,12);
$Rejected: rgb(225,36,76);
$Pending: rgb(225,196,10);
