&--underline {
  border-bottom: rem(1) solid $Ash;
  &.gutterless {
    margin: 0.5rem 0 0 0;
  }

  &-background {
    border-top: rem(1) solid $HalfAsh;
    background: $HalfQuartz;

    .tabs {
      &__tabitem {
        padding-left: 2rem;
        padding-right: 2rem;
        margin: 0 !important;

        &--selected {
          background: $Salt;
        }
      }
    }
  }

  .tabs {

    &__tabitem {
      color: $Obsidian;
      border-bottom: rem(3) solid transparent;

      &:hover{
        color: $Coal;
        @include transition;
      }

      &--selected {
        color: $Coal;
        border-bottom: rem(3) solid $PinkAction;;
      }

    }
  }
}
