.pagination a:hover{
  position: relative;
  background: none;
  color: $RRPurple;
}
.pagination a, .pagination strong{
  display: inline-block;
  margin: .1875rem .625rem;
  padding: .1rem .4rem;
  border-radius: 0;
}
.pagination li{
  display: inline-block;
}
.pagination strong{
  color: $RRPurple;
  border-bottom: solid purple 1px;
}