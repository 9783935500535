// Browser Prefixes
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}
//
//@mixin tranform-rtxy($deg, $x, $y) {
//	@include transform(matrix ( 1,0,0);
//}
//
//// Rotate
//@mixin rotate ($deg) { 
//	@include transform(rotate(#{$deg}deg));
//}
//
//// Scale
//@mixin scale($scale) {
//	@include transform(scale($scale));
//}
//
//// Translate
//@mixin translate ($x, $y) {
//	@include transform(translate($x, $y));
//}
//
//// Skew
//@mixin skew ($x, $y) {
//	@include transform(skew(#{$x}deg, #{$y}deg));
//}
//
// Transform Origin
@mixin transform-origin ($origin) {
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}
