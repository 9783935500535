.modules_library-site-search-bar-select,
.modules_library-site-search-bar-selected {
  color: $Black;
  margin-right: 12px;
  position: relative;
  .select-selected {
    background-color: $Smoke;
    border: 1px solid $Salt;
    border-radius: 3px;
    cursor: pointer;
    padding: 12px;
    .fa-caret-square-o-down,
    .fa-caret-square-o-up {
      color: $HalfSlate;
      font-size: .8em;
      padding-left: 6px;
    }
    .sellerStarsImage {
      display: inline;
    }
  }
  .select-options {
    background-color: white;
    border: 1px solid $SeaSalt;
    border-radius: 2px;
    color: $DarkSlate;
    cursor: pointer;
    left: -1px;
    max-height: 50vh;
    min-width: 300px;
    overflow-y: scroll;
    position: absolute;
    top: 46px;
    li {
      a {
        color: $DarkSlate;
        display: block;
        padding: 4px 12px;
        &:hover {
          background-color: $Smoke;
          cursor: pointer;
        }
      }
      &.selected {
        a {
          background-color: $Smoke;
        }
      }
      ul {
        font-size: 0.8em;
        font-style: oblique;
      }
    }
  }
  &.sort-select {
    .fa-arrow-right {
      color: $HalfSlate;
      font-size: .6em;
      position: relative;
      top: -.1em;
    } 
  }
}
@media (max-width: 500px) {
  .modules_library-site-search-bar-select {
    margin: auto;
    position: relative;
    .select-selected {
      padding: 4px 12px;
    }
    .select-options {
      left: auto;
      max-height: 80vh;
      min-width: 100%;
      position: relative;
      top: auto;
      width: 100%;
    }        
  }
}
