.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap; 
  font-size: rem($bodyfont);
  height: 2rem;
  
  &-line {
    height: rem(1);
    background: $Quartz;
  }
  
  > span {
    position: relative;
    display: inline-block;
    height: 2rem;
    
    &:before, &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: rem(9999);
      height: rem(1);
      background: $Quartz;
    }
    
    &:before {
      right: 100%;
    }
    
    &:after {
      left: 100%;
    }
  }
  
  > .divider__text {
    &:before {
      margin-right: 1rem;
    }
    
    &:after {
      margin-left: 1rem;
    }
  }
}