.radio-group {

  &--bordered {
    border: .1rem solid $Ash;
    border-radius: 0.3rem;

    .radio-group {
      &__btn {
        padding: 1.25rem 1.5rem;
        border-bottom: 0.1rem solid $Ash;
      }
      &--active{
        background-color:#1AA0E7;
        color:$Salt;
      }
    }
  }

  &__btn {
    &:last-child{
      margin-bottom: -0.6rem;
    }
    padding: 0.7rem 0;
  }

  &--buttons {
    line-height: 0;
    padding: 0.5rem 0;

    & > .form__radio {
      display: inline-block;
      width: initial;
      margin-right: 0;
      margin-bottom: 0;
      padding: 0;

      &:first-child .form__radio-input + .form__label-container {
        border-width: rem(1);
        border-radius: rem(3) 0 0 rem(3);
      }

      &:last-child .form__radio-input + .form__label-container {
        border-radius: 0 rem(3) rem(3) 0 ;
      }

      .form__radio-icon i {
        display: none;
      }

      > .form__label-container {
        display: block;
        position: relative;
        padding: 0.5rem 1rem;
        color: $PinkAction;
        font-weight: bold;
        border-color: $Ash;
        border-style: solid;
        border-width: rem(1) rem(1) rem(1) 0;
        margin-right: 0;
        margin-left: 0;

        &::before {
          position: absolute;
          z-index: -1;
          top: 0;
          left: rem(-1);
          display: block;
          width: rem(1);
          height: 100%;
          background-color: $Ash;
          content: '';
        }
      }

      &--disabled {
        opacity: 1;
        > .form__label-container {
          color: $Ash;
          border-color: $Quartz;

          &::before {
            background-color: $Quartz;
          }
        }
      }

      &:hover:not(.form__radio--checked) {
        > .form__label-container {
          border-color: $PinkAction;
          transition: border-color 0.3s;

          &::before {
            background-color: $PinkAction;
            transition: background-color 0.3s;
            z-index: 1;
          }
        }
        + .form__radio > .form__label-container {
          border-left-color: $PinkAction;
          transition: border-left-color 0.3s;
        }
      }

      &--checked {

        > .form__label-container {
          color: $Salt;
          border-color: $PinkAction;
          background: $PinkAction;
          transition: background 0.3s, color 0.3s;
        }

        &.form__radio--disabled {
          > .form__label-container {
            color: $Ash;
            border-color: $Quartz;
            background: $Quartz;
          }
        }

        + .form__radio > .form__label-container {
          border-left-color: $PinkAction;
        }
      }
    }
  }
}