.soqa_form_preview{
  $font-main: 'Montserrat',"Source Sans Pro","Open Sans",sans-serif;
  $color-main: rgb(88,89,91);
  $color-input-text: rgb(30,30,30);
  $color-vivid: rgb(0,0,0);
  $color-required: rgb(156,0,0);
  $color-title: rgb(136,70,159);
  $color-highlight: rgb(234,14,234);
  $color-placeholder: rgb(170,170,170);
  $color-background: rgb(255,255,255);
  $color-background-alt: rgb(251,251,251);
  $color-background-input: rgb(230,230,230);
  $color-input-placeholder: rgb(230,230,230);
  $color-search-border: rgb(230,230,230);
  $color-button-bright-side: rgb(142, 96, 252);
  $color-button-dark-side: rgb(78, 44, 187);
  $color-answer-button-background-invalid: rgb(215,215,215);
  $color-answer-button-border-invalid: rgb(100,100,100);
  $color-answer-button-label: rgb(255,255,255);
  $color-answer-button-label-invalid: rgb(100,100,100);
  $color-answer-button-highlight: rgb(204,204,204);
  $color-answer-button-text: rgb(0,0,0);
  $color-powered-by: rgb(58,58,58);

  $box-shadow: 0 2px 6px rgb(89,89,89);

  $color-thankyou-title: rgb(88,89,91);

  $break-small: 700px;

  .clear {
    clear: both;
  }

  [contenteditable=true]:empty:before{
    content: attr(placeholder);
    display: block; /* For Firefox */
  }
    max-width: 600px;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: content-box;


  .rr.root.inputForm {

    * div{
    font-family: $font-main;
    }



    font-family: $font-main;
    background-color: white;
    padding: 2em;
    position: relative;

    .thankYou {
      text-align: center;
      color: $color-main;
      font-weight: 400;
      font-size: 1.2em;
      .title {
        font-size: 1.8em;
        font-weight: 500;
        color: $color-thankyou-title;
        margin-bottom: 10px;
      }
      p {
        font-family: $font-main !important;
      }
    }

    .soqa_header {
      text-align: center;
      .title {
        font-size: 26px;
        color: $color-title;
        line-height: 1.2em;
        margin-bottom: .7em;
        padding-top: 10px;
        font-weight: 900;
      }
      .titleExplanation {
        display: block;
        font-weight: 800;
        padding-bottom: .1em;
        font-size: 1.2em;
        color: $color-main;
        text-align: center;
        -webkit-font-smoothing: antialiased;
      }
      .imageHolder {
        position: relative;
        overflow: hidden;
        .image {
          display: inline;
          margin: 1em auto;
          width: auto;
          max-height: 8em;
          min-width: 8em;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .productName {
        font-size: 2.5em;
        font-weight: bold;
        text-align: center;
      }

      .description {
        margin: 1em;
      }

      .question {
        margin: 1em;
        font-size: 2.5em;
        color: $color-vivid;
        text-align: center;
      }
    }

    .form {
      .formRow {
        margin: 1.2em 0;
      }

      .soqa_label {
        font-size: 1.6em;
        color: $color-title;
        font-weight: 700;
        margin-bottom: .5em;
        position: relative;

        .required {
          padding-left: .3em;
          color: $color-required;
          font-weight: 700;
        }

        .validationText {
          display: block;
          font-style: italic;
          padding-left: .5em;
          font-weight: 300;
          font-size: .8em;
          color: $color-main;
        }
      }

      .input {
        border: 2px solid $color-search-border;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.04) 0 0 3.5em 0;
        padding: .5em;
        display: block;
        font-size: 1.8em;
        line-height: 1em;
        background-color: $color-background-input;
        color: $color-input-text;
        font-weight: 500;
        overflow-y: hidden;
        box-sizing: content-box;
      }
      .input:empty {
        color: $color-placeholder;
        font-style: italic;
        overflow-y: hidden;
        font-weight: 300;
      }

      .longInput {
        height: auto;
        min-height: 6em;
        max-height: 10em;
      }


      .nameInput:focus, 
      .emailInput:focus, 
      .longInput:focus {
        outline-color: $color-vivid;
      }

      .nameInput, 
      .emailInput {
        height: 2em;
        line-height: 2em;
        overflow-x: hidden;
        white-space: nowrap; 
      }

      .soqa_link {
        -webkit-touch-callout: none;
        color: $color-highlight;
        font-weight: 300;
        cursor: pointer;
        text-decoration: underline;

        .soqa_link:focus,
        .soqa_link:hover {
          border-bottom: 1px solid $color-highlight;
        }

      }
      .commands {
        text-align: center;

        .button {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          width: -webkit-fill-available;
          box-shadow: $box-shadow;
          border: 1px solid transparent;
          border-radius: 5px;
          background: linear-gradient(to right,$color-button-bright-side,$color-button-dark-side);
          color: $color-answer-button-label;
          cursor: pointer;
          display: inline-block;
          font-size: 1.3em;
          line-height: 1;
          margin-bottom: 1rem;
          padding: 1.3em;
          text-align: center;
          vertical-align: middle;
          font-weight: bolder;
          margin-top: 0.5em;
          border: none;
        }
        
        .button:focus,
        .button:hover {
          background: linear-gradient(to right,$color-button-dark-side,$color-button-bright-side);
        }
        
        .button.invalid {
          border: 1px dashed;
          border-color: $color-answer-button-border-invalid;
          background: $color-answer-button-background-invalid;
          color: $color-answer-button-label-invalid;
        }
        .button.invalid:focus,
        .button.invalid:hover {
          background: $color-answer-button-background-invalid;
          color: $color-answer-button-label-invalid;
        }
      }

      .spacer {
        height: 1em;
        font-size: 13px;
      }

      .row {
        font-size: 13px;
        color: $color-main;
        font-weight: 400;
        vertical-align: baseline;
        box-sizing: border-box;
        z-index: 100000;
      }

      .centerAlign {
        text-align: center;
      }

      .poweredBy {
        position: relative;
        top: -.3em;
        color: $color-powered-by;
        font-weight: 700;
        font-size: 13px;
      }

      .rRLogo {
        height: 1.6em;
        margin-left: .4em;
        font-size: 13px;
        display: inline-block;
      }
    }
  }

  .rr.root.readText {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    font-size: 16px;
    background: rgba(0,0,0,.5);
    color: $color-main;

    .contents {
      border: 2px solid $color-search-border;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.04) 0 0 3.5em 0;
      padding: .5em;
      line-height: 1em;
      background-color: $color-background-alt;

      top: 0;
      right: 0;
      left: 0;
      bottom: 7em;

      display: flex;
      position: fixed;
      width: 600px;
      margin-left: auto;
      margin-right: auto;
      height: auto;
      max-height: 100%;

      .inner {
        position: absolute;
        background-color: $color-background-input;
        border: 2px solid $color-search-border;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.04) 0 0 3.5em 0;
        top: 0;
        right: 0;
        left: 0;
        bottom: 8em;
        margin: 3em 1em 1em 1em;
        padding: .5em;
        overflow-y: auto;

        /* fix low specificity issues */
        div {
          margin: 1em 1em;
          h3 {
            font-size: 1.17em;
            line-height: 1em;
            font-weight: 700;
            margin: 1em 0;
            display: block;
          }
          h4 {
            font-size: 1.05em;
            line-height: 1em;
            font-weight: 700;
            margin: 1em 0;
            display: block;
          }
          p {
            display: block;
            margin: 1em 0;
          }
          ul {
            list-style-type: disc;
            list-style-position: outside;
            padding-left: 3em;
            margin: 1em 0;

            li {
              text-align: left;
              margin: 0.5em 0;
            }
          }
          strong {
            font-weight: 700;
          }
          a {

          }
        }
      }

      .footer {
        position: absolute;
        height: 4em;
        right: 0;
        left: 0;
        bottom: 0;
        margin: 1em 3em 3em 3em;
        text-align: center;
        padding: .5em;

        .button {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          width: 100%;
          box-shadow: $box-shadow;
          border: 1px solid transparent;
          border-radius: 5px;
          background: linear-gradient(to right,$color-button-bright-side,$color-button-dark-side);
          color: $color-answer-button-label;
          cursor: pointer;
          display: inline-block;
          font-size: 1.3em;
          line-height: 1;
          margin-bottom: 1rem;
          padding: 1.3em;
          text-align: center;
          vertical-align: middle;
          font-weight: bolder;
          margin-top: 0.5em;
          border: none;
        }

        .button:focus,
        .button:hover {
          background: linear-gradient(to right,$color-button-dark-side,$color-button-bright-side);
        }
      }
    }
  }





  @media screen and (min-width: $break-small) {


    .rr.root.inputForm {
      .thankYou {
        font-size: 1.2em;
        .title {
          font-size: 1.8em;
        }
      }
      .soqa_header {
        .imageHolder {
          .image {
            max-height: 8em;
          }
        }

        .productName {
          text-align: left;
        }

        .question {
          font-size: 1.5em;
        }
      }

      .form {
        .soqa_label {
          font-size: 1em;
          .validationText {
            display: inline-block;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
        .input {
          font-size: 1em;
        }
        .commands {
          text-align: center;
        }

        .soqa_links {
          display: block;
          padding: 0;
          margin-top: .6em;
          font-size: 1em;

          .soqa_link {
            display: inline-block;
            margin: 0 .3em;
            width: auto;
          }
        }
      }
    }

    .rr.root.readText {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      font-size: .8em;

      .contents {
        bottom: 4em;
        .inner {
          bottom: 3.5em;
        }

        .footer {
          margin: 1em;
          height: 2em;

          .button {
            width: 100px;
            font-size: 1em;
            display: inline-block;
          }
        }
      }
    }

  }
}