.clipboard {
  &__content {
    position: relative;
    display: inline-block;
    &--success {
      background: $Salt;
      text-align: center;
      width: 100%;
      position: absolute;
      animation-delay: 2s;
      -webkit-animation-delay: 2s;
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: clipboardSuccess;
      animation-name: clipboardSuccess;
    }
  }
}
@-webkit-keyframes clipboardSuccess {
    0% {opacity: 1;}
    100% {opacity: 0;}
 }

 @keyframes clipboardSuccess {
    0% {opacity: 1;}
    100% {opacity: 0;}
 }
