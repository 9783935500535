// UI triangles
@mixin triangle($dir, $size, $color) {
  border: $size transparent solid;

  @if ($dir == "top" or $dir == "up") {
    border-bottom-color: $color;
  } @else if ($dir == "right" or $dir == "after") {
    border-left-color: $color;
  } @else if ($dir == "bottom" or $dir == "down") {
    border-top-color: $color;
  } @else if ($dir == "left" or $dir == "before") {
    border-right-color: $color;
  }
}

/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
          to top : bottom,
          to top right : bottom left,
          to right top : left bottom,
          to right : left,
          to bottom right : top left,
          to right bottom : left top,
          to bottom : top,
          to bottom left : top right,
          to left bottom : right top,
          to left : right,
          to left top : right bottom,
          to top left : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin radial-gradient($from, $to) {
  background: -moz-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: -webkit-gradient(radial, center center, 0, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
  background: -webkit-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: -o-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: -ms-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: radial-gradient(center, circle cover, $from 0%, $to 100%);
  background-color: $from;
}

// TRANSITIONS
@mixin transition($property: all, $duration: 150ms, $method: ease) {
  -webkit-transition: $property, $duration, $method;
  -moz-transition: $property, $duration, $method;
  -ms-transition: $property, $duration, $method;
  -o-transition: $property, $duration, $method;
  transition: $property, $duration, $method;
}

// TRANSFORM
@mixin transform($property: all) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  -o-transform: $property;
  transform: $property;
}

// TRANSFORM ORIGIN
@mixin transformOrigin($property: 0 0) {
  -webkit-transform-origin: $property;
  -moz-transform-origin: $property;
  -ms-transform-origin: $property;
  -o-transform-origin: $property;
  transform-origin: $property;
}

// ANIMATION
@mixin animation($property: animation) {
  -webkit-animation: $property;
  animation: $property;
}

// ALIGNMENT
@mixin align-center($position:relative) {
  position: $position;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin align-y-center($position:relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

@mixin align-x-center($position:relative) {
  position: $position;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
// Input Placeholder color
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
// Button generator
@mixin button-gen($font-size: rem($bodyfont), $color: $c-button-text, $grad-top: $c-button-grad-top, $grad-bottom: $c-button-grad-bottom, $padding: rem(6) rem(25)) {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: $padding;
  white-space: nowrap;
  -webkit-appearance: none;
  border-radius: $border-radius;
  background-color: $PinkAction;
  cursor: pointer;
  color: $Salt;
  font-size: $font-size;
  text-align: center;
  outline: none;
  // text-transform: uppercase;
  @include transition(all, 450ms, ease-in);
  &:hover {
    background: $DarkPinkAction;
    color: $Salt;
  }
  @extend %noselect;
}

// Breakpoints are labeled as devices for convenience only
// Using breakpoints should be for content-specific reasons, not device specific
// http://responsivedesign.is/articles/why-you-dont-need-device-specific-breakpoints
@mixin breakpoint($point) {
  @if $point == desk {
    @media (min-width: rem(1024)) {
      @content;
    }
  } @else if $point == portable {
    @media (max-width: rem(1023)) {
      @content;
    }
  } @else if $point == lap {
    @media (max-width: rem(481)) {
      @content;
    }
  } @else if $point == palm {
    @media (max-width: rem(480)) {
      @content;
    }
  }
}

@mixin shadow($level: 1, $color: $HalfAsh) {
  @if $level == 1 {
      box-shadow: 0 rem(1) rem(3) $color, 0 rem(1) rem(2) $color;
      -webkit-box-shadow: 0 rem(1) rem(3) $color, 0 rem(1) rem(2) $color;
      -moz-box-shadow: 0 rem(1) rem(3) $color, 0 rem(1) rem(2) $color;
      box-shadow: 0 rem(1) rem(3) $color, 0 rem(1) rem(2) $color;
    }
  @else if $level == 2 {box-shadow: 0 rem(3) rem(6) $color, 0 rem(3) rem(6) $color;}
  @else if $level == 3 {box-shadow: 0 rem(10) rem(20) $color, 0 rem(6) rem(6) $color;}
  @else if $level == 4 {box-shadow: 0 rem(14) rem(28) $color, 0 rem(10) rem(10) $color;}
  @else if $level == 5 {box-shadow: 0 rem(19) rem(38) $color, 0 rem(15) rem(12) $color;}
  @else if $level == 6 {
    box-shadow: inset 0 0 rem(5) $color;
    -webkit-box-shadow: inset 0 0 rem(5) $color;
    -moz-box-shadow: inset 0 0 rem(5) $color;
    box-shadow: inset 0 0 rem(5) $color;
  }
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
