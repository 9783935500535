.publish-feedback {
  &__container {
    padding: 2em;
    background-color: $c_app_white;
  }
  &__title {
    color: $c-app-grey-80;
    padding-bottom: 1em;
    font-size: 1.8em;
    border-bottom: rem(1) solid $c-app-grey-20;
  }
  &__container {
    padding: 2em;
    background-color: $c_app_white;
  }
  &__where-title {
    padding: 1em 0;
    font-weight: bold;
    font-size: 1.8em;
  }
  &__everywhere-title2 {
    color: $c-app-grey-20;
  }
  &__text-area {
    max-width: 50em;
    min-height: 10em;
  }
}

.progress-indicator {
  &--bar {
    height: rem(4); 
    &--indeterminate {
      background-image: url(../img/progress-indicator--bar--indeterminate.gif);
      background-repeat: repeat-y;
      background-size: 100% auto;
    }
  }
}

.settings-feedback {
  &__publish-card {
    width: 28%;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: rem(860)) {
      &:nth-child(1) {
        //margin-bottom: 2em;
      }
      width: 100%;
      overflow: hidden;
    }
    &__top {
      border: rem(1) solid $c-app-grey-20;
      border-bottom-width: 0;
      padding: em(20) em(10) em(10);
      position: relative;
      background: $Coal;
      @media screen and (max-width: rem(860)) {
        width: 38%;
        height: em(200);
        float: left;
        border-width: rem(1) 0 rem(1) rem(1);
        padding: em(20);
      }
    }
    &__bottom {
      border: rem(1) solid $c-app-grey-20;
      border-top-width: 0;
      padding: em(10) em(40) em(40);
      @media screen and (max-width: rem(1200)) {
        padding: em(10) em(30) em(30);
      }
      @media screen and (max-width: rem(860)) {
        width: 62%;
        height: em(200);
        float: left;
        border-width: rem(1) rem(1) rem(1) 0;
        padding: em(20);
      }
    }
    & h5 {
      font-size: 1.6em;
      margin-bottom: 2rem;
      padding: 0;
      @media screen and (max-width: rem(1100)) {
        font-size: 1.3em;
      }
    }
    &__title {
      font-family: $f-app-font-regular;
    }
    &__icon-container {
      width: em(110);
      height: em(110);
      margin: 0 auto;
      position: relative;
    }
    &__icon,
    &__icon-complete {
      color: $Salt;
      position: absolute;
      top: 0;
      left: .8rem;
      right: 0;
      bottom: 0;
    }
    &__icon {
      font-size: 10em;
      line-height: .7em;
    }
    &__icon-complete {
      font-size: 5em;
      line-height: 2em;
    }
    &__status {
      color: $Obsidian;
      font-size: 1.3em;
      font-family: $f-app-font-semibold;
      text-transform: uppercase;
      margin-top: 2rem;
    }
    &__description {
      margin: 0 1.2em 2em;
      font-size: 1.3em;
      line-height: 1.6em;
      color: $Obsidian;
      height: rem(100);
      @media screen and (max-width: rem(1100)) {
        font-size: 1em;
      }
      @media screen and (max-width: rem(860)) {
        height: auto;
      }
    }
    & .button {
      display: block;
      width: 100%;
      @media screen and (max-width: rem(1200)) {
        font-size: 1em;
      }
    }
    &.settings-feedback__publish-card--active, &.settings-feedback__publish-card--complete {
      & .settings-feedback__publish-card__top {
        border-width: rem(3) rem(3) 0;
        border-color: $Coal;
        background: $Jellyfish;
        padding: em(18) em(8) em(10);
        @media screen and (max-width: rem(860)) {
          border-width: rem(3) 0 rem(3) rem(3);
          padding: em(17) em(20) em(17) em(17);
        }
      }
      & .settings-feedback__publish-card__bottom {
        border-width: 0 rem(3) rem(3);
        border-color: $Coal;
        padding: em(10) em(38) em(38);
        @media screen and (max-width: rem(1200)) {
          padding: em(10) em(28) em(28);
        }
        @media screen and (max-width: rem(860)) {
          border-width: rem(3) rem(3) rem(3) 0;
          padding: em(18) em(18) em(18) em(20);
        }
      }
    }
    &.settings-feedback__publish-card--complete .settings-feedback__publish-card__icon {
      opacity: 0.2;
    }
    &--not-verified {
      opacity: .4;
    }
  }
  &__publish-staging .settings-feedback__publish-card__icon {
    color: $Jellyfish;
    text-shadow:  0 rem(1) 0 $Salt,
                  0 rem(-1) 0 $Salt,
                  rem(-1) rem(-1) 0 $Salt,
                  rem(-1) rem(1) 0 $Salt,
                  rem(1) rem(-1) 0 $Salt,
                  rem(1) rem(1) 0 $Salt;
  }
  &__publish-alerts .settings-feedback__publish-card__icon {
    font-size: 7em;
    line-height: 1.5em;
  }
  &__publish-arrow {
    display: inline-block;
    color: $c-app-grey-20;
    width: 8%;
    text-align: center;
    position: relative;
    bottom: 23.5em;
    vertical-align: bottom;
    i {
      font-size: 4em;
    }
    @media screen and (max-width: rem(860)) {
      display: none;
    }
    &.settings-feedback__publish-arrow--completed {
      color: $Jellyfish;
    }
  }
  &__publish-heading {
    font-size: 1.4em;
    color: $Slate;
  }
  &__project-toggle {
    font-size: 1.2em;
    color: $c-app-grey-90;
    margin: .4em 0;
    & * {
      display: inline-block;
      vertical-align: middle;
    }
    &__status {
      width: 2.5em;
      margin-left: .4em;
      font-weight: bold;
      color: $Jellyfish;
    }
    &__control {
      font-size: 1.8em;
      margin: .2em 0 0;
    }
  }
  &__publish-container {
    float: left;
    width: 100%;
  }
  &__publish-buttons-container {
    text-align: center;
    margin: 2em 0;
    padding: 0;
    max-width: rem(1380);
    position: relative;
    @extend .clearfix;
  }
  &__publish-buttons-loader {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $c-app-white-transparent-70;
    & .panel-loading__text {
      box-shadow: 0 rem(2) rem(5) $c-app-grey-20;
    }
  }
  &__progress-indicator {
    position: absolute;
    bottom: -1em;
    left: 0;
    right: 0;
  }
}


.replay-publish {
  &__terms-container{
    padding-top:2em;
    padding-left:1em;
  }
  &__terms-buttons{
    float:left;
    padding-top:1em;
    &--left{
      float:left;
      padding-top: 0;
      padding-left: 2em;
      button {
        margin-right:1em;
      }
    }
    &--right{
      float:right;
      button {
        margin-left:1em;
      }
    }
  }
}