@import './site/fonts';
@import './mobile_nav';
@import './progress_bar';

.comparisonController{

	.review-reactions-grid{
		align-items: center;
	}

    .helpful-icon {
      width: 30px;
      height: 30px;
      display: inline-block;
      background-size: contain;
      transition: background 0.5s ease;
 
        background-image: url('/static/images/review_buttons/helpful_review_icon-hover.png');

    }
    .cool-icon {
      width: 30px;
      height: 30px;
      display: inline-block;
      background-size: contain;
      transition: background 0.5s ease;
      background-image: url('/static/images/review_buttons/cool_review_icon-hover.png');
      
    }
	.purple{
		color: purple;
	} 

	.button{
		background-color: purple;
		margin-left: 1.0em;
	}

	.button:hover{
		background-color: #BA55D3;
	}

	.main-bg{
    	background-image: linear-gradient(white, #f0f0f0);
	}

	.transparent-bg{
		background: transparent;
	}

	.gray{
		background-color: #F0F0F0 !important;
	}

	.grid-padding-x{
		padding-top: 0.7em;
	}

	.category-rank{
		font-weight: 400;
	}

	.compare-header{
		padding-top: 5rem;
		paddint-bottom: 4rem;
	}

	.field-header{
		background-color: gainsboro;
	}

	.overall-row{
		display: flex;
		align-items: center;
		margin: inherit;
	}

	.meter-text-grid{
		width: 100%;
	}

	.category-row{
		display: flex;
		align-items: baseline;
	}

	.progress p {
	    position: absolute;
	    display: block;
	    width: 100%;
	    margin-left: 1.5em;
	 }
 
	.grid-y{
	 	height: 400px;
	}
	.title-container{
		display: inline-block;
	}

	.subheader{
		display: flex;

		div{
			flex-grow: 1;
			width: 0;
		}
	}

	.title{
		display:inline;
		font-size:2.0em;  
		font-weight:700;
	}

	.seller-bar-name
	{
		position:relative;
		left: 0;
	}
	.loser{
		.progress-meter	{
			background-color: #808080;
		}
	}
	.winner{
		.progress-meter {
			background-color: indigo;
		}
	}
	.progress-meter-text{
		font-size:1em;
	}
	.customer-scores, .overall-row {
		.progress {
			position: relative;
			float:left;
			width: 80%;
			height: 2.8em;
		}
	}


	.rating-value{
		margin-left: 0.5em;
		text-align:center;
		color: $RRPurple;
		font-weight: 800;
		display:inline-block;
		vertical-align: middle;
		height: 2.8em;
	}
	.add-store-button{
		display:inline;
		padding-left: 10px;
		white-space: nowrap;
		color: purple;
		cursor: pointer;

	}

	.rating-text{
		text-align:center;
		color: indigo;
		font-weight: 800;
		width: 100%;
		display:inline-block;

	}

	.review-title{
		font-size: 1.2em;
		font-weight: 800;
	} 

	.meter-cell{
		position: relative;
	}

	.seller-name{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.category-rank-text{
		h5{
			font-size:0.95em;
		}
	}

	.site-store-store{
		padding: 10px;
	}

	.store-icon{
		width: 30em;
	}
	.reaction-text{
		font-weight: 700;
	}
}