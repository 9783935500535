.legend{

	&--inline{
		overflow: auto;
		.legend__item {
			float: left;
			margin-right: 1.2rem;

			&:last-child {
	  		margin-right: 0;
			}
		}
	}

	&__item{
		font-size: 1.2rem;
		font-family: $f-app-font-semibold;
	}

	&__icon{
		margin-right: 0.5rem;
	}
}
