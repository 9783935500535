textarea, input {
  outline: none;
  resize: none;
}
.form-input {
  padding: rem($form-element-padding-unit / 2, $bodyfont) rem($form-element-padding-unit, $bodyfont);
  -webkit-appearance: none;
  background: $Salt;
  width: 100%;
  border: rem(1) solid $Ash;
  border-radius: $border-radius;
  display: block;
  font-size: rem($bodyfont);
  height: $inputHeight;
  line-height: initial;
  
  &:active, &:focus {
    border: rem(1) solid $Jellyfish ;
    box-shadow: 0 0 rem(3) $Jellyfish;
  }
  
  &--error {
    border: rem(1) solid $Dragonfruit;

    &:active, &:focus {
      border: rem(1) solid $Dragonfruit ;
      box-shadow: 0 0 rem(3) $Dragonfruit;
    }
  }
  
  &--readOnly {
    border: none;
    background: $HalfQuartz;

    &:active, &:focus {
      border: none;
      box-shadow: none;
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5em;
    height: $inputHeight;
    line-height: $inputHeight;
    text-align: center;
    cursor: pointer;
    
    background-color: $Salt;
    border: rem(1) solid $Ash;
    border-top-right-radius: rem(3);
    border-bottom-right-radius: rem(3);
    width: 3.5rem;
    
    &:hover {
      background-color: $Quartz;
    }
    
    &--error {
      border: rem(1) solid $Dragonfruit;
    }

    &--disabled {
      opacity: 0.3;
      cursor: initial;
      
      &:hover {
        background-color: initial;
      }
    }
  }

  &__xlarge-icon {
    height: $xLargeInputHeight;
    line-height: $xLargeInputHeight;
    width: 5.0rem;
  }

  &--inverse {
    color: $Slate;
    background: transparent;
    border: rem(1) solid $Obsidian;
    box-shadow: none;
  }
  
  &--autoWidth {
    width: auto;
    padding-right: 0;
  }

  &__input-icon {
    position: absolute;
    right: 0;
    top: 0;
    border-left: rem(1) solid $Ash;
    font-size: 1.5em;
    height: $inputHeight;
    line-height: $inputHeight;
    width: 4.5rem;
    text-align: center;
    cursor: pointer;

    &--disabled {
      opacity: 0.3;
      cursor: initial;
    }
  }
  
  &__tokens {
    position: absolute;
    top: rem(4);
    right: rem(4);
  }
  
  &__popover.popover {
    position: initial;
  }
  
  @include formSizes();
}
