$itemHeight: rem(30);
$formHeight: rem(25);

.searchable-menu {
  max-height: 40rem;
  overflow: auto;

  &--no-scroll {
    overflow: inherit;
  }

  &--no-max-height {
    max-height: none;
  }

  &__icon {
    vertical-align: text-bottom;
    margin-left: 0.5rem;
  }

  &__tooltip {
    width: 25rem;
  }

  &__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: $itemHeight;
    line-height: $itemHeight !important;
    padding-left: 2rem;
    padding-right: 2rem;

    &--selectable {
      cursor: pointer;
      &:hover{
        background: $HalfQuartz;
        @include transition();
      }
    }
  }

  &__items {
    &--scroll {
      max-height: 35rem;
      overflow: auto;
    }
  }

  &__inline-block {
    display: inline-block;
  }

  &__overflow-protection {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__item {
    width: 100%;
    /*white-space: nowrap;*/
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: rem($bodyfont);
    color: $Coal;
    font-family: $f-app-font-regular;
    line-height: $itemHeight;
    height: $itemHeight;
    cursor: pointer;
    padding: 0 2rem;

    label{
      width: 100%;
    }

    &--form {
      line-height: $formHeight;
      /*height: $formHeight;*/
      height:auto;

      .form__checkbox, .form__radio {
        vertical-align: middle;
      }
    }
    &--wrap {
      height:auto;
      overflow: auto;
      white-space: normal;
      padding: 0.5rem 2rem;
    }
    &--default-search {
      padding: 0;
    }

    &:hover{
      background: $HalfQuartz;
      @include transition();
    }

    &__col-left {
      float: left;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__col-right {
      text-align: right;
      float: right;
      width: 20%;
    }
  }

  &__input {
    padding: 0 2rem;
    min-height: $inputHeight;
  }

  &__list {
  }

  &__collapse-container {
    font-size: 1.2rem;
    color: $Ash;
    padding: 1rem 2rem 0;
    cursor: pointer;

    span {
      margin-right: 1rem;
    }
  }

  &__collapse-highlight {
    color: $PinkAction;

    .text {
      font-family: $f-app-font-semibold;
      color: $PinkAction;
    }

    &--background {
      background-color: $PinkAction;

      .text {
        font-family: $f-app-font-semibold;
        color: $Salt;
      }

      &:hover {
        background-color: $PinkAction;
      }
    }
  }

  &__divider {
    &--spacing {
      &-top {
        margin-top: 1rem;
      }
      &-bottom {
        margin-bottom: 1rem;
      }

      &-left {
        margin-left: 2rem;
      }
      &-right {
        margin-right: 2rem;
      }
    }
  }

  &__error {
    text-align: center;
    font-size: 1.6rem;
    margin: 7rem 4rem;
    color: $Ash;
  }

  &__highlight{
    background: $ThirdBananarama;
  }

  .search {
    &__control {
      max-width: inherit;
    }

    &--transparent {
      margin-left: 1rem;

      i {
        top: rem(15)
      }

      .search__control {
        height: 5rem;
      }
    }

    &.search--inline {

      i {
        top: 0.7rem;
      }

      .search__control {
        height: 3.5rem
      }
    }
  }
  
  &-wrapper {
    &__menus {
      max-height: 35rem;
      overflow: auto;
    }
    
    &__group-header {
      color: $Slate;
    }
    
    .search {
      &__control {
        max-width: inherit;
      }

      &--transparent {
        margin-left: 1rem;

        i {
          top: rem(15)
        }

        .search__control {
          height: 5rem;
        }
      }
    }
  }
}
