// Make nice radios, but not for IE8
:not(.lt-ie9) {
  // removes built in radio
  .form__radio-input {
    display: none;
  }

  // add cursor to radio label
  .form__radio-input + label {
    cursor: pointer;
  }

  .form__radio-input {
    + label {
      line-height: rem(16);
      color: $Obsidian;

      // Radio style
      .form__radio-icon i {
        color: transparent;
        background-color: $Quartz;
        border: rem(1) solid $Ash;
        border-radius: 50%;
        line-height: rem(16);
        max-height: rem(16);
        max-width: rem(16);
        height: rem(16);
        width: rem(16);
        font-size: rem(11);
        text-align: center;
        @include transition(background-color, 250ms);

        &:after {
          @extend .fa;
          @extend %noselect;
          content: "";
        }
      }

      &.form__label-container {
        font-size: rem($bodyfont);

        // Spacing between radio and form label
        .form__radio-icon i {
          margin: 0 1em 0 0;
        }

        // Allows text to wrap
        .form__label-text {
          width: calc(100% - 3rem);
        }

        // Radio right aligned
        &--right {
          float: right;

          // Spacing between radio and form label
          .form__radio-icon i {
            display: inline-block;
            margin: 0 0 0 1em !important;
            float:right;
          }
        }
      }
    }

    // Radio style when checked
    &:checked + label {
      .form__radio-icon i {
        color: $Salt;
        border-color: $Atlantis;

        &:after {
          @extend .fa;
          @extend %noselect;
          content: "";
          background-color: $Jellyfish;
          width: rem(8);
          height: rem(8);
          line-height: rem(16);
          box-sizing: border-box;
          border-radius: 50%;

          margin-top: rem(3);
        }
      }
    }
  }
}

// Width is equal to the width of the elements
.form__radio {
  display: inline-block;
  width: 100%;

  &--disabled {
    pointer-events: none;
    opacity: .5;

    .form__radio-input {
      + label {
        cursor: default;
      }
    }
  }

  &--checked {
    opacity: 1;
  }

  &--in-line {
    width: auto;
    margin-right: $gutter / 2;
  }

  &--border {
    border-radius: rem(16);
    border: rem(1) solid $Ash;
    padding: .33rem;
    padding-right: .66rem;

    &.form__radio--checked {
      border: rem(1) solid $Jellyfish;
    }

    .form__radio-input {
      + label {
        .form__radio-icon i {
          background-color: transparent;
        }
      }
      &:checked + label {
        color: $Jellyfish;

        .form__radio-icon i {
          border: rem(1) solid transparent;
          background-color: $Jellyfish;

          &:after {
            width: 0;
            height: 0;
          }

        }
      }
    }
  }
}
