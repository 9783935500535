.select {
  position: relative;

  &__item, &__label {
    padding: 0 2rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__group {
    border-bottom: rem(1) solid $Quartz;
    &:last-of-type {
      border-bottom: none;
    }
  }

  &__label {
    text-transform: uppercase;
    font-family: $f-app-font-bold !important;
    font-size: rem(12);
    color: $Obsidian !important;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-top: .5rem;
    cursor: default;

    &:empty{
      display: none !important;
    }
  }

  &__dropdown {
    font-size: 1.3rem;

    .search {
      margin: rem(5) 1rem;

      &__control {
        max-width: none;
      }
    }
  }

  &__options {
    max-height: 20rem;
    overflow-y: auto;

    &--no-max-height {
      max-height: none;
    }
  }

  &__item {
    font-size: 1.4rem;
    color: $Coal;
    font-family: $f-app-font-regular;
    cursor: pointer;
    height: $inputHeight;
    line-height: $inputHeight;

    &:hover {
      background: $HalfQuartz;
      @include transition();
    }

    &--selected {
      background: $Quartz !important;
      color: $Obsidian
    }

    &--disabled {
      color: $Ash;
      &:hover{
        background: $Salt;
      }
    }
  }

  &__button {
    padding: 0 rem(8) rem(6) 0;
    width: 100%;
    color: $Obsidian;
    word-wrap: break-word;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.3rem;

    &-label {
      color: $Slate;
      display: inline-block;
      font-size: 1.3rem;

      &--required {
        color: $Dragonfruit;
        padding-left: 0.5rem;
        font-family: $f-app-font-semibold;
      }

      &--error {
        color: $Dragonfruit;
        font-size: 1.2rem;
      }
    }

    &--readOnly {
      border: none !important;
      background: $HalfQuartz !important;

      &:active, &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }

    &--material {
      border-bottom: rem(1) solid $Ash;
      padding: 0 rem(8) rem(6) 0;
      height: 3rem;
    }
    &--navigation {
      font-size: 1.6rem;
      color: $Salt;
      line-height: 4rem;
      font-family: $f-app-font-semibold;
      padding-left:1rem;
    }

    &--bordered {
      background-color: $Salt;
      border: 0.1rem solid $Ash;
      padding: 0.3rem 1rem;
      font-size: 1.4rem;
      height: 3.5rem;
      line-height: 2.6rem;
      border-radius: $border-radius;
    }

    &--error {
      border-bottom: 0.1rem solid $Dragonfruit !important;
      border-color: $Dragonfruit !important;
    }

    &-caret {
      &:after {
        @extend .fa;
        position: absolute;
        content: "\f0d7";
        height: rem(10);
        line-height: rem(10);
        top: 50%;
        right: rem(10);
        margin-top: rem(-5);
        padding-left: rem(5);
      }
    }

    &--disabled:after {
      color: $Quartz;
    }

    &--opened:after {
      content: $fa-var-caret-up;
    }
    &--navigation:after {
      color: $Salt;
      margin-top:0;
    }
    @include formSizes();
  }

  &:hover .select__button {
    border-color: $Coal;
    color: $Coal;
    &.form__control--inverse {
      border-color: $Atlantis;
      color: $Salt;
    }
  }
  &__icon-gutter {
    margin-right: 2rem;
    &--svg {
      margin-right: 1.6rem;
    }
  }
}
