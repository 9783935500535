.merchantOnboard {
  .progressTrackerContainer{
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 2.5rem;

    p{
      font-size: 1.5rem;
      margin-bottom: 5px;
    }
    p.link{
      color: #6740E8;
    }
  }
  .onboarding-images{
    max-width: 450px;
  }

  .onboardElement{
    color: #6740E8;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 15px;
    cursor: inherit;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 10px;
    padding-left: 15px;

    .onboard-title{
      font-size: 80%;
    }

    h5{
      font-size: 1.4625rem;
      color: #0a0a0a;
    }

    p{
      color: #7c858e;
    }

    .action{
      .text{
        font-size: 16px;
        text-decoration: underline;
      }
    }

    &.linkable{
      cursor: pointer;
    }

    &.linkable:hover{
      background-color: #decffc;
      border-bottom: 1px solid #f1f1f1;
    }

    &.row{
      margin:0;
    }

    p{
      padding-top: 0;
    }

    .onboardStatus, .onboardRightArrow{
      margin-top: 10px;
      font-size: 2rem;
    }
  }
}