.modal {
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  &__close {
    position: absolute;
    right: 0;
    top: rem(-30);
    font-size: 2.2rem;
    color: $Salt;
    cursor: pointer;
  }

  &__content {
    z-index: 2147483648;
    max-width: rem(800);
    margin: 10rem auto;

    &--padding{
      position: fixed;
      padding: 2rem;
      margin: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: initial;
    }
  }
  &__medium {
    max-width: rem(650);
    background: $Salt;
  }
  &__dialog {
    max-width: rem(450);
    margin: 10rem auto;

    &--small {
      max-width: 35rem;
      margin: 10rem auto;
    }
  }

  &__fullscreen {
    background: $Salt;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: initial;

    &--with-padding{
      position: relative;
    }

    .modal__close {
      color: $Obsidian;
      top: 2rem;
      right: 2rem;
      z-index: 3;
    }
  }
}
