@import "../colors";

.reactTableContainer{
    width: 100%;
    overflow-y: auto;
    position: relative;
    padding-bottom: 0;
}

.reactDataTable.tfooter{
    bottom: 0;
}

.reactDataTable {
    border: 1px solid #dedede;
    border-top: none;
    border-collapse: collapse;
    table-layout: fixed;
    margin-bottom: 0;

    .fancyStars{
        span {
            margin: 1px;
        }
    }

    .highPriority{
        font-weight: bold;
        color: darkred;
    }

    &.full{
        width: 100%;
    }

    .anonymousUsers{
        .fa, .fal, .far, .fas{
            font-size: 16px;
            margin-right: 15px;
        }
    }

    td{
        border-bottom: 1px solid #dedede;
        padding: 5px 10px;
        height: 16px;
        line-height: 16px;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;


        a {
            color: $Atlantis;
            font-weight: bold;

            &:hover{
                color: $Water;
            }
        }

        .bigAvatar{
            a{
                display: inline-block;
                color: $RRMain;

                &:hover{
                    text-decoration: none;
                }
            }
        }

        span.center {
            text-align: center;
            width: 100%;
            display: block;
        }


    }

    tr{
        &.expanded{
            td{
                overflow: visible;
                white-space: normal;
                word-break: break-word;
                width: 35%;
                padding-bottom: 20px;
                padding-top: 15px;
                border-bottom-width: 0;
            }
        }
    }

    thead{
        background: #44384c;
    }

    th:nth-child(1) {
        border-radius: 5px 0 0 5px;
    }

    th:nth-last-child(1) {
        border-radius: 0 5px 7px 0;
    }

    thead tr th {
        color: white;
        padding: 0 5px 0 10px;
        font-weight:normal;
        line-height:20px;
        text-align:left;
        position:relative;
        cursor: pointer;
        font-size: 0.9rem;

        &:hover, &.selected{
            color: $LightEmerald
        }

        &.nonSortable:hover{
            font-weight:normal;
            color: white;
            cursor: inherit;
        }

        &[data-tooltip]:hover:after {
            content: attr(data-tooltip);
            padding: 8px 16px;
            color: #333;
            position: absolute;
            left: 100%;
            top: 50%;
            width: 250px;
            max-width: 400px;
            z-index: 20;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            -moz-box-shadow: 0 0 4px #222;
            -webkit-box-shadow: 0 0 4px #222;
            box-shadow: 0 0 4px #222;
            background: white;
        }

    }

    tr{
        height: 49px;
    }

    tbody tr{
        cursor: pointer;
        background: white;
        transition: box-shadow 180ms 70ms;

        &:hover{
            box-shadow: inset 0 0 0 2px #625df5;
            border-radius: 5px;
            transition-timing-function: ease-out;
            transition: 0.35s;
            transform: translateY(0);

            .textIcons, .copy-to-clipboard{
                .pastelIcon, .fa-copy{
                    opacity: 1;
                }
            }
        }

        &.nonClickAble{
            cursor: default;
        }

        &.selected{
            background: #93e17a;
        }
    }

    tfoot tr th{
        padding-left: 20px;
        padding-right: 20px;

        i, span, .showing_count{
            color: #666;
        }
    }

    .clip-square{
        width: 35px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .sort {
        position: absolute;
        right: 0;
        top: 17px;
        font-size:16px;
        cursor: pointer;
    }

    th:last-child .sort{
        right: 8px;
    }

    .sort--ascending {
        color: $LightEmerald;
    }

    .sort--descending {
        color: $LightEmerald;
    }

    .fa-step-backward, .fa-step-forward{
        margin: 0 5px;
    }

    .pagination i{
        cursor: pointer;
    }

    .pagination input{
        width: 50px;
        margin: 0 3px;
        display: inline;
    }

    .showing_count {
        float: right;
        margin: 10px 0;
    }

    .pagination.left {
        float: left;
    }

    .pagination {
        margin: 10px 0;
    }

    .more{
        margin-top: 7px;
        color: $RRMain;
        font-weight: 700;
        font-size: 1rem;

        i{
            font-size: 1rem;
        }
    }

    .inlineAvatar{
        overflow: hidden;

        span{
            margin-left: 10px;
        }

        a{
            h6{
                color: $Atlantis;
                font-weight: bold;
                margin:0;

                &:hover{
                    color: $Water;
                }
            }
        }

        img{
            clip-path: circle(25px at center);
        }

        .align-middle{
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 30px;
        }

        .userNameContainer{
            width: auto;
            float: left;
        }
    }

    .avatarWidget{
        a{
            float: left;
            margin-top: 12px;

            &.noFloat{
                float: none;
            }
        }


        img{
            float: left;
            margin-right: 10px;
        }
    }

    span .fa-comment{
        margin-right: 5px;
        font-size: 15px;
    }

    .totals td {
        background: #fef6d8;
        font-weight: bold;
    }

    .actions{
        text-align: center;
        a{
            img{
                opacity: 0.8;
                &:hover{
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }


        i{
            color: $Atlantis;
            max-font-size: 3.5rem;
            padding: 7px;
            cursor: pointer;

            &:hover{
                color: $Water
            }

            &.grey{
                color: grey;
            }
        }
    }
}

.reactDataTable--percentage {
    font-style:italic;
    margin-left:50px;
    float:right;
}

.productTableToolTip{
    text-align: left !important;
    line-height: 2em !important;
}

