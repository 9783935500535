.plan-grid-wrapper {
  .plan-grid-row{
    display: flex;
    flex-wrap: wrap;
    .plan-grid-item{
      .plan-icon {
        opacity: .3;
        svg{
          transition: transform .2s ease-in-out;
        }
        &:hover{
          svg{
            transform: scale(1.1);
          }
        }

        &.selected {
          opacity: 1;
        }
      }
      padding: 10px;
      width: 20%;
      text-align: center;
    }
  }
}
