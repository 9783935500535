// Make nice checkboxes/radios, but not for IE8
:not(.lt-ie9) {
  // removes built in checkbox
  .form__checkbox-input {
    display: none;
  }

  // add cursor to checkbox label
  .form__checkbox-input + label {
    cursor: pointer;
  }

  .form__checkbox-input {
    + label {
      line-height: rem(16);
      @include transition(color, 250ms);

      // Checkbox style
      .form__checkbox-icon i {
        color: transparent;
        background-color: $Quartz;
        border: rem(1) solid $Ash;
        border-radius: rem(3);
        line-height: rem(16);
        max-height: rem(16);
        max-width: rem(16);
        height: rem(16);
        width: rem(16);
        font-size: rem(11);
        text-align: center;
        @include transition(background-color, 250ms);
      }

      &.form__label-container {
        font-size: rem($bodyfont);

        // Spacing between checkbox and form label
        .form__checkbox-icon i {
          margin: 0 1em 0 0;
        }

        // Allows text to wrap
        .form__label-text {
          width: calc(100% - 3rem);
        }

        // Changes background color of checkbox to $Coal
        &--inverse {
          .form__checkbox-icon i {
            background-color: $Coal;
            border-color: $Obsidian;
          }
        }

        // Checkbox right aligned
        &--right {
          float: right;

          // Spacing between checkbox and form label
          .form__checkbox-icon i {
            display: inline-block;
            margin: 0 0 0 1em !important;
            float:right;
          }
        }
      }
    }

    // Checkbox style when checked
    &:checked + label {
      .form__checkbox-icon i {
        font-family: 'Font Awesome 5 Pro';
        color: $Salt;
        border-color: $PinkAction;
        background-color: $LightPinkAction;
      }
    }
  }
}

// Width is equal to the width of the elements
.form__checkbox {
  color: $Obsidian;
  display: inline-block;
  width: 100%;
  
  &--disabled {
    pointer-events: none;
    opacity: .5;
    
    .form__checkbox-input {
      + label {
        cursor: default;
      }
    }
  }
  
  // &--checked {
  //   opacity: 1;
  // }
  
  &--in-line {
    width: auto;
    margin-right: $gutter / 2;
  }
  
  &--border {
    border: rem(1) solid $Ash;
    padding: .33rem;
    
    &.form__checkbox--checked {
      border: rem(1) solid $Jellyfish;
    }
    
    .form__checkbox-input {
      + label {
        .form__checkbox-icon i {
          border-radius: 0;
        }
      }
      
      &:checked + label {
        color: $Jellyfish;
      }
    }
  }
}
