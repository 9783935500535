.dropdown-flyout {

  &__header {
    font-family: $f-app-font-semibold;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;  
    width: 100%;

    &--large {
      font-size: 2rem;
    }
    &--medium {
      font-size: 1.6rem;
    }
    &--small {
      font-size: 1.2rem;
    }

    &--inline {
      width: auto;
      margin-right: 1rem;
    }

    &--bordered {
      border: 0.1rem solid $Ash;
      padding: 0.5rem;
      font-size: 1.4rem;
      & span:last-child {
        color: $Ash;
        margin: 0 1rem;
      }
    }

    &--disabled {
      cursor: default;
      color: $Quartz;
    }

    &--error {
      border: 0.1rem solid $Dragonfruit;
    }
  }

  &__icon {
    margin-right: 1rem;
  }
}

@keyframes popover_body {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 0
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1
    }
}
