.text {
  line-height: rem(21);

  &--bold {
    font-family: $f-app-font-semibold !important;
  }
  &--regular {
    font-family: $f-app-font-regular !important;
  }

  &--center {
    text-align: center;
  }

  &--all-caps {
    text-transform: uppercase;
  }

  &--no-line-height {
    line-height: inherit !important;
  }

  &--display {
    font-size: rem(36);
    line-height: rem(54);
  }
  
  &--highlight {
    background: $ThirdBananarama;
  }
  
  &--tooltip {
    border-bottom: 1px dashed $Jellyfish;
  }

  &--inline {
    display: inline;
  }

  &--inline-block {
    display: inline-block;
  }

  &--h1 {
    font-family: $f-app-font-semibold;
    font-size: rem(23);
    line-height: rem(34);
  }
  &--h1p5 {
    font-family: $f-app-font-semibold;
    font-size: rem(20);
    line-height: rem(32);
  }
  &--h2 {
    font-family: $f-app-font-semibold;
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: rem(0.1);
  }
  &--h3 {
    font-family: $f-app-font-regular;
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: rem(0.1);
  }
  &--h4 {
    font-family: $f-app-font-semibold;
    font-size: rem(12);
    line-height: rem(18);
    text-transform: uppercase;
    letter-spacing: rem(0.2);
  }

  &--body {
    font-family: $f-app-font-regular;
    font-size: rem(14);
    line-height: rem(21);
    letter-spacing: rem(0.1);
  }

  &--subtext {
    font-family: $f-app-font-regular;
    font-size: rem(14);
    line-height: rem(21);
    font-weight: 400;
  }

  &--subtext-small {
    font-family: $f-app-font-regular;
    font-size: rem(13);
    line-height: rem(19);
    font-weight: 400;
  }

  &--quote {
    font-family: $f-app-font-regular;
    font-size: rem(14);
    line-height: rem(21);
    font-style: italic;
  }

  &--bold-subtext {
    font-family: $f-app-font-semibold;
    font-size: rem(12);
    line-height: rem(18);
  }

  &--white {
    color: $LightGray !important;
  }

  &--link {
    font-family: $f-app-font-semibold;
    cursor: pointer;

    &:focus,
    &:hover {
      color: $Atlantis;
    }

    &.txt--PinkAction{
      &:hover {
        color: $DarkPinkAction;
      }
    }
  }

  &--tag {
    font-family: $f-app-font-regular;
    font-size: rem(12);
    border-radius: $border-radius;
    border: rem(1) solid $Ash;
    background-color: $HalfQuartz;
    color:$Slate;
    padding:rem(3) rem(5);
  }
  &--divider:after{
      content:"|";
      color: $Ash;
      padding: 10px;
  }
}

