.form-input {
  &--inline {
    background: transparent;
    width: 100%;
    border: none;
    display: block;
    height:25%;


    &:active, &:focus {
      border: none;
      box-shadow: none;
    }

    &__line-container {
      position:relative;
      height: rem(1);

      &--autoWidth {
        width: auto;
        padding-right: 0;
      }
    }

    &.form-input--error {
      background: transparent;
      border: none;
      box-shadow: none;
    }

    &__line {
      transform: scaleX(1);
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      border-top: rem(1) solid transparent;
      position: absolute;
      width: 100%;
      height: rem(1);
      margin: 0;
    }

    &__label, &__label--placeholder {
      -webkit-transition: color .4s;
      transition: color .4s;
      line-height: 2;
      font-size: 2rem;
      vertical-align: middle;
      display: inline-block;
    }

    &__label {
      opacity: 1;
      transform: translate3d(0, .5rem, 0) scale(0.80);
      transform-origin: left top;
      transition: 450ms;
      padding-left:1rem;

      &--placeholder {
        pointer-events: none;
        transform: translate3d(0, 3.5rem, 0) scale(1);
        transform-origin: left top;
        transition: 450ms;
        padding-left:1rem;
      }
    }

    &__required {
      color: $Dragonfruit;
      padding-left: 0.5rem;
      font-family: $f-app-font-semibold;
    }
  }


  &--inline-small {
    .form-input--inline {
      font-size: 1.6rem;
      padding-left: 0;
      height: auto;

      &__label, &__label--placeholder {
        font-size: 1.6rem;
      }

      &__label {
        transform: translate3d(0, 1.5rem, 0) scale(0.75);
        padding-left: 0;
        &--placeholder {
          padding-left: 0;
        }
      }
    }
  }

}
