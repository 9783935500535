@import "./../frameworks/font-awesome/variables"; /* required to be imported at the top */
@import 'cx-component-library/colors.scss';
@import 'cx-component-library/config.scss';
@import "cx-component-library/fonts";
@import "cx-component-library/core/borders";
@import "cx-component-library/core/mixins";
@import "cx-component-library/core/utils";
@import "cx-component-library/components/flyout-pages";
@import "cx-component-library/components/flyout";

html{
  body{
    .cx-component-library {

      .fal {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
      }

      .far {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
      }

      .fas {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
      }

      .fab {
        font-family: 'Font Awesome 5 Brands';
      }

      input{
        box-shadow: inherit;
      }

      select{
        width: inherit;
      }

      ul.tabs{
        border: 0;
        background: transparent;
      }

      .button.button--primary{
        background-color: $PinkAction;
        border-color: $PinkAction;

        &:hover{
          background-color: $DarkPinkAction;
          border-color: $DarkPinkAction;
        }
      }

      @import "cx-component-library/core/typography";
      @import "cx-component-library/core/base";
      @import "cx-component-library/icons/base";
      @import "cx-component-library/components/base";
      @import "./../helper/react-table";

      .card--full{
        &.card--wrap_content{
          height: inherit;
        }
      }

      .inlineAvatar{
        margin: 0 20px;
        .text{
          padding-top: 1rem;
        }

        img{
            width: 45px;
            height: 45px;
        }
      }

      .notificationCard{
         > div{
           border-radius: 10px;
           padding: 10px 20px;
           background: linear-gradient(to right, rgb(186, 87, 255), rgb(103, 64, 232));;
           box-shadow: 0 1px 3px #B7C0CC, 0 1px 2px #B7C0CC;
         }

         span{
           position: absolute;
           top: calc(50% - 0.7rem);
           left: 5px;
         }

         i.cx-icon--fa{
           font-size: rem(20);
         }
       }

      .notificationCardDisabled{
        > div{
          padding: 10px 20px;
          background: #f7f7f7;
          box-shadow: 0 1px 3px #B7C0CC, 0 1px 2px #B7C0CC;
        }

        span{
          position: absolute;
          top: calc(50% - 0.7rem);
          left: 5px;
        }

        i.cx-icon--fa{
          font-size: rem(20);
        }
      }

      .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
      }

      .cardGroupContainer{
        margin: 0 20px;
      }

      .tableRatingsSideBar.selectable{
        tr{
          border-right: 2px solid transparent;
          border-left: 2px solid transparent;
          border-top: 2px solid transparent;
          cursor: pointer;

          h5{
            text-decoration: underline;
          }
        }

        tr.selected, tr:hover {
          border: 2px solid $PinkAction;

          h5{
            color: $PinkAction;
          }
        }
      }

      .cardContainer{
        float: left;
        width: 100%;
        padding: 0 0 13.2px;

        &.spacer{
          padding-right: 25px;
        }
      }

      .pull-left{
        float: left;
      }

      .pull-right{
        float: right;
      }

      .reactSeparator{
        position: relative;
      }

      .clearAble{
        position: absolute;
        right: 5px;
        top: 8px;
        opacity: 0.7;
        cursor: pointer;

        &:hover{
            opacity: 1.0;
        }
      }

    }
  }
}

