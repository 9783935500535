.fancyStars{
    text-align: center;
    &.no-center{
      text-align: left;
    }

    span{
      display: inline-block;
      margin: 5px;

      .fa-star, .fa-star-o, .fa-star-half-o, .fa-star-half, .fa-star-half-alt{
        color: #FEB515;
      }
      .fa-star.unselected {
        color: #7e8890;
      }
    }

  .ratingMessageSpan {
    margin-left: 20px;
    font-weight: bold;
    min-width: 190px;
  }

  .clickable {
    cursor: pointer;
  }

  .reactDataTable{
    text-align: left;
  }

}

