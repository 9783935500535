.card {
  border-radius: $border-radius;
  margin-bottom: 1rem;
  background: $Salt;

  &.upsell{
    background: rgb(227 227 227 / 50%)
  }

  &--visible-overflow {
    overflow:visible;
  }

  &--relative{
    position: relative;
  }

  @include transition(all, 250ms);
  @include shadow(1);

  &--padded {
    padding: 0 2rem;
  }

  &--flex {
    display: flex;
    justify-content: space-between;
    margin: 1rem 2rem;
  }

  &--small {
    width: 20rem;
    height: 22.5rem;
  }

  &--medium {
    width: 41rem;
    height: 46rem;
  }

  &--large {
    width: 62rem;
    height: 46rem;
  }

  &--full {
    width: 100%;
    max-width: 125rem;
    height: 46rem;
  }

  &:hover {
    // box-shadow:  0 rem(3) rem(12) rem(4) #BFC3C8;
    transition: all 0.1s ease-in;
    max-height:auto;
    z-index: 1;
    // @include transform(translateY(rem(-2)));
  }

  &--focused, &--focused:hover {
    // box-shadow:  0 rem(3) rem(20) rem(5) #BFC3C8;
    transition: all 0.1s ease-in;
    max-height:auto;
    z-index: 1;
  }

  &--shadow-lvl-1 {
    box-shadow: 0 rem(1) rem(3) $Ash, 0 rem(1) rem(2) $Ash;
    @include transition(all, 0.1s, ease-in);
    max-height:auto;
  }
  &--shadow-lvl-2 {
    box-shadow: 0 rem(2) rem(6) $Ash, 0 rem(2) rem(4) $Ash;
    @include transition(all, 0.1s, ease-in);
    max-height: auto;
  }
  &--shadow-lvl-3 {
    box-shadow: 0 rem(3) rem(9) $Ash, 0 rem(3) rem(6) $Ash;
    @include transition(all, 0.1s, ease-in);
    max-height: auto;
  }

  &__header {
    &-subtext {
      line-height: initial !important;
    }

    &-padded {
      padding: 0.5rem 0;
    }

    &-flex{
      display: flex;
      align-items: center;
      margin: 1rem 0;
    }

    .text {
      line-height: 3.5rem;
    }

    &--leftnode {
      float:left;
    }

    &--rightnode{
      float:right;
      padding:1.5rem 0;
    }
  }

  &__footer {
    width: 100%;
    left: 0;
    bottom: 0;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    border-top: .1rem solid $Quartz;
    position: absolute;

    &--height {
      padding-bottom: 78px;
    }
    &--rightnode{
      float:right;
    }
  }

  &__modal {
    &--header{
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
      height: 110px;
    }

    &--tabs {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      position: relative;

      &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 0.255rem;
          background: #e7e6ea;
          bottom: 0;
        }
    }

    &--button {
      display: flex;
      justify-content: end;
    }

    &--content {
      min-height: 400px;

      & p {
        font-size: 16px;
        margin-bottom: 20px;
      }

      & div {
        display: flex;
        flex-flow: column;
      }

      & ul li {
        list-style-position: inside;
        list-style-type: disc;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }

  }
}
