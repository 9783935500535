.progressTracker{
  .card{
    a.indicatorLink{
      display: block;
      color: #58595b;

      .indicator{
        margin-top: 10px;
        font-size: 1.5rem;
      }

      i{
        font-size: 2.0rem;
        color: $PinkAction;
      }

      &:hover{
        color: $PinkAction;
        div{
          text-decoration: underline;
        }
      }
    }

    a.indicatorLinkWhite{
      display: block;
      color: $LightGray;

      .indicator{
        margin-top: 10px;
        font-size: 1.5rem;
      }

      i{
        font-size: 2.0rem;
        color: $LightGray;
      }

      &:hover{
        color: $LightGray;
        div{
          text-decoration: underline;
        }
      }
    }
  }
}
