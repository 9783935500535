.foCardsContainer{
  .foundation-card {
    position: relative;
    z-index: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1rem;
    border: 0;
    border-radius: 0;
    background: #fefefe;
    box-shadow: none;
    overflow: hidden;
    color: #0a0a0a;
    margin-right: 15px;
    height: 315px;
  }

  .foundation-img-container{
    text-align: center;
  }

  .foundation-card > :last-child {
    margin-bottom: 0;
  }

  .foundation-card-img{
    height: 230px;
    background: white;
  }

  .foundation-card:hover .foundation-card-img {
    opacity: 1;
  }

  .foundation-card-content {
    position: relative;
    padding: 10px;
  }

  .foundation-card-content::before {
    position: absolute;
    content: '';
    top: -3.4375rem;
    left: -1.875rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3.4375rem 0 0 31.25rem;
    z-index: 0;
    border-color: transparent transparent transparent #fefefe;
  }

  .foundation-card-actions {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    background: #fefefe;
    display: block;
    text-align: center;

    a:hover{
      text-decoration: underline;
    }
  }

  .foundation-card-actions > :last-child {
    margin-bottom: 0;
  }

  .foundation-card-img {
    max-width: 100%;
    opacity: 0.85;
    height: 215px;
    transition: all 0.25s ease;
    object-fit: scale-down;
    object-fit: cover;
  }

  .foundation-card-avatar {
    position: absolute;
    bottom: 100%;
    right: 0.4rem;
    z-index: 2;
    max-width: 5.625rem;
    color: white;
    padding: 8px;
    border-radius: 50%;
    line-height: 28px;

    &.approved{
      color: $Leaf;
    }

    &.pending{
      color: $Amber;
    }

    &.disabled{
      color: $velvet;
    }
  }

  .foundation-card-avatar img {
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  }

  .foundation-card-name {
    margin-bottom: 0;
    font-size: 1.2rem;
    word-break: break-all;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    width: 100%;
    max-height: 1.7rem;
    text-overflow: ellipsis;
  }

  .foundation-card-button {
    margin-bottom: 0;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
  }

  .foundation-card-button + .foundation-card-button {
    margin-left: 1rem;
  }

  .foundation-card-info {
    font-size: 0.875rem;
    letter-spacing: 1px;
    opacity: 0.8;
  }

  a{
    color: $RRMain;
    word-break: break-all;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
    width: 75px;
    text-align: center;
  }
}

.cardsContainerFooter {
  bottom: 0;
  padding: 0 20px 0 0;
  border-top: none;
  border-bottom: none;
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 0;

  tfoot{
    background: transparent;
    border: none;

    th{
      padding: 8px 20px 0 0;
    }
  }

  .tr {
    height: 49px;

    th i{
      color: #666;
    }

    th{
      padding: 0;
    }
  }

  .fa-step-backward, .fa-step-forward{
    margin: 0 5px;
  }

  .pagination i{
    cursor: pointer;
  }

  .pagination input{
    width: 50px;
    margin: 0 3px;
    display: inline;
  }

  .showing_count {
    float: right;
  }

  .pagination.left {
    float: left;
  }
}


.flexCardsContainer{
  padding: 0;
  margin: 20px -1vw 25px -1vw;
  list-style: none;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: row wrap;
  justify-content: space-around;

  .flexCard{
    background: $Salt;
    padding: 20px;
    margin-bottom: 25px;
    width: 500px;
    height: 400px;
    color: $HalfSlate;
    line-height: 1rem;
    text-align: center;

    h4.normal{
      font-weight: normal;
    }
  }
}