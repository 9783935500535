.category-rank{
  color: $RRPurple;
  align-items: center;
  .category-rank-icon-wrapper{
    margin-right: 10px;
    border-radius: 50%;
    background: linear-gradient(to right, $Heliotrope, $RoyalBlue);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    .category-rank-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #fff;
      width: 65px;
      height: 65px;
    }
  }
  .category-rank-text{
    .category-link{
      text-decoration: underline;
      text-transform: capitalize;
      &:hover{
        color: $RRPurple;
      }
    }
  }
}