.svg-icon {
  fill: currentColor;
  width:1em;
  height:1em;
  overflow:hidden;
  vertical-align: middle;
  display: inline-flex;
  flex-direction: column;
  &__label {
    padding-left:1rem;
    vertical-align: middle;
    display: inline-flex;
    flex-direction: column;
    &--small {
      font-size: 1.2rem;
    }
    &--medium {
      font-size: 1.4rem;
    }
    &--large {
      font-size: 1.4rem;
    }
    &--xlarge {
      font-size:1.6rem;
    }
    &--display {
      font-size:2.3rem;
    }
  }
  &--disabled {
    fill: $Quartz;
  }
  &--small {
    font-size: rem(12, 10);
  }
  &--medium {
    font-size: rem(15);
  }
  &--large {
    font-size: rem(20);
  }
  &--xlarge {
    font-size:2.4rem;
  }
  &--display {
    font-size:3.4rem;
  }
  &--avatar {
    font-size: 3rem;
    padding-top:0.5rem;
  }
}
