.form_date {
  &__clear {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    cursor: pointer;
    color: $Ash;
    
    &--xlarge {
      bottom: 1.8rem;
    }
    &:hover {
      color: $Slate;
    }
  }
}
