.collapsible{
    margin-top: 10px;
    margin-bottom: 10px;

    .arrowContainer{
        position: relative;
        display: inline-block;
        margin-bottom: 5px;
    }

    .fa, .fal, .far, .fas{
        position: absolute;
        color: #B8B8B8;
        width: 15px;
        height: 15px;
        cursor: pointer;
        margin-top: .5vh;
        top: 50%;
        transform: translate(0, -50%);
    }

    &.arrowLeft{
        .arrowContainer {
            .fa, .fal, .far, .fas {
                margin-top: 0;
                left: 0;
            }
        }
    }

    .iconContainer{
        position: relative;

        i{
            float: right;
            position: inherit;
            top: 11px;
            margin: 0;
        }
    }

    &.arrowRight{
        .arrowContainer{
            h5{
                padding-right: 30px;
            }
        }
        .fa, .fal, .far, .fas{
            right: 0;
        }
    }

    a{
        color: #4AB5E2;
    }

    h4{
        font-size: 15px;
        font-weight: lighter;
        margin-bottom: 14px;
    }

    h5{
        margin: 0;
        display: inline-block;
        padding-left: 20px;
        padding-right: 20px;
    }

    ul{
        margin-left: 30px;
        font-size: 14px;
        font-weight: bold;
    }
}

.collapsibleCheckboxList{
    .fa, .fal, .far, .fas{
        width: 15px;
        height: 15px;
        margin-left: 10px;
        margin-top: 3px;
        font-size: 18px;
        cursor: pointer;

        &.checkbox{
            width: 22px;
            height: 22px;
            font-size: 24px;
            position: absolute;
            margin: 0;
        }
    }

    .reactToolTip{
        margin: 5px 0 0 30px;
        display: block;
    }

    ul{
        float: left;
        margin-right: 30px;
        margin-bottom: 0;
    }

    li{
        clear: both;
        line-height: 14px;
        margin-bottom: 5px;
        overflow: hidden;
        position: relative;
        max-width: 850px;
        min-height: 24px;
        font-size: 0.95rem;

        p{
            margin: 0 0 0 40px;
            width: 95%;
            font-size: 12px;
        }
    }

    h4{
        font-size: 13px;
        margin-bottom: 10px;
        color: #585955;
        float: left;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
    }
}
