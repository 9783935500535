.chip {
  &__control {
    display: block;
    padding: 0 em($form-element-padding-unit, $bodyfont);
    padding-right: 5rem;
    -webkit-appearance: none;
    color: $Jellyfish;
    background: $Salt;
    line-height: 3.5rem;
    width: 100%;
    border-radius: $border-radius;
    border: rem(1) solid $Ash;
    font-size: em($bodyfont);
    height: $inputHeight;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: $Quartz;
    }

    .chip--user & {
      border: none;
      background: $Quartz;
      color: $Obsidian;
    }

    .chip--pill & {
      border: none;
      background: $Quartz;
      color: $Obsidian;
      border-radius: 5rem;
      height: 2.2rem;
      line-height: 2.2rem;
      padding-right: 1rem;
      font-family: $f-app-font-semibold;
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5em;
    height: $inputHeight;
    line-height: $inputHeight;
    text-align: center;
    cursor: pointer;
    background-color: $Salt;
    border: rem(1) solid $Ash;
    border-top-right-radius: rem(3);
    border-bottom-right-radius: rem(3);
    width: 3.5rem;

    &:hover {
      background-color: $Quartz;
    }

    &--disabled {
      opacity: 0.3;
      cursor: initial;
    }

    .chip--user & {
      border: none;
      background: transparent;
      color: $Slate;
      line-height: 3.8rem;

      .cx-icon {
        font-size: 1.6rem;
      }

      &:hover {
        color: $Coal;
      }
    }

    .chip--pill & {
      border: none;
      background: transparent;
      color: $Slate;
      line-height: 2.5rem;
      height: 2.2rem;
      width: 2.5rem;
      right: 0.5rem;
      opacity: 0.8;

      .cx-icon {
        font-size: 1.5rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &--pill {
    border: none;
    display: inline-block;
    padding-right: 0.5rem;
  }

  &--user {
    border: none;

    .avatar__icon {
      bottom: 0.1rem;
    }
  }

  &--rounded {
    .chip__control {
      border-radius: 5rem;
      padding-left: 2rem;
      background: $TenthJellyfish;
      color: $Obsidian;
      border-color: $TenthJellyfish;
    }

    .chip__icon {
      background: transparent;
      border-color: transparent;
    }
  }

  &--dashed {
    .chip__control {
      border: 1px dashed $Jellyfish;
      background: transparent;
    }

    .chip__icon {
      background: transparent;
      border-color: transparent;
    }
  }

  &--disabled {
    .chip__control {
      background: $Quartz;
      color: $Obsidian;
      border-color: $Quartz;
    }
  }
}
