.site-cell{
  background: #fff;
  padding: 1em;
  align-items: center;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
  &.site-cell-nav{
    h4 {
      font-size: 1.2em;
      line-height: 1.2;
      @media screen and (max-width: 63.9375em) {
        line-height: 1;
      }
    }
    .subheader{
      font-size: 1em;
      margin-bottom: 0;
    }
    i, img{
      color: $RRPurple;
    }
  }
  &.site-cell-sales{
    background: linear-gradient(to right,#8e60fc,#6740e8);
    border-radius: 5px;
    p, h1, h2, h3, h4, h5, h6{
      color: white;
    }
    .rr-pink {
      &:hover{
        color: $RRHotPink;
      }
    }
    a.modules_library-site-button, button.modules_library-site-button {
      border: 2px solid #ffffff;
      text-transform: uppercase;
      font-weight: bolder;
    }
  }
  &.clickable{
    cursor: pointer;
    .category-link{
      text-decoration: underline;
      text-transform: capitalize;
      &:hover{
        color: $RRPurple;
      }
    }
    &:hover{
      box-shadow: 0 0 2px 0 rgba(0,0,0,.05);
    }
  }
}