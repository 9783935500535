.panel-head {
  background: $Salt;
  position: relative;
  z-index: 2;
  @include shadow(7, $ShadowColor);
  
  &--stick {
    position: absolute;
    left: 0;
    right: 0;
  }

  &:after {
    display:table;
    clear:both;
    content:'';
  }
  &__description{
    color: $c-app-grey-45;
    font-family: $f-app-font-regular;
  }
  &__edit-icon{ 
    font-size: em(14, $bodyfont); 
    color: $c-app-grey-45;
    margin-right:0.15em;
    display: inline-block;
    width: em(20, $bodyfont);
    text-align: center;
    &--hover{
      color: $Jellyfish;
    }
    &--error {
      color: $Dragonfruit;
    }
  }
  &__title {
    margin: 0;
    padding:.1em 0;
    &__input {
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      line-height: inherit;
      padding-left:1.35em;
      width:100%;
      color: inherit;
    }
    &--editable {
      position: relative;
      & span[contenteditable] {
        border: 1px solid transparent;
        padding: 1px 5px;
        outline: 0;
        border-radius: $border-radius;
        white-space: nowrap;
        display: inline-block;
        &:hover {
          border-color: #ccc;
        }
        &:focus {
          outline: 0;
          border-color: #ccc;
        }
      }
    }
  }
  &__greeting {
    font-family: $f-app-font-light;
  }
  &__username {
    font-family: $f-app-font-regular;
  }
  &__message {
    font-family: $f-app-font-regular;
    color: #d1d4d8;
  }
  &__affixed{
    position: fixed;
    top: 5rem;
    z-index: 6;
    width: 100%;
    padding: 1px 0 0 0; 
    background: $Salt;
    @include shadow(1, $Ash);

    .tabs__iconbar{
      right: 26rem;
    }
    
    .panel-head & {
      @include shadow(1, $ShadowColor);
    }
  }

}

.survey_builder__title {
  text-align: center;
  color: $Obsidian;
  font-size: 2.2em;
  font-family: $f-app-font-semibold;
  margin: 2rem 0;

  &--description{
    margin-bottom: 0;
  }
}
.survey_builder__description {
    text-align: center;
    color: $Slate;
    font-size: 1.3em;
    margin-bottom: 2rem;
}