@mixin buttonColors($background, $stroke, $color) {
  background-color: $background;
  border: rem(1) solid $stroke;
  color: $color;
}

@mixin buttonIcon($x, $y, $fontSize) {
  &.button--icon {
    padding: 0;
    .cx-icon {
      height: rem($y);
      width: rem($x);
      line-height: rem($y);
      font-size: rem($fontSize);
      display: inline-block;
      vertical-align: sub;
    }
    &.button--xlarge{
      padding: rem(3, $bodyfont) rem(20, $bodyfont);
    }
  }
  &--icon-label {
    padding-right: rem(10) !important;
  }
}

.button {
  @include button-gen;

  &--warning {
    background-color: $HalfDragonfruit;
    border: none;
    // height: 3.5rem;
    color: $Salt;
    &:hover {
      background: $Dragonfruit;
      border: none;
      color: $Salt;
    }
  }

  &--warningtertiary {
    @include buttonColors(transparent, transparent, $HalfDragonfruit);
    &:hover {
      @include buttonColors(transparent, transparent, $Dragonfruit);
    }
  }

  &--disabled,
  &--disabled:hover {
    @include buttonColors($Quartz, $Quartz, $Ash);
    cursor: default;
  }

  &--secondary {
    @include buttonColors($Salt, $Ash, $Jellyfish);
    &:hover {
      @include buttonColors($HalfQuartz, $Ash, $Jellyfish);
    }

    &.button--disabled,
    &.button--disabled:hover{
      @include buttonColors($Salt, $Quartz, $Ash);
    }
  }

  &--ellipsis {
    @include buttonColors(transparent, transparent, $Ash);
    &:hover {
      @include buttonColors($HalfQuartz, $Ash, $Slate);
    }

    // &.button--disabled,
    // &.button--disabled:hover{
    //   @include buttonColors($Salt, $Quartz, $Ash);
    // }
  }

  &--tertiary {
    @include buttonColors(transparent, transparent, $Jellyfish);
    &:hover {
      @include buttonColors(transparent, transparent, $Atlantis);
    }
    &.button--disabled,
    &.button--disabled:hover{
      @include buttonColors(transparent, transparent, $Ash);
    }
  }

  &--quaternary {
    @include buttonColors($Salt, $Ash, $c-app-black);

    &:hover {
      @include buttonColors($HalfQuartz, $Ash, $Jellyfish);
    }

    &.button--disabled,
    &.button--disabled:hover{
      @include buttonColors($Salt, $Quartz, $Ash);
    }
  }

  &--transparent {
    @include buttonColors(transparent, transparent, $Coal);
    padding:0;
    margin:0;

    &:hover {
      @include buttonColors(transparent, transparent, $Coal);
    }

    &--disabled {
      @include buttonColors(transparent, transparent, $Ash);

      &:hover {
        @include buttonColors(transparent, transparent, $Ash);
      }
    }
  }

  &--inverse {
    @include buttonColors($Slate, $Slate, $Salt);

    &:hover {
      @include buttonColors($Jellyfish, $Jellyfish, $Salt);
    }

    &.button--disabled,
    &.button--disabled:hover{
      @include buttonColors($HalfSlate, $HalfSlate, $Ash);
      border: none;
      height: 3.5rem;
    }
  }

  &--inverse-tertiary {
    @include buttonColors(transparent, transparent, $Salt);
    &:hover {
      @include buttonColors(transparent, transparent, $Quartz);
    }
    &.button--disabled,
    &.button--disabled:hover{
      @include buttonColors(transparent, transparent, $Ash);
    }
  }

  &--saved {
    background-color: $Kiwi !important;
    border: rem(1) solid $Kiwi !important;
    color: $Salt !important;
  }
  &--grey {
    @include buttonColors(transparent, transparent, $Ash);
    padding:0;
    margin:0;
    &:hover {
      @include buttonColors(transparent, transparent, $Ash);
    }
    &--disabled {
      @include buttonColors(transparent, transparent, $Ash);
      &:hover {
        @include buttonColors(transparent, transparent, $Ash);
      }
    }
  }

  &--back {
    margin: 1rem 2rem;
    background:transparent;
    border:none;
    color:$Coal;
    font-size:1.2rem;
    font-family: $f-app-font-light;
    text-transform:uppercase;
    &:before {
      color: $Quartz;
      font-size:1rem;
      border: rem(1) solid $Quartz;
      border-radius:50%;
      padding:0.5rem;
      margin-right:1rem;
    }
    &:hover {
      background:transparent;
      border:none;
      color:#000;
      &:before {
        color:$Ash;
        border-color:$Ash;
      }
    }
  }

  @include buttonIcon(33, 33, 18);

  // SIZES
  &--xlarge {
    width: 100%;
    padding: rem(14, $bodyfont) rem(35, $bodyfont);
    @include buttonIcon(38, 38, 20);
  }
  &--large {
    padding: rem(8, $bodyfont) rem(25, $bodyfont);
    @include buttonIcon(38, 38, 20);
  }
  &--medium {
    padding: rem(6, $bodyfont) rem(15, $bodyfont);
    font-size: rem(12);
  }
  &--small {
    padding: rem(4, $bodyfont) rem(10, $bodyfont);
    font-size: rem(12);
    @include buttonIcon(28, 28, 16);
  }
  &--wide {
    width: 100%;
  }

  &--dashed {
    border-style: dashed;
  }
}
