.hierarchyContainer{
  .f-no-select {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .m-tree {
    position: relative;
    overflow: hidden;
    padding-bottom: 150px;
  }

  .m-draggable {
    position: absolute;
    opacity: 0.8;
  }

  .m-node {
    &.placeholder > * {
      visibility: hidden;
    }

    &.placeholder {
      border: 1px dashed #ccc;
    }

    .inner {
      position: relative;
      cursor: pointer;
      padding-left: 1.0rem;
    }

    .collapse {
      position: absolute;
      left: 0;
      cursor: pointer;
      top: 3px;
    }

    .caret-right:before {
      content: '\25B8';
    }

    .caret-down:before {
      content: '\25BE';
    }
  }

  .tree {
    overflow-x: hidden;
    overflow-y: auto;
    color: $Obsidian;
    height: calc(100% - 150px);
    margin-left: 75px;
    margin-right: 75px;
  }

  .m-node {
    .dropdown .dropdown_toggle{
      padding-top: 0;
      padding-bottom: 0;

      .fa, .fal, .far, .fas{
        top: 2px;
      }
    }


    &.placeholder {
      border: 1px dashed #1385e5;
    }

    .inner {
      color: $Obsidian;
      border: solid 2px white;
      font-size: 1.2rem;
    }

    .node {
      display: inline-block;
      width: 100%;
      padding: 4px 5px;

      &.headquarters{
        background-color: $HalfSlate;
        color: $Salt;
        margin-bottom: 10px;
      }

      &.region, &.branch, &.district, &.grouping, &.division, &.territory, &.zone{
        background-color: $Ash;
        color: $Salt;
      }

      .fa-times, .fa-pencil{
        margin-left: 20px;
        font-size: 1.6rem;
        line-height: 1.6rem;

        &:hover{
          color: $Emerald;
        }
      }

      &.is-active {
        background-color: $RRMain;
        color: $Salt;
      }

      &.seller-inactive {
        color: $velvet;

        &.is-active{
          color: $Salt;
        }
      }
    }
  }
}

