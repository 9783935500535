.questionMode {
  .statusCard{
    margin-top: 0;
  }
}

.goBackLink {
  color: rgb(91,56,229);
  font-size: 1.2rem;
  font-weight: 700;

  &:hover{
    text-decoration: underline;
  }
}

.statusCard{
  min-height: 12em;
  background: white;
  position: relative;
  padding: 5px 20px 0;
  overflow: auto;
  margin-bottom: 1em;

  .avatar{
    display: block;
  }

  .inlineArrow{
    display: inline;
    margin-left: 20px;
    color: white;
    font-size: 1.3rem;
  }

  a{
    i{
      font-size: 1rem;
    }
  }

  div{
    display: inline;
    i{
      font-size: 1rem;
    }
  }

  h4{
    font-weight: bold;
    margin-top: .5em;
    margin-bottom: .3em;
  }

  .authorAlign{
    display: block;
    position: relative;
    text-align: center;
  }

  .author{
    color: rgb(208,0,226);

    font-size: 1.15rem;
  }

  h4{
    font-weight: bold;
    margin-top: .5em;
    margin-bottom: .3em;
  }

  .questionLabel{
    font-size: 1.5rem;
    font-weight: bold;
    margin: .5em 0;
  }

  .authorAlign{
    display: block;
    position: relative;
    text-align: center;
  }

  .author{
    font-size: 1.3rem;
    font-weight: 500;

    &:hover{
      text-decoration: underline;
    }
  }

  .highlyVoted{
    margin-top: 10px;

    i{
      font-size: 1.3rem;
      width: 100%;
    }

    h4{
      font-size: 1.7vh;
    }
  }

  .status{
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15px;
    height: calc(100% - 10px);

    &.published{
      background: $Published
    }

    &.rejected{
      background: $Rejected;
    }

    &.pending, &.unpublished{
      background: $Pending;
    }
  }

  .statusField{
    display: block;
    font-size: 1.1em;
    font-weight: 700;
    margin: 2rem 0 0;
  }

  .statusLabel{
    padding: .5rem 3rem .9rem;
    color: white;
    font-weight: 500;
    border-radius: 1.4rem;

    &.published{
      background-color: $Published;
    }

    &.rejected{
      background-color: $Rejected;
    }

    &.pending{
      background-color: $Pending;
    }
  }

  .votes{
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: bold;
  }

  .questionAnswerText{
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    color: #58595b;
    min-height: 40px;
  }

  .separator{
    position: absolute;
    display: block;
    height: 2px;
    width: calc(100% - 15px);
    right: 0;
    bottom: 0;
    background: rgb(200,200,200);
  }

  .questionAnswerText{
    font-size: 1.1em;
    font-weight: 700;
    color: #58595b;
  }

  .rejected{
    color: rgb(180,180,180);
    font-weight: 300;
    font-size: 100%;
  }

  .date{
    padding-right: 0;
    text-align: right;
    font-size: 1em;
    font-weight: 700;
    padding-top: 10px;
  }

  .dashboard{
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: rgb(208,0,226);
  }

  .dashboardLink{
    font-size: 1.2rem;
    font-weight: 600;

    &:hover{
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .commonLink{
    color: rgb(208,0,226);
  }

  .rejectedLink{
    color:#DC143C;
  }

  .unpublishedLink{
    background: rgb(208,0,226);
    padding: .8em;
    color: white;
    border-radius: 5px;
  }

}
