.rating {
  color: $Slate;
  
  &--disabled {
    color: $Ash;
    
    .rating__block-container {
      border: rem(1) solid $Quartz;
    }
    
    .rating__block {
      display: inline-block;
      border-left: rem(1) solid $Quartz;
      text-align: center;
      padding: .5rem 1rem;
      pointer-events: none;
      
      &:first-child {
        border-left: none;
      }
      
      &--fill {
        color: $Salt;
      }
      
      &--active {
        color: $Salt;
      }
    }
  }
  
  &__container {
    display: inline-block;
  }
  
  &__block-container {
    border: rem(1) solid $Ash;
  }
  
  &__label-block {
    display: inline-block;
    text-align: center;
  }

  &__block {
    display: inline-block;
    border-left: rem(1) solid $Ash;
    text-align: center;
    padding: .5rem 1rem;
    
    &:first-child {
      border-left: none;
    }
    
    &:hover {
      cursor: pointer;
      color: $Salt;
      background-color: $Jellyfish;
    }
    
    &--fill {
      background-color: lighten($Jellyfish, 2);
      border-left: rem(1) solid $Salt;
      color: $Salt;
    }
    
    
    &--active {
      background-color: $Jellyfish;
      border-left: rem(1) solid $Salt;
      color: $Salt;
      
      + .rating__block {
        border-left: rem(1) solid $Salt;
      }
    }
  }
}