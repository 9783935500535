.site-scrollable {
  position: relative;
  overflow: hidden;
  .button-link {
    position: absolute;
    top: 0;
    border-radius: 1px;
    &.left-button-link {
      left: -4px;
    }
    &.right-button-link {
      right: -4px;
    }
  }
  .button-wrapper {
    align-items: center;
    background: $HalfSlate;
    display: flex;
    justify-content: center;
    height: 100px;
    width: 40px;
    &.left-button-wrapper {}
    &.right-button-wrapper {}  
  }  
}
