.code-block {
  width: 100%;
  height: 17rem;
  position: relative;
  border: rem(1) solid $Ash;
  border-radius: rem(3);
  
  &__editor {
    width:100%;
    position:absolute;
    height:100%;
  }
}