.trigger {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  position: relative;
  
  border: rem(1) solid $Ash;
  border-radius: $border-radius;
  padding: 0rem 2rem 0 1rem;
  background-color: $Salt;
  color: $Obsidian;
  width: 100%;
  height: 3.5rem;
  line-height: 3.5rem;
  
  .text {
    color: $Obsidian;
  }
  
  &__caret {
    &-text {    
      position: absolute;
      right: 1rem;
    }
    
    vertical-align: text-bottom;
    margin-left: 1rem;
    font-size: 1rem;
  }
  
  &--open {
    background-color: $Slate;
    border-color: $Slate;
    color: $Salt;
    
    .text {
      color: $Salt;
    }
  }
  
  &--disabled {
    cursor: default;
    pointer-events: none;
    opacity: .7;
    position: relative;
    color: $Quartz;
  }

  &--error {
    border: rem(1) solid $Dragonfruit;
  }
}