.flyout {
  color: $Obsidian;
  line-height: $base-lineheight;

  &--animate {
    @include animation(popover_body $duration $curve forwards);
    @include transformOrigin(0 0);
  }

  &--inverse {
    color: $Salt;
  }

  &__container {
    border-radius: $border-radius;
    background: $Salt;
    padding: 1rem;
    z-index: 2147483645;
    @include shadow(1);

    &--inverse {
      background: $Obsidian;
      padding: 0.5rem 1rem;
    }

    &--flat {
      border-radius: 0;
    }

    &--no-padding {
      padding: 0;
    }
  }

  &__caret {
    &:before{
        width: 1rem;
        height: 1rem;
        position: absolute;
        content: '';
        background-color: $Salt;
        box-shadow: rem(-2) rem(2) rem(2) rem(-1) $Ash;

        .flyout--attachment-top.flyout--axis-vertical & {
          bottom: rem(1);
          margin-bottom: -.5rem;
          @include transform(rotate(315deg));
        }
        .flyout--attachment-top.flyout--axis-horizontal & {
          top: rem(10);
        }

        .flyout--attachment-bottom.flyout--axis-vertical & {
          top: rem(1);
          margin-top: -.5rem;
          box-shadow: rem(-1) rem(1) rem(1) rem(-1) $Ash;
          @include transform(rotate(135deg));
        }
        .flyout--attachment-bottom.flyout--axis-horizontal & {
          bottom: rem(10);
        }

        .flyout--attachment-right.flyout--axis-vertical & {
          right: rem(10);
        }
        .flyout--attachment-right.flyout--axis-horizontal & {
          left: 0;
          margin-left: -.35rem;
          @include transform(rotate(45deg));
        }

        .flyout--attachment-left.flyout--axis-vertical & {
          left: rem(10);
        }
        .flyout--attachment-left.flyout--axis-horizontal & {
          right: 0;
          margin-right: -.35rem;
          @include transform(rotate(225deg));
        }

        .flyout--attachment-center & {
          left: 50%;
          margin-left: -.5rem;
        }

        .flyout--attachment-middle & {
          top: 50%;
          margin-top: -.5rem;
        }
    }

    &--inverse:before{
      background: $Obsidian;
      box-shadow: none;
    }
  }
}
