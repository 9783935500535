@import "../colors";
@import "../variables";

.Notification {
	color: $Salt;
	&.card {
		background-color: $Whale;
	}
	.message {
		font-family: $font-family-2;
		&:before {
	        content: "\f0a1";
	        font-family: 'Font Awesome 5 Pro';
	        padding-right: 8px;
	        font-size: 1.2em;
		}
		a {
			color: $LightWhale;
			font-weight: 700;
			text-decoration: underline;
		}
		b {
			font-weight: 700;
		}
	}
}