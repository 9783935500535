.flexCardsContainer{
  height: calc(100vh - 300px);
  overflow-y: scroll;

  .flexCard{
    width: calc(44vw - 93px);
    min-width: 500px;
    min-height: 300px;
    height: 50vh;
  }

  .summaryChart{
    height: 35vh;
  }

  .reviewShowCase{
    padding: 0;
  }

  &.autoHeight{
    height: auto;

    .flexCard{
      height: auto;
    }
  }
}

