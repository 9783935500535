.drag-and-drop {
  &__draggable {
    cursor:move;
  }
  
  &__dragging {
    opacity: 0.7;
  }

  &__drop-zone {
    background-color: transparent;
    border: rem(2) dashed $Ash;
    min-height: rem(50);
    margin-bottom: 1em;

    * {
      pointer-events: none;
    }

    &__hover {
      background: $Salt;
      min-height: rem(46);
      opacity: 0.5;
    }

    &__hide {
      display: none;
    }
  }
}