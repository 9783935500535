.product-card {
  border-radius: $border-radius;
  margin-bottom: 1rem;
  background: $Salt;

  @include transition(all, 250ms);
  @include shadow(1);
  width: rem(195);
  height: rem(195);
  position: relative;
  float: left;
  
  &--active {
    
  }
  
  &--inactive {
    background: $HalfQuartz;
  }
  
  &__checkbox {
    position: absolute;
    top: rem(10);
    left: rem(10);
    &--checked {
      color: $Kiwi;
    }
    
    &--unchecked {
      background: $Quartz;
      width: rem(18);
      height: rem(18);
      border-radius: rem(18);
    }
  }
  
  &__content {
      height: rem(155);
      position: relative;
      cursor: pointer;
  }
  
  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  &__details {
    border-top: rem(1) solid $Ash;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $Salt;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    @include transition(all, 300ms);
  }

  &__tools {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: right;
      margin-top: 0.5rem;
      opacity: 1;
  }

  &__name div:first-child {
    width: rem(110);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    padding: rem(9) rem(10);
  }
}
