.gauge {
  &score {
    position: relative;
    top: -25.5rem;
    display: inline-block;
    &__integer.text {
      font-size: 86px;
    }
  }
}
