.ui-icon {
  &.active {color:$Jellyfish}
	background:none;
	font-size: em(14,10);
  &--inverse {color:$c-app-white;}
  
	&--button {
		height:3rem;
		width:3rem;
		text-align: center;
		&-reorder {
			height:3rem;
		}
	}
  &--small {
    font-size: em(12, 10);
    line-height: 1.5;
  }
  &--large {
    font-size: em(17, 10);
    line-height: 1;
  }
  &--settings:after {
    @extend .fa;
    content: $fa-var-pencil;
  }
  &--chart:after {
    @extend .fa;
    content: $fa-var-chart-pie;
  }
  &--copy:after {
    @extend .fa;
    content: $fa-var-copy;
  }
  &--trash:after {
    @extend .fa;
    content: $fa-var-trash-alt;
  }
  &--play:after {
    @extend .fa;
    content: $fa-var-play-circle;
  }
  &--video-camera:after {
    @extend .fa;
    content: $fa-var-video;
  }
  &--toggle-on:after {
    @extend .fa;
    font-size: em(12, 10);
    content: $fa-var-toggle-on;
    color: $Jellyfish;
  }
  &--toggle-off:after {
    @extend .fa;
    font-size: em(12, 10);
    content: $fa-var-toggle-on;
    color: $Ash;
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  &--add:after {
    @extend .fa;
    font-size: em(16, $bodyfont);
    content: $fa-var-plus;
  }
  &--up:after {
    @extend .fa;
    font-size: em(12, 10);
    content: $fa-var-arrow-up;
  }
  &--sort-up:after {
    @extend .fa;
    font-size: em(12, 10);
    content: $fa-var-chevron-up;
  }
  &--down:after {
    @extend .fa;
    font-size: em(12, 10);
    content: $fa-var-arrow-down;
  }
  &--sort-down:after {
    @extend .fa;
    font-size: em(12, 10);
    content: $fa-var-chevron-down;
  }
  &--remove:after {
    @extend .fa;
    font-size: em(14, 10);
    content: $fa-var-minus;
    color: $c-app-red;
  }
  &--desktop:before {
    @extend .fa;
    content: $fa-var-desktop;
  }
  &--tablet:before {
    @extend .fa;
    content: $fa-var-tablet;
  }
  &--mobile:before {
    @extend .fa;
    content: $fa-var-mobile;
  }
  &--refresh:before {
    @extend .fa;
    content: $fa-var-sync-alt;
  }
  &--close:before {
    @extend .fa;
    content: $fa-var-times;
  }
  &--info:before {
    @extend .fa;
    content: $fa-var-info;
  }
  &--info-circle:before {
    @extend .fa;
    content: $fa-var-info-circle;
  }
  &--feedback:before {
    @extend .fa;
    content: $fa-var-comment;
  }
  &--check:before {
    @extend .fa;
    font-size: em(16, $bodyfont);
    content: $fa-var-check;
    position: relative;
    left: rem(1);
    top: rem(-2);
  }
  &--exclamation:before {
    @extend .fa;
    font-size: em(16, $bodyfont);
    content: $fa-var-exclamation-triangle;
  }
  &--pin:before {
    @extend .fa;
    content: $fa-var-thumbtack;
  }
  &--send:before {
    @extend .fa;
    content: $fa-var-paper-plane;
  }
  &--collapse:before {
    @extend .fa;
    content: $fa-var-chevron-down;
  }
  &--smile:before {
    @extend .fa;
    content: $fa-var-smile;
  }
  &--frown:before {
    @extend .fa;
    content: $fa-var-frown;
  }
  &--download:before {
    @extend .fa;
    content: $fa-var-download;
  }
  &--circle:before {
    @extend .fa;
    content: $fa-var-circle;
  }
  &--square:before {
    @extend .fa;
    content: $fa-var-square;
  }
  &--dropdown:before {
    @extend .fa;
    content: $fa-var-caret-down;
  }
  &--caret-left:before {
    @extend .fa;
    content: $fa-var-caret-left;
  }
  &--caret-right:before {
    @extend .fa;
    content: $fa-var-caret-right;
  }
  &--reorder:before {
    @extend .fa;
    content: $fa-var-bars;
  }
  &--filter:before {
    @extend .fa;
    content: $fa-var-filter;
  }
   &--times:before {
    @extend .fa;
    content: $fa-var-times;
  }
  &--star:before {
    @extend .fa;
    content: $fa-var-star;
  }
  &--back:before {
    @extend .fa;
    content: $fa-var-arrow-left;
  }
  /* WYSIWYG */
  &--link:before {
    @extend .fa;
    content: $fa-var-link;
  }
  &--bold:before {
		@extend .fa;
    content: $fa-var-bold;
  }
  &--italic:before {
  	@extend .fa;
    content: $fa-var-italic;
  }
  &--underline:before {
  	@extend .fa;
    content: $fa-var-underline;
  }
  &--strikethrough:before {
  	@extend .fa;
    content: $fa-var-strikethrough;
  }
  &--undo:before {
  	@extend .fa;
    content: $fa-var-undo;
  }
  &--align-l:before {
  	@extend .fa;
    content: $fa-var-align-left;
  }
  &--align-r:before {
  	@extend .fa;
    content: $fa-var-align-right;
  }
  &--align-c:before {
  	@extend .fa;
    content: $fa-var-align-center;
  }
  &--list-ol:before {
  	@extend .fa;
    content: $fa-var-list-ol;
  }
  &--list-ul:before {
    @extend .fa;
    content: $fa-var-list-ul;
  }
  &--warning:before {
    @extend .fa;
    content: $fa-var-exclamation-triangle;
  }
  &--cube:before {
    @extend .fa;
    content: $fa-var-cube;
  }
  &--envelope:before {
    @extend .fa;
    content: $fa-var-envelope;
  }
  &--angle-double-left:before {
    @extend .fa;
    content: $fa-var-angle-double-left;
  }
  &--angle-double-right:before {
    @extend .fa;
    content: $fa-var-angle-double-right;
  }
  &--angle-left:before {
    @extend .fa;
    content: $fa-var-angle-left;
  }
  &--angle-right:before {
    @extend .fa;
    content: $fa-var-angle-right;
  }
  &--loading:before {
    @extend .fa;
    content: $fa-var-spinner;
  }
  &--expand:before {
    @extend .fa;
    content: $fa-var-caret-down;
  }
  // REMOVE CLOUD AND TREE ONCE TA ICONS ARE AVAILIBLE
  &--cloud:before {
    @extend .fa;
    content: $fa-var-cloud;
  }
  &--tree:before {
    @extend .fa;
    content: $fa-var-tree;
  }
}