.popover {
  position: relative;

  &--disabled {
    cursor: default;
    pointer-events: none;
    opacity: .3;
    position: relative;
  }

  &--readOnly {
    cursor: default;
    pointer-events: none;
    position: relative;
  }

  &--open {
     z-index: 100;
    .popover__label {
      @include transform(translate3d(0, rem(30),0));
      opacity: 0;

      &--reverse {
        @include transform(translate3d(0, -rem(30),0));
        opacity: 0;
      }
    }

    .popover__content {
      @include animation(popover_body $duration $curve forwards);
      @include transformOrigin(0 0);
    }
    .popover__trigger {
      overflow: hidden;
    }
  }

  &--closed {
    .popover__content{
      @include transform(scaleY(0));
      opacity: 0;
    }
  }

  &__label {
    cursor: pointer;
    @include transition(-webkit-transform, $duration, $curve);
    @include transition(transform, $duration, $curve);
    @include transform(translate3d(0,0,0));
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 2rem;

    &:hover {
      border-color: $PinkAction;
      @include transition(border-color, $duration, $curve);
    }

    &--caret:after{
      position: absolute;
  	  content: "\f078";
      height: rem(10);
      line-height: rem(10);
      top: 50%;
      margin-top: rem(-5);
      right: 1rem;
      padding-left: rem(5);
    }
  }

  &__content {
    position:absolute;
    top: 0;
    min-width: 100%;
    z-index: 2147483649;
    background: $Salt;
    @include shadow(2, rgba(98,112,133, 0.25));
  }

  &--reverse {
    .popover__content{
      top: auto;
      bottom: 0;
    }
  }

  &--right {
    .popover__content{
      right: 0;
    }
  }
}

@-webkit-keyframes popover_body {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 0
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1
  }
}

@keyframes popover_body {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 0
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1
  }
}
