.progress {
  height: rem(4);
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: $Quartz;
  
  &:before {
    display: block;
    position: absolute;
    content: "";
    left: rem(-200);
    width: rem(200);
    height: rem(4);
    background-color: $Kiwi;
    animation: loading 2s linear infinite;
  }
}

@keyframes loading {
    from {left: rem(-200); width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}