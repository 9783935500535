.settings {
  &__header {
    padding: rem(20) rem(10);
    
    &--with-button {
      padding: rem(13) rem(20);
      padding-right: 0;
      
      .settings__header-text {
        line-height: rem(37);
      }
      
      .button--icon {
        margin-right: rem(10);
      }
    }
  }
  
  &_modal{
    background: $Salt;
    border-radius: rem(3);

    &__header {
      padding: rem(20);
      font-size: rem(20);
    }
    
    &__gear {
      color: $Ash;
      margin-right: rem(20);
      vertical-align: middle;
      line-height: rem(27);
      font-size: rem(30);
    }
    
    &__body {
      padding: rem(20);
      overflow: auto;      
    }
    
    &__footer {
      padding: rem(20);
    }
  }
}

