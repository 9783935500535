.modules_library-site-search-bar-filter {
  background-color: $SeaSalt;
  display: flex;
  font-size: .85em;
  padding: 14px;
  position: relative;
  z-index: 1;
  .modules_library-site-icon {
    margin-left: 8px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
}
