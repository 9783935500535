.dialog {
  background: $Salt;
  text-align: center;
  padding: rem(20);
  border-radius: rem(3);

  &--small {
    word-wrap: break-word;
    background: $Salt;
    padding: 2rem;
    border-radius: .3rem;
  }
}
