
html body h1, html body h2, html body h3, html body h4, html body h5, html body h6, html h1, html h2, html h3, html h4, html h5, html h6{
  font-family: Merriweather, sans-serif;
}

body {
  font-family: Lato, sans-serif;
}

body h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

body h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

body h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

body h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

body h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

body h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

body p, .p{
  font-family: "Lato", sans-serif;
  font-size: 1rem; }

.header{
  font-size: 2rem;
}

.boldest{
  font-weight: 900;
}

@media print, screen and (min-width: 1301px) {
  .header{
    font-size: 3rem;
  }
  .header-subtext{
    font-size: 1.8rem;
    font-weight: 400;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 1023px) {
  .header{
    font-size: 2.5rem;
  }
  .header-subtext{
    font-size: 1.7rem;
    font-weight: 400;
  }
}

@media print, screen and (max-width: 599px) {
  .header{
    font-size: 2rem;
  }
  .header-subtext{
    font-size: 1.2rem;
    font-weight: 400;
  }
}

@media print, screen and (min-width: 40em) {
  body h1, .h1 {
    font-size: 2rem; }
  body h2, .h2 {
    font-size: 1.5rem; }
  body h3, .h3 {
    font-size: 1.35rem; }
  body h4, .h4 {
    font-size: 1.25rem; }
  body h5, .h5 {
    font-size: 1.125rem; }
  body h6, .h6 {
    font-size: 1.0625rem; }
  body p, .p{
    font-size: 1.0625rem; }
}