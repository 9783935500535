.apps-site-containers-user{
	background: #ECECEC;
	.tabs{
		background: #ECECEC;
	}
	.tabs__tabitem{
		display: inline-block;
		padding-right: 4em;
		color: #639;
	}
	.tabs__tabitem:hover{
		cursor: pointer;
		color: #6740E8;
	}
	.tabs__tabitem--selected{
		text-decoration: underline;
		color: #6740E8;

	}
	.discover_content{
		.review.store-review{
			background: white;
			padding: 35px;
			border-radius: 5px;
		}
		.user-wrapper{
			height: 100%;
			background: white;
		}
	}
}
#user_view {
	.subheader {
		color: #1f2532;
	}
	section.discover_content:before {
		display: none;
	}
	.avatar{
		border-radius: 50px;
	}
	.user-info{
		align-items: center;
		padding: 30px;
	}
	.biography{
		white-space: pre-line;
	}
	.no-reviews{
		display: flex;
		height: 100%;
	}
}