.stepper {
  display: flex;
  align-items: center;
  justify-content: center;

  &__panel {
    border-bottom: solid 0.1rem rgba(183, 192, 204, 0.62)
  }

  &__right-node {
    position: absolute;
    right: 0;
    margin-right: 2rem;
  }

  &__children {
    padding-top: 9.2rem;
    margin-bottom: 6.5rem;
  }

  &__step {
    flex: 0 0 auto;

    &_details {
      height: rem(65);
      display: flex;
      align-items: center;
      padding-left: $gutter/2;
      padding-right: $gutter/2;
      text-align: center;
    }

    &--clickable {
      cursor: pointer;
      &:hover {
        .stepper__icon {
          background: $Slate;
        }
      }
    }
  }

  &__spacer {
    flex: 1 1 auto;
    max-width: 8rem;
  }

  &__loading {
    display: inline-block;

    &.cx-icon--loader {
      line-height: 1.5rem !important;
    }
  }

  &__check {
    display: inline-block;
    transform: scale(0.5);
    @include transition();
    &--active {
      transform: scale(1);
    }
  }

  &__icon {
    display: inline-block;
    width: rem(24);
    height: rem(24);
    border-radius: rem(24);
    line-height: rem(24);
    background: $Ash;
    color: $Salt;
    font-size: rem(14);
    @include transition();

    &--active, &--past {
      background: $Jellyfish !important;
    }

    &--error {
      background: $Dragonfruit !important;
    }

    &--warning {
      background: $Tangerine !important;
    }

    .cx-icon {
      line-height: rem(24);
    }
  }

  &__number {
    display: inherit;
  }

  &__line-container {
    position:relative;
    height: rem(1);
  }

  &__line {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    border-top: rem(1) solid $Quartz;
    position: absolute;
    width: 100%;
    height: rem(1);
    margin: 0;

    &_animate {
      transform: scale(0);
      transform-origin: 0 0;
      &--active {
        transform: scale(1);
        border-color: $Jellyfish;
      }
    }
  }

  &__footer {
    height: 7.2rem;
    background: $Salt;
    box-shadow: 0 -0.2rem 0.4rem 0 rgba(58, 71, 91, 0.07);
    border-top: 0.1rem solid rgba(183, 192, 204, 0.62);

    &--container {
      max-width: 125.6rem;
      margin: 0 auto;
    }

    &--button {
      margin: 2rem 0;
    }
    &--button-container {
      padding-left: 0;
    }
    &--prev {
      float: left;
    }
    &--next {
      float: right;
    }
    &--finish {
      float: right;
    }
  }
}
