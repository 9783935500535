.site-store-store{
  .store-link{
    font-weight:500;
  }
  .store-thumb{
    position: relative;
    padding-top: 10px;
    .closeButton{
      position:absolute;
      cursor: pointer;
      color: white;
      background-color: $RRPurple;
      border-radius: 50%;
      top: 0.75em;
      right: 0.8em;
    }
  }
  .store-label {
    .siteStars{
      min-height: 60px;
    }
    .fancyStars span {
      margin: 0;
    }
  }
  .rating-count{
    font-size: 1.5em;
  } 
  .rr-purple {
    color: $RRPurple;
    &.store-link:hover {
      color: rebeccapurple;
    }
    &.store-title{
      font-size: 1.2em;
    }
    &.button{
      background: $RRPurple;
      color: white;
    }
    &.button:hover{
      color: white;
      background: rebeccapurple;
    }
  }
}
.underline{
  text-decoration: underline;
}
.apps-site-containers-store{
  .comparison-button{
    border-radius: 20px;
  }
}

//Styles that are required for the working of the tooltip
$curve: cubic-bezier(.23,1,.32,1);
$duration: 400ms;
$Obsidian: #222B3C;
$base-lineheight: 1.618;
$Salt: #FFFFFF;
$border-radius: rem(3) !default;
$Ash: #B7C0CC;
$HalfAsh: rgba(183,192,204, 0.5);

@import "../../../cx-component-library/core/_mixins.scss";
@import "../../../cx-component-library/components/_flyout.scss";

.rel{
  position: relative;
}
.fixed{
  position: fixed;
}
.rate-info-icon{
  position: absolute;
  top: 15%;
  right: 5%;
}
