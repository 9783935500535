@import "avatar";
@import "site_content";
@import "site_menu";
@import "side_bar_menu";
@import "button";
@import "collapsible_list";
@import "dropdown";
@import "fancy_stars";
@import "circle_stars";
@import "input";
@import "panel";
@import "pre_loader";
@import "range_slider";
@import "react_tooltip";
@import "react_data_table";
@import "summary_card";
@import "react_tag_list";
@import "review_show_case";
@import "review_request";
@import "site_nav";
@import "review_reply";
@import "simple_table";
@import "speech_bubble";
@import "help_robot";
@import "hierarchy";
@import "map_container";
@import "flex_cards_container";
@import "flex_cards_graph";
@import "social_share_buttons";
@import "c3";
@import "manage";
@import "status_card";
@import "notification";
@import "categories";
@import "dropdown_tree_select";
@import "store_picker";
@import "top_nav";
@import "comparison";
@import "paid_features";
@import "subscription_summary";
@import "react_table";
@import "progress_tracker";
@import "photo_grid";
@import 'site/button.scss';
@import 'widget_preview';
@import 'soqa_preview';
@import 'soqa_form_preview';
@import "onboard_cards";
@import "review_item_showcase";
@import "sales/upsell_alert";
@import "sales/paid_features";

.__react_component_tooltip.sales-tooltip{
    h1,h2,h3,h4,h5{
        &.txt--Obsidian{
            color: white;
        }
        color: white !important;
    }

    .text{
        font-family: Jost, "ProximaNova-Regular",Helvetica,Arial,sans-serif !important;
        line-height: 1.618 !important;
    }
    &:before{
        z-index: 999;
    }
    &.place-right::before{
        border-right: 8px solid #3B2B71 !important;
    }
    &.place-bottom::before{
        border-bottom: 8px solid #3B2B71 !important;
    }
    &.place-left::before{
        border-left: 8px solid #3B2B71 !important;
    }
    &.place-top::before{
        border-top: 8px solid #3B2B71 !important;
    }

    background: #3B2B71;
    color: white !important;
    opacity: 1 !important;
    font-weight: bold;
    border-radius: 1rem;
    border: 1px solid #3B2B71 !important;
}

[data-for="your-plan"]{
    .__react_component_tooltip.sales-tooltip {
        width: 600px;
        background: #3B2B71;
        border: 1px solid #3B2B71 !important;
        &.place-right::before{
            border-right: 8px solid #3B2B71 !important;
        }
        &.place-bottom::before{
            border-bottom: 8px solid #3B2B71 !important;
        }
        &.place-left::before{
            border-left: 8px solid #3B2B71 !important;
        }
        &.place-top::before{
            border-top: 8px solid #3B2B71 !important;
        }
    }
}


.reactSelect{
    &.dark{
        .has-value.Select--single>.Select-control .Select-value .Select-value-label{
            color: $Salt;
        }

        .Select-control{
            color: $Salt;
            border-color: $Slate;
            background-color: $Slate;
            border-radius: $border-radius;

            .Select-placeholder{
                color: $Salt;
            }

            .Select-arrow {
                border-color: $Salt transparent transparent;
            }

            &:hover,  &:focus {
                border-color: $Ash;
            }
        }

        .Select-noresults {
            color: $Salt;
        }

        .Select-menu-outer{
            border-radius: $border-radius;
            border-color: $Ash;
            background-color: $Slate;
            color: $Salt;
        }

        .Select-option {
            color: $Salt;
            background-color: $Slate;

            &.is-selected {
                background-color: $Ash;
            }

            &.is-focused {
                background-color: $Ash;
            }
        }
    }
}

.reactSeparator{
    margin-bottom: 12px;
}

.distribution-chart{
    .fancyStars span{
        margin: 2px;
    }
}

.reactSelect{
    box-sizing: border-box;

    &.dark{
        .has-value.Select--single>.Select-control .Select-value .Select-value-label{
            color: $Salt;
        }

        .Select-control{
            color: $Salt;
            border-color: $Slate;
            background-color: $Slate;
            border-radius: $border-radius;

            .Select-placeholder{
                color: $Salt;
            }

            .Select-arrow {
                border-color: $Salt transparent transparent;
            }

            &:hover,  &:focus {
                border-color: $Ash;
            }
        }

        .Select-noresults {
            color: $Salt;
        }

        .Select-menu-outer{
            border-radius: $border-radius;
            border-color: $Ash;
            background-color: $Slate;
            color: $Salt;
        }

        .Select-option {
            color: $Salt;
            background-color: $Slate;

            &.is-selected {
                background-color: $Ash;
            }

            &.is-focused {
                background-color: $Ash;
            }
        }
    }

    &.light{
        .has-value.Select--single>.Select-control .Select-value .Select-value-label{
            color: $DarkSlate;
        }

        .Select-control{
            color: $DarkSlate;
            border-color: #cacaca;
            background-color: $Salt;
            border-radius: $border-radius;

            .Select-placeholder{
                color: $DarkSlate;
            }

            .Select-arrow {
                border-color: $DarkSlate transparent transparent;
            }

            &:hover,  &:focus {
                border-color: $Ash;
            }
        }

        .Select-noresults {
            color: $DarkSlate;
        }

        .Select-menu-outer{
            border-radius: $border-radius;
            border-color: $Ash;
            background-color: $Salt;
            color: $DarkSlate;
        }

        .Select-option {
            color: $DarkSlate;
            background-color: $Salt;

            &.is-selected {
                background-color: $Ash;
            }

            &.is-focused {
                background-color: $Ash;
            }
        }
    }
}

.menu-item {
    border-right: none;
    background: $Salt;
    background: -moz-linear-gradient(top, $Salt 0%, #FCFCFC 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #FCFCFC));
    background: -webkit-linear-gradient(top, $Salt 0%, #FCFCFC 100%);
    background: -o-linear-gradient(top, $Salt 0%, #FCFCFC 100%);
    background: -ms-linear-gradient(top, $Salt 0%, #FCFCFC 100%);
    background: linear-gradient(to bottom, $Salt 0%, #FCFCFC 100%);
    cursor: pointer;
}

.review .user{
    font-size: 12px;
}

.component-container .loading{
    opacity: 0.3;
}

.custom-select__click-interceptor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9000;
}

.rr-purple{
    color: $RRPurple;
}

.accent-action{
    color: $PinkAction;
}

.custom-select__flyout {
    position: absolute;
    z-index: 9001;
}
//
//.fancyBackground{
//    min-height: 92vh;
//    background: rgba(228,243,250,1);
//    background: -moz-linear-gradient(-45deg, rgba(228,243,250,1) 0%, rgba(240,233,248,1) 100%);
//    background: -webkit-linear-gradient(-45deg, rgba(228,243,250,1) 0%, rgba(240,233,248,1) 100%);
//    background: -o-linear-gradient(-45deg, rgba(228,243,250,1) 0%, rgba(240,233,248,1) 100%);
//    background: -ms-linear-gradient(-45deg, rgba(228,243,250,1) 0%, rgba(240,233,248,1) 100%);
//    background: linear-gradient(135deg, rgba(228,243,250,1) 0%, rgba(240,233,248,1) 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4f3fa', endColorstr='#f0e9f8', GradientType=1 );
//}

.avatarList{
    overflow: auto;
    padding: 5px;
    margin: 0;

    li {
        float: left;

        .fa, .fal, .far, .fas{
            padding: 7px;
            color: $PinkAction;

            &:hover{
                color: $Coal;
            }
        }

        .delete{
            float: right;
            padding: 0;
            cursor: pointer;
        }
    }

    .avatar{
        margin: 2px;
    }
}

.inlineAvatar{
    position: relative;
    .delete{
        position: absolute;
        right: 0;

        &.i{
            color: $PinkAction;
            cursor: pointer;
            font-size: 1.4rem;

            &:hover{
                color: $Coal;
            }
        }
    }
}

.ui-helper-hidden-accessible { display:none; };

.videoThumbnail{
    .column{
        position: relative;
    }

    i{
        cursor: pointer;
        position: absolute;
        right: calc(50% - 12.5px);
        font-size: 25px;
        color: #ba57ff;

        &:hover{
            color: #6740e8;
        }
    }
}

.bigAvatar{
    .align-middle {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100px;
        display: flex;
        justify-content: center;
    }

    a{
        color: $PinkAction;
    }

    img.thumbnail {
        max-width: 190px;
        max-height: 190px;
        width: 100%;
        margin: 5px;
        line-height: 20px;

        &.small{
            max-width: 100px;
            max-height: 100px;
        }

        &.smallest{
            max-width: 90px;
            max-height: 90px;
        }
    }

    p.storeName{
        display: inline;
        color: $PinkAction;
        font-weight: 700;
        font-size: 1.4rem;
        word-break: break-all;

        &:hover{
            color: $Coal;
        }
    }

    p{
        max-width: 150px;
        white-space: pre-wrap;
        color: #444;
        font-size: 1.0rem;
    }
}

.xlarge-2 {
    @media(min-width: 100em) {
        width:16.667%;
    }
}

.componentDescription{
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.4vh;
}

.footer{
    a{
        color: $PinkAction;
    }
}

.categorySelector {
    .toggle{
        color: $PinkAction;
        font-size: 1.5rem;
    }

    .node.tree{
        label{
            vertical-align: text-bottom;
        }
    }

}

.reactRadioButtons{
    &.horizontal{
        .reactLabel{
            display: inline-block;
            margin-right: 20px;
        }
    }
}

.tag-cloud {
    flex: 1;
    font-size: 100%;

    .tag-cloud-item{
        color: #6740E8;
    }
}

.tag-cloud > div {
    transition: 1.4s;
}

.importantLinks{
    h2{
        margin-top:10px;
        color: $PinkAction;
    }
    a{
        display: block;
    }
    div.row{
        &:hover{
            h2,p{
                text-decoration: underline;
            }
        }
    }
    p{
        color: #444;
    }
}

.pastelIconContainer{
    width: 100%;
    display: block;
    text-align: center;
}

.pastelIcon{
    opacity: 0.8;
    &:hover{
        opacity: 1;
    }
}

.marketingLink{
    img{
        width: 25px;
        margin-right: 10px;
    }

    a.upgrade{
        margin-left: 20px;
        background: #6740E8;
        padding: 10px;
        border-radius: 30px;
        color: white;
        font-size: 12px;
        font-weight: normal;

        &:hover{
            color: white;
            text-decoration: underline;
        }

        i{
            font-size: 15px;
            color: white;
            margin-right: 5px;
        }
    }
}

.txt--Slate.card__header-subtext.card__header-padded.text.text--subtext {
    font-weight: 400 !important;
}