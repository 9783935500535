.fade-enter {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}
.fade-enter.fade-enter-active {
  opacity: 1;
}
.fade-leave {
  opacity: 1;
}
.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}