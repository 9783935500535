@font-face {
  font-family: 'cx-icon-font';
  src:url('#{$cx-media-folder}/icons/cxicons.eot');
  src:url('#{$cx-media-folder}/icons/cxicons.eot#iefix') format('embedded-opentype'),
    url('#{$cx-media-folder}/icons/cxicons.ttf') format('truetype'),
    url('#{$cx-media-folder}/icons/cxicons.woff') format('woff'),
    url('#{$cx-media-folder}/icons/cxicons.svg#cxicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova-Light';
  src: url('#{$cx-media-folder}/fonts/30F752_14_0.eot');
  src: url('#{$cx-media-folder}/fonts/30F752_14_0.eot?#iefix') format('embedded-opentype'),
    url('#{$cx-media-folder}/fonts/30F752_14_0.woff2') format('woff2'),
    url('#{$cx-media-folder}/fonts/30F752_14_0.woff') format('woff'),
    url('#{$cx-media-folder}/fonts/30F752_14_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('#{$cx-media-folder}/fonts/30F752_20_0.eot');
  src: url('#{$cx-media-folder}/fonts/30F752_20_0.eot?#iefix') format('embedded-opentype'),
    url('#{$cx-media-folder}/fonts/30F752_20_0.woff2') format('woff2'),
    url('#{$cx-media-folder}/fonts/30F752_20_0.woff') format('woff'),
    url('#{$cx-media-folder}/fonts/30F752_20_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('#{$cx-media-folder}/fonts/30F752_24_0.eot');
  src: url('#{$cx-media-folder}/fonts/30F752_24_0.eot?#iefix') format('embedded-opentype'),
    url('#{$cx-media-folder}/fonts/30F752_24_0.woff2') format('woff2'),
    url('#{$cx-media-folder}/fonts/30F752_24_0.woff') format('woff'),
    url('#{$cx-media-folder}/fonts/30F752_24_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('#{$cx-media-folder}/fonts/30F752_A_0.eot');
  src: url('#{$cx-media-folder}/fonts/30F752_A_0.eot?#iefix') format('embedded-opentype'),
    url('#{$cx-media-folder}/fonts/30F752_A_0.woff2') format('woff2'),
    url('#{$cx-media-folder}/fonts/30F752_A_0.woff') format('woff'),
    url('#{$cx-media-folder}/fonts/30F752_A_0.ttf') format('truetype');
}
