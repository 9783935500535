$menu-height: 40px;

.siteMenu{
    background-color: #e9ecf2;

    .reactMenu {
        height: $menu-height;
        width: 100%;
        background-color: #FFF;
        margin: 0;
        display: inline-block;
        z-index: 6;
        border-left: solid 1px #e7e7e2;

        .backButton{
            font-size: 18px;
            position: absolute;
            color: $Obsidian;
            left: 9px;
            bottom: 13px;
            cursor: pointer;
        }

        h4{
            margin: 0 0 0 3.5rem;
            padding: 0;
            color: #58595b;
            line-height: 2.9rem;
        }

        .react_menu_item{
            display: inline-block;

            a{
                height: $menu-height;
                text-align: center;
                font-size: 13px;
                font-weight: 600;

                &:hover, &:active, &:focus{
                  color: $PinkAction;
                }
            }

            &.react_menu_item_selected a{
                color: $PinkAction;
            }

            &.menuAction{
                padding-top: 0;
            }

            &.input_container{
                float: right;
                padding-top: 0;

                input{
                    width: 120px;
                    margin: 4px 3px 4px 0;
                    background-color: white;
                    color: #444;
                    text-align: center;

                }
            }

            &.error{
                input{
                    border: solid 1px $HalfDragonfruit;
                }
            }

        }


        .menuAction {
            float: right;
            height: $menu-height;

            .buttonContainer {
                border-left: 1px solid #DDD;
                color: $Slate;
                position: relative;

                :hover {
                    color: $RRMain;
                }

                .reactCustomSelectButtonIcon {
                    margin: 0;
                    padding: 3px 30px;
                    height: 39px;
                    background: none;
                    border: none;
                    font-size: 16px;
                    cursor: pointer;
                }

                .fa-download:before {
                    vertical-align: -70%;
                }
            }

           .actionMenu {
                top: 93px;
                right: 0;
                position: absolute;
                width: 400px;
                background-color: $Salt;
                box-shadow: 0 1px 1px;
                z-index: 9001;
                padding: 20px;

                input.button {
                    color: #fff;
                    font-size: 12px;
                    width: 80px;
                }

                input {
                    float: none;
                }

                div.validation-error input{
                    color: red;
                }

                div.validation-error {
                    position: relative;
                }

                div.validation-error:after {
                    color: red;
                    content: "\f00d";
                    font-family: 'Font Awesome 5 Pro';
                    position: absolute;
                    top: 20px;
                    right: 110px;
                    font-size: 20px;
                }

                a {
                    color: $RRMain;

                    :hover {
                       text-decoration: underline;
                    }
                }

                input#emailReceiverInput {
                    width: 225px;
                    text-align: left;
                }

                .report_email_complete_container {
                    text-align: center;

                    .icon_container {
                        color: green;
                        font-size: 80px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}