




.managementModule{
  h4{
    font-size: 1.4rem;
  }

  .panel{
    background: #F8F8F8;
    border-top: solid 1px #e6e6e6;
    margin: 0;
    padding: 15px 20px 0;
    overflow: auto;
  }

  .fullHeight{
    background-color: white;
  }

  .fa-eye-slash{
    margin-top: 2px;
    font-size: 16px;
    color: black;
  }

  .manageItemInputTA {
    max-height: 400px;
  }

  .headerTitle{
    margin-bottom: 0;
  }

  .manageContent [class*="column"] + [class*="column"]:last-child{
    text-align: right;
    position: relative;
  }

  .manageItemDetailsContainer{
    min-width: 300px;
    padding-top: 10px;

    a{
      color: $PinkAction;
      font-weight: 700;
      font-size: 1.2rem;
      label{
        cursor: pointer;
      }

      &:hover{
        text-decoration: underline;
      }
    }

    h5{
      font-weight: 700;
      padding-top: 12px;
    }

    .collapsible{

      h5, h5 span{
        font-weight: 400;
        padding-top: 0;
      }
    }

    .reactTagList{
      margin-bottom: 0;
      ul{
        overflow: auto;
        margin-bottom: 10px;
      }

      li{
        span{
          font-size: 1.0rem;
        }
      }
    }

    .itemDetail{
      i{
        margin-right: 10px;
        font-size: 1.2rem;
      }
    }
  }

  #manageItemDetails{
    float: right;

    label{
      margin-bottom: 8px;
    }

    h5{
      margin-top: -22px;
      font-size: 15px;
      font-weight: normal;
      color: #58595B;
    }

    h3.subheader{
      font-size: 15px;
    }
  }

  #inputControls {
    h4{
      font-size: 15px;
    }

    .labelContainer{
      height: 20px;
    }

    .panel div{
      margin-bottom: 12px;
    }

    p.displayError{
      color: red;
      font-size: 12px;
      margin-bottom: 2px;
    }

    textarea{
      background: white;
      padding: 10px;
    }

    .confirmReply textarea{
      margin-top: 10px;
      min-height: 60px;
    }

    .buttons{
      float: right;
      margin-top: 15px;
      margin-bottom: 0;

      input{
        margin-left: 10px;
      }
    }

    div a, div.labelContainer span{
      margin-right: 20px;
      color: #585955;
      font-size: 0.9rem;
      font-weight: bold;
    }

    div a:last-child{
      float: right;
      margin-right: -2px;
    }

    div a.active, div a:hover, div.labelContainer span{
      color: $PinkAction;
    }

    &.noteMode, &.flagMode{
      textarea{
        background: #EFF6FD;
      }
    }

    .toolTipContent{
      position: fixed;
    }
  }

  .inputControlsContainer{
    width: calc(100% - 492px);
    min-width: 490px;
    position: absolute;
    bottom: 94px;
    left: 0;
  }

  @media (max-height: 700px) {
    .inputControlsContainer{
      min-width: 490px;
    }
  }

  .manageContent {
    height: calc(100vh - 328px);
    margin-left: -12px;
    overflow-y: scroll;
    border-right: solid 1px #e7e7e2;
    border-left: solid 1px #e7e7e2;

    .breadcrumb{
      margin-right: 10px;
    }

    .actions{
      .fa, .fal, .far, .fas{
        margin: 5px;
      }
    }

    &.flagMode{
      height: calc(100vh - 405px);
    }

    .managedItem{
      padding-bottom: 30px;
      border-bottom: solid 1px #E7E7E2;

      .comment{
        .stars{
          margin-top: 5px;
        }
      }

      .user{
        margin-top: 5px;
        font-size: 1.1rem;
        text-align: center;
        word-break: break-all;
      }

      .email{
        word-break: break-all;
        text-align: center;
      }
    }

    .avatar{
      display: block;
      .clip-circle{
        float: none;
      }
    }
  }

  .commentAdmin h5{
    font-weight: bold;
  }

  #manageMessages{
    font-size: 13px;

    p{
      font-size: 1.2rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    span{
      color: #666;
    }

    .row{
      margin-left: 0;
      width: 100%;
      max-width: 100%;
    }

    .note{
      padding-left: 5vw;
      background: #EFF6FD;

      i.fa-times-circle{
        right: -20px;
        top: -13px;
        font-size: 14px;
      }
    }

    .review_request{
      background: #e6e6e6;
      padding: 10px 0;
    }

    .notification{
      background: #cefccc;

      .avatar{
        background: url("/static/images/faces_status.png") no-repeat;
        width: 71px;
        height: 63px;
        float: left;
        display: block;

        &.check{
          background-position-x: 0;
        }
        &.ellipsis-h{
          background-position-x: -100px;
        }
        &.ban{
          background-position-x: -204px;
        }
      }

    }

    .note i, .reply i, .flag i{
      width: 15px;
      height: 15px;
      margin-right: 10px;
      position: absolute;
      right: 0;

      &.arrow{
        top: 4px;
      }
    }

    .row{
      .fa-calendar{
        position: relative;
      }

      .content{
        p{
          margin-bottom: 10px;
        }
      }

      &.customerReply{
        background: #D2E0F1;
      }

      &.flag{
        background: #FCEBF2;

        &.corrected, &.removed, &.valid{
          background: #F3F3F5;
        }

        .content{
          margin-top: 5px;

          .name{
            font-weight: normal;
          }
        }

        .reactToolTip{
          span{
            font-size: 15px;
            color: $SoftBlack;
            text-decoration: underline;

            &::after{
              content: ",";
            }
          }

          &:last-child{
            span{
              &::after{
                content: "";
              }
            }
          }
        }
      }

      &:last-child{
        border-bottom: none;
      }
    }

    .sender, .sentDate{
      margin-bottom: 0;
    }

    .note.delete .button{
      float: right;
      margin-left: 25px;
    }

    .sender, .sentTime {
      font-size: 12px;
    }

    .sender{
      color: $DarkerPurple;
      font-size: 18px;
    }

    .header{
      font-size: 16px;
    }
  }

  .triage.leftPanel{
    .manageContent{
      height: calc(100vh - 50px);
    }
  }
}

