.modules_library-site-search-bar {
  .modules_library-site-search-bar-input {
    z-index: 1000;
    right: 320px;
    position: absolute;
    top: 29px;
    width: calc(70vw - 1025px);
    min-width: 300px;
    @media (min-width: 1024px) and (max-width: 1390px) {
      display: none;
    }
    @media (max-width: 1023px) {
      position: fixed;
      left: 50px;
      min-width: 200px;
      top: 8px;
      width: calc(97vw - 100px);
    }
  }
  &.on-home {
    .modules_library-site-search-bar-input {
      .textInput {
        border-radius: 100px;
        border: 2px solid #6157f3;
      }
    }
    @media (min-width: 1023px) {
      .modules_library-site-search-bar-input {
        left: 27%;
        top: 336px;
        width: 50%;
        .fa-search {
          top: 24px;
        }
        .textInput {
          height: 4em;
        }
      }
      .modules_library-site-search-bar-suggest {
        position: absolute;
        left: 27%;
        top: 399px;
        width: 50%;
      }
      .modules_library-site-search-bar-filter {
        display: none;
      }
    }
    @media (min-width: 1715px) {

      .modules_library-site-search-bar-input {
        top: 336px;
      }    
      .modules_library-site-search-bar-suggest {
        top: 399px;
      }
    }
  }
}
