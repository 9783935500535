.card-button {
  cursor: pointer;

  &.selected{
    border: solid 1px $PinkAction;
  }

  &__default {
    display: inline-block;
    &:hover{
      cursor: pointer;
      -webkit-box-shadow: 0 0rem 3rem 0rem #d2d3d4;
      box-shadow: 0 0rem 3rem 0rem #d2d3d4;
      border-radius: 15px;
    }
  }

  &__add-content {
    padding: 2rem;
    text-align: center;
    color: $Ash;
    border-radius: 0.3rem;
    border: 0.4rem solid $Ash;
    border-style: dashed;
    display: table-cell;
    vertical-align: middle;
    box-shadow: none;

    &:hover {
      background: $HalfQuartzSolid;
    }

    &--plus {
      font-size: 9rem;
      font-weight: 600;
    }
  }
}