.data-card{
  border-radius: $border-radius;
  margin-bottom: 1rem;
  background: #e7e7e7;

  &--relative{
    position: relative;
  }

  @include transition(all, 250ms);
  @include shadow(1);

  &--padded {
    padding: 2rem 2rem;
  }

  &--gradient {
    background: linear-gradient(to right, rgb(186, 87, 255), rgb(103, 64, 232));
    .text{
      color: white !important;
    }
  }

  &__content{
    color: #71737E;
    display: flex;
    align-items: center;
    .text:first-child{
      width: 100%;
    }
    .text:last-child{
      min-width: 20px;
    }
  }

  &--upgrade{
    .text:first-child {
      width: 90%;
    }
    .text:last-child{
      min-width: 120px;
      text-align: right;
    }
    .purple-gradient:hover {
      background-image: -webkit-gradient(linear, left top, right top, from(#a321dd), to(#c600ea));
      background-image: linear-gradient(to right, #a321dd, #c600ea, #c600ea, #a321dd);
    }
  }

  &--progress{
    .text{
      width: 75%;
    }
    div:last-child {
      width: 100px;
      text-align: right;
      display:block;
    }
  }

  &--small {
    width: 20rem;
    height: 22.5rem;
  }

  &--medium {
    width: 41rem;
    height: 46rem;
  }

  &--large {
    width: 62rem;
    height: 46rem;
  }

  &--full {
    width: 100%;
    max-width: 125rem;
    height: 46rem;
  }

  &:hover {
     box-shadow:  0 0.050rem 0.4rem 0.14rem #bfc3c866;
    transition: all 0.1s ease-in;
    max-height:auto;
    z-index: 1;
     @include transform(translateY(rem(-2)));
  }

  &--focused, &--focused:hover {
     box-shadow:  0 rem(3) rem(20) rem(5) #BFC3C8;
    transition: all 0.1s ease-in;
    max-height:auto;
    z-index: 1;
  }

  &--shadow-lvl-1 {
    box-shadow: 0 rem(1) rem(3) $Ash, 0 rem(1) rem(2) $Ash;
    @include transition(all, 0.1s, ease-in);
    max-height:auto;
  }
  &--shadow-lvl-2 {
    box-shadow: 0 rem(2) rem(6) $Ash, 0 rem(2) rem(4) $Ash;
    @include transition(all, 0.1s, ease-in);
    max-height: auto;
  }
  &--shadow-lvl-3 {
    box-shadow: 0 rem(3) rem(9) $Ash, 0 rem(3) rem(6) $Ash;
    @include transition(all, 0.1s, ease-in);
    max-height: auto;
  }
}
