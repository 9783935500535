.reviewReply, .replyBack, .questionReply{
    padding-top: 15px;
    padding-bottom: 10px;

    h5{
        color: $RRMain;
        font-size: 0.8rem;
        margin-top: 5px;
        text-align: center;
        word-wrap: break-word;

        strong{
            word-wrap: break-word;
        }

        &.errorMessage{
            text-align: left;
        }
    }

    .reviewReplyContent{
        margin-top: 15px;
    }
}
