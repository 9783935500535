.top-bar, .top-bar ul {
  background-color: $Salt !important;
}
.top-bar{
  .top-bar-left{
    flex: 0 1 auto;
  }
  .top-bar-right{
    flex: 0 1 auto;
    margin-left: auto;
  }
}
li{
  list-style: none;
}
.off-canvas-wrapper {
  .title-bar {
    width: 100%;
    margin-top: 0;
    position: fixed;
    left: 0;
    z-index: 50;
    background-color: $Salt;

    .menu-icon-wrapper {
      padding: 5px;
      width: 40px;
      margin-left: auto;
      background: black;
      .menu-icon::after {
        color: white;
        box-shadow: 0 7px 0 white, 0 14px 0 white;
        background: white;
      }
    }
  }
  .off-canvas{
    position: fixed;
    z-index: 12;
    transition: transform 0.5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    &.position-top {
      top: 0;
      left: 0;
      width: 100%;
      height: 360px;
      overflow-x: auto;
      -ms-transform: translateY(-360px);
      transform: translateY(-360px);
    }
    &.is-open{
      -ms-transform: translate(0, 50px);
      transform: translate(0, 50px);
    }
    &.position-top.is-transition-push {
      box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }
    &.position-top.is-open {
      z-index: 15;
      width: 100%;
    }
    .is-accordion-submenu-parent>a {
      &:after{
        border-color: #0c0c0c transparent transparent;
      }
    }
    &.foundation_site_nav_mobile{
      > div > ul > ul {
        margin: 10px;
      }
      > div > ul > li {
        margin: 10px;
      }
      .menu.vertical {
        background: #fff;
      }
      padding-left: 10px;
      padding-right: 10px;
      .social{
        display: inline-block;
      }
    }
    background-color: $Salt;
    ul{
      li{
        ul{
          li{
            a{
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        h4{
          color: #0c0c0c;
          font-size: 17px;
          font-weight: 300;
          margin-bottom: 10px;
        }
      }
    }
  }

  #menu_avatar_dropdown {
    & > a {
      padding-right: 0;
      &::after {
        content: none;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    #foundation_site_header{
      .menu.vertical {
        background: #fff;
      }
      li {
        a {
          color: white;
        }

        ul a {
          color: white;
        }
      }
      &.solutions-menu li{
        a{
          font-weight: bold;
          color: $Slate;
        }
        ul a{
          font-weight: bold;
          color: $Slate;
        }
      }
    }

  }

  .top-bar-left{
    #topBarLogo{
      margin-bottom: 0;
    }
  }

  #foundation_site_header{
    #demoButton{
      display: inline-block;
      text-align: center;
      line-height: 1;
      cursor: pointer;
      -webkit-appearance: none;
      transition: background-color .55s ease-out,color .25s ease-out;
      vertical-align: middle;
      border: 2px solid #f1eff1;
      border-radius: 3em;
      font-size: .9rem;
      background-color: #b32962;
      color: #fefefe;
    }
    @media screen and (min-width: 1024px) {
      .separator_top{
        border-top: 1px solid;
        border-color: lightgrey transparent transparent;
      }
    }
    #demoButton:hover{
      background-color: #79143e;
    }
    li > i{
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
    }
    li > i > a{
      padding-left: 15px;
    }
    a{
      font-size: 14px;
      color: $Slate;
      font-family: 'Lato',sans-serif;
    }
    .dropdown.menu>li.is-dropdown-submenu-parent>a::after {
      border-color: $Slate transparent transparent !important;
    }
  }

  .foundation_site_nav {
    font-family: 'Lato', sans-serif;
    padding-top: 6px;
    padding-right: .7rem;
    li {
      a {
        color: #40454f;
        font-weight: normal;

      }

      &#careDropdown{
        a:after{
          display: none;
        }
      }

      &.user-buttons{
        a {
          &:hover {
            background: none;
            color: #627085;
            text-decoration: underline;
          }
        }
      }

      & > a {
        font-size: 14px;
        font-weight: normal;
      }

      &.is-dropdown-submenu-parent {
        > a {
          &::after {
            border-color: $Slate transparent transparent !important;
          }
        }

        & > ul.is-dropdown-submenu {
          opacity: 0;
          &.js-dropdown-active{
            opacity: 1;
            animation-name: fadeInOpacity;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: .1s;

            @keyframes fadeInOpacity {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          }
          top: 85%;
          left: 0;
          li {
            a {
              font-weight: 400;
              h4{
                font-size: 17px;
                font-weight: 300;
                margin-bottom: 10px;
                color: $Slate;

              }
              p{
                font-size: 16px;
              }

              &:hover{
                h4{
                  color:$Salt;
                }
              }
            }

            &.separator_top {
              border-top: 1px solid $Slate;
            }

            &.dd_menu_button {
              a {
                color: $Salt;
                background-color: $RRMain;

                &:hover {
                  text-shadow: 0 0 1px $Salt;
                }
              }
            }
          }
        }
      }

      &.accountNotActive {
        a {
          color: $Salt;
          background-color: #FF9800;

          &:hover {
            text-shadow: 0 0 1px $Salt;
          }
        }
      }
    }
  }
  .site-button-wrapper{
    border-bottom: none !important;
    padding: 0 5px;
    .book-a-demo{
      color: #b000d3;
      border: 4px solid #3d243c;
      border-radius: 14px;
      font-weight: 700;
      font-size: 16px;
      padding: 20px;
    }
    .for-companies-button{
      color: #fff;
      background-color: #120a2c;
      border-radius: 0;
      padding: 20px;
      transition-property: all;
      transition: background-color .2s;
      &:hover{
        color: #0f1f3d;
        background-color: rgba(255, 255, 255, .98);
      }
    }
    .site-button{
      font-weight: 700;
      background-image: -webkit-gradient(linear, left top, right top, from(#a321dd), to(#c600ea));
      background-image: linear-gradient(to right, #a321dd, #c600ea, #c600ea, #a321dd);
      background-size: 300% 100%;
      display: inline-block;
      color: #fff;
      padding: 15px 24px;
      border-radius: 1000px;
      font-size: .85rem;
      letter-spacing: .25px;
      white-space: nowrap;
      -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
      box-shadow: 0 2px 4px rgba(0,0,0,0.08), 0 4px 8px rgba(0,234,182,0.08);
      text-transform: uppercase;
      moz-transition: all .4s ease-in-out;
      -o-transition: all .4s ease-in-out;
      -webkit-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;

      &:hover{
        background-position: 100% 0;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
      }
    }
  }
}