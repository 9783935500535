.toggle-button {
  position: relative;
  width: rem(40);
  height: rem(20);
  border-radius: rem(100);
  background-color: $HalfAsh;
  overflow: hidden;
  box-shadow: inset 0 0 rem(2) rem(1) rgba(0,0,0,.05);

  div {
    display: block;
  }
  
  &__container {
    display: inline-block;
  }
  
  &--disabled {
    opacity: .3;
  }
  
  &--inverse {
    background-color: $Slate;
  }
  
  &__label {
    float: left;
    margin-right: 1rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $Coal;
    
    &.bold, &.semibold {
      color: $Obsidian;
    }

    &.colored-Label{
      color: $PinkAction;
    }

    &.dark-colored-Label{
      color: $DarkPinkAction;
    }
  }

  &__text {
    font-family: $f-app-font-bold;
    font-size: 1.3rem;
    line-height: 2.3rem;
    color: $Ash;
    
    &--inverse {
      &.toggle-button__text--checked {
        color: $Slate;
      }
    }
    
    &--checked {
      color: $PinkAction;
    }
    
    &--left {
      margin-right: 1rem;
    }
    
    &--right {
      margin-left: 1rem;
    }
  }
  
  &__check {
    position: absolute;
    display: block !important;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
    
    &:checked ~ .toggle-button__track {
      box-shadow: inset 0 0 0 rem(20) $PinkAction;
    }
    
    &:checked ~ .toggle-button__switch {
      right: rem(2);
      left: rem(20);
      transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
      transition-property: left, right;
      transition-delay: .05s, 0s;
    }
  }
  
  &__switch {
    position: absolute;
    left: rem(3);
    top: rem(2);
    bottom: rem(2);
    right: rem(20);
    width: rem(17);
    background-color: #fff;
    border-radius: rem(36);
    z-index: 1;
    transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
    transition-property: left, right;
    transition-delay: 0s, .05s;
    box-shadow: 0 rem(1) rem(2) rgba(0,0,0,.2);
  }
  
  &__track {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
    box-shadow: inset 0 0 0 rem(2) rgba(0,0,0,.05);
    border-radius: rem(40);
    
    &--has-text {
      box-shadow: inset 0 0 0 rem(20) $PinkAction;
    }
    
    &--inverse {
      &.toggle-button__track--has-text {
        box-shadow: inset 0 0 0 rem(20) $Slate !important;
      }
    }
  }


}
