.policy{
  .policy-grid{
    .policy-text{
      font-size: .75em;
    }
    .not-policy{
      opacity: 40%;
      color: grey;
      img{
        filter: grayscale(100%);
      }
    }
    .has-policy{
      h6 {
        color: black;
        font-weight: bold;
      }
    }
  }
  .border{
    display: block;
    position: relative;
    border-bottom: 2px solid rgb(241, 241, 241);
    top: 27px;
    z-index: -1;
  }
  .policy-title{
    display: inline-block;
    background: #e6e6e6;
    color: #363636;
    padding: 10px;
    text-align: center;
    font-weight: bold;
  }
}