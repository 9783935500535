/*===========================================
=            Major layout styles            =
===========================================*/

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

hr{
  border: none;
  border-top: rem(1) solid $Quartz;
  margin: 2rem 0;
}

/*
    Layout for some app hangars.
    These elements are needed for the app to hook into
    and should be considered superflous to the actual page
    but they do need to have height and floats for the nested
    frame containers to work.
*/

.sidebar {
  height: 100%;
  float: left;
  .sidebarButtonContainer {
    padding: 1em;
    padding-right: 1.5em;
  }
}

.content {
  height: 100%;
}

.ae-container {
  padding-right:rem(40);
}

.ae-ui [class^=ae-toolbar-].ae-toolbar-transition {
  z-index: 11 !important;
}  

/*

    Layout for framing up the page.
    The grid will nest into these elements.

*/

.frame__row,
.frame__col {
  overflow: hidden;
  position: absolute;
}

.frame__row {
  left: 0;
  right: 0;
}

.frame__col {
  top: 0;
  bottom: 0;
}

.frame__scroll-x {
  overflow-x: auto;
}

.frame__scroll-y {
  overflow-y: auto;
}

.frame__head {
  top: 0;
  height: rem(50);
  @include breakpoint(portable) {
    height: auto;
  }
  overflow: visible;
  z-index: 3;
}

.frame__body {
  top: rem(50);
  bottom: 0;
  z-index: 1;
  background: $HalfQuartzSolid;
}

.frame__subnav{
  top: rem(50);
  bottom: 0;

  + .frame__body{
    top: rem(100);
  }
}

.frame__body--login {
  //background: $Jellyfish url('../img/login-bg-1080.jpg') top center no-repeat;
  top:0;
  background-size:cover;
}

.frame__body--chart {
  top: rem(0);
  bottom: 0;
  z-index: 1;
  background: #FFF;
}


.frame__body--fb-admin {
  top: 0;
  .insights__header {
      position: fixed;
      width: 100%;
      z-index: 10;
  }
}

.frame__flex {
  height: 100%;
  max-height: 100%;
  float: left;
  &--main {
    float: none;
    background: $HalfQuartzSolid;
  }
}

.grid {
  @extend %clearfix;
  position: relative;
}

.nonfluid-container {
  max-width: rem(1256);
  margin: 0 auto;
  height: 100%;
}

.container {
  height: 100%;
  position: relative;
  padding: $grid-padding;
  &--dragonfruit {
    color: $Dragonfruit;
    margin-right: rem(20);
  }
  &--tangerine {
    color: $Kiwi;
    margin: rem(20);
  }
}

.sticky{
 .container {
    margin-top: rem(35);
  }
}

.flex-panel {
  height: 100%;
  position: relative;
  display: table-cell;
  vertical-align: top;
  overflow: hidden;
  .container {
    display: block;
  }
}

.frame__flex-panel {
  position: relative;
  background: $c-flex-panel-bg;
  height: 100%;
  overflow: hidden;
  display: block;
  min-width: rem(200);
  max-width:rem(260);
  
  &--no-bg {
    background: none;
  }
  
  //padding-right: $flex-panel-resizer-width;
  .container {
    padding: 0 2em 0 $grid-padding;
    &--no-pad {
      padding: 0;
    }
  }
  &__resizer {
    // @include linear-gradient(90deg, $c-flex-panel-resizer-grad-top, $c-flex-panel-resizer-grad-bottom);
    // box-shadow: inset 0 1px 0 $c-flex-panel-resizer-grad-bottom, 0 2px 2px rgba(0, 0, 0, 0.4);
    background: $c-flex-panel-resizer;
    width: $flex-panel-resizer-width;
    height: 100%;
    top: 0;
    right: 0;
    cursor: col-resize;
    position: absolute;
    z-index: 300;
    &:before {
      content: '• • •';
      color: #999;
      width: rem(5);
      position: absolute;
      top: 50%;
      margin-top: rem(-15);
      line-height: 0.6;
      left: rem(1);
    }
  }
  &--closed {
    min-width: 0;
    width: rem(45) !important;
    height: 100%;
    background: darken($c-flex-panel-bg, 10%);
    z-index: 1;
    box-shadow: rem(2) 0 rem(18) 0 rgba(0, 0, 0, 0.75);
    @include transition;
    .frame__flex-panel__resizer {
      display: none;
    }
  }
  &--inverse{
    background: $Salt;
    border-right: rem(1) solid $Ash;
  }
}

.panel-loading {
  width: 100%;
  text-align: center;
  height: 100%;
  padding: rem(20);
  .panel-loading__text {
    font-size: rem(16);
    margin: auto;
    background: #fff;
    display: inline-block;
    padding: 1em;
    border-radius: 10em;
    color: #959BA3;
    line-height: 0;
    .fa-spin {
      width: 1em;
      height: 1em;
    }
  }
}